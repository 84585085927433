import { Fields, Row } from '@web/apps/Design/features/WiringList/types.ts';
import {
  getConnectionStateFromConnection,
  isConnectionStateEmpty,
} from '@web/apps/Design/features/WiringList/utils/state.ts';
import { ResolvedConnection, ResolvedConnectionPoint, UUID } from '@web/apps/types';
import { useCallback, useEffect } from 'react';

import { useConnections } from '../../../../hooks/useConnections.tsx';
import { useDesign } from '../../../../hooks/useDesign.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';

export const useWiringListRowActions = (
  row: Row,
  connectionPoint: ResolvedConnectionPoint,
  connection: ResolvedConnection | null,
  state: Fields,
  isDirty: boolean,
  resetState: (newState?: Fields) => void,
) => {
  const { designId } = useDesign();
  const { createConnection, updateConnection, deleteConnection } = useConnections();
  const { resetCrimpSplice, setRowPendingChange } = useWiringList();

  useEffect(() => {
    const isPending = createConnection.isPending || updateConnection.isPending || deleteConnection.isPending;
    setRowPendingChange(isPending ? row : null);
  }, [createConnection.isPending, updateConnection.isPending, deleteConnection.isPending, setRowPendingChange, row]);

  const getConnectionData = useCallback(
    () => ({
      sourceId: connectionPoint.id,
      destinationId: state.destinationId,
      conductorId: state.conductorId,
      signalName: state.signalName,
      sourceContactId: state.sourceContactId,
      destinationContactId: state.destinationContactId,
    }),
    [connectionPoint, state],
  );

  const handleCreateConnection = useCallback(async () => {
    const updatedState = await createConnection.mutateAsync({ designId, data: getConnectionData() });
    resetState(getConnectionStateFromConnection(updatedState));
  }, [getConnectionData, createConnection, designId, resetState]);

  const handleDeleteConnection = useCallback(
    async (connectionId: UUID) => await deleteConnection.mutateAsync({ designId, connectionId }),
    [deleteConnection, designId],
  );

  const handleUpdateConnection = useCallback(
    async (connectionId: UUID) => {
      const updatedState = await updateConnection.mutateAsync({ designId, connectionId, data: getConnectionData() });
      resetState(getConnectionStateFromConnection(updatedState));
    },
    [getConnectionData, updateConnection, designId, resetState],
  );

  const saveConnection = useCallback(async () => {
    if (!isDirty) return;

    if (connection) {
      if (isConnectionStateEmpty(state)) {
        await handleDeleteConnection(connection.id);
      } else {
        await handleUpdateConnection(connection.id);
      }
    } else {
      await handleCreateConnection();
    }

    resetCrimpSplice();
  }, [
    connection,
    handleCreateConnection,
    handleDeleteConnection,
    handleUpdateConnection,
    isDirty,
    resetCrimpSplice,
    state,
  ]);

  const clearConnection = async () => {
    if (connection) {
      await handleDeleteConnection(connection.id);
    } else {
      resetCrimpSplice();
    }
  };

  return {
    saveConnection,
    clearConnection,
  };
};
