import { NotableType, UUID } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';

import { getTargetDirection } from '../../../Layout/utils/geometry';
import { defaultDirection, getTargetNodeDirection } from '../../../Layout/utils/getDirection';
import { RelationalSegmentEdgeType } from '../../components/edges/SegmentEdge/RelationalSegmentEdge';

export const useCalculateNoteTargetDirection = (
  sourceNodeId: UUID,
  notableType: NotableType,
  notableId: UUID,
  notableEdgeId: UUID | undefined,
) => {
  const { getNode, getEdge } = useReactFlow();

  const sourceNode = getNode(sourceNodeId);
  const targetNode = getNode(notableId);
  const targetEdge = notableEdgeId ? (getEdge(notableEdgeId) as RelationalSegmentEdgeType) : undefined;

  if (!sourceNode) {
    return defaultDirection;
  }

  if (notableType === NotableType.LAYOUT_NODE && targetNode) {
    return getTargetNodeDirection(sourceNode, targetNode);
  }

  if (notableType === NotableType.CONTROL_POINT && targetEdge) {
    const controlPoint = targetEdge.data?.controlPoints.find((controlPoint) => controlPoint.id === notableId);
    if (controlPoint) {
      return getTargetDirection(sourceNode.position, { x: controlPoint.x, y: controlPoint.y });
    }
  }

  return defaultDirection;
};
