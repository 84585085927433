import { useCreateDesignPartLayoutEdgeMutation } from '@web/apps/Design/api/design-part-layout-edges-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { generateDesignPartNameFromPart } from '@web/apps/Design/utils/generateDesignPartName.ts';
import { Part, PartType, UUID } from '@web/apps/types';
import { useCallback } from 'react';

import AddIconButton from '../../../../components/AddIconButton';
import { useDesignParts } from '../../../../hooks/useDesignParts';
import { usePartSearchModal } from '../../../../hooks/usePartSearchModal.tsx';

export const useRelationalLabelActions = (layoutEdgeId: UUID, closeMenu: () => void) => {
  const { openModal, closeModal } = usePartSearchModal();
  const designId = useDesignId();
  const { designParts = [] } = useDesignParts();

  const { mutateAsync: createDesignPartLayoutEdge } = useCreateDesignPartLayoutEdgeMutation();

  const addEdgePart = useCallback(
    (part: Part) => {
      createDesignPartLayoutEdge({
        designId,
        layoutEdgeId,
        partId: part.id,
        name: generateDesignPartNameFromPart(designParts, part),
      });
      closeModal();
    },
    [closeModal, layoutEdgeId, designId, createDesignPartLayoutEdge, designParts],
  );

  const handleAddLabel = useCallback(() => {
    openModal({
      initialPartTypeFilters: [PartType.LABEL],
      filtersDisabled: true,
      renderSearchResultAction: (part: Part) => (
        <AddIconButton
          aria-label="Add label"
          onClick={() => addEdgePart(part)}
          bg="gray.300"
          _hover={{ color: 'white', bg: 'blue.500' }}
        />
      ),
    });
    closeMenu();
  }, [openModal, addEdgePart, closeMenu]);

  return { handleAddLabel };
};
