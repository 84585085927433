import { StackProps, VStack } from '@chakra-ui/react';
import { Schematic } from '@web/apps/types';
import { Fragment } from 'react';

import { defaultSchematicConfig } from '../../../config.ts';
import { isLoopback } from '../../../utils/helpers.ts';
import { hasShieldSplices } from '../../../utils/inlines.ts';
import { Cavity } from './Cavity.tsx';

export interface CavitiesProps extends StackProps {
  destinationGroup: Schematic.Destination;
  type: 'source' | 'destination';
}

export const Cavities = ({ destinationGroup, type, ...stackProps }: CavitiesProps) => {
  return (
    <VStack bg={defaultSchematicConfig.connectorColor} spacing={0} borderRadius="md" py={2} {...stackProps}>
      {destinationGroup.paths.map((path, index) => {
        const point = type === 'source' ? path.source : path.destination;
        const loopbackPoint = isLoopback(path) ? (type === 'source' ? path.destination : path.source) : null;

        return (
          <Fragment key={`${type}-cavity-${index}`}>
            {hasShieldSplices(path) && <Cavity type="empty" />}
            <Cavity type={type} point={point} path={path} />
            {loopbackPoint && <Cavity type={type} point={loopbackPoint} path={path} />}
          </Fragment>
        );
      })}
    </VStack>
  );
};
