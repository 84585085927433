import { BOMItem, DesignPart, UUID } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { compact, keyBy, uniq } from 'lodash';
import { useMemo } from 'react';

import { useBOM } from '../../../../hooks/useBOM.tsx';
import { useDesignId } from '../../../../hooks/useDesignId.tsx';
import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';
import { isDesignPartNode, isSegmentEdge } from '../../types.ts';

// If the part exists, adds the part's part number to the array
const addPartNumber = (partNumbers: string[], part: DesignPart | undefined) => {
  if (part) {
    partNumbers.push(part.partData.partNumber);
  }
};

// Given a design part, returns all the part numbers on this part, including backshells and contacts.
const getDesignPartNumbers = (designPart: DesignPart | undefined): string[] => {
  const partNumbers: string[] = [];
  if (designPart) {
    const { backshell, contacts } = designPart;

    addPartNumber(partNumbers, designPart);
    addPartNumber(partNumbers, backshell);
    contacts?.forEach((contact) => addPartNumber(partNumbers, contact));
  }

  return uniq(partNumbers);
};

// Given an array of part numbers and a map from part number to bom item, return the bom items that exist for the part numbers
const getBomItemsForPartNumbers = (partNumbers: string[], bomByPartNumber: { [partNumber: string]: BOMItem }) => {
  const bomItems = partNumbers.map((partNumber) => bomByPartNumber[partNumber]);
  return compact(bomItems);
};

export const useLayoutElementBomItems = (layoutElementId?: UUID): BOMItem[] => {
  const designId = useDesignId();
  const { getDesignPartById } = useDesignParts();
  const { getNode, getEdge } = useReactFlow();

  const { bom } = useBOM(designId);
  const bomByPartNumber: { [key: string]: BOMItem } = useMemo(() => keyBy(bom, 'partNumber'), [bom]);

  const node = layoutElementId ? getNode(layoutElementId) : null;
  const edge = layoutElementId ? getEdge(layoutElementId) : null;

  return useMemo(() => {
    if (node && isDesignPartNode(node)) {
      const designPartId = node.data.designPartId;
      const designPart = getDesignPartById(designPartId);
      const partNumbers = getDesignPartNumbers(designPart);

      return getBomItemsForPartNumbers(partNumbers, bomByPartNumber);
    } else if (edge && isSegmentEdge(edge)) {
      return getBomItemsForPartNumbers(edge.data.overwraps, bomByPartNumber);
    }

    return [];
  }, [bomByPartNumber, getDesignPartById, node, edge]);
};
