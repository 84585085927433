import { IconButtonProps, SelectProps } from '@chakra-ui/react';
import { DesignPart, isConnectorDesignPart, Part } from '@web/apps/types';
import { useMemo } from 'react';

import { useDesignParts } from '../../../../hooks/useDesignParts.tsx';
import { AddAccessoryButtonContent } from './AddAccessoryButtonContent.tsx';

interface Props {
  part: Part;
  connectorPart?: DesignPart;
  selectProps?: SelectProps;
  buttonProps?: IconButtonProps;
  visibleIfDraftPart?: boolean;
}

export const AddAccessoryButton = ({ part, connectorPart, selectProps, buttonProps, visibleIfDraftPart }: Props) => {
  const { designParts } = useDesignParts();
  const connectors = useMemo(() => designParts.filter(isConnectorDesignPart), [designParts]);

  if (connectors.length > 0) {
    return (
      <AddAccessoryButtonContent
        part={part}
        connectors={connectors}
        connectorPart={connectorPart}
        selectProps={selectProps}
        buttonProps={buttonProps}
        visibleIfDraftPart={visibleIfDraftPart}
      />
    );
  }

  return null;
};
