import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { DesignPartLayoutEdge, UUID } from '@web/apps/types';
import { doRequest, getUrl } from '@web/common/api';

import { useDesignToast } from '../hooks/useDesignToast';
import { designKeys } from './query-keys';
import { useInvalidateQueries } from './react-query-helpers';

type CreateDesignPartLayoutEdgeParams = {
  designId: UUID;
  layoutEdgeId: UUID;
  partId: UUID;
  name?: string;
};

const createDesignPartLayoutEdge = async ({
  designId,
  layoutEdgeId,
  partId,
  name,
}: CreateDesignPartLayoutEdgeParams) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_part_layout_edges`);
  return await doRequest<DesignPartLayoutEdge>(
    'POST',
    url,
    JSON.stringify({
      designPartLayoutEdge: { layoutEdgeId },
      partId,
      name,
    }),
  );
};

type UpdateDesignPartLayoutEdgeParams = {
  designId: UUID;
  designPartLayoutEdgeId: UUID;
  data: Partial<DesignPartLayoutEdge>;
};

const updateDesignPartLayoutEdge = async ({
  designId,
  designPartLayoutEdgeId,
  data,
}: UpdateDesignPartLayoutEdgeParams) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_part_layout_edges/${designPartLayoutEdgeId}`);
  return await doRequest<DesignPartLayoutEdge>('PUT', url, JSON.stringify({ designPartLayoutEdge: data }));
};

type DeleteDesignPartLayoutEdgeParams = {
  designId: UUID;
  designPartLayoutEdgeId: UUID;
};

const deleteDesignPartLayoutEdge = async ({ designId, designPartLayoutEdgeId }: DeleteDesignPartLayoutEdgeParams) => {
  const url = getUrl(`/api/v1/designs/${designId}/design_part_layout_edges/${designPartLayoutEdgeId}`);
  return await doRequest<DesignPartLayoutEdge>('DELETE', url);
};

export const useCreateDesignPartLayoutEdgeMutation = (): UseMutationResult<
  DesignPartLayoutEdge | null,
  Error,
  CreateDesignPartLayoutEdgeParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-design-part-layout-edge'],
    mutationFn: async (params: CreateDesignPartLayoutEdgeParams) => {
      const createdEdge = await createDesignPartLayoutEdge(params);
      return createdEdge ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error creating design part layout edge', error.message),
  });
};

export const useUpdateDesignPartLayoutEdgeMutation = (): UseMutationResult<
  DesignPartLayoutEdge | null,
  Error,
  UpdateDesignPartLayoutEdgeParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-design-part-layout-edge'],
    mutationFn: async (params: UpdateDesignPartLayoutEdgeParams) => {
      const updatedEdge = await updateDesignPartLayoutEdge(params);
      return updatedEdge ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error updating design part layout edge', error.message),
  });
};

export const useDeleteDesignPartLayoutEdgeMutation = (): UseMutationResult<
  void,
  Error,
  DeleteDesignPartLayoutEdgeParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-design-part-layout-edge'],
    mutationFn: async (params: DeleteDesignPartLayoutEdgeParams) => {
      await deleteDesignPartLayoutEdge(params);
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error deleting design part layout edge', error.message),
  });
};
