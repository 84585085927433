import * as Sentry from '@sentry/react';
import BOM from '@web/apps/Design/features/BOM';
import Cutlist from '@web/apps/Design/features/Cutlist';
import WiringList from '@web/apps/Design/features/WiringList';
import { isTechnician } from '@web/common/util.ts';
import ErrorPage from '@web/components/errors/ErrorPage';
import NotFound from '@web/components/errors/NotFound';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import { ReactFlowProvider } from '@xyflow/react';
import { Navigate, Route, Routes } from 'react-router';

import { AssemblyNavigatorWindow, Design, DesignLayout, Designs, Layout, Schematic } from './apps/Design';
import {
  BackshellsDashboard,
  BulkUploadDashboard,
  CablesDashboard,
  ConnectorsDashboard,
  ContactsDashboard,
  GenericPartsDashboard,
  InsertArrangementsDashboard,
  LabelsDashboard,
  OverwrapsDashboard,
  PartsLibraryLayout,
  PassivesDashboard,
  PigtailsDashboard,
  SplicesDashboard,
  WiresDashboard,
} from './apps/Parts-Library';
import {
  CrimpsDashboard,
  DieSetsDashboard,
  InsertionsRemovalsDashboard,
  PositionersDashboard,
  ToolsLibraryLayout,
  TorqueAdaptersDashboard,
  TurretHeadsDashboard,
} from './apps/Tools-Library';
import { DefaultLayout } from './components/layouts/DefaultLayout';
import AdminEditTenant from './routes/admin/tenants/AdminEditTenant';
import AdminNewTenant from './routes/admin/tenants/AdminNewTenant';
import AdminTenants from './routes/admin/tenants/AdminTenants';
import AdminEditUser from './routes/admin/users/AdminEditUser';
import AdminNewUser from './routes/admin/users/AdminNewUser';
import AdminUsers from './routes/admin/users/AdminUsers';
import Logout from './routes/Logout';
import Root from './routes/Root';

export function AuthenticatedRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();

  if (isUserLoading || !currentUser) {
    return null;
  }

  Sentry.setUser({
    id: currentUser.id,
    email: currentUser.email,
    tenant: currentUser.tenant.name,
  });

  const designToolRoutes = (
    <Route element={<DesignLayout />}>
      <Route path={RouteNames.DESIGNS.DESIGN} element={<Design />} />
      <Route path={RouteNames.DESIGNS.DESIGN_PART} element={<Design />} />
      <Route path={RouteNames.DESIGNS.DESIGN_PARTS} element={<AssemblyNavigatorWindow />} />
      <Route path={RouteNames.DESIGNS.BOM} element={<BOM />} />
      <Route
        path={RouteNames.DESIGNS.LAYOUT}
        element={
          <ReactFlowProvider>
            <Layout />
          </ReactFlowProvider>
        }
      />
      <Route path={RouteNames.DESIGNS.SCHEMATIC} element={<Schematic />} />
      <Route path={RouteNames.DESIGNS.WIRING_LIST} element={<WiringList />} />
      <Route path={RouteNames.DESIGNS.CUTLIST} element={<Cutlist />} />
    </Route>
  );

  if (isTechnician(currentUser)) {
    return (
      <SentryRoutes>
        <Route path="*" element={<NotFound />} />
        {designToolRoutes}
      </SentryRoutes>
    );
  }

  return (
    <SentryRoutes>
      <Route path={RouteNames.ROOT} element={<Root />} errorElement={<ErrorPage />}>
        <Route path={RouteNames.SIGN_OUT} element={<Logout />} />
        <Route path={RouteNames.NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<DefaultLayout />}>
          <Route path={RouteNames.TENANTS.INDEX} element={<AdminTenants />} />
          <Route path={RouteNames.TENANTS.NEW} element={<AdminNewTenant />} />
          <Route path={RouteNames.TENANTS.EDIT} element={<AdminEditTenant />} />
          <Route path={RouteNames.USERS.INDEX} element={<AdminUsers />} />
          <Route path={RouteNames.USERS.NEW} element={<AdminNewUser />} />
          <Route path={RouteNames.USERS.EDIT} element={<AdminEditUser />} />
        </Route>
        <Route element={<PartsLibraryLayout />}>
          <Route path={RouteNames.PARTS.INDEX} element={<Navigate to={RouteNames.PARTS.CONNECTORS} replace />} />
          <Route path={RouteNames.PARTS.BACKSHELLS}>
            <Route path=":partId?" element={<BackshellsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.CABLES}>
            <Route path=":partId?" element={<CablesDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.CONNECTORS}>
            <Route path=":partId?" element={<ConnectorsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.CONTACTS}>
            <Route path=":partId?" element={<ContactsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.GENERICS}>
            <Route path=":partId?" element={<GenericPartsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.INSERT_ARRANGEMENTS}>
            <Route path=":insertArrangementId?" element={<InsertArrangementsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.LABELS}>
            <Route path=":partId?" element={<LabelsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.OVERWRAPS}>
            <Route path=":partId?" element={<OverwrapsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.PASSIVES}>
            <Route path=":partId?" element={<PassivesDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.PIGTAILS}>
            <Route path=":partId?" element={<PigtailsDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.SPLICES}>
            <Route path=":partId?" element={<SplicesDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.WIRES}>
            <Route path=":partId?" element={<WiresDashboard />} />
          </Route>
          <Route path={RouteNames.PARTS.BULK_UPLOAD} element={<BulkUploadDashboard />} />
        </Route>
        <Route element={<ToolsLibraryLayout />}>
          <Route path={RouteNames.TOOLS.INDEX} element={<Navigate to={RouteNames.TOOLS.TORQUE_ADAPTERS} replace />} />
          <Route path={RouteNames.TOOLS.TORQUE_ADAPTERS}>
            <Route path=":toolId?" element={<TorqueAdaptersDashboard />} />
          </Route>
          <Route path={RouteNames.TOOLS.CRIMPERS}>
            <Route path=":toolId?" element={<CrimpsDashboard />} />
          </Route>
          <Route path={RouteNames.TOOLS.POSITIONERS}>
            <Route path=":toolId?" element={<PositionersDashboard />} />
          </Route>
          <Route path={RouteNames.TOOLS.DIE_SETS}>
            <Route path=":toolId?" element={<DieSetsDashboard />} />
          </Route>
          <Route path={RouteNames.TOOLS.TURRET_HEADS}>
            <Route path=":toolId?" element={<TurretHeadsDashboard />} />
          </Route>
          <Route path={RouteNames.TOOLS.INSERTIONS_REMOVALS}>
            <Route path=":toolId?" element={<InsertionsRemovalsDashboard />} />
          </Route>
        </Route>
        <Route element={<DesignLayout />}>
          <Route path={RouteNames.DESIGNS.INDEX} element={<Designs />} />
        </Route>
        <Route path={RouteNames.DESIGNS.DESIGNS} element={<Navigate to={RouteNames.DESIGNS.INDEX} replace />} />
        {designToolRoutes}
      </Route>
    </SentryRoutes>
  );
}
