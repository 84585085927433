import { defaultViewport } from '@web/apps/types';
import { NodeOrigin, SnapGrid } from '@xyflow/react';
import { CSSProperties } from 'react';

// Interface for LayoutConfig settings
export interface LayoutConfig {
  // ReactFlow properties
  defaultViewport: { x: number; y: number; zoom: number };
  minZoom: number;
  maxZoom: number;
  nodeOrigin: NodeOrigin | undefined;
  snapToGrid: boolean;
  snapGrid: SnapGrid | undefined;
  style?: CSSProperties;
  // Custom properties
  gridLinesGap: number;
  gridLinesColor: string;
  nodeColor: string;
  nodeSecondaryColor?: string;
  nodeOutlineColor?: string;
  selectedNodeColor: string;
  edgeColor: string;
  selectedEdgeColor: string;
  measurementEdgeColor: string;
  measurementEdgeSelectedColor: string;
  overWrapEdgeColor1: string;
  overWrapEdgeColor2: string;
  overWrapEdgeSelectedColor1: string;
  overWrapEdgeSelectedColor2: string;
  controlPointMergeHighlightColor: string;
}

// Default LayoutConfig settings
export const defaultLayoutConfig: LayoutConfig = {
  // ReactFlow properties
  defaultViewport: defaultViewport,
  minZoom: 0.5,
  maxZoom: 3,
  nodeOrigin: [0.5, 0.5],
  snapToGrid: true,
  snapGrid: [5, 5],
  style: { backgroundColor: 'white' },
  // Custom properties
  gridLinesGap: 15,
  gridLinesColor: '#E2E8F0',
  nodeColor: '#2D3748',
  nodeSecondaryColor: '#1A202C',
  nodeOutlineColor: '#4A5568',
  selectedNodeColor: '#3182CE',
  edgeColor: '#000000',
  selectedEdgeColor: '#3182CE',
  measurementEdgeColor: '#A0AEC0',
  measurementEdgeSelectedColor: '#A0AEC0',
  overWrapEdgeColor1: '#4A5568',
  overWrapEdgeColor2: '#718096',
  overWrapEdgeSelectedColor1: '#3182CE',
  overWrapEdgeSelectedColor2: '#90CDF4',
  controlPointMergeHighlightColor: '#F56565',
};
