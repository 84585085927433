import { HStack, Icon, Text } from '@chakra-ui/react';
import { DesignPartLayoutEdge, isOverwrapDesignPart } from '@web/apps/types';
import { BsBox } from 'react-icons/bs';

import { useDesignParts } from '../../../../../hooks/useDesignParts';
import { BaseDetailsAccordionItem } from './BaseDetailsAccordionItem.tsx';

interface Props {
  overwrapPartLayoutEdges: DesignPartLayoutEdge[];
}

export const OverwrapsDetails = ({ overwrapPartLayoutEdges }: Props) => {
  const { getDesignPartById } = useDesignParts();

  const overwraps = overwrapPartLayoutEdges
    .map(({ designPartId }) => getDesignPartById(designPartId))
    .filter((overwrap) => !!overwrap && isOverwrapDesignPart(overwrap));

  return (
    <BaseDetailsAccordionItem
      header={
        <HStack spacing={4}>
          <Icon as={BsBox} />
          <Text fontWeight="medium">Overwraps</Text>
        </HStack>
      }
      borderColor="purple.200"
      bgColor="purple.50"
    >
      {overwraps.map(({ id, partData }, index) => (
        <HStack key={id} p={2} bg="white">
          <Text fontWeight="medium">{partData.partNumber}</Text>
          <Text fontSize="sm" color="gray.600">
            {partData.subtype}
          </Text>
          <Text fontSize="sm" color="gray.600" ml="auto">
            {index === 0 ? 'Innermost' : index === overwraps.length - 1 ? 'Outermost' : ''}
          </Text>
        </HStack>
      ))}
    </BaseDetailsAccordionItem>
  );
};
