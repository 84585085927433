import { HStack, Spacer, TabList, TabPanels, Tabs, TabsProps, Text } from '@chakra-ui/react';
import { LayoutWithDrawer, SchematicWithDrawer } from '@web/apps/Design';
import { format } from 'date-fns';

import { useDesignOverview } from '../../hooks/useDesignOverview';
import { ContextPanelHeight } from '../ContextPanel/types';
import { DesignTab } from './DesignTab';
import { DesignTabPanel } from './DesignTabPanel';
import { DesignTabsProvider, useDesignTabs } from './DesignTabsProvider';

export const DesignTabs = (props: Omit<TabsProps, 'children'>) => {
  return (
    <DesignTabsProvider>
      <DesignTabsComponent {...props} />
    </DesignTabsProvider>
  );
};

const DesignTabsComponent = (props: Omit<TabsProps, 'children'>) => {
  const { createdAt, updatedAt } = useDesignOverview();
  const { contextPanelHeight } = useDesignTabs();

  return (
    <Tabs height="full" display="flex" flexDirection="column" isLazy {...props}>
      <HStack bg="gray.100" px={4} display={contextPanelHeight !== ContextPanelHeight.FULL ? 'flex' : 'none'}>
        <TabList>
          <DesignTab>Layout</DesignTab>
          <DesignTab>Schematic</DesignTab>
        </TabList>
        <Spacer />
        {(createdAt || updatedAt) && (
          <Text color="gray.500" fontSize="xs">
            Last saved: {format(updatedAt ? updatedAt : createdAt, 'Pp')}
          </Text>
        )}
      </HStack>
      <TabPanels display="flex" flex={1} minHeight={0}>
        <DesignTabPanel>
          <LayoutWithDrawer />
        </DesignTabPanel>
        <DesignTabPanel>
          <SchematicWithDrawer />
        </DesignTabPanel>
      </TabPanels>
    </Tabs>
  );
};
