import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';

interface Props {
  onClick: () => void;
  isOpen: boolean;
}

export const CollapseButton = ({ onClick, isOpen }: Props) => (
  <IconButton
    aria-label={isOpen ? 'collapse' : 'expand'}
    position="absolute"
    top={3}
    right={0}
    transform="translateX(50%)"
    icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    backgroundColor="white"
    color="gray.400"
    borderWidth={1}
    borderColor="gray.300"
    size="sm"
    fontSize="xl"
    onClick={onClick}
    _hover={{ color: 'white', backgroundColor: 'blue.500' }}
    isRound
  />
);
