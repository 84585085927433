import {
  Box,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { apiDeleteTenant } from '@web/api/admin-api.ts';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { adminTenantsCacheKey, useAdminTenants } from '@web/queries/admin.ts';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { RiSettings5Fill } from 'react-icons/ri';
import { Link, NavLink, useSearchParams } from 'react-router';

import Button from '../../../components/buttons/Button';
import { FilterBox } from '../../../components/FilterBox';
import Loading from '../../../components/Loading';
import Paginate from '../../../components/Paginate';
import AdminPage from '../AdminPage';

const AdminTenants = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const [query, setQuery] = useState(searchParams.get('q') ?? '');
  const { isLoading, data: tenants } = useAdminTenants(page, searchParams.get('q'));
  const data = tenants || { data: [], meta: { totalPages: 0 } };

  const debouncedSearchTenants = useMemo(
    () =>
      debounce((value: string) => {
        setSearchParams(value ? `q=${value}` : undefined);
      }, 500),
    [setSearchParams],
  );

  const deleteTenant = (tenantId: string) => {
    void apiDeleteTenant(tenantId).then(async () => {
      await queryClient.invalidateQueries({ queryKey: adminTenantsCacheKey(page, query) });
      successToast(`Tenant was successfully deleted.`);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  return (
    <AdminPage
      title="Admin Tenants"
      actions={[
        <NavLink key={RouteNames.TENANTS.NEW} to={RouteNames.TENANTS.NEW}>
          <Button>New Tenant</Button>
        </NavLink>,
      ]}
    >
      <Box mb={6}>
        <FilterBox
          value={query}
          onChange={(text) => {
            setQuery(text);
            debouncedSearchTenants(text);
          }}
        />
      </Box>
      {isLoading && <Loading message="Loading tenants…" />}
      {!isLoading && (
        <>
          <Table mb={6} size="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Order Number Prefix</Th>
                <Th>Users</Th>
                <Th>U.S. Based</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {(data.data || []).map((tenant) => {
                return (
                  <Tr key={tenant.id}>
                    <Td>
                      <ChakraLink
                        as={Link}
                        color="primary"
                        to={RouteNames.TENANTS.EDIT.replace(':tenantId', tenant.id)}
                      >
                        {tenant.id}
                      </ChakraLink>
                    </Td>
                    <Td>{tenant.name}</Td>
                    <Td>{tenant.orderNumberPrefix}</Td>
                    <Td>
                      {}
                      <ChakraLink as={Link} color="primary" to={RouteNames.USERS.INDEX + `?q=${tenant.name}`}>
                        {tenant.users?.length || 0}
                      </ChakraLink>
                    </Td>
                    <Td>
                      {tenant.usBased ? (
                        <Text as="span">Yes</Text>
                      ) : (
                        <Text as="span" bgColor="secondary" p={1}>
                          No
                        </Text>
                      )}
                    </Td>
                    <Td>
                      <Box fontSize="sm">
                        <Menu>
                          <MenuButton tabIndex={0} cursor="pointer" _hover={{ opacity: '0.8' }}>
                            <RiSettings5Fill size={18} />
                          </MenuButton>
                          <MenuList bg="background" borderRadius={0}>
                            <MenuItem
                              as="a"
                              href={RouteNames.TENANTS.EDIT.replace(':tenantId', tenant.id)}
                              bg="background"
                              _hover={{ bg: 'whiteAlpha.100' }}
                            >
                              Edit Tenant
                            </MenuItem>
                            <MenuItem bg="background" _hover={{ bg: 'whiteAlpha.100' }}>
                              <Text
                                as="span"
                                color="red.300"
                                onClick={() => {
                                  if (window.confirm(`Are you sure that you want to delete tenant "${tenant.name}"?`)) {
                                    deleteTenant(tenant.id);
                                  }
                                }}
                              >
                                Delete tenant
                              </Text>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Paginate onPageChange={handlePageClick} selectedPage={parseInt(page) - 1} pageCount={data.meta.totalPages} />
        </>
      )}
    </AdminPage>
  );
};

export default AdminTenants;
