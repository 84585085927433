import { Graph, Schematic } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';

import { SchematicNodeData } from '../components/nodes/SchematicNode/SchematicNode.tsx';
import { schematicNodeId } from '../types/nodes.ts';
import { generateGroupedEdges } from './groupedEdges.ts';
import { generatePathEdges } from './pathEdges.ts';

export const buildGraph = (schematicData: Schematic.SchematicData, width: number): Graph => {
  return {
    nodes: [createSchematicNode(schematicData, width)],
    edges: createEdges(schematicData),
  };
};

/**
 * Creates a Schematic node.
 * handles.
 * @param data
 * @param width
 */
const createSchematicNode = (data: Schematic.SchematicData, width: number) => {
  return {
    id: schematicNodeId,
    type: 'Schematic',
    data: {
      schematic: data,
      width,
    },
    position: { x: 0, y: 0 },
    selectable: false,
  } as Node<SchematicNodeData>;
};

/**
 * Creates edges for a schematic, including Conductor lines, shielding, and twisting.
 * @param data
 */
const createEdges = (data: Schematic.SchematicData) => {
  const edges: Edge[] = [];

  data.destinations.forEach((destination) => {
    destination.paths.forEach((path) => {
      edges.push(...generatePathEdges(path));
    });
  });

  edges.push(...generateGroupedEdges(data, (conductor) => conductor.shieldGroup, 'shield'));
  edges.push(...generateGroupedEdges(data, (conductor) => conductor.twistingGroup, 'twisting'));

  return edges;
};
