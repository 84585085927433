import { HStack, Text } from '@chakra-ui/react';
import { ColorBlock } from '@web/apps/Design/components/ColorBlock.tsx';
import { Schematic } from '@web/apps/types';
import { EdgeLabelRenderer } from '@xyflow/react';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

interface Props {
  position: 'source' | 'destination';
  conductor: Schematic.Conductor;
  coords: { x: number; y: number };
}

export const FloatingEdgeLabel = ({ position, conductor, coords }: Props) => {
  const isSource = position === 'source';

  return (
    <EdgeLabelRenderer>
      <HStack
        position="absolute"
        transform={
          isSource
            ? `translate(${coords.x}px, ${coords.y}px) translateY(-50%)`
            : `translate(${coords.x}px, ${coords.y}px) translateX(-100%) translateY(-50%)`
        }
        px={2}
      >
        {isSource ? (
          <HStack>
            <IoIosArrowRoundBack fontSize="1.5rem" />
            <HStack bg="gray.200" borderRadius="md" p={2} py={1}>
              <ColorBlock text={conductor.name} />
              <Text>{conductor.name}</Text>
              <Text>{conductor.partNumber}</Text>
              <Text color="gray.500">{conductor.gauge ? `${conductor.gauge} AWG` : null}</Text>
              <Text color="gray.500">{conductor.signal}</Text>
            </HStack>
          </HStack>
        ) : (
          <HStack>
            <HStack bg="gray.200" borderRadius="md" p={2} py={1}>
              <Text>{conductor.partNumber}</Text>
              <Text color="gray.500">{conductor.gauge ? `${conductor.gauge} AWG` : null}</Text>
              <Text color="gray.500">{conductor.signal}</Text>
              <Text>{conductor.name}</Text>
              <ColorBlock text={conductor.name} />
            </HStack>
            <IoIosArrowRoundForward fontSize="1.5rem" />
          </HStack>
        )}
      </HStack>
    </EdgeLabelRenderer>
  );
};
