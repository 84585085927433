import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Icon, StackProps } from '@chakra-ui/react';
import { BOMItem as Item, DesignPart, Part } from '@web/apps/types';
import { MouseEvent, ReactNode } from 'react';

import AddIconButton from '../../../components/AddIconButton.tsx';
import { ItemCell } from '../../../components/ItemTable/ItemCell.tsx';
import { ItemRow } from '../../../components/ItemTable/ItemRow.tsx';
import { useDesign } from '../../../hooks/useDesign.tsx';
import { usePartSearchModal } from '../../../hooks/usePartSearchModal.tsx';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import AddAlternateButton from './AddAlternateButton.tsx';

interface Props extends StackProps {
  bomItem?: Item;
  isHeader?: boolean;
  isExpanded?: boolean;
  hasFocus?: boolean;
  designParts?: DesignPart[];
}

/**
 * BOMItem component displays a single row of the Bill of Materials.
 * @param bomItem
 * @param isHeader
 * @param isExpanded
 * @param hasFocus
 * @param rest
 * @constructor
 */
const BOMItem = ({ bomItem, isHeader, isExpanded, hasFocus = false, designParts, ...rest }: Props) => {
  const { isViewOnly } = useDesign();
  const selectedDesignPart = useSelectedDesignPart();
  const isSelected = bomItem?.partNumber === selectedDesignPart?.partData.partNumber;

  const { openModal } = usePartSearchModal();
  const bomItemPart = designParts?.find((part) => part.partData.partNumber === bomItem?.partNumber);

  if (!isHeader && !bomItem) {
    throw new Error('BOMItem: bomItem is required, if header is false.');
  }

  // Render the action button for a search result from the part search modal when adding an alternate part.
  const searchResultActionRenderFn = (part: Part) => {
    if (bomItem && bomItemPart) {
      // If the part is already an alternate, don't show the added alternate button
      if (bomItem.alternates.some((alternate) => alternate.alternatePart.id === part.id)) return;
      // Show the added alternate button
      return <AddAlternateButton part={part} preferredPart={bomItemPart} />;
    }
  };

  // Handle the close event of the modal.
  const handleAddAlternateClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isSelected) {
      event.stopPropagation();
    }
    openModal({
      initialPartTypeFilters: bomItem?.type ? [bomItem.type] : [],
      filtersDisabled: true,
      renderSearchResultAction: searchResultActionRenderFn,
    });
  };

  const getCellValue = (headerLabel: ReactNode, cellValue: ReactNode) => (isHeader ? headerLabel : cellValue);

  return (
    <ItemRow
      isHeader={isHeader}
      isSelected={isSelected}
      bg={isExpanded ? 'blue.100' : 'transparent'}
      role="group"
      {...rest}
    >
      <Icon
        as={isExpanded ? ChevronDownIcon : ChevronRightIcon}
        mb={1}
        mr={1}
        visibility={isHeader || !hasFocus ? 'hidden' : 'visible'}
      />
      <ItemCell flex={1}>{getCellValue('ID', bomItem?.id)}</ItemCell>
      <ItemCell flex={2} textAlign="center">
        {getCellValue('Type', bomItem?.type)}
      </ItemCell>
      <ItemCell flex={1} textAlign="center">
        {getCellValue('Qty', bomItem?.quantity)}
      </ItemCell>
      <ItemCell flex={1}>{getCellValue('Unit', bomItem?.unit)}</ItemCell>
      <ItemCell flex={2}>{getCellValue('Part Number', bomItem?.partNumber)}</ItemCell>
      <ItemCell flex={4}>{getCellValue('Usage', bomItem?.usage.join(', '))}</ItemCell>
      <ItemCell flex={8}>{getCellValue('Description', bomItem?.description)}</ItemCell>
      <ItemCell flex={4}>{getCellValue('Manufacturers', bomItem?.manufacturer)}</ItemCell>
      <ItemCell flex={1} textAlign="center">
        {getCellValue('Alts', bomItem?.alternates.length)}
      </ItemCell>
      {!isViewOnly && (
        <AddIconButton
          aria-label={'Add alternate part'}
          variant="secondary"
          visibility="hidden"
          _groupHover={{ visibility: !isHeader && hasFocus ? 'visible' : 'hidden' }}
          onClick={handleAddAlternateClick}
        />
      )}
    </ItemRow>
  );
};

export default BOMItem;
