import { Design } from '@web/apps/types';

import { fetchDesign } from '../api/designs-api';
import { useExportDesignMutation } from '../api/export-api';
import { relationalEdgeTypes, relationalNodeTypes } from '../features/RelationalLayout/relational_types_map';
import { edgeTypes as schematicEdgeTypes } from '../features/Schematic/types/edges';
import { nodeTypes as schematicNodeTypes } from '../features/Schematic/types/nodes';
import { getSchematics, processSchematicLayout } from '../utils/generateImageForExport';
import { useExportReactFlow } from './useExportReactFlow';
import { selectLayoutData } from './useRelationalLayoutData/useRelationalLayoutData';

export const useDesignExport = () => {
  const { exportImage: exportLayoutImage, renderedReactFlow: layoutReactFlow } = useExportReactFlow({
    nodeTypes: relationalNodeTypes,
    edgeTypes: relationalEdgeTypes,
    getData: selectLayoutData,
  });

  const { exportImage: exportSchematicImage, renderedReactFlow: schematicReactFlow } = useExportReactFlow({
    nodeTypes: schematicNodeTypes,
    edgeTypes: schematicEdgeTypes,
    getData: processSchematicLayout,
  });

  const { mutateAsync: exportDesign } = useExportDesignMutation();

  const handleExportPdf = async (design: Design, baseExportData: any) => {
    const designData = await fetchDesign(design.id);
    if (!designData) {
      throw new Error('Failed to fetch design');
    }

    const layout = await exportLayoutImage(designData);
    if (!layout) {
      throw new Error('Failed to export layout');
    }

    const schematics = await getSchematics(designData);
    const schematicsImages = [];
    for (const schematic of schematics) {
      const image = await exportSchematicImage(schematic);
      if (!image) continue;
      schematicsImages.push({ image, designPartId: schematic.sourcePart.id });
    }

    await exportDesign({
      ...baseExportData,
      images: [{ layout, schematics: schematicsImages }],
    });
  };

  return {
    handleExportPdf,
    exportDesign,
    layoutReactFlow,
    schematicReactFlow,
  };
};
