import { SystemStyleObject } from '@chakra-ui/system';
import { createGroupStyledOptions } from '@web/common/select.ts';

const baseStyles = {
  color: 'gray.700',
  fontSize: 'xs',
  paddingLeft: '30px',
  paddingRight: 2,
};

export const customSelectStyles = {
  option: (provided: SystemStyleObject, state: any) => {
    const baseOptionStyles = {
      ...provided,
      ...baseStyles,
      bg: state.isSelected ? 'blue.50' : state.isFocused ? 'gray.100' : 'none',
    };

    return createGroupStyledOptions('Used', { color: 'gray.400' }).option(baseOptionStyles, state);
  },
  control: (provided: SystemStyleObject) => ({
    ...provided,
    ...baseStyles,
  }),
  groupHeading: (provided: SystemStyleObject) => ({
    ...provided,
    ...baseStyles,
    fontWeight: 'bold',
  }),
};
