import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -8,
  y: 0,
};

const originalWidth = 16;
const originalHeight = 32;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const RectangularConnector = ({
  color = defaultLayoutConfig.nodeColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M16 32L2 32C0.895431 32 7.8281e-08 31.1046 1.74846e-07 30L2.62268e-06 2C2.71925e-06 0.895428 0.895433 -1.32048e-06 2 -1.22392e-06L16 0L16 32Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 31.5L15.5 31.5L15.5 0.5L2 0.499999C1.17158 0.499999 0.500003 1.17157 0.500003 2L0.5 30C0.5 30.8284 1.17157 31.5 2 31.5ZM16 32L16 0L2 -1.22392e-06C0.895433 -1.32048e-06 2.71925e-06 0.895428 2.62268e-06 2L1.74846e-07 30C7.8281e-08 31.1046 0.895431 32 2 32L16 32Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
