import { UUID } from '@web/apps/types';

export type Row = number | null;

export type Col = keyof Fields | null;

export interface Cell {
  row: Row;
  col: Col;
}

export interface Fields {
  destinationId: UUID | '';
  conductorId: UUID | '';
  signalName: string;
  sourceContactId: UUID | '';
  destinationContactId: UUID | '';
}

export const emptyConnectionState: Fields = {
  destinationId: '',
  conductorId: '',
  signalName: '',
  sourceContactId: '',
  destinationContactId: '',
};
