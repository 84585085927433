import { StackProps, Text, VStack } from '@chakra-ui/react';
import { defaultSchematicConfig } from '@web/apps/Design/features/Schematic/config.ts';
import { Schematic } from '@web/apps/types';

interface Props extends StackProps {
  part?: Schematic.Part | null;
}

export const PartLabel = ({ part, ...rest }: Props) => {
  const handleClick = () => {
    console.debug('Part', part);
  };

  return (
    <VStack
      width={part ? 'full' : defaultSchematicConfig.cavityWidth}
      color="gray.900"
      bg={part ? 'transparent' : defaultSchematicConfig.connectorColor}
      fontFamily="mono"
      onClick={handleClick}
      pointerEvents="all"
      cursor="pointer"
      px={part ? 2 : 10}
      py={2}
      {...rest}
    >
      <Text fontWeight="bold" whiteSpace="nowrap">
        {part?.name}
      </Text>
      <Text whiteSpace="nowrap">{part?.partNumber}</Text>
    </VStack>
  );
};
