import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -12,
  y: 0,
};

const originalWidth = 24;
const originalHeight = 24;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const CircularConnector = ({
  color = defaultLayoutConfig.nodeColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M24 22C24 23.1046 23.1046 24 22 24L6 24C2.68629 24 2.34843e-07 21.3137 5.24537e-07 18L1.57361e-06 6C1.8633e-06 2.68629 2.68629 -1.8633e-06 6 -1.57361e-06L22 -1.74846e-07C23.1046 -7.8281e-08 24 0.895432 24 2L24 22Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 23.5L22 23.5C22.8284 23.5 23.5 22.8284 23.5 22L23.5 2C23.5 1.17157 22.8284 0.5 22 0.5L6 0.499998C2.96244 0.499998 0.500002 2.96243 0.500002 6L0.500001 18C0.5 21.0376 2.96243 23.5 6 23.5ZM22 24C23.1046 24 24 23.1046 24 22L24 2C24 0.895432 23.1046 -7.8281e-08 22 -1.74846e-07L6 -1.57361e-06C2.68629 -1.8633e-06 1.8633e-06 2.68629 1.57361e-06 6L5.24537e-07 18C2.34843e-07 21.3137 2.68629 24 6 24L22 24Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
