import { Input, InputProps } from '@chakra-ui/react';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';

interface Props extends InputProps {
  initialText: string;
  onEdit: (value: string) => void;
  onCancelEdit: () => void;
}

export const EditableTextInputComponent = ({ initialText, onEdit, onCancelEdit, ...rest }: Props) => {
  const [value, setValue] = useState(initialText);
  const inputRef = useRef<HTMLInputElement>(null);

  // On mount, use a timeout to ensure focus is applied after rendering
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  }, []);

  const handleEdit = () => {
    if (value !== initialText) {
      onEdit(value);
    } else {
      onCancelEdit();
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEdit();
    } else if (event.key === 'Escape') {
      onCancelEdit();
    }
  };

  return (
    <Input
      ref={inputRef}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleEdit}
      onKeyDown={handleOnKeyDown}
      autoComplete="off"
      size="sm"
      {...rest}
    />
  );
};
