import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { Schematic, UUID } from '@web/apps/types';
import { doRequest, getUrl } from '@web/common/api.ts';

// API Calls
const emptyData: Schematic.SchematicData = {
  sourcePart: { id: '', name: '', partNumber: '' },
  destinations: [],
};

export const fetchSchematic = async (designId: UUID, designPartId: UUID): Promise<Schematic.SchematicData> => {
  const url = getUrl(`/api/v1/designs/${designId}/design_parts/${designPartId}/schematic`);
  return (await doRequest<Schematic.SchematicData>('GET', url)) || emptyData;
};

// React Query Hook: useSchematicQuery
export const useSchematicQuery = <TData = Schematic.SchematicData>(
  designId: UUID,
  designPartId: UUID,
  select?: (data: Schematic.SchematicData) => TData,
): UseQueryResult<TData | null> => {
  return useQuery({
    queryKey: designKeys.schematic(designId, designPartId),
    queryFn: async () => (await fetchSchematic(designId, designPartId)) ?? null,
    select,
    enabled: !!designPartId,
  });
};
