import { AddIcon } from '@chakra-ui/icons';
import { AccordionItem, AccordionPanel, Button, Center, Stack } from '@chakra-ui/react';
import { ConnectorData, DesignPart, PartType } from '@web/apps/types';

import { useDesign } from '../../../../../hooks/useDesign';
import { usePartSearchActions } from '../../../hooks/usePartSearchActions';
import { StaticTypeListItem } from '../StaticTypeListItem';
import { AccessoryListItem } from './AccessoryListItem';
import { ConnectorListItemButton } from './ConnectorListItemButton';

const getAccessoryFilters = (acceptsBackshell: boolean, acceptsContacts: boolean) => [
  PartType.GENERIC,
  ...(acceptsBackshell ? [PartType.BACKSHELL] : []),
  ...(acceptsContacts ? [PartType.CONTACT] : []),
];

interface Props {
  part: DesignPart;
}

export const ConnectorListItem = ({ part }: Props) => {
  const { isViewOnly } = useDesign();
  const { handleAddSpecificPart } = usePartSearchActions();

  const partData = part.partData as ConnectorData;
  const { acceptsBackshell, acceptsContacts } = partData;

  const handleAddAccessory = () => {
    const accessoryFilters = getAccessoryFilters(acceptsBackshell, acceptsContacts);
    handleAddSpecificPart(accessoryFilters, part);
  };

  return (
    <AccordionItem as={Stack} id={part.id} gap={0} border="none" bgColor="gray.100">
      {({ isExpanded }) => (
        <>
          <ConnectorListItemButton part={part} isExpanded={isExpanded} />
          <AccordionPanel as={Stack} gap={1} p={1} borderTopWidth={1} borderColor="gray.200">
            {!acceptsBackshell && <StaticTypeListItem type="Backshell" label="Not Supported" />}
            {!acceptsContacts && <StaticTypeListItem type="Contacts" label="Not Supported" />}
            {part.accessoryParts?.map((accessoryPart) => (
              <AccessoryListItem key={accessoryPart.id} part={accessoryPart} />
            ))}
            {!isViewOnly && (
              <Center p={2}>
                <Button
                  leftIcon={<AddIcon boxSize={2} />}
                  colorScheme="blue"
                  variant="outline"
                  size="xs"
                  onClick={handleAddAccessory}
                >
                  Add Accessory
                </Button>
              </Center>
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
