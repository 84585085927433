import { HStack, IconButtonProps, Select, SelectProps, Text } from '@chakra-ui/react';
import { useCreateDesignPartMutation } from '@web/apps/Design/api/design-parts-api.ts';
import { ConnectorData, DesignPart, Part, PartStatus } from '@web/apps/types';
import { ChangeEvent, useState } from 'react';

import AddIconButton from '../../../../components/AddIconButton.tsx';
import { useDesignId } from '../../../../hooks/useDesignId.tsx';
import { useDesignToast } from '../../../../hooks/useDesignToast.tsx';

export interface AddAccessoryButtonContentProps {
  part: Part;
  connectors: (DesignPart & { partData: ConnectorData })[];
  connectorPart?: DesignPart;
  selectProps?: SelectProps;
  buttonProps?: IconButtonProps;
  visibleIfDraftPart?: boolean;
}

export const AddAccessoryButtonContent = ({
  part,
  connectors,
  connectorPart,
  selectProps,
  buttonProps,
  visibleIfDraftPart = false,
}: AddAccessoryButtonContentProps) => {
  const designId = useDesignId();
  const { showSuccessToast } = useDesignToast();

  const [selectedConnector, setSelectedConnector] = useState<DesignPart | null>(connectors[0]);

  // Mutation to add accessory to connector
  const handlePartCreateSuccess = () => {
    const connectorName = connectorPart ? connectorPart.name : selectedConnector?.name;
    showSuccessToast(
      'Accessory Added',
      `The ${part.type.toLowerCase()} was successfully added to connector ${connectorName}.`,
    );
  };
  const { mutate: addDesignPart, isPending } = useCreateDesignPartMutation(handlePartCreateSuccess);

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const connector = connectors.find((connector) => connector.id === event.target.value);
    if (connector) {
      setSelectedConnector(connector);
    }
  };

  const handleAddClick = () => {
    const designPartId = connectorPart?.id || selectedConnector?.id;
    if (designPartId) {
      addDesignPart({
        designId,
        data: { name: window.crypto.randomUUID(), partId: part.id, designPartId: designPartId },
      });
    }
  };

  const visibility = part.status === PartStatus.DRAFT && !visibleIfDraftPart ? 'hidden' : 'visible';

  return (
    <HStack visibility={visibility} p={0} maxW={44}>
      {connectorPart ? (
        <HStack overflow="hidden" fontSize="sm">
          <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW={12} flexShrink={0}>
            {connectorPart.name}
          </Text>
          <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="blue.500">
            {connectorPart.partData.partNumber}
          </Text>
        </HStack>
      ) : (
        <Select onChange={handleSelect} size="sm" {...selectProps}>
          {connectors.map((connector) => (
            <option
              key={connector.id}
              value={connector.id}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {`${connector.name} ${connector.partData.partNumber}`}
            </option>
          ))}
        </Select>
      )}
      <AddIconButton
        aria-label="Add"
        onClick={handleAddClick}
        bg="gray.300"
        _hover={{ color: 'white', bg: 'blue.500' }}
        isLoading={isPending}
        {...buttonProps}
      />
    </HStack>
  );
};
