import { Box, BoxProps } from '@chakra-ui/react';
import { Schematic } from '@web/apps/types';
import { Position } from '@xyflow/system';

import { defaultSchematicConfig } from '../../../config.ts';
import { conductorSymbolOffset, shieldAndTwistingVerticalOffset } from '../../../types/positioning.ts';
import { getConductorGroupHandle, getInputHandle, getOutputHandle } from '../../../utils/handles.ts';
import { isFirstInGroup, isLastInGroup } from '../../../utils/helpers.ts';
import { ConnectionHandle } from './ConnectionHandle.tsx';

export interface PathProps extends BoxProps {
  conductor: Schematic.Conductor;
}

export const Conductor = ({ conductor, ...rest }: PathProps) => {
  const { shieldGroup, twistingGroup } = conductor;

  const shouldRenderShieldAbove = isFirstInGroup(conductor, shieldGroup);
  const shouldRenderShieldBelow = isLastInGroup(conductor, shieldGroup);

  const shouldRenderTwistingAbove = isFirstInGroup(conductor, twistingGroup);
  const shouldRenderTwistingBelow = isLastInGroup(conductor, twistingGroup);

  return (
    <Box position="relative" h={defaultSchematicConfig.cavityHeight} w="full" {...rest}>
      {/* Input/Output Conductor Handles */}
      <ConnectionHandle
        id={getInputHandle(conductor.name)}
        handlePosition={Position.Left}
        position="absolute"
        left={0}
        top="50%"
        transform="translateY(-50%)"
      />

      <ConnectionHandle
        id={getOutputHandle(conductor.name)}
        handlePosition={Position.Right}
        position="absolute"
        right={0}
        top="50%"
        transform="translateY(-50%)"
      />

      {shouldRenderShieldBelow && conductor.shieldId && (
        <ConnectionHandle
          id={getInputHandle(conductor.shieldId)}
          handlePosition={Position.Bottom}
          position="absolute"
          left={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderShieldBelow && conductor.shieldId && (
        <ConnectionHandle
          id={getOutputHandle(conductor.shieldId)}
          handlePosition={Position.Bottom}
          position="absolute"
          right={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {/* Shield Group Handles */}
      {shouldRenderShieldAbove && (
        <ConnectionHandle
          id={getConductorGroupHandle('shield', conductor.id, 'topLeft')}
          handlePosition={Position.Top}
          position="absolute"
          left={`${conductorSymbolOffset}px`}
          top={`calc(50% - ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderShieldAbove && (
        <ConnectionHandle
          id={getConductorGroupHandle('shield', conductor.id, 'topRight')}
          handlePosition={Position.Top}
          position="absolute"
          right={`${conductorSymbolOffset}px`}
          top={`calc(50% - ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderShieldBelow && (
        <ConnectionHandle
          id={getConductorGroupHandle('shield', conductor.id, 'bottomLeft')}
          handlePosition={Position.Bottom}
          position="absolute"
          left={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderShieldBelow && (
        <ConnectionHandle
          id={getConductorGroupHandle('shield', conductor.id, 'bottomRight')}
          handlePosition={Position.Bottom}
          position="absolute"
          right={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {/* Twisting Group Handles */}
      {shouldRenderTwistingAbove && (
        <ConnectionHandle
          id={getConductorGroupHandle('twisting', conductor.id, 'topLeft')}
          handlePosition={Position.Top}
          position="absolute"
          left={`${conductorSymbolOffset}px`}
          top={`calc(50% - ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderTwistingAbove && (
        <ConnectionHandle
          id={getConductorGroupHandle('twisting', conductor.id, 'topRight')}
          handlePosition={Position.Top}
          position="absolute"
          right={`${conductorSymbolOffset}px`}
          top={`calc(50% - ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderTwistingBelow && (
        <ConnectionHandle
          id={getConductorGroupHandle('twisting', conductor.id, 'bottomLeft')}
          handlePosition={Position.Bottom}
          position="absolute"
          left={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}

      {shouldRenderTwistingBelow && (
        <ConnectionHandle
          id={getConductorGroupHandle('twisting', conductor.id, 'bottomRight')}
          handlePosition={Position.Bottom}
          position="absolute"
          right={`${conductorSymbolOffset}px`}
          top={`calc(50% + ${shieldAndTwistingVerticalOffset}px)`}
        />
      )}
    </Box>
  );
};
