import { Text, TextareaProps } from '@chakra-ui/react';
import { useState } from 'react';

import { EditableTextareaComponent } from './EditableTextareaComponent';

interface Props extends TextareaProps {
  text: string;
  onEdit: (value: string) => void;
  editAction?: 'dblclick' | 'click';
}

const EditableTextarea = ({ text, onEdit, editAction = 'dblclick', ...rest }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = () => setIsEditing(true);

  const handleEdit = (newValue: string) => {
    setIsEditing(false);
    onEdit(newValue);
  };

  return isEditing ? (
    <EditableTextareaComponent
      initialText={text}
      onEdit={handleEdit}
      onCancelEdit={() => setIsEditing(false)}
      {...rest}
    />
  ) : (
    <Text
      w="full"
      onClick={editAction === 'click' ? startEditing : undefined}
      onDoubleClick={editAction === 'dblclick' ? startEditing : undefined}
      whiteSpace="pre-wrap"
    >
      {text}
    </Text>
  );
};

export default EditableTextarea;
