import { HTMLChakraProps, Spinner, Td, Text, Tr } from '@chakra-ui/react';
import { Col, Row } from '@web/apps/Design/features/WiringList/types.ts';
import {
  getConnectionStateFromConductorChange,
  getConnectionStateFromConnection,
} from '@web/apps/Design/features/WiringList/utils/state.ts';
import { ResolvedConnection, ResolvedConnectionPoint } from '@web/apps/types';
import { stringToOption } from '@web/common/select.ts';
import { useStateWithDirtyFlag } from '@web/hooks/useStateWithDirtyFlag.ts';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useConnections } from '../../../../hooks/useConnections.tsx';
import { useConductorOptions } from '../../hooks/useConductorOptions.tsx';
import { useDestinationContactOptions } from '../../hooks/useDestinationContactOptions.tsx';
import { useDestinationOptions } from '../../hooks/useDestinationOptions.tsx';
import { useSourceContactOptions } from '../../hooks/useSourceContactOptions.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';
import { WiringListSelectCell } from '../WiringListCell/WiringListSelectCell.tsx';
import { WiringListTextCell } from '../WiringListCell/WiringListTextCell.tsx';
import { useWiringListRowActions } from './useWiringListRowActions.tsx';
import { WiringListRowActions } from './WiringListRowActions.tsx';

interface Props extends Omit<HTMLChakraProps<'tr'>, 'children'> {
  row: Row;
  connectionPoint: ResolvedConnectionPoint;
  connection: ResolvedConnection | null;
}

export const WiringListRow = ({ row, connectionPoint, connection, ...rest }: Props) => {
  const { connectionPointsById } = useConnections();
  const { activeEditCell, setActiveEditCell, addCrimpSplice, rowPendingChange } = useWiringList();
  const initialState = useMemo(() => getConnectionStateFromConnection(connection?.original || null), [connection]);
  const { state, isDirty, setStateWithDirtyFlag, resetState } = useStateWithDirtyFlag(initialState);
  const rowRef = useRef<HTMLTableRowElement>(null);

  const { options: destinationOptions } = useDestinationOptions(connectionPoint, connection?.destination?.id ?? '');
  const { options: conductorOptions } = useConductorOptions(connection?.conductor?.id ?? '');
  const { options: sourceContactOptions } = useSourceContactOptions();
  const { options: destinationContactOptions } = useDestinationContactOptions(state.destinationId);

  const { saveConnection, clearConnection } = useWiringListRowActions(
    row,
    connectionPoint,
    connection,
    state,
    isDirty,
    resetState,
  );

  const handleFieldChange = useCallback(
    (_row: Row, col: Col, value: string) => {
      if (col) {
        setStateWithDirtyFlag((prevState) => ({ ...prevState, [col]: value }));
      }
    },
    [setStateWithDirtyFlag],
  );

  const handleConductorIdChange = useCallback(
    (_row: Row, _col: Col, value: string) => {
      setStateWithDirtyFlag((prevState) => {
        return getConnectionStateFromConductorChange(
          { ...prevState, conductorId: value },
          connectionPoint,
          connectionPointsById[prevState.destinationId],
          sourceContactOptions,
          destinationContactOptions,
        );
      });
    },
    [connectionPoint, connectionPointsById, destinationContactOptions, setStateWithDirtyFlag, sourceContactOptions],
  );

  useEffect(() => {
    resetState(initialState);
  }, [initialState, resetState]);

  const sharedCellProps = {
    row,
    onEnterKeyPress: saveConnection,
    onCellFocus: resetState,
    onCellBlur: saveConnection,
  } as const;

  return (
    <Tr
      ref={rowRef}
      h={10}
      role="group"
      bg={activeEditCell?.row === row ? 'blue.50 !important' : 'none'}
      _hover={{ bg: 'gray.50' }}
      {...rest}
    >
      <Td
        onClick={() => setActiveEditCell(null)}
        bg="gray.100"
        borderRight="1px solid"
        borderBottom="1px solid"
        borderColor="gray.300"
        fontWeight="bold"
        textAlign="center"
      >
        <Text>
          {connectionPoint.name === 'Housing' ? 'HS' : connectionPoint.name === 'Splice' ? 'SPL' : connectionPoint.name}
        </Text>
      </Td>
      <WiringListSelectCell
        col={'destinationId'}
        value={stringToOption(state.destinationId, destinationOptions)}
        options={destinationOptions}
        onFieldChange={handleFieldChange}
        {...sharedCellProps}
      />
      <WiringListSelectCell
        col={'conductorId'}
        value={stringToOption(state.conductorId, conductorOptions)}
        options={conductorOptions}
        onFieldChange={handleConductorIdChange}
        {...sharedCellProps}
      />
      <WiringListTextCell
        col={'signalName'}
        value={state.signalName}
        onFieldChange={handleFieldChange}
        {...sharedCellProps}
      />
      <WiringListSelectCell
        col={'sourceContactId'}
        value={stringToOption(state.sourceContactId, sourceContactOptions)}
        options={sourceContactOptions}
        onFieldChange={handleFieldChange}
        {...sharedCellProps}
      />
      <WiringListSelectCell
        col={'destinationContactId'}
        value={stringToOption(state.destinationContactId, destinationContactOptions)}
        options={destinationContactOptions}
        onFieldChange={handleFieldChange}
        {...sharedCellProps}
      />
      <Td textAlign="right" borderBottom="1px solid" borderColor="gray.300" onClick={() => setActiveEditCell(null)}>
        {rowPendingChange === row ? (
          <Spinner size="sm" mr={4} />
        ) : (
          <WiringListRowActions
            connection={connection}
            onAddCrimpSplice={() => addCrimpSplice(connectionPoint.id)}
            onClearRow={clearConnection}
            onClick={() => setActiveEditCell(null)}
          />
        )}
      </Td>
    </Tr>
  );
};
