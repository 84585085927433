import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -18,
  y: -3,
};

const originalWidth = 36;
const originalHeight = 30;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const CircularConnector45 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M6.90816 5.3136C6.90816 4.76132 6.46044 4.3136 5.90815 4.3136L0.999998 4.3136C0.447714 4.3136 -4.65541e-08 4.76132 0 5.3136L1.18011e-06 19.3136C1.22667e-06 19.8659 0.447716 20.3136 1 20.3136H5.90816C6.46044 20.3136 6.90816 19.8659 6.90816 19.3136L6.90816 5.3136Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4.8136L5.90815 4.8136C6.1843 4.8136 6.40816 5.03746 6.40816 5.3136L6.40816 19.3136C6.40816 19.5897 6.1843 19.8136 5.90816 19.8136L1 19.8136C0.723859 19.8136 0.500002 19.5897 0.500002 19.3136L0.499999 5.3136C0.499999 5.03746 0.723858 4.8136 1 4.8136ZM5.90815 4.3136C6.46044 4.3136 6.90816 4.76132 6.90816 5.3136L6.90816 19.3136C6.90816 19.8659 6.46044 20.3136 5.90816 20.3136H1C0.447716 20.3136 1.22667e-06 19.8659 1.18011e-06 19.3136L0 5.3136C-4.65541e-08 4.76132 0.447714 4.3136 0.999998 4.3136L5.90815 4.3136Z"
          fill={outlineColor}
        />
        <path
          d="M12.7067 24.2543L17.7366 29.2842C18.5176 30.0652 19.784 30.0652 20.565 29.2842L34.7071 15.142C35.4882 14.361 35.4882 13.0946 34.7071 12.3136L22.3935 0L11.5163 0C8.20355 0.0779116 5.58116 3.14605 5.65908 6.45884L5.71577 18.6776C5.79368 21.9904 8.76784 24.3907 12.0806 24.3128L12.7067 24.2543Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5163 0L22.3935 0L34.7071 12.3136C35.4882 13.0946 35.4882 14.361 34.7071 15.142L20.565 29.2842C19.784 30.0652 18.5176 30.0652 17.7366 29.2842L12.7067 24.2543L12.0806 24.3128C8.76784 24.3907 5.79368 21.9904 5.71577 18.6776L5.65908 6.45884C5.58116 3.14605 8.20355 0.0779116 11.5163 0ZM6.21574 18.6704L6.15903 6.44709C6.08761 3.41036 8.49138 0.571281 11.5281 0.499862L11.531 0.499793L21.9093 0.514436C20.2574 4.49611 17.9246 10.1614 15.9913 14.9491C14.9741 17.468 14.0671 19.745 13.4278 21.4066C13.1083 22.237 12.8549 22.9158 12.6879 23.3949C12.6392 23.5347 12.5974 23.6588 12.5632 23.7655L12.0518 23.8133C8.97417 23.8773 6.2886 21.6522 6.21574 18.6704ZM13.0487 23.8892L18.0901 28.9306C18.6759 29.5164 19.6257 29.5164 20.2115 28.9306L34.3536 14.7885C34.9394 14.2027 34.9394 13.2529 34.3536 12.6672L22.3774 0.690966C20.7255 4.67254 18.3899 10.3445 16.4549 15.1363C15.438 17.6545 14.5322 19.9285 13.8944 21.5862C13.5754 22.4154 13.3243 23.0882 13.1601 23.5595C13.1163 23.6849 13.0792 23.795 13.0487 23.8892Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
