import { Accordion, Box, BoxProps } from '@chakra-ui/react';
import { EmptyState } from '@web/apps/Design/components/EmptyStates/EmptyState';
import { PartType, PassiveData } from '@web/apps/types';
import { groupBy, range } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { useDesignOverview } from '../../../../hooks/useDesignOverview';
import { useDesignParts } from '../../../../hooks/useDesignParts';
import { ConnectorList } from './ConnectorList/ConnectorList';
import { filterPartsByString, filterPartsByType, isConductor, isOtherPart } from './filterUtils.ts';
import { PartNameList } from './PartNameList.tsx';
import { PartTypeList } from './PartTypeList.tsx';

export interface PartListProps extends BoxProps {
  expandedItems: number[];
  setExpandedItems: Dispatch<SetStateAction<number[]>>;
  filter: string;
}

const maxNumberOfSections = 6;
export const allExpandedItems = range(maxNumberOfSections);

export const PartList = ({ expandedItems, setExpandedItems, filter, ...rest }: PartListProps) => {
  const { designParts, isLoading } = useDesignParts();
  const { lengthUnit } = useDesignOverview();

  // Filter parts by name or part number
  const filteredParts = filterPartsByString(designParts, filter);

  // Group the parts by type
  const {
    [PartType.CONNECTOR]: connectors,
    [PartType.SPLICE]: splices,
    [PartType.PIGTAIL]: pigtails,
    [PartType.PASSIVE]: passives,
    [PartType.OVERWRAP]: overwraps,
    [PartType.LABEL]: labels,
  } = groupBy(filteredParts, 'partData.type');

  // Special cases for grouped part types
  const conductors = filterPartsByType(filteredParts, isConductor);
  const otherParts = filterPartsByType(filteredParts, isOtherPart);

  return (
    <Box overflowY="auto" {...rest}>
      {!isLoading && designParts.length === 0 ? (
        <EmptyState message="Once you add parts to your drawing, they'll show up here." />
      ) : (
        <Accordion
          allowMultiple
          index={expandedItems}
          onChange={(updatedIndex: number[]) => setExpandedItems(updatedIndex)}
          borderColor="gray.200"
        >
          <ConnectorList parts={connectors} />
          <PartNameList
            label="Cables + Wires"
            parts={conductors}
            getInfo={(conductor) => `${conductor.quantity} ${lengthUnit || ''}`}
          />
          <PartNameList label="Splices" parts={splices} />
          <PartNameList label="Pigtails" parts={pigtails} />
          <PartNameList label="Passives" parts={passives} getInfo={(part) => (part.partData as PassiveData).subtype} />
          <PartTypeList label="Overwraps" parts={overwraps} />
          <PartTypeList label="Labels" parts={labels} />
          <PartTypeList label="Other" parts={otherParts} />
        </Accordion>
      )}
    </Box>
  );
};
