import { Box, Divider, HStack, Stack } from '@chakra-ui/react';
import { Part, PartType } from '@web/apps/types';
import { isCustomer } from '@web/common/util.ts';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import TextAreaInput from '@web/components/form/TextAreaInput.tsx';
import TextInput from '@web/components/form/TextInput.tsx';
import ManufacturerDropdownMultiValue from '@web/components/ManufacturerDropdownMultiValue.tsx';
import { useCurrentUser } from '@web/queries/users.ts';
import { ElementType } from 'react';
import { useFormContext } from 'react-hook-form';

import { BackshellFields } from '../features/Backshells/BackshellFields.tsx';
import { CableFields } from '../features/Cables/CableFields.tsx';
import { ConnectorFields } from '../features/Connectors/ConnectorFields.tsx';
import { ContactFields } from '../features/Contacts/ContactFields.tsx';
import { GenericPartFields } from '../features/Generic/GenericPartFields.tsx';
import { OverwrapFields } from '../features/Overwraps/OverwrapFields.tsx';
import { PassiveFields } from '../features/Passives/PassiveFields.tsx';
import { PigtailFields } from '../features/Pigtails/PigtailFields.tsx';
import { SpliceFields } from '../features/Splices/SpliceFields.tsx';
import { WireFields } from '../features/Wires/WireFields.tsx';
import { Mode } from '../partsLibraryTypes.ts';
import PartConfidenceDisplay from './PartConfidenceDisplay.tsx';
import { PartFieldsActionBar } from './PartFieldsActionBar.tsx';
import PartSearchAIButton from './PartSearchAIButton.tsx';
import PartSpecReferences from './PartSpecReferences.tsx';
import UserMeta from './UserMeta.tsx';

const partTypeToAdditionalFieldsMap = {
  [PartType.BACKSHELL]: BackshellFields,
  [PartType.CABLE]: CableFields,
  [PartType.CONNECTOR]: ConnectorFields,
  [PartType.CONTACT]: ContactFields,
  [PartType.GENERIC]: GenericPartFields,
  [PartType.OVERWRAP]: OverwrapFields,
  [PartType.PASSIVE]: PassiveFields,
  [PartType.PIGTAIL]: PigtailFields,
  [PartType.SPLICE]: SpliceFields,
  [PartType.WIRE]: WireFields,
} as { [key in PartType]: ElementType };

interface Props {
  partType?: PartType;
  mode: Mode;
  setMode?: (mode: Mode) => void;
  onDeletePart?: () => Promise<void>;
}

export const PartFields = ({ partType, mode, setMode, onDeletePart }: Props) => {
  const { data: currentUser } = useCurrentUser();
  const { register, getValues, watch } = useFormContext<Part>();
  const part = getValues();
  const partNumber = watch('partNumber');

  const isEditing = mode !== Mode.READ;

  const AdditionalFieldsComponent = partType ? partTypeToAdditionalFieldsMap[partType] : null;

  return (
    <Stack>
      <Divider />
      <HStack spacing={10}>
        <Box flex="1">
          <EditableFormText label="Part Number" value={getValues('partNumber')} editing={isEditing}>
            <TextInput
              label="Part Number"
              formControlProps={{ isRequired: true, isDisabled: !isEditing }}
              inputRightElement={<PartSearchAIButton partNumber={partNumber} position="absolute" right="0" />}
              {...register('partNumber', { required: true })}
            />
          </EditableFormText>
        </Box>
        <Box flex="1">
          <ManufacturerDropdownMultiValue editing={isEditing} />
        </Box>
      </HStack>
      <Box>
        <EditableFormText label="Description" value={getValues('description')} editing={isEditing}>
          <TextAreaInput
            label="Description"
            formControlProps={{ isDisabled: !isEditing }}
            {...register('description')}
          />
        </EditableFormText>
      </Box>
      {AdditionalFieldsComponent && <AdditionalFieldsComponent editing={isEditing} />}
      <HStack alignItems="flex-start" spacing={10}>
        <Box flex="1">
          <PartSpecReferences editing={isEditing} />
        </Box>
        <Box flex="1">
          <PartConfidenceDisplay editing={isEditing} />
        </Box>
      </HStack>
      <Divider mt={30} />
      {!!part && (
        <>
          <HStack spacing={20}>
            {!!part.creator && <UserMeta text="Created " timestamp={part.createdAt} userName={part.creator.name} />}
            {!!part.updater && <UserMeta text="Updated " timestamp={part.updatedAt} userName={part.updater.name} />}
          </HStack>
          <Divider />
        </>
      )}
      <PartFieldsActionBar
        mode={mode}
        setMode={setMode}
        canUpdatePart={currentUser && (!isCustomer(currentUser) || currentUser.tenant.id === part?.tenantId)}
        onDeletePart={onDeletePart}
      />
    </Stack>
  );
};
