import { HStack, Radio, RadioGroup, Table, TableContainer, Tbody, Td, Textarea, Tr } from '@chakra-ui/react';
import { BuildNote, NoteType, UUID } from '@web/apps/types';
import { useCurrentUser } from '@web/queries/users.ts';
import { KeyboardEvent, useCallback, useState } from 'react';

import DeleteIconButton from '../../components/DeleteIconButton';
import { EditableText } from '../../components/EditableText';
import EditableTextarea from '../../components/EditableTextarea';
import NoteTableTypeDisplay from './NoteTableTypeDisplay';

interface Props {
  notes?: BuildNote[];
  onAddNote: (body: string, type: NoteType) => void;
  onEditNotePosition: (noteId: UUID, body: string) => void;
  onEditNoteBody: (noteId: UUID, body: string) => void;
  onDeleteNote: (noteId: UUID) => void;
}

const NotesTable = ({ notes = [], onAddNote, onEditNotePosition, onEditNoteBody, onDeleteNote }: Props) => {
  const [noteType, setNoteType] = useState(NoteType.GENERAL);
  const [inputValue, setInputValue] = useState('');
  const { data: currentUser } = useCurrentUser();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        const trimmedValue = event.currentTarget.value.trim();
        if (trimmedValue) {
          onAddNote(trimmedValue, noteType);
          setInputValue('');
        }
      }
    },
    [onAddNote, noteType],
  );

  return (
    <TableContainer color="gray.500" h="full" overflowY="auto">
      <Table>
        <Tbody>
          {notes.map(({ id, position, body, type }) => (
            <Tr key={id}>
              <Td borderRightWidth={1} borderBottomWidth={0}>
                <HStack gap={2} px={2} minW={20}>
                  <EditableText
                    size="md"
                    type="number"
                    w={16}
                    text={String(position)}
                    onEdit={(value) => onEditNotePosition(id, value)}
                    textProps={{ py: 2 }}
                  />
                  <NoteTableTypeDisplay type={type} />
                </HStack>
              </Td>
              <Td role="group">
                <HStack position="relative" w="full">
                  <EditableTextarea text={body} onEdit={(value) => onEditNoteBody(id, value)} editAction="dblclick" />
                  <DeleteIconButton
                    aria-label={`delete note ${position}`}
                    position="absolute"
                    right={0}
                    display="none"
                    _groupHover={{ display: 'flex' }}
                    onClick={() => onDeleteNote(id)}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td borderRightWidth={1} width={0} />
            <Td>
              <Textarea
                variant="unstyled"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Add note…"
              />
              {!currentUser?.featureFlags.relationalLayoutData && (
                <RadioGroup onChange={(updatedNoteType) => setNoteType(updatedNoteType as NoteType)} value={noteType}>
                  <HStack gap={4}>
                    <Radio value={NoteType.GENERAL}>General</Radio>
                    <Radio value={NoteType.FLAG}>Flag</Radio>
                  </HStack>
                </RadioGroup>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default NotesTable;
