import { HStack, Text } from '@chakra-ui/react';

interface Props {
  type: string;
  label: string;
}

export const StaticTypeListItem = ({ type, label }: Props) => (
  <HStack px={8} py={1} color="gray.600" fontSize="sm" alignItems="flex-end" minH={8}>
    <Text fontSize="xs">{type}</Text>
    <Text>{label}</Text>
  </HStack>
);
