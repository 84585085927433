import { RelationalNoteEdge } from './components/edges/NoteEdges/RelationalNoteEdge';
import { RelationalSegmentEdge } from './components/edges/SegmentEdge/RelationalSegmentEdge';
import { RelationalBreakoutPointNode } from './components/nodes/BreakoutPointNode/RelationalBreakoutPointNode';
import { RelationalConnectorNode } from './components/nodes/ConnectorNode/RelationalConnectorNode';
import { RelationalNoteGroupNode } from './components/nodes/NoteGroupNode/RelationalNoteGroupNode';
import { RelationalPassiveNode } from './components/nodes/PassiveNode/RelationalPassiveNode';
import { RelationalPigtailNode } from './components/nodes/PigtailNode/RelationalPigtailNode';
import { RelationalSpliceNode } from './components/nodes/SpliceNode/RelationalSpliceNode';
import { EdgeType, NodeType } from './relational_types';

// Register node types with specific component associations
export const relationalNodeTypes = {
  [NodeType.BreakoutPoint]: RelationalBreakoutPointNode,
  [NodeType.Connector]: RelationalConnectorNode,
  [NodeType.NoteGroup]: RelationalNoteGroupNode,
  [NodeType.Passive]: RelationalPassiveNode,
  [NodeType.Pigtail]: RelationalPigtailNode,
  [NodeType.Splice]: RelationalSpliceNode,
};

// Register edge types with specific component associations
export const relationalEdgeTypes = {
  [EdgeType.SegmentEdge]: RelationalSegmentEdge,
  [EdgeType.NoteEdge]: RelationalNoteEdge,
};
