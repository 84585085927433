import { Input, InputProps } from '@chakra-ui/react';
import { useRef } from 'react';
import { useMount } from 'react-use';

interface MeasurementInputProps extends InputProps {
  focusOnMount?: boolean;
}

export const MeasurementInput = ({ focusOnMount, ...rest }: MeasurementInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useMount(() => {
    if (focusOnMount && ref.current) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  });

  return (
    <Input
      ref={ref}
      onFocus={(e) => e.target.select()}
      variant="unstyled"
      size="xs"
      w={12}
      textAlign="center"
      bgColor="blackAlpha.100"
      {...rest}
    />
  );
};
