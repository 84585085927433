import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { Actor, BOMItem, Design, Tenant, UUID } from '@web/apps/types';

import { useDesignId } from './useDesignId.tsx';

// Define the DesignOverview interface
export interface DesignOverview {
  id: UUID;
  name: string;
  description: string;
  partNumber: string;
  partRevision: string;
  lengthUnit: 'mm' | 'in';
  measurementMode: 'Face' | 'Rear';
  createdAt: string;
  lockedAt: string | null;
  updatedAt: string;
  creator?: Actor;
  tenant: Tenant;
  bom: BOMItem[];
}

// Define a default overview object
const defaultDesignOverview: DesignOverview = {
  id: '',
  name: '',
  description: '',
  partNumber: '',
  partRevision: '',
  lengthUnit: 'mm',
  measurementMode: 'Rear',
  createdAt: '',
  lockedAt: null,
  updatedAt: '',
  creator: undefined,
  tenant: {} as Tenant,
  bom: [],
};

// Define the select function
const selectDesignOverview = (design: Design | null): DesignOverview => ({
  id: design?.id || '',
  name: design?.name || '',
  description: design?.description || '',
  partNumber: design?.partNumber || '',
  partRevision: design?.partRevision || '',
  lengthUnit: design?.lengthUnit || 'mm',
  measurementMode: design?.measurementMode || 'Rear',
  createdAt: design?.createdAt || '',
  lockedAt: design?.lockedAt || null,
  updatedAt: design?.updatedAt || '',
  creator: design?.creator,
  tenant: design?.tenant || ({} as Tenant),
  bom: design?.bom || [],
});

/**
 * Hook to get a design overview by its ID, only subscribing to specific properties by using the select function of
 * useDesignQuery.
 */
export const useDesignOverview = (): DesignOverview => {
  const designId = useDesignId();

  // Fetch design overview
  const { data } = useDesignQuery(designId, selectDesignOverview);

  return data || defaultDesignOverview;
};
