import { Schematic } from '@web/apps/types';
import { BaseEdge, Edge, EdgeProps, getSmoothStepPath, getStraightPath } from '@xyflow/react';
import { Fragment } from 'react';

import { defaultSchematicConfig } from '../../../config.ts';
import { isShield } from '../../../utils/helpers.ts';
import { EdgeLabel } from './EdgeLabel.tsx';
import { FloatingEdgeLabel } from './FloatingEdgeLabel.tsx';

export type ConductorEdgeData = {
  conductor: Schematic.Conductor;
  type: 'bend' | 'transition' | 'grouped-with-source' | 'grouped-with-destination' | 'shield';
};

export const defaultConductorEdgeData: ConductorEdgeData = {
  conductor: {
    id: '',
    name: '',
    partNumber: '',
    gauge: 22,
    signal: '',
    shieldId: null,
    shielding: 'Unshielded',
    shieldGroup: [],
    twistingGroup: [],
  },
  type: 'transition',
};

export type ConductorEdgeType = Edge<ConductorEdgeData>;

export const Conductor = (props: EdgeProps<ConductorEdgeType>) => {
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data } = props;
  const { conductor, type } = data ?? defaultConductorEdgeData;

  // Get edge path and label position
  let edgePath, labelX, labelY;

  if (type === 'bend') {
    [edgePath, labelX, labelY] = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });
  } else {
    [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      offset: 0,
    });
  }

  const edgeLength = getEdgeLength(props);
  const showInlineLabel = type === 'transition' && edgeLength >= 400;

  return (
    <Fragment>
      {/* Draw the edge */}
      <svg>
        <BaseEdge
          path={edgePath}
          style={{
            stroke: defaultSchematicConfig.conductorColor,
            strokeWidth: 1,
            ...(isShield(conductor) && {
              strokeDasharray: '4 4',
              strokeLinecap: 'square',
            }),
          }}
          interactionWidth={10}
        />
      </svg>

      {/* Render Edge Label */}
      {showInlineLabel && <EdgeLabel labelX={labelX} labelY={labelY} edgeLength={edgeLength} conductor={conductor} />}

      {/* Render Inline Labels */}
      {type === 'grouped-with-source' && (
        <FloatingEdgeLabel position="source" conductor={conductor} coords={{ x: props.targetX, y: props.sourceY }} />
      )}
      {type === 'grouped-with-destination' && (
        <FloatingEdgeLabel
          position="destination"
          conductor={conductor}
          coords={{ x: props.sourceX, y: props.targetY }}
        />
      )}
    </Fragment>
  );
};

const getEdgeLength = <T extends Edge<any>>({ sourceX, sourceY, targetX, targetY }: EdgeProps<T>): number => {
  return Math.hypot(targetX - sourceX, targetY - sourceY);
};
