import { Box, Checkbox, FormControl, FormLabel, HStack, Select, Stack } from '@chakra-ui/react';
import { Splice } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import Loading from '@web/components/Loading.tsx';
import { useController, useFormContext } from 'react-hook-form';

import { useConfiguration } from '../../../../queries/admin.ts';
import MinMaxAwgFields from '../../components/MinMaxAwgFields.tsx';
import ToolRelationRows from '../../components/ToolRelationRows.tsx';

interface Props {
  editing: boolean;
}

export const SpliceFields = ({ editing }: Props) => {
  const { register, getValues, control } = useFormContext<Splice>();
  const { field: insulated } = useController({ name: 'insulated', control });
  const { field: madeOnAssembly } = useController({ name: 'madeOnAssembly', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  return (
    <Stack>
      <HStack spacing={10}>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Splice Type" value={getValues('subtype') ?? ''}>
            <FormControl>
              <FormLabel>Splice Type</FormLabel>
              <Select isDisabled={!editing} {...register('subtype')}>
                {config.parts.splice.types.map((types) => {
                  return (
                    <option key={types} value={types}>
                      {types}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <MinMaxAwgFields editing={editing} />
        </Box>
      </HStack>
      <FormControl>
        <Checkbox
          isDisabled={!editing}
          isChecked={insulated.value}
          onChange={(e) => insulated.onChange(e.target.checked)}
          aria-label="Insulated"
        >
          Insulated
        </Checkbox>
      </FormControl>
      <FormControl>
        <Checkbox
          isDisabled={!editing}
          isChecked={madeOnAssembly.value}
          onChange={(e) => madeOnAssembly.onChange(e.target.checked)}
          aria-label="Made On Assembly"
        >
          Made On Assembly
        </Checkbox>
      </FormControl>
      <ToolRelationRows editing={editing} />
    </Stack>
  );
};
