import { DesignPart } from '@web/apps/types';

import { PartListGroup } from './PartListGroup';
import { PartNameListItem } from './PartNameListItem';

interface Props {
  label: string;
  parts?: DesignPart[];
  getInfo?: (part: DesignPart) => string;
}

export const PartNameList = ({ label, parts, getInfo }: Props) => {
  if (parts && parts.length > 0) {
    return (
      <PartListGroup label={label} count={parts.length}>
        {parts.map((part) => (
          <PartNameListItem key={part.id} part={part} info={getInfo ? getInfo(part) : undefined} />
        ))}
      </PartListGroup>
    );
  }

  return null;
};
