import { Box, Text } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';

interface Props {
  designPart: DesignPart;
  angle: number;
}

export const DesignPartLabel = ({ designPart, angle }: Props) => {
  const isFacingLeft = angle > 90 && angle < 270;

  return (
    <Box
      position="absolute"
      top="10px"
      {...(isFacingLeft ? { right: '52px' } : { left: '52px' })}
      transform={`rotate(${angle}deg)`}
    >
      <Text color="gray.900" fontFamily="mono" fontWeight="bold" transform={`rotate(${-angle}deg)`} whiteSpace="nowrap">
        {designPart.name}
      </Text>
    </Box>
  );
};
