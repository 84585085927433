import { Input, InputProps, Text } from '@chakra-ui/react';
import { useWiringList } from '@web/apps/Design/features/WiringList/hooks/useWiringList.tsx';
import { Col, Row } from '@web/apps/Design/features/WiringList/types.ts';
import { processArrowKeys } from '@web/apps/Design/features/WiringList/utils/processArrowKeys.ts';
import { KeyboardEvent, useEffect, useRef } from 'react';

interface Props extends InputProps {
  row: Row;
  col: Col;
  isActive: boolean;
  onEnterKeyPress?: () => void;
}

export const EditableCellText = ({ value, isActive, onEnterKeyPress, ...rest }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { rowPendingChange, setIsKeyboardNav } = useWiringList();

  const handleFocus = () => {
    if (!rowPendingChange) {
      requestAnimationFrame(() => {
        inputRef.current?.select();
      });
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    setIsKeyboardNav(true);

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onEnterKeyPress?.();
      return;
    }

    processArrowKeys(event);
  };

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  useEffect(() => {
    if (!rowPendingChange && inputRef.current) {
      inputRef.current.select();
    }
  }, [rowPendingChange]);

  return isActive ? (
    <Input
      ref={inputRef}
      value={value}
      variant="unstyled"
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      isReadOnly={Boolean(rowPendingChange)}
      isDisabled={Boolean(rowPendingChange)}
      _focus={{
        outline: 'none',
        boxShadow: 'none',
      }}
      fontSize="xs"
      color="blue.500"
      px={8}
      {...rest}
    />
  ) : (
    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" px={8}>
      {value || ''}
    </Text>
  );
};
