import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -12,
  y: 0,
};

const originalWidth = 24;
const originalHeight = 32;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const RectangularConnector0 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path d="M9 28L3.49691e-07 28L2.44784e-06 4L9 4L9 28Z" fill={secondaryColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.5 27.5L8.5 27.5L8.5 4.5L0.500002 4.5L0.5 27.5ZM9 28L9 4L2.44784e-06 4L3.49691e-07 28L9 28Z"
          fill={outlineColor}
        />
        <path
          d="M24 32L10 32C8.89543 32 8 31.1046 8 30L8 2C8 0.89543 8.89543 -1.32048e-06 10 -1.22392e-06L24 0L24 32Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 31.5L23.5 31.5L23.5 0.5L10 0.499999C9.17158 0.499999 8.5 1.17157 8.5 2L8.5 30C8.5 30.8284 9.17157 31.5 10 31.5ZM24 32L24 0L10 -1.22392e-06C8.89543 -1.32048e-06 8 0.89543 8 2L8 30C8 31.1046 8.89543 32 10 32L24 32Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
