import { MenuItemOption, MenuOptionGroup, Text } from '@chakra-ui/react';
import {
  useCreateNoteGroupBuildNoteMutation,
  useDeleteNoteGroupBuildNoteMutation,
} from '@web/apps/Design/api/note-group-build-notes-api';
import { useDesignBuildNotes } from '@web/apps/Design/hooks/useDesignBuildNotes';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId';
import { BuildNote, NoteGroupBuildNote } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { MenuPosition } from '../../../../../../components/menu/useContextMenuState';
import { MenuGroupTitleWithLoading } from '../../components/menus/MenuGroupTitleWithLoading';

interface Props {
  noteGroupBuildNotes?: NoteGroupBuildNote[];
  noteGroupId?: string;
  targetId?: string;
  targetType?: 'LayoutNode' | 'LayoutEdge';
  menuPosition?: MenuPosition;
  onToggle?: () => void;
}

export const BuildNoteMenuSection = ({
  noteGroupBuildNotes = [],
  noteGroupId,
  targetId,
  targetType = 'LayoutNode',
  menuPosition,
  onToggle,
}: Props) => {
  const { data: buildNotes = [] } = useDesignBuildNotes();
  const designId = useDesignId();
  const { screenToFlowPosition } = useReactFlow();

  const { mutateAsync: createNoteGroupBuildNoteMutation, isPending: isCreatingNoteGroupBuildNote } =
    useCreateNoteGroupBuildNoteMutation();
  const { mutateAsync: deleteNoteGroupBuildNoteMutation, isPending: isDeletingNoteGroupBuildNote } =
    useDeleteNoteGroupBuildNoteMutation();

  const handleToggleFlagNote = useCallback(
    async (note: BuildNote) => {
      const existingNoteGroupBuildNote = noteGroupBuildNotes?.find(
        (noteGroupBuildNote) => noteGroupBuildNote.buildNoteId === note.id,
      );

      if (existingNoteGroupBuildNote) {
        await deleteNoteGroupBuildNoteMutation({
          designId,
          noteGroupBuildNoteId: existingNoteGroupBuildNote.id,
        });
      } else {
        const position = menuPosition ? screenToFlowPosition(menuPosition) : undefined;
        await createNoteGroupBuildNoteMutation({
          designId,
          buildNoteId: note.id,
          noteGroupId,
          targetId,
          targetType,
          position,
        });
      }

      onToggle?.();
    },
    [
      noteGroupBuildNotes,
      deleteNoteGroupBuildNoteMutation,
      designId,
      menuPosition,
      screenToFlowPosition,
      createNoteGroupBuildNoteMutation,
      noteGroupId,
      targetId,
      targetType,
      onToggle,
    ],
  );

  if (buildNotes?.length === 0) {
    return (
      <MenuOptionGroup title="Flag Notes">
        <MenuItemOption isDisabled>No Flag Notes</MenuItemOption>
      </MenuOptionGroup>
    );
  }

  return (
    <MenuOptionGroup
      // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
      title={
        <MenuGroupTitleWithLoading
          title="Flag Notes"
          isLoading={isCreatingNoteGroupBuildNote || isDeletingNoteGroupBuildNote}
        />
      }
      type="checkbox"
      value={noteGroupBuildNotes?.map((noteGroupBuildNote: NoteGroupBuildNote) => noteGroupBuildNote.buildNoteId)}
    >
      {buildNotes.map((note) => (
        <MenuItemOption key={note.id} value={note.id} onClick={() => handleToggleFlagNote(note)}>
          <Text
            width="200px"
            isTruncated
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >{`${note.position} - ${note.body}`}</Text>
        </MenuItemOption>
      ))}
    </MenuOptionGroup>
  );
};
