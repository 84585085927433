import { OptionBase, OptionsOrGroups } from 'chakra-react-select';

export interface Option extends OptionBase {
  value: string;
  label: string;
}

export interface GroupedOption {
  label: string;
  options: Option[];
}

export const isOption = (value: unknown): value is Option => {
  return typeof value === 'object' && value !== null && 'value' in value && 'label' in value;
};

export const isGroupedOption = (item: Option | GroupedOption): item is GroupedOption => {
  return Array.isArray((item as GroupedOption).options);
};

export const stringToOption = (value: string, options: (Option | GroupedOption)[]): Option | null => {
  return findSelectedOption(value, options);
};

export const optionToString = (option: Option | null): string => {
  return option ? option.value : '';
};

export const findSelectedOption = (
  value: string | undefined,
  options: OptionsOrGroups<Option, GroupedOption>,
): Option | null => {
  if (!value || !Array.isArray(options) || options.length === 0) {
    return null;
  }

  for (const item of options) {
    if (isGroupedOption(item)) {
      // Handle grouped options
      const found = item.options.find((opt) => isOption(opt) && opt.value === value);
      if (found) return found;
    } else if (isOption(item) && item.value === value) {
      // Handle ungrouped options
      return item;
    }
  }

  return null;
};

export const isOptionInGroup = (
  optionValue: string,
  options: (Option | GroupedOption)[],
  groupLabel: string,
): boolean => {
  const group = options.find((group): group is GroupedOption => 'options' in group && group.label === groupLabel);

  return !!group?.options.find((option) => option.value === optionValue);
};

export const createGroupStyledOptions = (groupLabel: string, style: Record<string, any>) => {
  return {
    option: (provided: any, state: any) => {
      const isInGroup = isOptionInGroup(state.data.value, state.selectProps.options, groupLabel);

      if (isInGroup) {
        return {
          ...provided,
          ...style,
        };
      }

      return provided;
    },
  };
};
