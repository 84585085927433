import { Box, BoxProps } from '@chakra-ui/react';
import { createGradient, extractColors } from '@web/common/colors.ts';
import { useConfiguration } from '@web/queries/admin';

interface Props extends BoxProps {
  text?: string;
  height?: number;
  width?: number;
}

export const ColorBlock = ({ text = '', height = 3, width = 6, ...rest }: Props) => {
  const { data: config } = useConfiguration();

  if (!config) return null;

  const milSpecColors = config.colors.milSpec;
  const colors = extractColors(text, milSpecColors);

  if (colors.length === 0) return null;

  return (
    <Box
      w={width}
      h={height}
      bgImage={createGradient(colors)}
      border={colors.includes(milSpecColors.White) ? '1px solid' : `none`}
      borderColor="gray.500"
      borderRadius="3"
      {...rest}
    />
  );
};
