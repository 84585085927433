import { Divider, Stack, StackProps } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import { AssemblyNavigatorPartDetails } from './components/AssemblyNavigatorPartDetails.tsx';
import { AssemblyNavigatorTitle } from './components/AssemblyNavigatorTitle.tsx';
import { FilterBar } from './components/PartList/FilterBar.tsx';
import { allExpandedItems, PartList } from './components/PartList/PartList.tsx';

interface Props extends StackProps {
  windowIcon: ReactNode;
}

export const AssemblyNavigator = ({ windowIcon, ...rest }: Props) => {
  const [expandedItems, setExpandedItems] = useState<number[]>(allExpandedItems);

  const [filter, setFilter] = useState('');

  return (
    <Stack spacing={0} borderColor="gray.300" divider={<Divider />} {...rest}>
      <AssemblyNavigatorPartDetails windowIcon={windowIcon} />
      <AssemblyNavigatorTitle
        onAddPart={() => {
          setExpandedItems(allExpandedItems);
        }}
      />
      <FilterBar filter={filter} setFilter={setFilter} />
      <PartList flex={1} filter={filter} expandedItems={expandedItems} setExpandedItems={setExpandedItems} />
    </Stack>
  );
};
