import { Box } from '@chakra-ui/react';
import { BreakoutPoint } from '@web/apps/Design/components/Shapes/BreakoutPoint.tsx';
import { GroupedConductor, NoteGroupBuildNote } from '@web/apps/types';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';

import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle';
import { HandleTypes } from '../../../../Layout/types/handles';
import { getNodeColor } from '../../../../Layout/utils/common';
import { isBreakoutPointNode } from '../../../relational_types';
import { BreakoutPointContextMenu } from './BreakoutPointContextMenu';

export type RelationalBreakoutPointNodeData = {
  groupedConductors: GroupedConductor[];
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalBreakoutPointNodeData: RelationalBreakoutPointNodeData = {
  groupedConductors: [],
  noteGroupBuildNotes: [],
};

export type RelationalBreakoutPointNodeType = Node<RelationalBreakoutPointNodeData>;

/**
 * Breakout point node component.
 * @param props
 * @constructor
 */
export const RelationalBreakoutPointNode = (props: NodeProps<RelationalBreakoutPointNodeType>) => {
  const { id, selected, data } = props;
  const { noteGroupBuildNotes, groupedConductors } = data;
  const { getIntersectingNodes } = useReactFlow();
  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  // Check if two breakout points are intersecting
  const intersections = getIntersectingNodes(props, true).filter(isBreakoutPointNode);

  // If they are on top of each other, color them red
  const color = intersections.length > 0 ? 'red' : getNodeColor(selected);

  return (
    <Box position="relative" onContextMenu={handleContextMenu}>
      <BreakoutPoint color={color} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Note} />
      <BreakoutPointContextMenu
        isOpen={isOpen}
        position={position}
        closeMenu={closeMenu}
        layoutNodeId={id}
        noteGroupBuildNotes={noteGroupBuildNotes}
        groupedConductors={groupedConductors}
      />
    </Box>
  );
};
