import { Box, HStack, Spacer } from '@chakra-ui/react';
import { Fragment } from 'react/jsx-runtime';

import { defaultSchematicConfig } from '../../../../config.ts';
import { getInlineType } from '../../../../utils/inlines';
import { Inline } from '../Inline';
import { RowProps } from '../Paths.tsx';
import { Row } from './Row.tsx';

export const ShieldSpliceRow = ({ path }: RowProps) => (
  <Fragment>
    <Row>
      {path.inlines.map((inline, index) => (
        <HStack key={`inline-${index}`} flex={1}>
          {getInlineType(inline, path) === 'destination' && <Spacer />}
          <Inline inline={inline} path={path} />
          {index !== path.inlines.length - 1 && <Spacer />}
        </HStack>
      ))}
    </Row>
    <Row>
      <Box h={defaultSchematicConfig.cavityHeight} />
    </Row>
  </Fragment>
);
