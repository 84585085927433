import { AccordionButton, AccordionItem, AccordionPanel, Badge, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

interface Props {
  label: string;
  count: number;
  children: ReactNode;
}

export const PartListGroup = ({ label, count, children }: Props) => (
  <AccordionItem id={label} borderTopWidth={0} borderBottomWidth={1} p={1}>
    {({ isExpanded }) => (
      <>
        <AccordionButton gap={1} px={3} py={2} lineHeight={4} _hover={{ bgColor: 'gray.200' }}>
          <Text as="span" fontWeight="bold" fontSize="sm" color="gray.700">
            {label}
          </Text>
          <Icon boxSize={4} as={isExpanded ? BiChevronUp : BiChevronDown} />
          <Spacer />
          <Badge variant="solid" minW={4} fontSize="0.625rem">
            {count}
          </Badge>
        </AccordionButton>
        <AccordionPanel as={Stack} gap={1} p={0} mt={1}>
          {children}
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);
