import { ShapeProps } from '@web/apps/types';

export const Resistor = ({ color }: ShapeProps) => (
  <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill={color}
    />
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64289 3.30547C6.85948 3.3197 7.04463 3.46653 7.10783 3.67418L9.12006 10.2858L10.301 7.45151C10.382 7.25708 10.572 7.13043 10.7826 7.13043H12.8696C13.1577 7.13043 13.3913 7.36403 13.3913 7.65217C13.3913 7.94032 13.1577 8.17391 12.8696 8.17391H11.1304L9.52508 12.0268C9.44043 12.2299 9.23739 12.3581 9.01757 12.3472C8.79775 12.3363 8.60843 12.1886 8.54434 11.978L6.51188 5.29991L5.34454 7.86807C5.25988 8.05433 5.07416 8.17391 4.86957 8.17391H2.78261C2.49446 8.17391 2.26087 7.94032 2.26087 7.65217C2.26087 7.36403 2.49446 7.13043 2.78261 7.13043H4.53361L6.13372 3.61019C6.22354 3.41259 6.42631 3.29124 6.64289 3.30547Z"
      fill="white"
    />
  </svg>
);
