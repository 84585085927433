import { Td, Text, ToastId, Tooltip, Tr } from '@chakra-ui/react';
import { Design } from '@web/apps/types';
import { RouteNames } from '@web/consts/routeNames';
import { MouseEvent } from 'react';
import { generatePath, useNavigate } from 'react-router';

import { useDeleteDesignMutation } from '../api/designs-api';
import { Extension } from '../api/export-api';
import { useDesignExport } from '../hooks/useDesignExport';
import { useDesignToast } from '../hooks/useDesignToast';
import { DesignActions } from './DesignActions';
import { PartRevisionLabel } from './PartRevisionLabel';

interface DesignRowProps {
  design: Design;
  openCloneModal: () => void;
  openEditModal: () => void;
  setSelectedDesign: (design: Design) => void;
}

export const DesignRow = ({ design, openCloneModal, openEditModal, setSelectedDesign }: DesignRowProps) => {
  const navigate = useNavigate();
  const designPath = generatePath(RouteNames.DESIGNS.DESIGN, { designId: design.id });
  const { showLoadingToast, showErrorToast, closeToast } = useDesignToast();
  const { handleExportPdf, exportDesign, layoutReactFlow, schematicReactFlow } = useDesignExport();
  const { mutate: deleteDesign } = useDeleteDesignMutation();

  const handleRowClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.metaKey || event.ctrlKey) {
      // Cmd+Click (Mac) or Ctrl+Click (Windows) — Open in new tab
      window.open(designPath, '_blank');
    } else if (event.shiftKey) {
      // Shift+Click — Open in a new window
      window.open(designPath, '_blank', 'noopener,noreferrer');
    } else {
      // Regular click — Navigate normally
      navigate(designPath);
    }
  };

  const handleRowMouseOver = () => setSelectedDesign(design);

  const handleExportDesign = async (design: Design, extension: Extension) => {
    let toastId: ToastId | undefined;

    try {
      toastId = showLoadingToast('Exporting Design', `Exporting design to .${extension}`);

      const baseExportData = {
        designId: design.id,
        partNumber: design.partNumber,
        partRevision: design.partRevision,
        extension,
      };

      if (extension === 'pdf') {
        await handleExportPdf(design, baseExportData);
      } else {
        await exportDesign(baseExportData);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      showErrorToast('Export Failed', errorMessage);
    } finally {
      if (toastId) {
        closeToast(toastId);
      }
    }
  };

  const handleDeleteDesign = (design: Design) => deleteDesign({ designId: design.id });

  return (
    <Tr role="group" onClick={handleRowClick} onMouseOver={handleRowMouseOver}>
      <Td>
        <DesignActions
          onEdit={openEditModal}
          onClone={openCloneModal}
          onExportPdf={() => handleExportDesign(design, 'pdf')}
          onExportExcel={() => handleExportDesign(design, 'xlsx')}
          onDelete={() => handleDeleteDesign(design)}
          onClick={(e) => e.stopPropagation()}
        />
        {layoutReactFlow}
        {schematicReactFlow}
      </Td>
      <Td>
        <PartRevisionLabel partNumber={design.partNumber} partRevision={design.partRevision} />
      </Td>
      <Td>
        {design.description && (
          <Tooltip label={design.description}>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {design.description}
            </Text>
          </Tooltip>
        )}
      </Td>
      <Td>{design.lengthUnit}</Td>
      <Td>
        {design.creator?.name && (
          <Tooltip label={design.creator.name}>
            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {design.creator.name}
            </Text>
          </Tooltip>
        )}
      </Td>
    </Tr>
  );
};
