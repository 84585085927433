import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -12,
  y: 0,
};

const originalWidth = 24;
const originalHeight = 32;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const GenericConnector = ({ color = defaultLayoutConfig.nodeColor, angle = 0 }: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M24 29C24 30.6569 22.6569 32 21 32L11.4876 32C10.8294 32 10.1895 31.7836 9.66649 31.384L1.1789 24.9005C0.435906 24.333 7.47336e-07 23.4515 8.29073e-07 22.5165L1.96846e-06 9.48349C2.05019e-06 8.54853 0.435906 7.66702 1.1789 7.09947L9.66649 0.615973C10.1895 0.216441 10.8294 -1.15141e-06 11.4876 -1.09387e-06L21 -2.62268e-07C22.6569 -1.17422e-07 24 1.34315 24 3L24 29Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
