import { HStack, MenuItemOption, MenuOptionGroup, Text } from '@chakra-ui/react';
import { useDeleteDesignPartLayoutEdgeMutation } from '@web/apps/Design/api/design-part-layout-edges-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { InnermostOverwrapIcon } from '@web/apps/Design/icons/InnermostOverwrapIcon.tsx';
import { OutermostOverwrapIcon } from '@web/apps/Design/icons/OutermostOverwrapIcon.tsx';
import { DesignPartLayoutEdge } from '@web/apps/types';
import { map } from 'lodash';
import { useCallback } from 'react';

import { MenuGroupTitleWithLoading } from '../../menus/MenuGroupTitleWithLoading';

interface Props {
  overwrapEdges: DesignPartLayoutEdge[];
}

export const OverwrapsMenuSection = ({ overwrapEdges }: Props) => {
  const designId = useDesignId();

  const { mutateAsync: deleteDesignPartLayoutEdge, isPending: isDeletingDesignPartLayoutEdge } =
    useDeleteDesignPartLayoutEdgeMutation();

  const handleDeleteOverwrap = useCallback(
    async (overwrapEdge: DesignPartLayoutEdge) => {
      await deleteDesignPartLayoutEdge({
        designId,
        designPartLayoutEdgeId: overwrapEdge.id,
      });
    },
    [deleteDesignPartLayoutEdge, designId],
  );

  const showIcons = overwrapEdges.length > 1;

  return (
    <MenuOptionGroup
      // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
      title={<MenuGroupTitleWithLoading title="Overwraps" isLoading={isDeletingDesignPartLayoutEdge} />}
      type="checkbox"
      value={map(overwrapEdges, 'id')}
    >
      {overwrapEdges.length > 0 ? (
        overwrapEdges.map((overwrapEdge, index) => (
          <MenuItemOption
            key={overwrapEdge.id}
            value={overwrapEdge.id}
            onClick={() => handleDeleteOverwrap(overwrapEdge)}
          >
            <HStack spacing={4}>
              <Text flex={1} maxW={52} isTruncated>
                {overwrapEdge.designPartNumber}
              </Text>
              <Text>
                {showIcons && index === 0 ? (
                  <HStack align="center">
                    <InnermostOverwrapIcon />
                    <Text>Innermost</Text>
                  </HStack>
                ) : null}
                {showIcons && index === overwrapEdges.length - 1 ? (
                  <HStack align="center">
                    <OutermostOverwrapIcon />
                    <Text>Outermost</Text>
                  </HStack>
                ) : null}
              </Text>
            </HStack>
          </MenuItemOption>
        ))
      ) : (
        <MenuItemOption isDisabled>No Overwraps</MenuItemOption>
      )}
    </MenuOptionGroup>
  );
};
