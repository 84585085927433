import { Checkbox, FormControl, FormLabel, Select, SimpleGrid, Stack, VStack } from '@chakra-ui/react';
import { Accessory, Connector, PartType } from '@web/apps/types';
import { valueToLabel } from '@web/common/util.ts';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import React from 'react';
import { ControllerRenderProps, useController, useFormContext } from 'react-hook-form';

import CompatibleBackshellTable from '../../components/CompatibleBackshellTable.tsx';
import CompatibleContactsTable from '../../components/CompatibleContactsTable.tsx';
import InsertArrangementDropdown from '../../components/InsertArrangementDropdown.tsx';
import ToolRelationRows from '../../components/ToolRelationRows.tsx';

interface Props {
  editing: boolean;
}

export const ConnectorFields = ({ editing }: Props) => {
  const { control, getValues, register, setValue, watch } = useFormContext<Connector>();
  const { field: shielded } = useController({ name: 'shielded', control });
  const { field: acceptsContacts } = useController({ name: 'acceptsContacts', control });
  const { field: acceptsBackshell } = useController({ name: 'acceptsBackshell', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const accessoriesOfType = (partType: PartType) => {
    return getValues('accessories')?.filter((ia: Accessory) => {
      return ia.type === partType;
    });
  };

  const removeAccessoriesOfType = (partType: PartType) => {
    setValue(
      'accessories',
      (getValues('accessories') ?? [])
        .filter((ia: Accessory) => ia.type !== partType || ia.id !== undefined)
        .map((ia: Accessory) => {
          if (ia.type === partType) ia._destroy = true;
          return ia;
        }),
    );
  };

  const handleSwitchChange = (field: ControllerRenderProps<Connector>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field === acceptsContacts) {
      if (e.target.checked) setValue('termination', '');
      else removeAccessoriesOfType(PartType.CONTACT);
    } else if (field === acceptsBackshell && !e.target.checked) removeAccessoriesOfType(PartType.BACKSHELL);
    field.onChange(e.target.checked);
  };

  return (
    <Stack>
      <SimpleGrid columns={2} spacing={10}>
        <EditableFormText
          editing={editing}
          label="Shape"
          value={valueToLabel(getValues('shape'), config.parts.connector.shapes)}
        >
          <FormControl>
            <FormLabel>Shape</FormLabel>
            <Select isDisabled={!editing} {...register('shape')}>
              <option value=""></option>;
              {config.parts.connector.shapes.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </EditableFormText>
        <EditableFormText
          editing={editing}
          label="Gender"
          value={valueToLabel(getValues('gender'), config.parts.connector.genders)}
        >
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select isDisabled={!editing} {...register('gender')}>
              <option value=""></option>;
              {config.parts.connector.genders.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </EditableFormText>
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={10} mt={4}>
        <EditableFormText editing={editing} label="Termination" value={getValues('termination')}>
          <FormControl flex={1}>
            <FormLabel>Termination</FormLabel>
            <Select isDisabled={!editing || acceptsContacts.value != false} {...register('termination')}>
              <option value="">None</option>;
              {config.parts.connector.terminations.map((type) => {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </EditableFormText>
        <InsertArrangementDropdown editing={editing} />
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={10} mt={4}>
        <VStack spacing={4} alignSelf="flex-start">
          <FormControl>
            <Checkbox
              isDisabled={!editing}
              isChecked={shielded.value}
              onChange={handleSwitchChange(shielded)}
              aria-label="Shielded"
              defaultChecked
            >
              Shielded
            </Checkbox>
          </FormControl>
          <FormControl>
            <Checkbox
              isDisabled={!editing}
              isChecked={acceptsBackshell.value}
              onChange={handleSwitchChange(acceptsBackshell)}
              aria-label="Accepts backshell"
              defaultChecked
            >
              Accepts Backshell
            </Checkbox>
          </FormControl>
        </VStack>
        <FormControl flex={1}>
          <Checkbox
            isDisabled={!editing}
            isChecked={acceptsContacts.value}
            onChange={handleSwitchChange(acceptsContacts)}
            aria-label="Accepts contacts"
            defaultChecked
          >
            Accepts Contacts
          </Checkbox>
        </FormControl>
      </SimpleGrid>
      <Stack mt={4}>
        {watch('acceptsContacts', true) && (editing || accessoriesOfType(PartType.CONTACT).length > 0) && (
          <CompatibleContactsTable editing={editing} />
        )}
        {watch('acceptsBackshell', true) && (editing || accessoriesOfType(PartType.BACKSHELL).length > 0) && (
          <CompatibleBackshellTable editing={editing} />
        )}
      </Stack>
      <ToolRelationRows editing={editing} />
    </Stack>
  );
};
