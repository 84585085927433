import { ChakraProvider, ColorModeProvider, Flex, Grid } from '@chakra-ui/react';
import { Outlet } from 'react-router';

import TopbarNav from '../../../components/navigation/TopbarNav.tsx';
import lightTheme from '../../themes.ts';
import { PartsSideNav } from './PartsSideNav.tsx';

export const PartsLibraryLayout = () => {
  localStorage.setItem('chakra-ui-color-mode', 'light');

  return (
    <ChakraProvider theme={lightTheme}>
      <ColorModeProvider>
        <Flex height="full" flexDirection="column">
          <TopbarNav />
          <Grid flex={1} gridTemplateColumns="min-content 1fr" overflow="hidden">
            <PartsSideNav />
            <Outlet />
          </Grid>
        </Flex>
      </ColorModeProvider>
    </ChakraProvider>
  );
};
