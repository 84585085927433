import { Box, BoxProps, Center, Text } from '@chakra-ui/react';
import { getDestinationHandle, getSourceHandle } from '@web/apps/Design/features/Schematic/utils/handles.ts';
import { centerAbsoluteVertical } from '@web/apps/styles.ts';
import { Schematic } from '@web/apps/types';
import { Position } from '@xyflow/react';

import { defaultSchematicConfig } from '../../../config.ts';
import { CavityType } from '../../../types/cavities.ts';
import { isHousing, isLoopback } from '../../../utils/helpers.ts';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  type: CavityType;
  point?: Schematic.ConnectionPoint | null;
  path?: Schematic.Path | null;
}

export const Cavity = ({ type, point = null, path = null, ...rest }: Props) => {
  const cavityName = getCavityName(point);
  const handleId = getHandleId(point, path, type);
  const isHidden = shouldHideCavity(point, path, type);

  const handleClick = () => {
    console.debug('Cavity', point);
  };

  return (
    <Box display="flex" px={4} py={2} visibility={isHidden ? 'hidden' : 'visible'}>
      <Center
        data-cavity-id={point?.id}
        data-cavity-name={cavityName}
        data-cavity-type={type}
        position="relative"
        h={defaultSchematicConfig.cavityHeight}
        w={defaultSchematicConfig.cavityWidth}
        bg={defaultSchematicConfig.cavityColor}
        borderRadius="md"
        onClick={handleClick}
        pointerEvents="all"
        cursor="pointer"
        {...rest}
      >
        <>
          <Text fontFamily="mono" fontSize="sm">
            {cavityName}
          </Text>
          <Box
            sx={{
              ...(type === 'source' ? { right: '-8px' } : { left: '-8px' }),
              ...centerAbsoluteVertical,
            }}
          >
            {handleId && <ConnectionHandle id={handleId} handlePosition={getHandlePosition(type)} />}
          </Box>
        </>
      </Center>
    </Box>
  );
};

const getCavityName = (point: Schematic.ConnectionPoint | null) => {
  return isHousing(point) ? 'BS' : point?.name === 'Splice' ? 'SPL' : point?.name || '';
};

const getHandleId = (point: Schematic.ConnectionPoint | null, path: Schematic.Path | null, type: CavityType) => {
  // Early return if no path exists
  if (!path) {
    return null;
  }

  // Handle source points
  if (point && type === 'source') {
    return point.id === path.destination?.id ? getDestinationHandle(path) : getSourceHandle(path);
  }

  // Handle destination points
  if (point && type === 'destination') {
    return isLoopback(path) ? null : getDestinationHandle(path);
  }

  return null;
};

const getHandlePosition = (cavityType: CavityType) => (cavityType === 'source' ? Position.Right : Position.Left);

const shouldHideCavity = (point: Schematic.ConnectionPoint | null, path: Schematic.Path | null, type: CavityType) => {
  const isEmpty = !point;
  const isDuplicateOccurrence = !isEmpty && (point.occurrence ?? 0) > 1 && !isHousing(point);
  const shouldHideDestinationCavity = type === 'destination' && isLoopback(path);

  return isEmpty || isDuplicateOccurrence || shouldHideDestinationCavity;
};
