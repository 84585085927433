import { UUID } from '@web/apps/types';
import { isTechnician } from '@web/common/util.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';

import { useDesignId } from '../hooks/useDesignId.tsx';
import { useDesignOverview } from './useDesignOverview.tsx';

// Define all possible selection types
export const SELECTION_TYPES = {
  DESIGN_PART: 'designPart',
  BOM_ITEM: 'bomItem',
  CONNECTION: 'connection',
  LAYOUT_ELEMENT: 'layoutElement',
} as const;

type SelectionType = (typeof SELECTION_TYPES)[keyof typeof SELECTION_TYPES];

interface Selections {
  [SELECTION_TYPES.DESIGN_PART]: UUID | null;
  [SELECTION_TYPES.BOM_ITEM]: UUID | null;
  [SELECTION_TYPES.CONNECTION]: UUID | null;
  [SELECTION_TYPES.LAYOUT_ELEMENT]: string | null;
}

const emptySelections = Object.values(SELECTION_TYPES).reduce(
  (acc, key) => ({ ...acc, [key]: null }),
  {} as Selections,
);

// Context type for design
export type DesignContextType = {
  designId: UUID;
  selections: Selections;
  setSelection: <T extends SelectionType>(type: T, value: Selections[T], clearOthers?: boolean) => void;
  isSelected: (selectionType: SelectionType, id: UUID) => boolean;
  clearSelections: () => void;
  isViewOnly: boolean;
};

const DesignContext = createContext<DesignContextType | undefined>(undefined);

export const useDesign = () => {
  const context = useContext(DesignContext);
  if (context === undefined) {
    throw new Error('useDesign must be used within a DesignProvider');
  }
  return context;
};

export const DesignProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const designId = useDesignId();
  const { lockedAt } = useDesignOverview();
  const { designPartId } = useParams<{ designPartId: string }>();
  const { data: currentUser } = useCurrentUser();

  const [selections, setSelections] = useState<Selections>(emptySelections);

  const setSelection = <T extends SelectionType>(type: T, value: Selections[T], clearOthers = false) => {
    setSelections((current) => (clearOthers ? { ...emptySelections, [type]: value } : { ...current, [type]: value }));
  };

  const isSelected = (selectionType: SelectionType, id: UUID) => selections[selectionType] === id;

  const clearSelections = () => setSelections(emptySelections);

  // URL sync effects...
  useEffect(() => {
    if (designId && designPartId) {
      setSelection(SELECTION_TYPES.DESIGN_PART, designPartId);
    }
  }, [designId, designPartId]);

  useEffect(() => {
    if (selections.designPart) {
      const url = generatePath(RouteNames.DESIGNS.DESIGN_PART, {
        designId,
        designPartId: selections.designPart,
      });
      navigate(url, { replace: true });
    }
  }, [designId, selections.designPart, navigate]);

  const value = {
    designId,
    selections,
    setSelection,
    isSelected,
    clearSelections,
    isViewOnly: Boolean(lockedAt) || isTechnician(currentUser),
  };

  return <DesignContext.Provider value={value}>{children}</DesignContext.Provider>;
};
