import {
  CircularConnector,
  connectionPointCoordinates as circularCoordinates,
} from '@web/apps/Design/components/Shapes/Connectors/CircularConnector.tsx';
import {
  CircularConnector0,
  connectionPointCoordinates as circular0Coordinates,
} from '@web/apps/Design/components/Shapes/Connectors/CircularConnector0.tsx';
import {
  CircularConnector45,
  connectionPointCoordinates as circular45Coordinates,
} from '@web/apps/Design/components/Shapes/Connectors/CircularConnector45.tsx';
import {
  CircularConnector90,
  connectionPointCoordinates as circular90Coordinates,
} from '@web/apps/Design/components/Shapes/Connectors/CircularConnector90.tsx';
import {
  connectionPointCoordinates as genericCoordinates,
  GenericConnector,
} from '@web/apps/Design/components/Shapes/Connectors/GenericConnector.tsx';
import {
  connectionPointCoordinates as rectangularCoordinates,
  RectangularConnector,
} from '@web/apps/Design/components/Shapes/Connectors/RectangularConnector.tsx';
import {
  connectionPointCoordinates as rectangular0Coordinates,
  RectangularConnector0,
} from '@web/apps/Design/components/Shapes/Connectors/RectangularConnector0.tsx';
import {
  connectionPointCoordinates as rectangular45Coordinates,
  RectangularConnector45,
} from '@web/apps/Design/components/Shapes/Connectors/RectangularConnector45.tsx';
import {
  connectionPointCoordinates as rectangular90Coordinates,
  RectangularConnector90,
} from '@web/apps/Design/components/Shapes/Connectors/RectangularConnector90.tsx';
import { BackshellAngle, ConnectorShape, ShapeProps } from '@web/apps/types';
import { XYPosition } from '@xyflow/react';
import { FC } from 'react';

const genericShape = { shape: GenericConnector, connectionPoint: genericCoordinates };

const defaultShapes = {
  [ConnectorShape.CIRCULAR]: { shape: CircularConnector, connectionPoint: circularCoordinates },
  [ConnectorShape.RECTANGULAR]: { shape: RectangularConnector, connectionPoint: rectangularCoordinates },
};

const shapesWithAngles = {
  [ConnectorShape.CIRCULAR]: {
    [BackshellAngle.STRAIGHT]: { shape: CircularConnector0, connectionPoint: circular0Coordinates },
    [BackshellAngle.ANGLE_90]: { shape: CircularConnector90, connectionPoint: circular90Coordinates },
    [BackshellAngle.ANGLE_45]: { shape: CircularConnector45, connectionPoint: circular45Coordinates },
  },
  [ConnectorShape.RECTANGULAR]: {
    [BackshellAngle.STRAIGHT]: { shape: RectangularConnector0, connectionPoint: rectangular0Coordinates },
    [BackshellAngle.ANGLE_45]: { shape: RectangularConnector45, connectionPoint: rectangular45Coordinates },
    [BackshellAngle.ANGLE_90]: { shape: RectangularConnector90, connectionPoint: rectangular90Coordinates },
  },
};

export const getConnectorShapeData = (
  shape: ConnectorShape | null,
  angle: BackshellAngle | null,
): {
  shape: FC<ShapeProps>;
  connectionPoint: XYPosition;
} => {
  if (shape === null) {
    return genericShape;
  }

  const shapeData = angle === null ? defaultShapes[shape] : shapesWithAngles[shape]?.[angle];
  return shapeData || genericShape;
};
