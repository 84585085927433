import { BoxProps } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';

import ManagedWindow from '../../components/ManagedWindow.tsx';
import { AssemblyNavigator } from './AssemblyNavigator.tsx';

export const AssemblyNavigatorWindow = (props: BoxProps) => (
  <ManagedWindow
    title="Assembly Navigator"
    routeName={RouteNames.DESIGNS.DESIGN_PARTS}
    position="relative"
    bg="gray.50"
    {...props}
  >
    {({ windowIcon }) => <AssemblyNavigator h="full" windowIcon={windowIcon} />}
  </ManagedWindow>
);
