import { useMutation } from '@tanstack/react-query';
import { UUID } from '@web/apps/types';
import { downloadFile, getBaseOptions, getUrl } from '@web/common/api.ts';

export type Extension = 'pdf' | 'xlsx';

export interface ExportImage {
  layout: string;
  schematics?: { designPartId: UUID; image: string }[];
}

// React Query Hook: useExportDesignMutation
interface ExportDesignParams {
  designId: UUID;
  partNumber: string;
  partRevision: string;
  extension: Extension;
  images?: ExportImage[];
}

const postExport = async (designId: UUID, extension: string, images?: ExportImage[]) => {
  const url = getUrl(`/api/v1/designs/${designId}/export.${extension}`);

  const options = await getBaseOptions('POST');
  const response = await fetch(url, { ...options, body: images ? JSON.stringify({ design: { images } }) : null });

  if (!response.ok) {
    throw new Error('Failed to download export');
  }

  return response.blob();
};

export const useExportDesignMutation = () => {
  return useMutation({
    mutationKey: [`export-design`],
    mutationFn: async ({ designId, images, partNumber, partRevision, extension }: ExportDesignParams) => {
      const filename = `design-${partNumber}${partRevision ? `_${partRevision}` : ''}.${extension}`;
      const blob = await postExport(designId, extension, images);
      downloadFile(blob, filename);
    },
  });
};
