import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { TabData } from '../../../types/ContextPanelTab';
import { DesignTab } from '../DesignTabs/DesignTab';
import { useDesignTabs } from '../DesignTabs/DesignTabsProvider';
import { ContextPanel } from './ContextPanel';
import { ContextPanelHeight } from './types';
import { isContextPanelOpen } from './utils';

interface Props {
  tabs: TabData[];
}

export const ContextPanelWithTabs = ({ tabs }: Props) => {
  const { selectedContextPanelTab, setSelectedContextPanelTab, contextPanelHeight, setContextPanelHeight } =
    useDesignTabs();

  const handleTabClick = (index: number) => {
    if (index !== selectedContextPanelTab) {
      setSelectedContextPanelTab(index);
      if (contextPanelHeight === ContextPanelHeight.MINIMIZED) {
        setContextPanelHeight(ContextPanelHeight.SPLIT);
      }
    } else {
      setContextPanelHeight(
        isContextPanelOpen(contextPanelHeight) ? ContextPanelHeight.MINIMIZED : ContextPanelHeight.SPLIT,
      );
    }
  };

  return (
    <Tabs variant="unstyled" index={selectedContextPanelTab}>
      <ContextPanel
        headerContent={
          <TabList>
            {tabs.map(({ value, headerLabel }, index) => (
              <DesignTab key={value} onClick={() => handleTabClick(index)}>
                {headerLabel}
              </DesignTab>
            ))}
          </TabList>
        }
      >
        <TabPanels h={contextPanelHeight}>
          {tabs.map(({ value, content }) => (
            <TabPanel key={value} h="full" p={0}>
              {content}
            </TabPanel>
          ))}
        </TabPanels>
      </ContextPanel>
    </Tabs>
  );
};
