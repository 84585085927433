import { Conductor, ConductorEdgeData } from '../components/edges/Conductor/Conductor.tsx';
import { DoubleShieldGroup } from '../components/edges/DoubleShieldGroup.tsx';
import { Loopback } from '../components/edges/Loopback.tsx';
import { SingleShieldGroup } from '../components/edges/SingleShieldGroup.tsx';
import { TwistingGroup } from '../components/edges/TwistingGroup.tsx';

export type EdgeType = 'Conductor' | 'Loopback' | 'SingleShieldGroup' | 'DoubleShieldGroup' | 'TwistingGroup';

export type EdgeData = ConductorEdgeData;

export const edgeTypes = {
  Conductor: Conductor,
  Loopback: Loopback,
  SingleShieldGroup: SingleShieldGroup,
  DoubleShieldGroup: DoubleShieldGroup,
  TwistingGroup: TwistingGroup,
};
