import { ShapeProps } from '@web/apps/types';

export const Inductor = ({ color }: ShapeProps) => (
  <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2 6.5C4.88085 6.5 4.3 6.82492 4.3 8C4.3 8.27614 4.07614 8.5 3.8 8.5H1C0.723858 8.5 0.5 8.27614 0.5 8C0.5 7.72386 0.723858 7.5 1 7.5H3.33236C3.49408 6.28749 4.24486 5.5 5.2 5.5C5.76454 5.5 6.25768 5.7751 6.6 6.25047C6.94232 5.7751 7.43546 5.5 8 5.5C8.56454 5.5 9.05768 5.7751 9.4 6.25047C9.74232 5.7751 10.2355 5.5 10.8 5.5C11.7551 5.5 12.5059 6.28749 12.6676 7.5H15C15.2761 7.5 15.5 7.72386 15.5 8C15.5 8.27614 15.2761 8.5 15 8.5H12.2C11.9239 8.5 11.7 8.27614 11.7 8C11.7 6.82492 11.1192 6.5 10.8 6.5C10.4808 6.5 9.9 6.82492 9.9 8C9.9 8.27614 9.67614 8.5 9.4 8.5C9.12386 8.5 8.9 8.27614 8.9 8C8.9 6.82492 8.31915 6.5 8 6.5C7.68085 6.5 7.1 6.82492 7.1 8C7.1 8.27614 6.87614 8.5 6.6 8.5C6.32386 8.5 6.1 8.27614 6.1 8C6.1 6.82492 5.51915 6.5 5.2 6.5Z"
      fill="white"
    />
  </svg>
);
