import { HStack, StackProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { defaultSchematicConfig } from '../../../config.ts';

interface Props extends StackProps {
  children?: ReactNode;
}

/**
 * A component to display conductor details consistently. It displays the data and fills the remaining space with a
 * transparent background so that the conductor line can be seen.
 * @param children
 * @param rest
 * @constructor
 */
export const ConductorDetail = ({ children, ...rest }: Props) => {
  const isSimpleContent = typeof children === 'string' || typeof children === 'number';
  const isEmptyString = children === '';

  return (
    <HStack alignItems="stretch" justifyContent="flex-start" flex="1" color="gray.900" {...rest}>
      {/* Only render `children` if it's not an empty string */}
      {children &&
        !isEmptyString &&
        (isSimpleContent ? (
          <Text
            isTruncated
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            bg={defaultSchematicConfig.style.backgroundColor}
            px={2}
          >
            {children}
          </Text>
        ) : (
          children // Render `HStack` or other custom components as-is
        ))}
      {/* Transparent filler section ensures conductor line visibility */}
      <HStack flex="1" bg="transparent" />
    </HStack>
  );
};
