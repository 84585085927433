import './styles/globals.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';

import App from './App';
import { BAD_REQUEST } from './common/api';

Sentry.init({
  dsn: 'https://c8f3e4199aa4ab9de1f1135691e8933d@o4505840374775808.ingest.sentry.io/4505868678660096',
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
  ignoreErrors: [BAD_REQUEST, /Redirect to 404 page/],
});

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

const setFaviconHref = () => {
  const defaultFaviconPath = '/favicon.png';
  const stagingFaviconPath = '/favicon_staging.png';
  return import.meta.env.VITE_ENVIRONMENT === 'staging' ? stagingFaviconPath : defaultFaviconPath;
};

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <link rel="icon" href={setFaviconHref()} />
        </Helmet>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
);
