import { Text, Th, Thead, Tr, useToken } from '@chakra-ui/react';
import { truncateText } from '@web/apps/styles.ts';
import { Fragment, useMemo } from 'react';

interface HeaderConfig {
  label: string;
  width: string;
  minWidth?: string;
}

export const WiringListHead = () => {
  const [spacing10, spacing20] = useToken('space', ['10', '20']);

  const columns = useMemo<HeaderConfig[]>(
    () => [
      { label: 'Cavity', width: spacing10, minWidth: spacing10 },
      { label: 'Destination', width: 'auto', minWidth: spacing20 },
      { label: 'Conductor', width: 'auto', minWidth: spacing20 },
      { label: 'Signal', width: 'auto', minWidth: spacing20 },
      { label: 'Contact', width: 'auto', minWidth: spacing20 },
      { label: 'Dest. Contact', width: 'auto', minWidth: spacing20 },
      { label: 'Actions', width: 'auto', minWidth: spacing20 },
    ],
    [spacing10, spacing20],
  );

  return (
    <Fragment>
      <colgroup>
        {columns.map(({ label, width, minWidth }) => (
          <col key={label} style={{ width, minWidth }} />
        ))}
      </colgroup>
      <Thead position="sticky" top="0" zIndex="sticky" bg="gray.100" boxShadow="0 1px 0 0 rgba(0, 0, 0, .1)">
        <Tr h={10} borderBottom="1px solid" borderColor="gray.300">
          <Th textAlign="center">
            <Text {...truncateText}>Cav</Text>
          </Th>
          <Th textAlign="left">
            <Text px={8} {...truncateText}>
              Destination
            </Text>
          </Th>
          <Th textAlign="left">
            <Text px={8} {...truncateText}>
              Conductor
            </Text>
          </Th>
          <Th textAlign="left">
            <Text px={8} {...truncateText}>
              Signal
            </Text>
          </Th>
          <Th textAlign="left">
            <Text px={8} {...truncateText}>
              Contact
            </Text>
          </Th>
          <Th textAlign="left">
            <Text px={8} {...truncateText}>
              Dest. Contact
            </Text>
          </Th>
          <Th textAlign="left"></Th>
        </Tr>
      </Thead>
    </Fragment>
  );
};
