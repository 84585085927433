import { Button, FormControl, FormLabel, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Part, Tool } from '@web/apps/types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useTools } from '../../Tools-Library/api/queries.ts';
import ToolRelationRow from './ToolRelationRow.tsx';

interface Props {
  editing: boolean;
}

export const toOption = (tool: Tool) => ({
  label: tool.partNumber,
  value: tool.id,
  type: tool.type,
});

const ToolRelationRows = ({ editing }: Props) => {
  const { control } = useFormContext<Part>();
  const {
    fields: partTools,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'partTools',
  });

  const handleNewPartTool = () => {
    append({ toolId: '', notes: '', partNumber: '', type: '' });
  };

  const { data: result, isLoading } = useTools();

  if (isLoading || !result) return null;

  const defaultOptions = result ? result.data.filter((option) => !partTools.some((t) => t.toolId === option.id)) : [];

  return (
    (editing || partTools.length > 0) && (
      <FormControl>
        <FormLabel id="ToolRelationRows">
          <Text>Tools</Text>
        </FormLabel>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th px={4} py={1}>
                PART NUMBER
              </Th>
              <Th px={4} py={1}>
                TYPE
              </Th>
              <Th px={4} py={1}>
                USAGE
              </Th>
              {editing && (
                <Th px={4} py={1} textAlign="right">
                  <Button onClick={handleNewPartTool}>+</Button>
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {partTools.map((partTool, index) => {
              return (
                <ToolRelationRow
                  key={partTool.id}
                  index={index}
                  editing={editing}
                  remove={remove}
                  update={update}
                  defaultOptions={defaultOptions.map(toOption)}
                />
              );
            })}
          </Tbody>
        </Table>
      </FormControl>
    )
  );
};

export default ToolRelationRows;
