import { Box, IconButton } from '@chakra-ui/react';
import { useDesignOverview } from '@web/apps/Design/hooks/useDesignOverview.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { BiDownload } from 'react-icons/bi';

import { useCutlistData, useDownloadCutlistExcel } from '../../api/cutlist-api.ts';
import { ItemTable } from '../../components/ItemTable/ItemTable';
import ManagedWindow from '../../components/ManagedWindow';
import { useDesignId } from '../../hooks/useDesignId';
import { CutlistItem } from './CutlistItem';

export const Cutlist = () => {
  const designId = useDesignId();
  const { data: cutlist } = useCutlistData(designId);
  const { partNumber, partRevision } = useDesignOverview();
  const { mutate: downloadCutlist } = useDownloadCutlistExcel(designId, partNumber, partRevision);

  if (!cutlist || cutlist.length === 0) {
    return (
      <ManagedWindow title="Cutlist" routeName={RouteNames.DESIGNS.CUTLIST}>
        <Box p={8}>No measurements data available.</Box>
      </ManagedWindow>
    );
  }

  return (
    <ManagedWindow
      title="Cutlist"
      routeName={RouteNames.DESIGNS.CUTLIST}
      actions={
        <IconButton
          onClick={() => downloadCutlist()}
          aria-label="download cutlist"
          variant="unstyled"
          icon={<BiDownload fontSize="1.5em" />}
        />
      }
    >
      <ItemTable overflowY="auto">
        <CutlistItem isHeader />
        {cutlist?.map((cutlistData) => <CutlistItem key={cutlistData.id} cutlistData={cutlistData} />)}
      </ItemTable>
    </ManagedWindow>
  );
};
