import { MenuGroup, MenuItem, Text } from '@chakra-ui/react';
import { useCreateDesignPartLayoutEdgeMutation } from '@web/apps/Design/api/design-part-layout-edges-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { useDesignParts } from '@web/apps/Design/hooks/useDesignParts.tsx';
import { DesignPart, DesignPartLayoutEdge, PartType, UUID } from '@web/apps/types';
import { useCallback } from 'react';

import { MenuGroupTitleWithLoading } from '../../menus/MenuGroupTitleWithLoading.tsx';

interface Props {
  labelEdges: DesignPartLayoutEdge[];
  layoutEdgeId: UUID;
  closeMenu: () => void;
}

export const LabelMenuSection = ({ labelEdges, layoutEdgeId, closeMenu }: Props) => {
  const designId = useDesignId();
  const { getDesignPartsByType } = useDesignParts();
  const allLabels = getDesignPartsByType(PartType.LABEL);

  const { mutateAsync: createDesignPartLayoutEdge, isPending: isCreatingDesignPartLayoutEdge } =
    useCreateDesignPartLayoutEdgeMutation();

  const handleAddDesignPartLabel = useCallback(
    async (label: DesignPart) => {
      await createDesignPartLayoutEdge({
        designId,
        layoutEdgeId,
        partId: label.partData.id,
      });

      closeMenu();
    },
    [designId, layoutEdgeId, createDesignPartLayoutEdge, closeMenu],
  );

  return (
    <MenuGroup
      // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
      title={<MenuGroupTitleWithLoading title="Add Label" isLoading={isCreatingDesignPartLayoutEdge} />}
    >
      {allLabels.length > 0 || labelEdges.length > 0 ? (
        <>
          {allLabels.map((label, index) => (
            <MenuItem key={index} value={label.id} onClick={() => handleAddDesignPartLabel(label)}>
              <Text flex={1} maxW={52} isTruncated>
                {label.partData.partNumber}
              </Text>
            </MenuItem>
          ))}
        </>
      ) : (
        <MenuItem isDisabled>No Labels</MenuItem>
      )}
    </MenuGroup>
  );
};
