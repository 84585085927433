import { Box, HStack } from '@chakra-ui/react';
import { useDesignFilters } from '@web/apps/Design/hooks/useDesignFilters.tsx';
import { useDesignOverview } from '@web/apps/Design/hooks/useDesignOverview.tsx';
import { BOMItem, BuildNote, NotableType, NoteGroupBuildNote, UUID } from '@web/apps/types';
import { Handle, Node, NodeProps, Position, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect, useMemo } from 'react';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState.ts';
import { useDesignBuildNotes } from '../../../../../hooks/useDesignBuildNotes.tsx';
import { LayoutBomNote } from '../../../../Layout/components/nodes/NoteGroupNode/LayoutBomNote.tsx';
import { LayoutFlagNote } from '../../../../Layout/components/nodes/NoteGroupNode/LayoutFlagNote.tsx';
import { defaultLayoutConfig } from '../../../../Layout/config.ts';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { useCalculateNoteTargetDirection } from '../../../hooks/notes/useCalculateNoteTargetDirection.ts';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';

export type RelationalNoteGroupNodeData = {
  noteGroupBuildNotes?: NoteGroupBuildNote[];
  designPartId?: UUID;
  designPartName?: string;
  notableType: NotableType;
  notableId: UUID;
  notableEdgeId: UUID;
};

export const defaultRelationalNoteGroupNodeData: RelationalNoteGroupNodeData = {
  noteGroupBuildNotes: [],
  designPartId: '',
  designPartName: '',
  notableType: NotableType.LAYOUT_NODE,
  notableId: '',
  notableEdgeId: '',
};

export type RelationalNoteGroupNodeType = Node<RelationalNoteGroupNodeData>;

const emptyFlagNotes: BuildNote[] = [];
const emptyBomItems: BOMItem[] = [];

/**
 * Note Group point node component.
 * @param props
 * @constructor
 */
export const RelationalNoteGroupNode = ({
  id,
  dragging,
  data: { noteGroupBuildNotes, designPartName, notableEdgeId, notableId, notableType },
  selected,
}: NodeProps<RelationalNoteGroupNodeType>) => {
  const { data: buildNotes = [] } = useDesignBuildNotes();
  const { bom } = useDesignOverview();
  const updateNodeInternals = useUpdateNodeInternals();
  const { filters } = useDesignFilters();

  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const { horizontal: horizontalPosition } = useCalculateNoteTargetDirection(id, notableType, notableId, notableEdgeId);
  const flexDirection = horizontalPosition === Position.Right ? 'row' : 'row-reverse';

  useEffect(() => {
    updateNodeInternals(id);
  }, [updateNodeInternals, id, horizontalPosition]);

  const flagNotesForNoteGroup = useMemo(() => {
    const noteGroupBuildNoteIds = new Set(
      noteGroupBuildNotes?.map((noteGroupBuildNote) => noteGroupBuildNote.buildNoteId),
    );

    return buildNotes.filter((note) => noteGroupBuildNoteIds.has(note.id));
  }, [buildNotes, noteGroupBuildNotes]);

  const bomItems = bom.filter((item) => item.usage.includes(designPartName ?? ''));

  const flagNotesToRender = filters.flagNotesHidden ? emptyFlagNotes : flagNotesForNoteGroup;
  const bomNotesToRender = filters.bomNotesHidden ? emptyBomItems : bomItems;
  const nothingToRender = flagNotesToRender.length === 0 && bomNotesToRender.length === 0;

  return (
    <Box
      key={horizontalPosition}
      position="relative"
      borderRadius="lg"
      borderWidth={selected ? 1 : 0}
      borderColor={defaultLayoutConfig.selectedNodeColor}
      padding={1}
      onContextMenu={handleContextMenu}
    >
      <HStack gap={0} flexDirection={flexDirection}>
        {flagNotesToRender.map((flagNote) => (
          <LayoutFlagNote key={flagNote.id} flagNote={flagNote} disableTooltip={dragging} />
        ))}
        {bomNotesToRender.map((bomItem) => (
          <LayoutBomNote key={bomItem.id} bomItem={bomItem} disableTooltip={dragging} />
        ))}
      </HStack>
      {!nothingToRender && (
        <Box visibility="hidden">
          <Handle id={HandleTypes.Note} type="source" position={horizontalPosition} />
        </Box>
      )}
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={id} />
      </ContextMenu>
    </Box>
  );
};
