import { Connector, Contact, Overwrap, Part, PartType, Wire } from '@web/apps/types';

/**
 * Returns true if a string value is a PartType
 * @param value
 */
export const isPartType = (value: string): value is PartType => {
  return Object.values(PartType).includes(value as PartType);
};

/**
 * Map of PartType to a human-readable string
 */
export const partTypeTranslations: { [key in PartType]: string } = {
  [PartType.BACKSHELL]: 'Backshell',
  [PartType.CABLE]: 'Cable',
  [PartType.CONNECTOR]: 'Connector',
  [PartType.CONTACT]: 'Contact',
  [PartType.GENERIC]: 'Generic',
  [PartType.LABEL]: 'Label',
  [PartType.PASSIVE]: 'Passive',
  [PartType.PIGTAIL]: 'Pigtail',
  [PartType.SPLICE]: 'Splice',
  [PartType.WIRE]: 'Wire',
  [PartType.OVERWRAP]: 'Overwrap',
};

/**
 * Translate a PartType to a human-readable string
 * @param partType
 */
export const translatePartType = (partType: string): string => {
  if (isPartType(partType)) {
    return partTypeTranslations[partType];
  }
  return 'Unknown Part Type';
};

/**
 * Translate a PartType to a route name
 * @param type
 */
export const partTypeToRouteName = (type: PartType): string => {
  if (type === PartType.GENERIC) {
    return 'generic';
  }
  return type.toLowerCase() + 's';
};

/**
 * Returns true if a part is a connector
 * @param p
 */
export const isConnector = (p: Part): p is Connector => p.type === PartType.CONNECTOR;

/**
 * Returns true if a part is a contact
 * @param p
 */
export const isContact = (p: Part): p is Contact => p.type === PartType.CONTACT;

/**
 * Returns true if a part is a wire
 * @param p
 */
export const isWire = (p: Part): p is Wire => 'type' in p && p.type === PartType.WIRE;

/**
 * Returns true if a part is an overwrap
 * @param p
 */
export const isOverwrap = (p: Part): p is Overwrap => 'type' in p && p.type === PartType.OVERWRAP;

/**
 * Returns true if a part type is an accessory
 * @param partType
 */
export const isAccessory = (partType: PartType): boolean =>
  new Set<PartType>([PartType.BACKSHELL, PartType.CONTACT]).has(partType);

/**
 * Returns true if a part type is a conductor
 * @param partType
 */
export const isConductor = (partType: PartType): boolean =>
  new Set<PartType>([PartType.CABLE, PartType.WIRE, PartType.PIGTAIL]).has(partType);

/**
 * Returns true if a part type is linear
 * @param partType
 */
export const isLinear = (partType: PartType): boolean =>
  new Set<PartType>([PartType.CABLE, PartType.OVERWRAP, PartType.WIRE]).has(partType);
