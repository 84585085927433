import { HStack, StackDivider } from '@chakra-ui/react';
import { useCurrentUser } from '@web/queries/users.ts';
import { Panel } from '@xyflow/react';
import { FaRegCircle } from 'react-icons/fa';
import { FiTriangle } from 'react-icons/fi';
import { LuRoute, LuRuler, LuTag } from 'react-icons/lu';
import { MdGrid4X4 } from 'react-icons/md';

import { LayoutFilterButton } from './LayoutFilterButton';

export const LayerFilterControls = () => {
  const { data: currentUser } = useCurrentUser();

  return (
    <Panel position="top-right">
      <HStack divider={<StackDivider bg="gray.300" />} spacing={0} border="1px solid" borderColor="gray.300">
        <LayoutFilterButton label="Measurements" icon={LuRuler} filterKey="measurementHidden" iconSize="20px" />
        <LayoutFilterButton label="Grid Lines" icon={MdGrid4X4} filterKey="gridLinesHidden" iconSize="20px" />
        <LayoutFilterButton label="Flag Notes" icon={FiTriangle} filterKey="flagNotesHidden" iconSize="20px" />
        <LayoutFilterButton label="BOM Notes" icon={FaRegCircle} filterKey="bomNotesHidden" iconSize="18px" />
        <LayoutFilterButton label="Segment Labels" icon={LuTag} filterKey="segmentLabelsHidden" iconSize="18px" />
        {currentUser?.featureFlags.bezierSegments && (
          <LayoutFilterButton label="Linear Segments" icon={LuRoute} filterKey="bezierSegments" iconSize="20px" />
        )}
      </HStack>
    </Panel>
  );
};
