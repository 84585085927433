import { isHousing } from '@web/apps/Design/features/Schematic/utils/helpers.ts';
import { Schematic, UUID } from '@web/apps/types';

import { ConductorGroup, ConductorGroupHandlePosition } from '../types/groups.ts';

/**
 * Generalized function to generate a node handle ID.
 */
const getScopedKey = (namespace: string, key: string | null) => {
  if (namespace === '') throw new Error('namespace cannot be an empty string');
  if (key === '') throw new Error('key cannot be an empty string');

  return key ? `${key} [${namespace}]` : namespace;
};

/**
 * A source handle uses the source as the handle ID. If the source is a housing, the conductor ID connected to the
 * source is appended.
 */
export const getSourceHandle = (path: Schematic.Path) => {
  const sourcePoint = path.source;
  if (!sourcePoint) {
    // A handle for an undefined source point is not valid
    throw new Error('Source point is undefined');
  }

  if (isHousing(sourcePoint) && path.conductors.length === 0) {
    // A handle for a housing with no conductors is not valid
    throw new Error('Housing has no conductors');
  }

  // If the source is a housing, append the conductor ID; otherwise, return the source ID
  return getScopedKey(sourcePoint.displayName, isHousing(sourcePoint) ? path.conductors[0].id : null);
};

/**
 * A destination handle uses the destination as the handle ID. If the destination is a housing, the conductor ID
 * connected to the destination is appended.
 */
export const getDestinationHandle = (path: Schematic.Path) => {
  const destinationPoint = path.destination;
  if (!destinationPoint) {
    // A handle for an undefined destination point is not valid
    throw new Error('Destination point is undefined');
  }

  let relevantConductor: Schematic.Conductor | undefined;

  if (path.inlines.length > 0) {
    // If there are inlines, use the last conductor
    relevantConductor = path.conductors[path.conductors.length - 1];
  } else {
    // If no inlines, use the first conductor
    relevantConductor = path.conductors[0];
  }

  if (isHousing(destinationPoint) && !relevantConductor) {
    // A handle for a housing with no conductors is not valid
    throw new Error('Housing has no conductors');
  }

  // If the destination is a housing, append the conductor ID; otherwise, return the destination ID
  return getScopedKey(destinationPoint.displayName, isHousing(destinationPoint) ? relevantConductor.name : null);
};

/**
 * Returns the input handle given a ConnectionPoint ID.
 */
export const getInputHandle = (pointName: string) => getScopedKey('input', pointName);

/**
 * Returns the output handle given a ConnectionPoint ID.
 */
export const getOutputHandle = (pointName: string) => getScopedKey('output', pointName);

/**
 * Generates a unique handle ID for conductor groups (shielding or twisting).
 */
export const getConductorGroupHandle = (
  group: ConductorGroup,
  conductorId: UUID,
  position: ConductorGroupHandlePosition,
) => `${group}.${conductorId}.${position}`;
