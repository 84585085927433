import { useValueGetter } from '@web/hooks/useValueGetter.ts';
import { useEffect, useRef } from 'react';

import { useConnectionsData } from '../../../hooks/useConnectionsData';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes';

export const useUpdateLayoutEffects = (updateLayout: () => void, layoutLoaded: boolean) => {
  const isMountedRef = useRef(false);
  const updateLayoutGetter = useValueGetter(updateLayout);
  const { data: connectionsData, isSuccess: isConnectionsSuccess } = useConnectionsData();
  const { data: buildNotes = [], isSuccess: isBuildNotesSuccess } = useDesignBuildNotes();

  useEffect(() => {
    if (layoutLoaded && isConnectionsSuccess && isBuildNotesSuccess) {
      // We need to skip the first mount because we only want to listen for changes
      if (!isMountedRef.current) {
        isMountedRef.current = true;
        return;
      }

      const updateLayout = updateLayoutGetter() as () => void;
      updateLayout();
    }
  }, [
    connectionsData?.connections,
    connectionsData?.designParts,
    buildNotes,
    layoutLoaded,
    isConnectionsSuccess,
    isBuildNotesSuccess,
    updateLayoutGetter,
  ]);
};
