import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { Design } from '@web/apps/types';

import { useDesignId } from './useDesignId';

export const getNotes = (design: Design | null) => design?.buildNotes || [];

export const useDesignBuildNotes = () => {
  // Get the design ID from the URL params
  const designId = useDesignId();

  return useDesignQuery(designId, getNotes);
};
