import { AlternateDesignPart, DesignPart, PartType, UUID } from '@web/apps/types';
import { useCallback } from 'react';

import { useDesignPartsData } from './useDesignPartsData.tsx';

// Define the return type
interface UseDesignPartsResult {
  designParts: DesignPart[];
  alternateDesignParts: AlternateDesignPart[];
  getDesignPartById: (id: UUID | null) => DesignPart | undefined;
  getDesignPartsByType: (partType: PartType) => DesignPart[];
  getAlternateDesignPartById: (id: UUID | null) => AlternateDesignPart | undefined;
  getOverwrapByPartNumber: (partNumber: string) => DesignPart | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  error: Error | null;
}

/**
 * Custom hook to fetch designParts, alternateDesignParts, lookup maps, and utility functions. This hook uses the
 * select function of useDesignQuery to only subscribe to designParts and alternateDesignParts.
 */
export const useDesignParts = (): UseDesignPartsResult => {
  const {
    designParts,
    alternateDesignParts,
    designPartsMap,
    designPartsTypeMap,
    alternateDesignPartsMap,
    partNumberOverwrapMap,
    isLoading,
    isSuccess,
    error,
  } = useDesignPartsData();

  // Utility function to get a design part by ID
  const getDesignPartById = useCallback(
    (id: UUID | null): DesignPart | undefined => (id ? designPartsMap.get(id) : undefined),
    [designPartsMap],
  );

  // Utility function to get a design part by type
  const getDesignPartsByType = useCallback(
    (partType: PartType) => designPartsTypeMap.get(partType) || [],
    [designPartsTypeMap],
  );

  // Utility function to get an alternate design part by ID
  const getAlternateDesignPartById = useCallback(
    (id: UUID | null): AlternateDesignPart | undefined => (id ? alternateDesignPartsMap.get(id) : undefined),
    [alternateDesignPartsMap],
  );

  // Utility function to get an overwrap by partNumber
  const getOverwrapByPartNumber = useCallback(
    (partNumber: string | null): DesignPart | undefined =>
      partNumber ? partNumberOverwrapMap.get(partNumber) : undefined,
    [partNumberOverwrapMap],
  );

  return {
    designParts,
    alternateDesignParts,
    getDesignPartById,
    getDesignPartsByType,
    getAlternateDesignPartById,
    getOverwrapByPartNumber,
    isLoading,
    isSuccess,
    error,
  };
};
