import { Flex, Spinner } from '@chakra-ui/react';
import { ComponentType, ReactNode, Suspense } from 'react';

interface LazyWrapperProps {
  component: ComponentType;
  fallback?: ReactNode;
}

export const LazyWrapper = ({ component: Component, fallback }: LazyWrapperProps) => {
  return (
    <Suspense fallback={fallback || <DefaultFallback />}>
      <Component />
    </Suspense>
  );
};

// Chakra-styled fallback (Spinner centered towards the top)
const DefaultFallback = () => (
  <Flex justify="center" align="flex-start" height="100vh" pt={20}>
    <Spinner />
  </Flex>
);
