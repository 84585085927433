import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { QuickLayerFilters, useDesignFilters } from '@web/apps/Design/hooks/useDesignFilters';
import { IconType } from 'react-icons';

interface Props {
  label: string;
  icon: IconType;
  iconSize: string;
  filterKey: keyof QuickLayerFilters;
}

export const LayoutFilterButton = ({ label, icon, iconSize, filterKey }: Props) => {
  const { filters, setFilters } = useDesignFilters();

  const isHidden = filters[filterKey];
  const buttonLabel = `${isHidden ? 'Show' : 'Hide'} ${label}`;
  const bgColor = isHidden ? 'white' : 'gray.100';

  const handleToggleFilter = (filterKey: keyof typeof filters) => setFilters({ [filterKey]: !filters[filterKey] });
  return (
    <Tooltip label={buttonLabel}>
      <IconButton
        bgColor={bgColor}
        aria-label={buttonLabel}
        size="md"
        icon={<Icon as={icon} boxSize={iconSize} color="gray.700" />}
        variant="ghost"
        borderRadius={0}
        onClick={() => handleToggleFilter(filterKey)}
        transition="all 0.2s"
        _hover={{ bgColor, '& #icon': { color: 'gray.400' } }}
      />
    </Tooltip>
  );
};
