import { useQueryClient } from '@tanstack/react-query';
import { apiPutUser } from '@web/api/admin-api.ts';
import { doesUserHaveRole, Role } from '@web/apps/types';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { adminUserCacheKey, useAdminUser } from '@web/queries/admin.ts';
import { E164Number } from 'libphonenumber-js/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import AdminPage from '../AdminPage';
import UserForm from './UserForm';

const ADMIN_CONFIRMATION_MESSAGE =
  'WARNING! Are you sure that you want to set this user to admin? It grants them access to ALL customer data in the system.';

const AdminEditUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [phone, setPhone] = useState<E164Number>('' as E164Number);
  const { userId = '' } = useParams();

  const { data: user } = useAdminUser(userId);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm({
    values: {
      ...user,
      tenantId: user?.tenant.id,
      admin: doesUserHaveRole(user, Role.ADMIN),
      manufacturingEngineer: doesUserHaveRole(user, Role.MANUFACTURING_ENGINEER),
      technician: doesUserHaveRole(user, Role.TECHNICIAN),
    },
  });

  useEffect(() => {
    if (user) {
      setPhone((user.phone ?? '') as E164Number);
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    if (data.roles.includes(Role.ADMIN) && !window.confirm(ADMIN_CONFIRMATION_MESSAGE)) return;
    const results = await apiPutUser(userId, { ...data, phone });
    if (results) {
      navigate(RouteNames.USERS.INDEX);
      successToast('User successfully updated.');
      await queryClient.invalidateQueries({ queryKey: adminUserCacheKey(userId) });
    }
  };

  if (!user) return null;
  return (
    <AdminPage title="Edit User">
      <UserForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        control={control}
        selectedTenant={{ label: user.tenant.name, value: user.tenant.id }}
        isSubmitting={isSubmitting}
        isValid={isValid}
        phone={phone}
        setPhone={setPhone}
        setValue={setValue}
      />
    </AdminPage>
  );
};

export default AdminEditUser;
