import { Spacer, Text } from '@chakra-ui/react';
import { DesignPart, PartDataWithSubtype } from '@web/apps/types';

import { BasePartListItem } from '../BasePartListItem';

interface Props {
  part: DesignPart;
}

export const AccessoryListItem = ({ part }: Props) => {
  const partData = part.partData as PartDataWithSubtype;

  const type = partData.subtype ?? partData.type;

  return (
    <BasePartListItem part={part}>
      <Text color="gray.600" fontSize="xs">
        {type}
      </Text>
      <Text color="gray.700" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
        {partData.partNumber}
      </Text>
      <Spacer />
      {part.includedAccessory && (
        <Text fontSize="xs" fontFamily="monospace" color="blue.700">
          Included
        </Text>
      )}
    </BasePartListItem>
  );
};
