import { HStack, StackProps } from '@chakra-ui/react';
import { XYPosition } from '@xyflow/react';

interface Props extends StackProps {
  labelPosition: XYPosition;
}

export const MeasurementLabelWrapper = ({ labelPosition, ...rest }: Props) => (
  <HStack
    fontSize="sm"
    fontFamily="monospace"
    color="gray.900"
    position="absolute"
    top={`${labelPosition.y}px`}
    left={`${labelPosition.x}px`}
    transform="translate(-50%, -35%)"
    zIndex="docked"
    pointerEvents="all"
    {...rest}
  />
);
