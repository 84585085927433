import { Box } from '@chakra-ui/react';
import { originalWidth, Pigtail } from '@web/apps/Design/components/Shapes/Pigtail.tsx';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';
import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect } from 'react';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState.ts';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { CenterLock } from '../../../../Layout/components/nodes/common/CenterLock.tsx';
import { DesignPartLabel } from '../../../../Layout/components/nodes/common/DesignPartLabel.tsx';
import { RotatingHandle } from '../../../../Layout/components/nodes/common/RotatingHandle.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';
import { useRelationalShapeRotation } from '../../../hooks/useRelationalShapeRotation.tsx';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';

export type RelationalPigtailNodeData = {
  designPartId: UUID;
  rotateLock: boolean;
  angle: number;
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalPigtailNodeData: RelationalPigtailNodeData = {
  designPartId: '',
  rotateLock: false,
  angle: 0,
  noteGroupBuildNotes: [],
};

export type RelationalPigtailNodeType = Node<RelationalPigtailNodeData>;

const distanceFromCenter = originalWidth / 2;

export const RelationalPigtailNode = ({
  id,
  data = defaultRelationalPigtailNodeData,
  selected,
}: NodeProps<RelationalPigtailNodeType>) => {
  const { designPartId, rotateLock, angle, noteGroupBuildNotes } = data;
  const { getDesignPartById } = useDesignParts();
  const designPart = getDesignPartById(designPartId);

  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  // ReactFlow hook to update the node internals, because the handle is rotated and ReactFlow needs to know about it
  const updateNodeInternals = useUpdateNodeInternals();

  // Calculate the angle to rotate the shape
  const calculatedAngle = useRelationalShapeRotation({ nodeId: id });

  // Use the calculated angle if the rotate lock is not enabled
  const resolvedAngle = rotateLock ? angle : calculatedAngle;

  useEffect(() => {
    updateNodeInternals(id);
  }, [resolvedAngle, id, updateNodeInternals]);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative" onContextMenu={handleContextMenu}>
      <DesignPartLabel designPart={designPart} angle={resolvedAngle} />
      <Pigtail color={getNodeColor(selected)} angle={resolvedAngle} />
      <RotatingHandle
        id={HandleTypes.Segment}
        angle={resolvedAngle}
        offsetPosition={{ x: -distanceFromCenter, y: 0 }}
      />
      <CenterHandle id={HandleTypes.Note} />
      {rotateLock && <CenterLock />}
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={id} />
      </ContextMenu>
    </Box>
  );
};
