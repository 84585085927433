import { Flex } from '@chakra-ui/react';
import { ReactFlowProvider } from '@xyflow/react';

import { baseContextPanelTabs, ContextPanelTab } from '../../../types/ContextPanelTab';
import { ContextPanelWithTabs } from '../../components/ContextPanel/ContextPanelWithTabs';
import { ContextPanelHeight } from '../../components/ContextPanel/types';
import { useDesignTabs } from '../../components/DesignTabs/DesignTabsProvider';
import { DetailsView } from './components/details/DetailsView';
import { Layout } from './Layout';

export const LayoutWithDrawer = () => {
  const { contextPanelHeight } = useDesignTabs();
  const showLayout = contextPanelHeight !== ContextPanelHeight.FULL;

  return (
    <Flex flexDirection="column" flex={1}>
      <ReactFlowProvider>
        {showLayout && <Layout />}
        <ContextPanelWithTabs
          tabs={[
            ...baseContextPanelTabs,
            {
              value: ContextPanelTab.DETAILS,
              headerLabel: 'Details',
              content: <DetailsView />,
            },
          ]}
        />
      </ReactFlowProvider>
    </Flex>
  );
};
