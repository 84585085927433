import { useToken } from '@chakra-ui/react';
import { EdgeProps } from '@xyflow/react';

import { getShieldGroupPath } from '../../utils/paths/getShieldGroupPath.ts';

export const DoubleShieldGroup = (props: EdgeProps) => {
  const [edgePath] = getShieldGroupPath(props, 4);

  return (
    <path
      id={props.id}
      d={edgePath}
      strokeDasharray="10 3"
      strokeLinecap="round"
      stroke={useToken('colors', 'gray.600')}
      strokeWidth={1}
      fill="none"
    />
  );
};
