import { StackProps, Text, VStack } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

export interface EmptyStateProps extends StackProps {
  icon?: ReactElement;
  message: string;
  action?: ReactNode;
}

export const EmptyState = ({ icon, message, action, ...rest }: EmptyStateProps) => {
  return (
    <VStack boxSize="full" spacing={8} justify="center" textAlign="center" mx="auto" p={10} {...rest}>
      {icon}
      <Text color="gray.600" fontSize="lg" maxWidth={60}>
        {message}
      </Text>
      {action}
    </VStack>
  );
};
