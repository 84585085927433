import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -16,
  y: 0,
};

const originalWidth = 32;
const originalHeight = 24;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const CircularConnector0 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M11 19C11 19.5523 10.5523 20 10 20L1 20C0.447716 20 3.88832e-07 19.5523 4.37114e-07 19L1.66103e-06 5C1.70931e-06 4.44771 0.447718 4 1 4L10 4C10.5523 4 11 4.44771 11 5L11 19Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 19.5L10 19.5C10.2761 19.5 10.5 19.2761 10.5 19L10.5 5C10.5 4.72386 10.2761 4.5 10 4.5L1 4.5C0.72386 4.5 0.500002 4.72386 0.500002 5L0.5 19C0.5 19.2761 0.723858 19.5 1 19.5ZM10 20C10.5523 20 11 19.5523 11 19L11 5C11 4.44771 10.5523 4 10 4L1 4C0.447718 4 1.70931e-06 4.44771 1.66103e-06 5L4.37114e-07 19C3.88832e-07 19.5523 0.447716 20 1 20L10 20Z"
          fill={outlineColor}
        />
        <path
          d="M32 22C32 23.1046 31.1046 24 30 24L14 24C10.6863 24 8 21.3137 8 18L8 6C8 2.68629 10.6863 -1.8633e-06 14 -1.57361e-06L30 -1.74846e-07C31.1046 -7.8281e-08 32 0.895432 32 2L32 22Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 23.5L30 23.5C30.8284 23.5 31.5 22.8284 31.5 22L31.5 2C31.5 1.17157 30.8284 0.5 30 0.5L14 0.499998C10.9624 0.499998 8.5 2.96243 8.5 6L8.5 18C8.5 21.0376 10.9624 23.5 14 23.5ZM30 24C31.1046 24 32 23.1046 32 22L32 2C32 0.895432 31.1046 -7.8281e-08 30 -1.74846e-07L14 -1.57361e-06C10.6863 -1.8633e-06 8 2.68629 8 6L8 18C8 21.3137 10.6863 24 14 24L30 24Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
