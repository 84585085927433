import { Box } from '@chakra-ui/react';
import { defaultSchematicConfig } from '@web/apps/Design/features/Schematic/config.ts';

import { Row } from './Row.tsx';

export const LoopbackRow = () => (
  <Row>
    <Box h={defaultSchematicConfig.cavityHeight} />
  </Row>
);
