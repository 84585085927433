import { MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { useUpdateLayoutNodeMutation } from '@web/apps/Design/api/layout-nodes-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { MenuPosition } from '../../../../../../../components/menu/useContextMenuState.ts';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';

interface Props {
  isOpen: boolean;
  position: MenuPosition;
  closeMenu: () => void;
  layoutNodeId: UUID;
  rotateLock: boolean;
  angle: number;
  noteGroupBuildNotes: NoteGroupBuildNote[];
}

export const ConnectorContextMenu = ({
  isOpen,
  position,
  closeMenu,
  layoutNodeId,
  rotateLock = false,
  angle = 0,
  noteGroupBuildNotes,
}: Props) => {
  const designId = useDesignId();
  const { mutateAsync: updateLayoutNode } = useUpdateLayoutNodeMutation();

  const onRotateLock = (value: boolean) => {
    updateLayoutNode({
      designId,
      layoutNodeId,
      data: {
        rotateLocked: value,
      },
    });
    closeMenu();
  };

  const onChangeDirection = (angle: number) => {
    updateLayoutNode({
      designId: designId,
      layoutNodeId,
      data: {
        angle: angle,
      },
    });
    closeMenu();
  };

  return (
    <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
      <MenuGroup title="Actions">
        {!rotateLock ? (
          <MenuItem onClick={() => onRotateLock(true)}>Lock rotation</MenuItem>
        ) : (
          <MenuItem onClick={() => onRotateLock(false)}>Unlock rotation</MenuItem>
        )}
      </MenuGroup>
      {rotateLock && (
        <>
          <MenuDivider />
          <MenuOptionGroup defaultValue={String(angle)} title="Face connector:" type="radio">
            <MenuItemOption onClick={() => onChangeDirection(180)} value="180">
              Left
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(0)} value="0">
              Right
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(270)} value="270">
              Up
            </MenuItemOption>
            <MenuItemOption onClick={() => onChangeDirection(90)} value="90">
              Down
            </MenuItemOption>
          </MenuOptionGroup>
        </>
      )}
      <MenuDivider />
      <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={layoutNodeId} />
    </ContextMenu>
  );
};
