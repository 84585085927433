import { Flex, Text } from '@chakra-ui/react';
import FieldValueList from '@web/apps/Design/components/FieldValueList.tsx';
import { usePartData } from '@web/apps/Design/hooks/usePartData.tsx';
import { useSelectedDesignPart } from '@web/apps/Design/hooks/useSelectedDesignPart.tsx';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import React from 'react';

import { useDesign } from '../../../../hooks/useDesign.tsx';
import { RelationalSegmentView } from '../../../RelationalLayout/components/details/RelationalSegmentView/RelationalSegmentView.tsx';
import { EdgeType as RelationalEdgeType } from '../../../RelationalLayout/relational_types.ts';
import { edgeTypes, nodeTypes } from '../../types.ts';
import { MeasurementView } from './MeasurementView.tsx';
import { SegmentView } from './SegmentView/SegmentView.tsx';

type NodeType = keyof typeof nodeTypes;
type EdgeType = keyof typeof edgeTypes;
type LayoutElementType = NodeType | EdgeType;

const componentMap: Partial<Record<LayoutElementType, React.FC<LayoutElementViewProps>>> = {
  // Edge Types
  Segment: SegmentView,
  Measurement: MeasurementView,
  // Relational layout types
  [RelationalEdgeType.SegmentEdge]: RelationalSegmentView,
} as const;

type LayoutElementViewProps = {
  node?: Node;
  edge?: Edge;
};

export const DetailsView = () => {
  const selectedDesignPart = useSelectedDesignPart();
  const data = usePartData(selectedDesignPart);

  const { selections } = useDesign();
  const { getNode, getEdge } = useReactFlow();

  const selectedNode = getNode(selections.layoutElement || '');
  const selectedEdge = getEdge(selections.layoutElement || '');
  const selectedElement = selectedNode || selectedEdge;

  const Component = selectedElement?.type ? componentMap[selectedElement.type as keyof typeof componentMap] : undefined;

  if (Component) {
    return (
      <Flex w="full" flexDirection="column" overflow="auto">
        <Component key={selectedElement?.id} node={selectedNode} edge={selectedEdge} />
      </Flex>
    );
  } else if (selectedDesignPart) {
    return (
      <Flex w="full" flexDirection="column" overflow="auto">
        <FieldValueList data={data} p={4} />
      </Flex>
    );
  }

  return <Text p={8}>Select a valid target to show details.</Text>;
};
