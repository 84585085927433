import { Button, useColorModeValue } from '@chakra-ui/react';
import { matchPath, useLocation, useNavigate } from 'react-router';

interface PartsNavButtonProps {
  name: string;
  path: string;
  isSubType?: boolean;
}

export const PartsNavButton = ({ name, path, isSubType }: PartsNavButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = matchPath(location.pathname, path);
  const activeBlue = useColorModeValue('blue.100', 'blue.800');
  const borderColor = useColorModeValue('white', 'gray.800');

  return (
    <Button
      justifyContent="start"
      variant="ghost"
      borderRadius="0"
      borderWidth="2px"
      borderColor={isActive ? activeBlue : borderColor}
      boxSizing="border-box"
      onClick={() => navigate(path)}
      backgroundColor={isActive ? activeBlue : 'initial'}
      p={6}
      pl={isSubType ? 8 : undefined}
      _hover={{ borderColor: 'blue.400' }}
      fontWeight="normal"
    >
      {name}
    </Button>
  );
};
