import { Text } from '@chakra-ui/react';
import { Part, PartType, Wire } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';
import { formatConductorDiameter } from '../../utils.tsx';

const defaultValues = { type: PartType.WIRE };

const columns: CustomColumnDef[] = [
  {
    header: 'Gauge',
    renderFn: (part: Part) => {
      const wire = part as Wire;
      return <Text>{wire.gauge}</Text>;
    },
  },
  {
    header: 'Colors',
    renderFn: (part: Part) => {
      const wire = part as Wire;
      return <Text>{wire.colors.toString()}</Text>;
    },
  },
  {
    header: 'Inner Diameter',
    renderFn: (part: Part) => {
      const wire = part as Wire;
      return <Text>{formatConductorDiameter(wire.innerDiameter, wire.diameterUnitOfMeasurement)}</Text>;
    },
  },
  {
    header: 'Outer Diameter',
    renderFn: (part: Part) => {
      const wire = part as Wire;
      return <Text>{formatConductorDiameter(wire.outerDiameter, wire.diameterUnitOfMeasurement)}</Text>;
    },
  },
];

export const WiresDashboard = () => (
  <PartsLibraryDashboard
    title="Wires"
    addPartButtonLabel="Wire"
    routeName="wires"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.WIRE}
  />
);

export default WiresDashboard;
