import { Text } from '@chakra-ui/react';
import { CustomClearIndicator } from '@web/apps/Design/features/WiringList/components/WiringListCell/CustomClearIndicator.tsx';
import { customSelectStyles } from '@web/apps/Design/features/WiringList/components/WiringListCell/customSelectStyles.ts';
import { Col, Row } from '@web/apps/Design/features/WiringList/types.ts';
import { processArrowKeys } from '@web/apps/Design/features/WiringList/utils/processArrowKeys.ts';
import { findSelectedOption, GroupedOption, Option } from '@web/common/select.ts';
import { Props as ChakraReactSelectProps, Select, SelectInstance } from 'chakra-react-select';
import { FocusEvent, KeyboardEvent, useEffect, useRef } from 'react';

import { useWiringList } from '../../hooks/useWiringList.tsx';

interface Props extends ChakraReactSelectProps<Option, false, GroupedOption> {
  row: Row;
  col: Col;
  isActive: boolean;
  onEnterKeyPress?: () => void;
}

export const EditableCellSelect = ({ value, options = [], isActive, onEnterKeyPress, ...rest }: Props) => {
  const { isSelectMenuOpen, setIsSelectMenuOpen, rowPendingChange, setIsKeyboardNav } = useWiringList();
  const selectedOption = findSelectedOption((value as Option | null)?.value, options);
  const selectRef = useRef<SelectInstance<Option, false, GroupedOption>>(null);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isSelectMenuOpen) return;

    setIsKeyboardNav(true);

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onEnterKeyPress?.();
      return;
    }

    processArrowKeys(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsSelectMenuOpen(false);
    rest.onBlur?.(event);
  };

  useEffect(() => {
    if (isActive && selectRef.current) {
      selectRef.current.focus();
    }
  }, [isActive]);

  return isActive ? (
    <Select
      ref={selectRef}
      variant="unstyled"
      value={selectedOption}
      options={options}
      onMenuOpen={() => setIsSelectMenuOpen(true)}
      onMenuClose={() => setIsSelectMenuOpen(false)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      isDisabled={Boolean(rowPendingChange)}
      isClearable
      components={{ ClearIndicator: CustomClearIndicator }}
      formatGroupLabel={(data) => <Text textTransform="uppercase">{data.label}</Text>}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      chakraStyles={customSelectStyles}
      {...rest}
    />
  ) : (
    <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" px={8}>
      {selectedOption?.label || ''}
    </Text>
  );
};
