import { UUID } from '@web/apps/types';
import { ReactFlowProvider } from '@xyflow/react';
import { ReactNode } from 'react';

import { DesignFiltersProvider } from '../hooks/useDesignFilters';
import { DesignIdProvider } from '../hooks/useDesignId';
import { PartSearchModalProvider } from '../hooks/usePartSearchModal';

export const ExportReactFlowProvider = ({ children, designId }: { children: ReactNode; designId: UUID }) => {
  return (
    <ReactFlowProvider>
      <DesignIdProvider designId={designId}>
        <DesignFiltersProvider>
          <PartSearchModalProvider>{children}</PartSearchModalProvider>
        </DesignFiltersProvider>
      </DesignIdProvider>
    </ReactFlowProvider>
  );
};
