import { DesignOverview } from '../../../../../hooks/useDesignOverview';

export const getToleranceText = (min: string, max: string, lengthUnit: DesignOverview['lengthUnit']) => {
  const parsedMin = Number(min);
  const parsedMax = Number(max);

  if (parsedMin > 0 && parsedMax > 0) {
    return `(+${max}/-${min})${lengthUnit}`;
  } else if (parsedMin > 0) {
    return `(-${min})${lengthUnit}`;
  } else if (parsedMax > 0) {
    return `(+${max})${lengthUnit}`;
  } else {
    return '';
  }
};
