import { Box } from '@chakra-ui/react';
import { GroupedOption, Option } from '@web/common/select.ts';
import { ClearIndicatorProps, components } from 'chakra-react-select';
import { IoClose } from 'react-icons/io5';

export const CustomClearIndicator = (props: ClearIndicatorProps<Option, false, GroupedOption>) => (
  <components.ClearIndicator {...props}>
    <Box as={IoClose} color="gray.700" cursor="pointer" _hover={{ bg: 'transparent' }} />
  </components.ClearIndicator>
);
