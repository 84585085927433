import { DesignPart, Part, PartType } from '@web/apps/types';
import { isAccessory } from '@web/apps/utils/parts.ts';

import { usePartSearchModal } from '../../../hooks/usePartSearchModal.tsx';
import { AddAccessoryButton } from '../components/AddAccessoryButton/AddAccessoryButton.tsx';
import AddPartButton from '../components/AddPartButton.tsx';
import ReplacePartButton from '../components/ReplacePartButton.tsx';

/**
 * usePartSearchActions hook provides actions to open the part search modal with different configurations for the
 * Assembly Navigator. It includes actions to add a general part, add a specific part, and replace a part.
 */
export const usePartSearchActions = () => {
  const { isOpen, openModal, closeModal } = usePartSearchModal();

  const handleAddGeneralPart = () => {
    openModal({
      initialPartTypeFilters: Object.values(PartType),
      renderSearchResultAction: (searchResult: Part) => {
        return isAccessory(searchResult.type) ? (
          <AddAccessoryButton part={searchResult} />
        ) : (
          <AddPartButton part={searchResult} />
        );
      },
    });
  };

  const handleAddSpecificPart = (filters: PartType[], connectorPart?: DesignPart) => {
    openModal({
      initialPartTypeFilters: filters,
      filtersDisabled: true,
      renderSearchResultAction: (searchResult: Part) => {
        return connectorPart ? (
          <AddAccessoryButton part={searchResult} connectorPart={connectorPart} />
        ) : (
          <AddPartButton part={searchResult} />
        );
      },
    });
  };

  const handleReplacePart = (partToReplace: DesignPart) => {
    openModal({
      initialPartTypeFilters: [partToReplace.partData.type],
      filtersDisabled: true,
      renderSearchResultAction: (searchResult: Part) => (
        <ReplacePartButton part={searchResult} partToReplace={partToReplace} onPartReplaced={closeModal} />
      ),
    });
  };

  return { isOpen, handleAddGeneralPart, handleAddSpecificPart, handleReplacePart, closeModal };
};
