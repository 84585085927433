import { Button, HStack } from '@chakra-ui/react';

import { FilterBox, FilterBoxProps } from './FilterBox';

export const FilterBoxWithClearButton = ({ placeholder, value, onChange, shouldFocus }: FilterBoxProps) => (
  <HStack gap={6} px={8}>
    <FilterBox placeholder={placeholder} value={value} onChange={onChange} shouldFocus={shouldFocus} />
    <Button size="sm" onClick={() => onChange('')}>
      Clear filters
    </Button>
  </HStack>
);
