import { HStack, Text } from '@chakra-ui/react';
import { truncateText } from '@web/apps/styles.ts';
import { DesignPart } from '@web/apps/types';

interface Props {
  designPart?: DesignPart;
}

export const SelectedDesignPartLabel = ({ designPart }: Props) => {
  const name = designPart?.name || 'Unknown Connection Target';
  const partNumber = designPart?.partData?.partNumber || 'No Part Number';

  return (
    <HStack>
      <Text color="gray.900" fontWeight="bold" px={2} mr={2} {...truncateText}>
        {name}
      </Text>
      <Text color="gray.700" px={2} {...truncateText}>
        {partNumber}
      </Text>
    </HStack>
  );
};
