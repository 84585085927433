import { UUID } from '@web/apps/types';
import { Position, XYPosition } from '@xyflow/react';

import { RelationalSegmentEdgeType } from '../SegmentEdge/RelationalSegmentEdge.tsx';
import { BaseNoteEdge } from './BaseNoteEdge.tsx';

interface Props {
  sourceXYPosition: XYPosition;
  sourcePosition: Position;
  targetEdge: RelationalSegmentEdgeType;
  controlPointId: UUID;
}

export const ControlPointNoteEdge = ({ sourceXYPosition, sourcePosition, targetEdge, controlPointId }: Props) => {
  const controlPoint = targetEdge.data?.controlPoints.find((controlPoint) => controlPoint.id === controlPointId);

  if (controlPoint) {
    const controlPointPosition = { x: controlPoint.x, y: controlPoint.y };
    return (
      <BaseNoteEdge
        sourceXYPosition={sourceXYPosition}
        sourcePosition={sourcePosition}
        targetXYPosition={controlPointPosition}
      />
    );
  }

  return null;
};
