import { Box, Grid, HStack, Text } from '@chakra-ui/react';
import { defaultSchematicConfig } from '@web/apps/Design/features/Schematic/config.ts';
import { Schematic } from '@web/apps/types';
import { EdgeLabelRenderer } from '@xyflow/react';

import { ColorBlock } from '../../../../../components/ColorBlock.tsx';
import { conductorSymbolOffset } from '../../../types/positioning.ts';
import { ConductorDetail } from './ConductorDetail.tsx';

interface Props {
  labelX: number;
  labelY: number;
  edgeLength: number;
  conductor: Schematic.Conductor;
}

export const EdgeLabel = ({ labelX, labelY, edgeLength, conductor }: Props) => {
  return (
    <EdgeLabelRenderer>
      <Box
        position="absolute"
        left={`${labelX}px`}
        top={`${labelY}px`}
        transform="translate(-50%, -50%)"
        width={`${edgeLength - 2 * conductorSymbolOffset}px`}
        textAlign="center"
        px={2}
      >
        <Grid templateColumns="2fr 1fr 1fr 2fr" gap={2} px={8}>
          <ConductorDetail>{conductor.partNumber}</ConductorDetail>
          <ConductorDetail color="gray.500">{conductor.gauge && `${conductor.gauge} AWG`}</ConductorDetail>
          <ConductorDetail color="gray.500">{conductor.signal}</ConductorDetail>
          <ConductorDetail>
            <HStack px={2} bg={defaultSchematicConfig.style.backgroundColor}>
              <ColorBlock text={conductor.name} />
              <Text isTruncated whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {conductor.name}
              </Text>
            </HStack>
          </ConductorDetail>
        </Grid>
      </Box>
    </EdgeLabelRenderer>
  );
};
