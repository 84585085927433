import {
  Box,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { apiDeleteUser, apiResendInvitation } from '@web/api/admin-api.ts';
import { doesUserHaveRole, Role } from '@web/apps/types';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { adminUsersCacheKey, useAdminUsers } from '@web/queries/admin.ts';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { RiSettings5Fill } from 'react-icons/ri';
import { Link, NavLink, useSearchParams } from 'react-router';

import Button from '../../../components/buttons/Button';
import { FilterBox } from '../../../components/FilterBox';
import Loading from '../../../components/Loading';
import Paginate from '../../../components/Paginate';
import AdminPage from '../AdminPage';

const AdminUsers = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const [query, setQuery] = useState(searchParams.get('q') ?? '');
  const { isLoading, data: users } = useAdminUsers(page, searchParams.get('q'));
  const data = users ?? { data: [], meta: { totalPages: 0 } };

  const debouncedSearchUsers = useMemo(
    () =>
      debounce((value: string) => {
        setSearchParams(value ? `q=${value}` : undefined);
      }, 500),
    [setSearchParams],
  );

  const resendInvitation = (userId: string, email: string) => {
    void apiResendInvitation(userId).then((response) => {
      if (response) {
        successToast(`Resent invite to ${email}`);
      }
    });
  };

  const deleteUser = (userId: string) => {
    void apiDeleteUser(userId).then(async () => {
      await queryClient.invalidateQueries({ queryKey: adminUsersCacheKey(page, query) });
      successToast(`User was successfully deleted.`);
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  return (
    <AdminPage
      title="Admin Users"
      actions={[
        <NavLink key={RouteNames.USERS.NEW} to={RouteNames.USERS.NEW}>
          <Button>New User</Button>
        </NavLink>,
      ]}
    >
      <Box mb={6}>
        <FilterBox
          value={query}
          onChange={(text) => {
            setQuery(text);
            debouncedSearchUsers(text);
          }}
        />
      </Box>
      {isLoading && <Loading message="Loading users…" />}
      {!isLoading && (
        <>
          <Table mb={6} size="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Auth0 ID</Th>
                <Th>Name</Th>
                <Th>Tenant</Th>
                <Th>Primary Email</Th>
                <Th>Primary Phone</Th>
                <Th>Admin</Th>
                <Th>Manufacturing engineer</Th>
                <Th>Technician</Th>
                <Th>Authorized operator</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {(data.data || []).map((user) => (
                <Tr key={user.id}>
                  <Td>
                    <ChakraLink as={Link} color="primary" to={RouteNames.USERS.EDIT.replace(':userId', user.id)}>
                      {user.id}
                    </ChakraLink>
                  </Td>
                  <Td>
                    {user.auth0Id && (
                      <Text as="span" fontSize="xs">
                        {user.auth0Id}
                      </Text>
                    )}
                    {!user.auth0Id && (
                      <Text as="span" fontSize="xs" fontStyle="italic" opacity="0.8">
                        Invitation email sent
                      </Text>
                    )}
                  </Td>
                  <Td>{user.name}</Td>
                  <Td>
                    <ChakraLink
                      as={Link}
                      color="primary"
                      fontWeight={500}
                      to={RouteNames.TENANTS.EDIT.replace(':tenantId', user.tenant.id)}
                    >
                      {user.tenant.name}
                    </ChakraLink>
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>{user.phone}</Td>
                  <Td>{doesUserHaveRole(user, Role.ADMIN) ? '✓' : ''}</Td>
                  <Td>{doesUserHaveRole(user, Role.MANUFACTURING_ENGINEER) ? '✓' : ''}</Td>
                  <Td>{doesUserHaveRole(user, Role.TECHNICIAN) ? '✓' : ''}</Td>
                  <Td>
                    <Box fontSize="sm">
                      <Menu>
                        <MenuButton tabIndex={0} cursor="pointer" _hover={{ opacity: '0.8' }}>
                          <RiSettings5Fill size={18} />
                        </MenuButton>
                        <MenuList bg="background" borderRadius={0}>
                          <MenuItem bg="background" _hover={{ bg: 'whiteAlpha.100' }}>
                            {!user.auth0Id ? (
                              <span
                                role="button"
                                onClick={() => resendInvitation(user.id, user.email)}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    resendInvitation(user.id, user.email);
                                  }
                                }}
                                tabIndex={0}
                              >
                                Resend invitation
                              </span>
                            ) : null}
                          </MenuItem>
                          <MenuItem
                            as="a"
                            href={RouteNames.USERS.EDIT.replace(':userId', user.id)}
                            bg="background"
                            _hover={{ bg: 'whiteAlpha.100' }}
                          >
                            Edit User
                          </MenuItem>
                          <MenuItem bg="background" _hover={{ bg: 'whiteAlpha.100' }}>
                            <Text
                              as="span"
                              color="red.300"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure that you want to delete user "${user.name}" (${user.email})?`,
                                  )
                                ) {
                                  deleteUser(user.id);
                                }
                              }}
                            >
                              Delete user
                            </Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Paginate onPageChange={handlePageClick} selectedPage={parseInt(page) - 1} pageCount={data.meta.totalPages} />
        </>
      )}
    </AdminPage>
  );
};

export default AdminUsers;
