import { buildGraph } from '@web/apps/Design/features/Schematic/utils/graph.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { defaultViewport, Schematic, setEdgesType, setNodesType } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';

import { useSchematicQuery } from '../../../api/schematic-api.ts';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';
import { defaultSchematicConfig } from '../config.ts';

export interface UseLoadSchematic {
  schematicData: Schematic.SchematicData | null;
  isSchematicDataLoaded: boolean;
  updateSchematicWidth: (width: number) => void;
}

export const useLoadSchematic = (setNodes: setNodesType, setEdges: setEdgesType): UseLoadSchematic => {
  const designId = useDesignId();
  const [schematicLoaded, setSchematicLoaded] = useState(false);
  const [width, setWidth] = useState(defaultSchematicConfig.width);
  const { setViewport } = useReactFlow();

  const selectedDesignPart = useSelectedDesignPart();
  const { data: schematicData, isSuccess: isSchematicDataLoaded } = useSchematicQuery(
    designId,
    selectedDesignPart?.id || '',
  );

  // Provide a function to update the schematic width, which will trigger a reload
  const updateSchematicWidth = useCallback((width: number) => {
    setSchematicLoaded(false);
    setWidth(width);
  }, []);

  // Reset schematic loaded state when the selected design part changes, or when the schematic data changes
  useEffect(() => {
    setSchematicLoaded(false);
  }, [schematicData, selectedDesignPart]);

  // Initialize schematic
  useEffect(() => {
    const loadSchematic = async () => {
      if (!selectedDesignPart || schematicLoaded) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isSchematicDataLoaded && schematicData) {
        const graph = buildGraph(schematicData, width);
        console.debug('Graph:', graph);
        setNodes(graph.nodes);
        setEdges(graph.edges);
        await setViewport(defaultViewport);

        setSchematicLoaded(true);
      }
    };

    void loadSchematic();
  }, [
    isSchematicDataLoaded,
    schematicData,
    schematicLoaded,
    selectedDesignPart,
    setEdges,
    setNodes,
    setViewport,
    width,
  ]);

  return { schematicData: schematicData ?? null, isSchematicDataLoaded, updateSchematicWidth };
};
