import { HStack, useDisclosure } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';
import { MouseEvent, ReactNode } from 'react';

import { SELECTION_TYPES, useDesign } from '../../../../hooks/useDesign';
import { PartDiffIndicator } from './PartDiffIndicator';
import { PartMenu } from './PartMenu';

interface Props {
  part: DesignPart;
  canRename?: boolean;
  onRenamePart?: () => void;
  children: ReactNode;
}

export const BasePartListItem = ({ part, canRename, onRenamePart, children }: Props) => {
  const { isSelected, setSelection } = useDesign();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onMenuOpen();
  };

  const selected = isSelected('designPart', part.id);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      event.stopPropagation();
      setSelection(SELECTION_TYPES.DESIGN_PART, part.id);
    }
  };

  return (
    <HStack
      className="group"
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      pl={8}
      pr={3}
      bgColor={selected ? 'blue.100' : 'transparent'}
      _hover={selected ? undefined : { bgColor: 'gray.200' }}
      whiteSpace="nowrap"
      cursor="pointer"
    >
      <HStack fontSize="sm" flex={1} py={1} minW={0} alignItems="flex-end">
        {children}
      </HStack>
      <PartDiffIndicator part={part} />
      <PartMenu
        isOpen={isMenuOpen}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        part={part}
        canRename={canRename}
        onRenamePart={onRenamePart}
      />
    </HStack>
  );
};
