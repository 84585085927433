import { Box, Button, Heading, Stack, useColorModeValue } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames';
import { matchPath, useLocation, useNavigate } from 'react-router';

const ToolsSideNav = () => (
  <Box minW="225px" h="full" borderRight="2px" borderColor="gray.200">
    <Box borderBottom="2px" borderColor="gray.200" p={6}>
      <Heading as="h1" size="md">
        Tool Types
      </Heading>
    </Box>

    <Stack spacing={0}>
      <ToolTypeButton name="Torque Adapter" path={RouteNames.TOOLS.TORQUE_ADAPTERS} />
      <ToolTypeButton name="Crimper" path={RouteNames.TOOLS.CRIMPERS} />
      <ToolTypeButton name="Positioner" path={RouteNames.TOOLS.POSITIONERS} />
      <ToolTypeButton name="Die Set" path={RouteNames.TOOLS.DIE_SETS} />
      <ToolTypeButton name="Turret Head" path={RouteNames.TOOLS.TURRET_HEADS} />
      <ToolTypeButton name="Insertion + Removal" path={RouteNames.TOOLS.INSERTIONS_REMOVALS} />
    </Stack>
  </Box>
);

const ToolTypeButton = ({ name, path }: { name: string; path: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = matchPath(location.pathname, path);
  const activeBlue = useColorModeValue('blue.100', 'blue.800');
  const borderColor = useColorModeValue('white', 'gray.800');

  return (
    <Button
      justifyContent="start"
      variant="ghost"
      p={6}
      borderRadius="0"
      borderWidth="2px"
      borderColor={isActive ? activeBlue : borderColor}
      boxSizing="border-box"
      onClick={() => navigate(path)}
      backgroundColor={isActive ? activeBlue : 'initial'}
      _hover={{ borderColor: 'blue.400' }}
      fontWeight="normal"
    >
      {name}
    </Button>
  );
};

export default ToolsSideNav;
