import type { ControlPointData, UUID } from '@web/apps/types';
import type { BuiltInNode, XYPosition } from '@xyflow/react';
import { useReactFlow, useStore } from '@xyflow/react';
import { KeyboardEvent, PointerEvent as ReactPointerEvent, useCallback, useEffect, useRef, useState } from 'react';

import { useUpsertControlPointsMutation } from '../../../../../../api/control-points-api';
import { RelationalSegmentEdgeType } from '../RelationalSegmentEdge';

type useControlPointProps = {
  id: string;
  index: number;
  x: number;
  y: number;
  active?: boolean;
  designId: UUID;
  layoutEdgeId: UUID;
};

export function useControlPoint({ id, index, x, y, active, designId, layoutEdgeId }: useControlPointProps) {
  const container = useStore((store) => store.domNode);
  const { screenToFlowPosition, getEdge, updateEdgeData } = useReactFlow<BuiltInNode, RelationalSegmentEdgeType>();
  const [dragging, setDragging] = useState(false);
  const ref = useRef<SVGCircleElement>(null);

  const { mutate: upsertControlPoints, isPending: isPendingUpsert } = useUpsertControlPointsMutation();

  const getControlPoints = useCallback(() => getEdge(layoutEdgeId)?.data?.controlPoints || [], [getEdge, layoutEdgeId]);
  const setControlPoints = useCallback(
    (controlPoints: ControlPointData[]) => {
      if (controlPoints && controlPoints.length > 0) {
        updateEdgeData(layoutEdgeId, { controlPoints });
      }
    },
    [updateEdgeData, layoutEdgeId],
  );

  const updatePosition = useCallback(
    (pos: XYPosition) => {
      const points = getControlPoints();

      let updatedPoints;
      const shouldActivate = !active;
      if (shouldActivate) {
        if (index !== 0) {
          updatedPoints = points.flatMap((p, i) => (i === index * 0.5 - 1 ? [p, { ...pos, id, active: true }] : p));
        } else {
          updatedPoints = [{ ...pos, id, active: true }, ...points];
        }
      } else {
        updatedPoints = points.map((p) => (p.id === id ? { ...p, ...pos } : p));
      }

      setControlPoints(updatedPoints);

      return updatedPoints;
    },
    [getControlPoints, active, setControlPoints, index, id],
  );

  const deletePoint = useCallback(() => {
    const points = getControlPoints();
    const updatedPoints = points.filter((p) => p.id !== id);

    setControlPoints(updatedPoints);

    upsertControlPoints({
      designId,
      layoutEdgeId,
      data: updatedPoints.filter((p) => p.active),
    });

    // Previous active control points are always 2 elements before the current one
    const previousControlPoint = ref.current?.previousElementSibling?.previousElementSibling;
    if (previousControlPoint?.tagName === 'circle' && previousControlPoint.classList.contains('active')) {
      window.requestAnimationFrame(() => {
        (previousControlPoint as SVGCircleElement).focus();
      });
    }
  }, [designId, getControlPoints, id, layoutEdgeId, setControlPoints, upsertControlPoints]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const key = e.key;
      if (key === 'Enter' || key === 'Space') {
        if (!active) {
          e.preventDefault();
        }
        updatePosition({ x, y });
      } else if (key === 'Backspace' || key === 'Delete') {
        e.stopPropagation();
        deletePoint();
      } else if (key === 'ArrowLeft') {
        updatePosition({ x: x - 5, y });
      } else if (key === 'ArrowRight') {
        updatePosition({ x: x + 5, y });
      } else if (key === 'ArrowUp') {
        updatePosition({ x, y: y - 5 });
      } else if (key === 'ArrowDown') {
        updatePosition({ x, y: y + 5 });
      }
    },
    [active, updatePosition, x, y, deletePoint],
  );

  const handlePointerDown = (e: ReactPointerEvent<SVGCircleElement>) => {
    if (e.button === 2) return;
    updatePosition({ x, y });
    setDragging(true);
  };
  const handlePointerUp = () => setDragging(false);

  useEffect(() => {
    if (!container || !active || !dragging) return;

    const onPointerMove = (e: PointerEvent) => updatePosition(screenToFlowPosition({ x: e.clientX, y: e.clientY }));

    const onPointerUp = (e: PointerEvent) => {
      container.removeEventListener('pointermove', onPointerMove);

      if (!active) {
        e.preventDefault();
      }

      setDragging(false);
      const updatedPoints = updatePosition(screenToFlowPosition({ x: e.clientX, y: e.clientY }));

      upsertControlPoints({
        designId,
        layoutEdgeId,
        data: updatedPoints.filter((p) => p.active),
      });
    };

    container.addEventListener('pointermove', onPointerMove);
    container.addEventListener('pointerup', onPointerUp, { once: true });
    container.addEventListener('pointerleave', onPointerUp, { once: true });

    return () => {
      container.removeEventListener('pointermove', onPointerMove);
      container.removeEventListener('pointerup', onPointerUp);
      container.removeEventListener('pointerleave', onPointerUp);
    };
  }, [
    id,
    container,
    dragging,
    active,
    screenToFlowPosition,
    updatePosition,
    upsertControlPoints,
    designId,
    layoutEdgeId,
  ]);

  return { ref, deletePoint, isPendingUpsert, handleKeyPress, handlePointerDown, handlePointerUp };
}
