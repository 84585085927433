import { Box, chakra, ChakraProps, Flex } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';
import { featureFlag, useFeatureFlag } from '@web/hooks/useFeatureFlag.ts';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { TfiLayout, TfiNewWindow } from 'react-icons/tfi';
import { generatePath, useMatch } from 'react-router';

import { useDesignId } from '../hooks/useDesignId.tsx';
import { useWindowManager } from '../hooks/useWindowManager.tsx';

const OpenWindowIcon = chakra(TfiNewWindow, {
  baseStyle: {
    ariaLabel: 'Open window',
    role: 'button',
    cursor: 'pointer',
    fontSize: 'xl',
  },
});

const CloseWindowIcon = chakra(TfiLayout, {
  baseStyle: {
    ariaLabel: 'Close window',
    role: 'button',
    cursor: 'pointer',
    fontSize: 'xl',
  },
});

interface Props extends ChakraProps {
  title: string;
  routeName: string;
  windowFeatures?: string;
  actions?: ReactNode;
  children?: ReactNode | ((props: { hasFocus: boolean; windowIcon?: ReactNode; isInLayout: boolean }) => ReactNode);
}

/**
 * Wraps a component with a Title, and a button in the top right that will pop out the component in a new window.
 * @param title
 * @param routeName
 * @param windowFeatures
 * @param actions
 * @param children
 * @param rest
 * @constructor
 */
const ManagedWindow = ({ title, routeName, windowFeatures, actions, children, ...rest }: Props) => {
  const designId = useDesignId();
  const matchDesign = useMatch(RouteNames.DESIGNS.DESIGN);
  const matchDesignPart = useMatch(RouteNames.DESIGNS.DESIGN_PART);
  const isInLayout = matchDesign !== null || matchDesignPart !== null;
  const url = generatePath(routeName, { designId });
  const { openWindow, postWindowCloseMessage } = useWindowManager();
  const isPopOutEnabled = useFeatureFlag(featureFlag.PopOuts);
  const [hasFocus, setHasFocus] = useState(false);

  const handleWindowOpen = useCallback(() => {
    const windowName = title || '_blank';
    openWindow(url, windowName, windowFeatures || 'width=800,height=600,left=200,top=200');
  }, [title, openWindow, url, windowFeatures]);

  const handleWindowClose = useCallback(() => {
    postWindowCloseMessage(url);
  }, [postWindowCloseMessage, url]);

  const windowIcon = useMemo(() => {
    if (!isPopOutEnabled) {
      return null;
    }

    return isInLayout ? (
      <OpenWindowIcon data-testid="open-window-icon" onClick={handleWindowOpen} />
    ) : (
      <CloseWindowIcon data-testid="close-window-icon" onClick={handleWindowClose} />
    );
  }, [isInLayout, handleWindowOpen, handleWindowClose, isPopOutEnabled]);

  return (
    <Box
      onMouseEnter={() => setHasFocus(true)}
      onMouseLeave={() => setHasFocus(false)}
      display="flex"
      flexDirection="column"
      height="full"
      overflow="hidden"
      {...rest}
    >
      {actions && (
        <Flex justifyContent="flex-end" borderBottomWidth={1} borderColor="gray.300">
          {actions}
        </Flex>
      )}
      {typeof children === 'function' ? children({ hasFocus, windowIcon, isInLayout }) : children}
    </Box>
  );
};

export default ManagedWindow;
