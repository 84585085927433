import { Schematic, UUID } from '@web/apps/types';

export type InlineType = 'source' | 'destination' | 'standard';

export const hasInlines = (path: Schematic.Path): boolean => {
  return path.inlines.length > 0;
};

export const hasShieldSplices = (path: Schematic.Path): boolean => {
  return path.inlines.some((inline) => getInlineType(inline, path) !== 'standard');
};

export const isGroupedWith = (partId: UUID, inline: Schematic.ConnectionPoint) =>
  !!partId && inline.groupWith === partId;

export const getInlineType = (inline: Schematic.ConnectionPoint, path: Schematic.Path): InlineType => {
  if (path.source && isGroupedWith(path.source.partId, inline)) return 'source';
  if (path.destination && isGroupedWith(path.destination.partId, inline)) return 'destination';
  return 'standard';
};
