import { BaseEdge, EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';
import { getLoopbackPath } from '../../utils/paths/getLoopbackPath.ts';

export const Loopback = (props: EdgeProps) => {
  const edgeStyle = {
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1,
  };

  const [edgePath] = getLoopbackPath(props);

  return <BaseEdge path={edgePath} style={edgeStyle} />;
};
