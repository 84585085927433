import { useDesignQuery } from '@web/apps/Design/api/designs-api.ts';
import { Design, isNoteLayoutNode, UUID } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';
import { partition } from 'lodash';

import { useDesignId } from '../useDesignId.tsx';
import { createReactFlowNoteEdge, toReactFlowNode, toSegmentReactFlowEdge } from './transformLayoutElements.ts';

const emptyNodes: Node[] = [];
const emptyEdges: Edge[] = [];

export const selectLayoutData = (design: Design | null) => {
  if (design) {
    const { layoutNodes, layoutEdges } = design;

    const controlPointIdToEdgeIdMap = new Map<UUID, UUID>();
    layoutEdges.forEach(({ id: edgeId, controlPoints }) =>
      controlPoints?.forEach((controlPoint) => controlPointIdToEdgeIdMap.set(controlPoint.id, edgeId)),
    );

    // React flow requires parents to be before their children in the nodes array
    const mappedLayoutNodes = layoutNodes.map((node) => toReactFlowNode(node, controlPointIdToEdgeIdMap));
    const [parents, children] = partition(mappedLayoutNodes, ({ parentId }) => !parentId);
    const nodes = [...parents, ...children];

    // Map the edges and create any necessary note edges
    const edges = layoutEdges.map(toSegmentReactFlowEdge);
    layoutNodes.forEach((node) => {
      if (isNoteLayoutNode(node)) {
        edges.push(createReactFlowNoteEdge(node, controlPointIdToEdgeIdMap));
      }
    });

    return { nodes, edges };
  }

  return { nodes: [], edges: [] };
};

export const useRelationalLayoutData = () => {
  const designId = useDesignId();

  // Fetch layoutData
  const { data, isLoading, isSuccess, error } = useDesignQuery(designId, selectLayoutData);

  const nodes = data?.nodes || emptyNodes;
  const edges = data?.edges || emptyEdges;

  return {
    nodes,
    edges,
    isLoading,
    isSuccess,
    error,
  };
};
