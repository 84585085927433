import { setEdgesType, setNodesType } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';
import { useEffect, useState } from 'react';

import { useRelationalLayoutData } from '../../../../hooks/useRelationalLayoutData/useRelationalLayoutData.tsx';
import { useSelectedDesignPart } from '../../../../hooks/useSelectedDesignPart.tsx';

const addSelections = <T extends Node | Edge>(elements: T[], previousElements: T[]): T[] => {
  if (previousElements.length > 1) {
    const selectedIds = previousElements.filter(({ selected }) => selected).map(({ id }) => id);
    const selectedIdsSet = new Set(selectedIds);

    return elements.map((element) => (selectedIdsSet.has(element.id) ? { ...element, selected: true } : element));
  }

  return elements;
};

export const useLoadRelationalLayout = (setNodes: setNodesType, setEdges: setEdgesType): boolean => {
  const [reactFlowInitialized, setReactFlowInitialized] = useState(false);
  const { nodes, edges, isSuccess: isLayoutDataLoaded } = useRelationalLayoutData();
  const selectedDesignPart = useSelectedDesignPart();

  useEffect(() => {
    if (isLayoutDataLoaded) {
      setNodes((prevNodes: Node[]) => addSelections(nodes, prevNodes));
      setEdges((prevEdges: Edge[]) => addSelections(edges, prevEdges));
      setReactFlowInitialized(true);
    }
  }, [reactFlowInitialized, nodes, edges, setNodes, setEdges, isLayoutDataLoaded, selectedDesignPart]);

  return reactFlowInitialized;
};
