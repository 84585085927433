import { createContext, ReactNode, useContext, useState } from 'react';

import { ContextPanelHeight } from '../ContextPanel/types';

type DesignTabsContextType = {
  contextPanelHeight: ContextPanelHeight;
  setContextPanelHeight: (height: ContextPanelHeight) => void;
  selectedContextPanelTab: number;
  setSelectedContextPanelTab: (index: number) => void;
};

const DesignTabsContext = createContext<DesignTabsContextType | undefined>(undefined);

export const DesignTabsProvider = ({ children }: { children: ReactNode }) => {
  const [contextPanelHeight, setContextPanelHeight] = useState<ContextPanelHeight>(ContextPanelHeight.MINIMIZED);
  const [selectedContextPanelTab, setSelectedContextPanelTab] = useState(-1);

  const handleSetContextPanelHeight = (height: ContextPanelHeight) => {
    setContextPanelHeight(height);

    if (height === ContextPanelHeight.MINIMIZED) {
      setSelectedContextPanelTab(-1);
    }
  };

  return (
    <DesignTabsContext.Provider
      value={{
        contextPanelHeight,
        setContextPanelHeight: handleSetContextPanelHeight,
        selectedContextPanelTab,
        setSelectedContextPanelTab,
      }}
    >
      {children}
    </DesignTabsContext.Provider>
  );
};

export const useDesignTabs = () => {
  const context = useContext(DesignTabsContext);
  if (!context) {
    throw new Error('useDesignTabs must be used within a DesignTabsProvider');
  }
  return context;
};
