import { HStack, Text } from '@chakra-ui/react';
import { OpenGeneralPartSearchButton } from '@web/apps/Design/components/OpenGeneralPartSearchButton.tsx';

import { useDesign } from '../../../hooks/useDesign';

interface Props {
  onAddPart: () => void;
}

export const AssemblyNavigatorTitle = ({ onAddPart }: Props) => {
  const { isViewOnly } = useDesign();

  return (
    <HStack px={4} py={3} justifyContent="space-between">
      <Text fontSize="sm" fontWeight="bold">
        Assembly Navigator
      </Text>
      {!isViewOnly && <OpenGeneralPartSearchButton onOpenSearch={onAddPart} />}
    </HStack>
  );
};
