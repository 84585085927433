import { Checkbox, Text } from '@chakra-ui/react';
import { Part, PartType, Splice } from '@web/apps/types';

import { MinMaxAwg, minMaxAwgFormatter } from '../../components/MinMaxAwgFields.tsx';
import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.SPLICE };

const columns: CustomColumnDef[] = [
  {
    header: 'Type',
    renderFn: (part: Part) => {
      const splice = part as Splice;
      return <Text>{splice.subtype}</Text>;
    },
  },
  {
    header: 'AWG',
    renderFn: (part: Part) => {
      const splice = part as Splice;
      const minMaxAwg: MinMaxAwg = {
        gaugeMinAwg: splice.gaugeMinAwg,
        gaugeMaxAwg: splice.gaugeMaxAwg,
      };
      return <Text>{minMaxAwgFormatter(minMaxAwg)}</Text>;
    },
  },

  {
    header: 'Insulated?',
    renderFn: (part: Part) => {
      const splice = part as Splice;
      return <Checkbox isChecked={splice.insulated}></Checkbox>;
    },
  },
  {
    header: 'Made on Assembly?',
    renderFn: (part: Part) => {
      const splice = part as Splice;
      return <Checkbox isChecked={splice.madeOnAssembly}></Checkbox>;
    },
  },
];

export const SplicesDashboard = () => (
  <PartsLibraryDashboard
    title="Splices"
    addPartButtonLabel="Splice"
    routeName="splices"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.SPLICE}
  />
);
