import { Badge, Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useDesignOverview } from '../../../hooks/useDesignOverview.tsx';

interface Props {
  windowIcon?: ReactNode;
}

export const AssemblyNavigatorPartDetails = ({ windowIcon }: Props) => {
  const { partNumber, partRevision, description, tenant } = useDesignOverview();

  return (
    <Flex flexDirection="column" p={4}>
      <HStack spacing={3} pr={2}>
        <Tooltip label={partNumber}>
          <Text fontSize="xl" isTruncated overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {partNumber}
          </Text>
        </Tooltip>
        {partRevision && (
          <Badge bg="orange.500" color="white" flexShrink={0}>
            REV {partRevision}
          </Badge>
        )}
        {windowIcon && <Box flexShrink={0}>{windowIcon}</Box>}
      </HStack>
      <Text fontSize="xs" fontWeight="bold">
        {tenant.name}
      </Text>
      <Tooltip label={description}>
        <Text fontSize="sm" noOfLines={2}>
          {description}
        </Text>
      </Tooltip>
    </Flex>
  );
};
