import { BoxProps, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface RowProps extends BoxProps {
  children: ReactNode;
}

export const Row = ({ children, ...boxProps }: RowProps) => {
  return (
    <HStack display="flex" w="full" py={2} {...boxProps}>
      {children}
    </HStack>
  );
};
