import { Text } from '@chakra-ui/react';
import { Overwrap, Part, PartType } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.OVERWRAP };

const columns: CustomColumnDef[] = [
  {
    header: 'Consumable',
    renderFn: (part: Part) => {
      const overwrap = part as Overwrap;
      return <Text>{overwrap.consumable ? 'Yes' : 'No'}</Text>;
    },
  },
];

export const OverwrapsDashboard = () => (
  <PartsLibraryDashboard
    title="Overwraps"
    addPartButtonLabel="Overwrap"
    routeName="overwraps"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.OVERWRAP}
  />
);
