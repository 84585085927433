import { Text } from '@chakra-ui/react';
import { GenericPart, Part, PartType } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.GENERIC };

const columns: CustomColumnDef[] = [
  {
    header: 'Generic Part Type',
    renderFn: (part: Part) => {
      const generic = part as GenericPart;
      return <Text>{generic.subtype}</Text>;
    },
  },
  {
    header: 'Generic Part Unit',
    renderFn: (part: Part) => {
      const generic = part as GenericPart;
      return <Text textTransform="capitalize">{generic.genericPartUnit}</Text>;
    },
  },
];

export const GenericPartsDashboard = () => (
  <PartsLibraryDashboard
    title="Generic Parts"
    addPartButtonLabel="Generic Part"
    routeName="generic"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.GENERIC}
  />
);
