export const centerAbsolute = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
};

export const centerAbsoluteVertical = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
};

export const truncateText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
