import { Text } from '@chakra-ui/react';
import { DesignPart, PartDataWithSubtype } from '@web/apps/types';

import { BasePartListItem } from './BasePartListItem';
import { PartNumberDisplay } from './PartNumberDisplay';

interface Props {
  part: DesignPart;
}

export const PartTypeListItem = ({ part }: Props) => {
  const partData = part.partData as PartDataWithSubtype;
  const { subtype, partNumber } = partData;

  return (
    <BasePartListItem part={part}>
      {subtype && (
        <Text color="gray.600" fontSize="xs">
          {subtype}
        </Text>
      )}
      <PartNumberDisplay partNumber={partNumber} />
    </BasePartListItem>
  );
};
