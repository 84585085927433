import { Col, Row } from '@web/apps/Design/features/WiringList/types.ts';

import { EditableCellText } from './EditableCellText.tsx';
import { WiringListCell } from './WiringListCell.tsx';

interface Props {
  row: Row;
  col: Col;
  value: string;
  onFieldChange: (row: Row, col: Col, value: string) => void;
  onEnterKeyPress: () => void;
  onCellFocus: () => void;
  onCellBlur: () => void;
}

export const WiringListTextCell = ({
  row,
  col,
  value,
  onFieldChange,
  onEnterKeyPress,
  onCellFocus,
  onCellBlur,
}: Props) => {
  return (
    <WiringListCell row={row} col={col} onCellFocus={onCellFocus} onCellBlur={onCellBlur}>
      {({ isActive }) => (
        <EditableCellText
          row={row}
          col={col}
          isActive={isActive}
          value={value}
          onChange={(event) => onFieldChange(row, col, event.target.value)}
          onEnterKeyPress={onEnterKeyPress}
        />
      )}
    </WiringListCell>
  );
};
