import { apiPostTenant } from '@web/api/admin-api.ts';
import { generateOrderNumberPrefix } from '@web/common/lib/string-utils/order-utils.ts';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import AdminPage from '../AdminPage';
import TenantForm from './TenantForm';

const AdminNewTenant = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      usBased: 'true',
      orderNumberPrefix: '',
    },
  });

  const handleTenantNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    reset({ ...reset, orderNumberPrefix: generateOrderNumberPrefix(e.target.value) });
  };

  const onSubmit = async (data: object) => {
    const results = await apiPostTenant(data);
    if (results) {
      successToast('Tenant successfully created.');
      navigate(RouteNames.TENANTS.INDEX);
    }
  };

  return (
    <AdminPage title="New Tenant">
      <TenantForm
        isNew
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        isSubmitting={isSubmitting}
        isValid={isValid}
        handleTenantNameChange={handleTenantNameChange}
      />
    </AdminPage>
  );
};

export default AdminNewTenant;
