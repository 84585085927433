import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -16,
  y: -12,
};

const originalWidth = 32;
const originalHeight = 40;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const CircularConnector90 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M15 14C15.5523 14 16 13.5523 16 13L16 4C16 3.44772 15.5523 3 15 3L1 3C0.447716 3 4.35882e-07 3.44772 4.29296e-07 4L3.21972e-07 13C3.15386e-07 13.5523 0.447716 14 1 14L15 14Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 4L15.5 13C15.5 13.2761 15.2761 13.5 15 13.5L1 13.5C0.723858 13.5 0.5 13.2761 0.5 13L0.5 4C0.5 3.72386 0.723858 3.5 1 3.5L15 3.5C15.2761 3.5 15.5 3.72386 15.5 4ZM16 13C16 13.5523 15.5523 14 15 14L1 14C0.447716 14 3.15386e-07 13.5523 3.21972e-07 13L4.29296e-07 4C4.35882e-07 3.44772 0.447716 3 1 3L15 3C15.5523 3 16 3.44772 16 4L16 13Z"
          fill={outlineColor}
        />
        <path
          d="M27 18C27.5523 18 28 17.5523 28 17L28 1C28 0.447716 27.5523 -5.30385e-08 27 -5.96244e-08L13 -2.26573e-07C12.4477 -2.33159e-07 12 0.447716 12 1L12 17C12 17.5523 12.4477 18 13 18L27 18Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5 1L27.5 17C27.5 17.2761 27.2761 17.5 27 17.5L13 17.5C12.7239 17.5 12.5 17.2761 12.5 17L12.5 1C12.5 0.723858 12.7239 0.5 13 0.5L27 0.5C27.2761 0.5 27.5 0.723858 27.5 1ZM28 17C28 17.5523 27.5523 18 27 18L13 18C12.4477 18 12 17.5523 12 17L12 1C12 0.447716 12.4477 -2.33159e-07 13 -2.26573e-07L27 -5.96244e-08C27.5523 -5.30385e-08 28 0.447716 28 1L28 17Z"
          fill={outlineColor}
        />
        <path
          d="M30 40C31.1046 40 32 39.1046 32 38L32 22C32 18.6863 29.3137 16 26 16L14 16C10.6863 16 8 18.6863 8 22L8 38C8 39.1046 8.89543 40 10 40L30 40Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5 22L31.5 38C31.5 38.8284 30.8284 39.5 30 39.5L10 39.5C9.17157 39.5 8.5 38.8284 8.5 38L8.5 22C8.5 18.9624 10.9624 16.5 14 16.5L26 16.5C29.0376 16.5 31.5 18.9624 31.5 22ZM32 38C32 39.1046 31.1046 40 30 40L10 40C8.89543 40 8 39.1046 8 38L8 22C8 18.6863 10.6863 16 14 16L26 16C29.3137 16 32 18.6863 32 22L32 38Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
