import { ConnectorAssembly, Schematic, UUID } from '@web/apps/types';
import { Edge } from '@xyflow/react';

import { EdgeData, EdgeType } from '../types/edges.ts';
import { schematicNodeId } from '../types/nodes.ts';

export const isHousing = (point: Schematic.ConnectionPoint | null) => point?.name === ConnectorAssembly.HOUSING;

export const isShield = (conductor: Schematic.Conductor) => conductor.name.endsWith('.Shield') ?? false;

export const isLoopback = (path: Schematic.Path | null) =>
  path && !!path.destination && path.source?.partId === path.destination.partId;

export const isFirstInGroup = (conductor: Schematic.Conductor, group: UUID[]) =>
  group.length > 0 && group[0] === conductor.id;

export const isLastInGroup = (conductor: Schematic.Conductor, group: UUID[]) =>
  group.length > 0 && group[group.length - 1] === conductor.id;

export const createEdge = (type: EdgeType, sourceHandle: string, targetHandle: string, data?: EdgeData) => {
  return {
    id: `${sourceHandle} → ${targetHandle}`,
    type,
    source: schematicNodeId,
    sourceHandle,
    target: schematicNodeId,
    targetHandle,
    data,
  } as Edge;
};
