import { HStack, Text } from '@chakra-ui/react';
import { useUpdateLayoutEdgeMutation } from '@web/apps/Design/api/layout-edges-api.ts';
import { UUID } from '@web/apps/types';
import { XYPosition } from '@xyflow/react';
import { mapValues } from 'lodash';
import { useState } from 'react';

import { useDesignOverview } from '../../../../../hooks/useDesignOverview.tsx';
import { useDesignToast } from '../../../../../hooks/useDesignToast.tsx';
import { getToleranceText } from './getToleranceText.ts';
import { MeasurementEdgeEditableText, MeasurementEditProps } from './MeasurementEdgeEditableText.tsx';
import { MeasurementLabelWrapper } from './MeasurementLabelWrapper.tsx';

interface RelationalMeasurementEdgeLabelProps {
  layoutEdgeId: UUID;
  position: XYPosition;
  measurement: string;
  toleranceMinimum: string;
  toleranceMaximum: string;
}

export const RelationalMeasurementEdgeLabel = ({
  layoutEdgeId,
  position,
  measurement = '0.0',
  toleranceMinimum = '0.0',
  toleranceMaximum = '0.0',
}: RelationalMeasurementEdgeLabelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const { id: designId, lengthUnit } = useDesignOverview();
  const { showErrorToast } = useDesignToast();

  const { mutate: updateLayoutEdge } = useUpdateLayoutEdgeMutation();

  const handleEdit = (measurementData: MeasurementEditProps) => {
    const sanitizedValues = mapValues(measurementData, (value: string) => value || '0');
    for (const measurementValue of Object.values(sanitizedValues)) {
      const parsedValue = Number(measurementValue);

      if (isNaN(parsedValue) || parsedValue < 0) {
        showErrorToast('Error Updating Measurement', 'Please enter a valid number greater than or equal to zero');
        return;
      }
    }

    const { measurement, toleranceMinimum, toleranceMaximum } = sanitizedValues;
    updateLayoutEdge({ designId, layoutEdgeId, data: { measurement, toleranceMinimum, toleranceMaximum } });
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <MeasurementLabelWrapper labelPosition={position} p={1} cursor="default	">
        <MeasurementEdgeEditableText
          initialMeasurement={measurement}
          initialToleranceMinimum={toleranceMinimum}
          initialToleranceMaximum={toleranceMaximum}
          lengthUnit={lengthUnit}
          onEdit={handleEdit}
          onCancelEdit={() => setIsEditing(false)}
        />
      </MeasurementLabelWrapper>
    );
  }

  const toleranceText = getToleranceText(toleranceMinimum, toleranceMaximum, lengthUnit);

  return (
    <MeasurementLabelWrapper onDoubleClick={() => setIsEditing(true)} labelPosition={position} p={1} cursor="pointer">
      <HStack bgColor="whiteAlpha.800">
        <Text color="blue.700">{`${measurement}${lengthUnit}`}</Text>
        {toleranceText && <Text color="gray.500">{toleranceText}</Text>}
      </HStack>
    </MeasurementLabelWrapper>
  );
};
