import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { Location, Outlet, useLocation } from 'react-router';

const separator = ' · ';

function isExcludedRoute(pathname: string): boolean {
  // Match all `/designs` or `/design/...` routes except `/` and `/designs`
  const isDesignRoute = pathname.startsWith('/design') || pathname.startsWith('/designs');
  const isAllowedRoute = pathname === '/' || pathname === '/designs';

  return isDesignRoute && !isAllowedRoute;
}

export function setDocumentTitle(location: Location) {
  const envPrefix =
    import.meta.env.VITE_ENVIRONMENT === 'staging'
      ? `[${import.meta.env.VITE_ENVIRONMENT.toUpperCase() as string}] `
      : '';

  if (isExcludedRoute(location.pathname)) {
    return; // Skip setting the title for excluded routes
  }

  const pathParts = location.pathname.split('/').filter(Boolean);
  const firstPathPart = pathParts.length > 0 ? capitalize(pathParts[0]) : 'Designs';

  document.title = [envPrefix, firstPathPart].filter(Boolean).join(separator);
}

export default function Root() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setDocumentTitle(location);
    }
  }, [location]);

  return <Outlet />;
}
