import { Collapse } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/layout';
import { Flex, IconButton } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { VscChromeMaximize, VscChromeMinimize, VscSplitVertical } from 'react-icons/vsc';

import { SelectedDesignPartLabel } from '../../components/SelectedDesignPartLabel';
import { useSelectedDesignPart } from '../../hooks/useSelectedDesignPart';
import { useDesignTabs } from '../DesignTabs/DesignTabsProvider';
import { ContextPanelHeight } from './types';
import { isContextPanelOpen } from './utils';

interface Props {
  headerContent: ReactNode;
  children: ReactNode;
}

const buttonConfigs = [
  { targetHeight: ContextPanelHeight.MINIMIZED, icon: <VscChromeMinimize />, label: 'Minimize Context Panel' },
  { targetHeight: ContextPanelHeight.SPLIT, icon: <VscSplitVertical />, label: 'Open Context Panel' },
  { targetHeight: ContextPanelHeight.FULL, icon: <VscChromeMaximize />, label: 'Maximize Context Panel' },
];

export const ContextPanel = ({ headerContent, children }: Props) => {
  const selectedDesignPart = useSelectedDesignPart();
  const { contextPanelHeight, setContextPanelHeight, setSelectedContextPanelTab } = useDesignTabs();

  const handleButtonClick = (panelHeight: ContextPanelHeight) => () => {
    if (contextPanelHeight === ContextPanelHeight.MINIMIZED) {
      setSelectedContextPanelTab(0);
    }
    setContextPanelHeight(panelHeight);
  };

  const handleDoubleClick = () => {
    if (isContextPanelOpen(contextPanelHeight)) {
      setContextPanelHeight(ContextPanelHeight.MINIMIZED);
      return;
    } else {
      setSelectedContextPanelTab(0);
      setContextPanelHeight(ContextPanelHeight.SPLIT);
      return;
    }
  };

  return (
    <Flex flexDirection="column" data-testid="context-panel" data-collapsed={!isContextPanelOpen(contextPanelHeight)}>
      <HStack
        width="full"
        color="gray.500"
        bg="gray.100"
        borderTopWidth={contextPanelHeight === ContextPanelHeight.FULL ? 0 : 1}
        borderBottomWidth={contextPanelHeight === ContextPanelHeight.MINIMIZED ? 0 : 1}
        borderColor="gray.300"
        onDoubleClick={handleDoubleClick}
        justify="space-between"
        fontWeight="500"
        userSelect="none"
        textTransform="uppercase"
        px={2}
      >
        {headerContent}
        <HStack flex={1} justify="flex-end">
          {selectedDesignPart && <SelectedDesignPartLabel designPart={selectedDesignPart} />}
          {buttonConfigs.map(
            ({ targetHeight, icon, label }) =>
              targetHeight !== contextPanelHeight && (
                <IconButton
                  key={targetHeight}
                  onClick={handleButtonClick(targetHeight)}
                  icon={icon}
                  aria-label={label}
                />
              ),
          )}
        </HStack>
      </HStack>
      <Collapse in={isContextPanelOpen(contextPanelHeight)} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  );
};
