import { Badge, Box, Flex, HStack, StackProps } from '@chakra-ui/react';

export interface MultiSelectFilterProps extends StackProps {
  options: string[];
  selectedOptions: string[];
  isToggleable?: boolean;
  onFilter: (selectedOptions: string[]) => void;
  optionTransform?: (option: string) => string;
}

const MultiSelectFilter = ({
  options,
  selectedOptions,
  isToggleable = true,
  onFilter,
  optionTransform,
  ...rest
}: MultiSelectFilterProps) => {
  const handleToggleOption = (option: string) => {
    if (!isToggleable) {
      return;
    }

    const isSelected = selectedOptions.includes(option);
    const allOptionsSelected = selectedOptions.length === options.length;
    const isLastSelectedOption = isSelected && selectedOptions.length === 1;

    if (allOptionsSelected) {
      onFilter([option]);
    } else if (isLastSelectedOption) {
      onFilter(options);
    } else {
      const updatedSelectedOptions = isSelected
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];

      onFilter(updatedSelectedOptions);
    }
  };

  return (
    <HStack spacing={2} wrap="wrap" {...rest} data-testid="multi-select-filter">
      {options.map((option) => {
        const isSelected = selectedOptions.includes(option);

        return (
          <Box
            key={option}
            data-selected={isSelected}
            onClick={() => isToggleable && handleToggleOption(option)}
            cursor="pointer"
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={0}
          >
            <Badge color="gray.50" variant={isSelected ? 'solid' : 'outline'} bg={isSelected ? 'blue.700' : 'gray'}>
              <Flex alignItems="center">{optionTransform ? optionTransform(option) : option}</Flex>
            </Badge>
          </Box>
        );
      })}
    </HStack>
  );
};

export default MultiSelectFilter;
