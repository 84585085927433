import { DesignPart, PartType } from '@web/apps/types';

export const filterPartsByString = (designParts: DesignPart[], filterString: string) =>
  designParts.filter((part) => {
    const filterStringLowerCase = filterString.toLowerCase();
    const partNumber = part.partData.partNumber.toLowerCase();
    const name = part.name.toLowerCase();

    return partNumber.includes(filterStringLowerCase) || name.includes(filterStringLowerCase);
  });

export const filterPartsByType = (designParts: DesignPart[], filterType: (type: PartType) => boolean) =>
  designParts.filter((part: DesignPart) => filterType(part.partData.type));

export const isConductor = (type: PartType) => type === PartType.CABLE || type === PartType.WIRE;

const otherPartTypes = new Set([PartType.BACKSHELL, PartType.CONTACT, PartType.GENERIC]);
export const isOtherPart = (type: PartType) => otherPartTypes.has(type);
