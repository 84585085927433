import { Box } from '@chakra-ui/react';
import { LayoutEmptyState } from '@web/apps/Design/components/EmptyStates/LayoutEmptyState';
import { emptyEdges, emptyNodes } from '@web/apps/types';
import { RouteNames } from '@web/consts/routeNames';
import { ConnectionMode, Controls, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { isEmpty } from 'lodash';

import ManagedWindow from '../../components/ManagedWindow';
import { useDesign } from '../../hooks/useDesign';
import CoordinatePanel from './components/CoordinatePanel';
import { LayerFilterControls } from './components/LayerFilterControls';
import { useLayout } from './hooks/useLayout';
import { useLoadLayout } from './hooks/useLoadLayout';
import { edgeTypes, nodeTypes } from './types';

export const LayoutReactFlow = () => {
  const { isViewOnly } = useDesign();

  // Initialize the nodes and edges state
  const [nodes, setNodes, onNodesChange] = useNodesState(emptyNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(emptyEdges);

  // Initialize Layout
  const reactFlowInitialized = useLoadLayout(setNodes, setEdges);

  // Get config, init, context menus, and context menu handlers from the useLayout hook
  const {
    config,
    onPaneContextMenu,
    onGraphElementContextMenu,
    closeAllMenus,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDrag,
    onNodeDragStop,
    contextMenus,
  } = useLayout(reactFlowInitialized);

  return (
    <ManagedWindow title="Layout" routeName={RouteNames.DESIGNS.LAYOUT}>
      {() => (
        <Box w="full" h="full">
          {!reactFlowInitialized ? null : (
            <ReactFlow
              /* Config nodes */
              nodeTypes={nodeTypes}
              nodes={nodes}
              onNodesChange={onNodesChange}
              nodesConnectable={false}
              nodesDraggable={!isViewOnly}
              /* Config edges */
              edgeTypes={edgeTypes}
              edges={edges}
              onEdgesChange={onEdgesChange}
              /* Click handlers */
              onPaneClick={onPaneClick}
              onPaneContextMenu={onPaneContextMenu}
              onNodeClick={onNodeClick}
              onNodeContextMenu={onGraphElementContextMenu}
              onEdgeClick={onEdgeClick}
              onEdgeContextMenu={onGraphElementContextMenu}
              /* Drag handlers */
              onMove={closeAllMenus}
              onNodeDrag={onNodeDrag}
              onNodeDragStop={onNodeDragStop}
              /* ReactFlow settings (fixed) */
              connectionMode={ConnectionMode.Loose}
              proOptions={{ hideAttribution: true }}
              zoomOnDoubleClick={false}
              panOnScroll={true}
              deleteKeyCode={null}
              /* ReactFlow settings (from config.ts) */
              defaultViewport={config.defaultViewport}
              minZoom={config.minZoom}
              maxZoom={config.maxZoom}
              nodeOrigin={config.nodeOrigin}
              snapToGrid={config.snapToGrid}
              snapGrid={config.snapGrid}
              style={config.style}
            >
              <LayerFilterControls />
              <Controls showInteractive={false} position="bottom-left" />
              <CoordinatePanel position="bottom-right" />
              {isEmpty(nodes) && <LayoutEmptyState />}
            </ReactFlow>
          )}
          {/* Render all context menus */}
          {!isViewOnly && contextMenus}
        </Box>
      )}
    </ManagedWindow>
  );
};
