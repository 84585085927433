import { PageData, Tool, ToolPayload, ToolType } from '@web/apps/types';

import { doRequest, getUrl } from '../common/api';

export interface ToolsResponse {
  data: Tool[];
  meta: PageData;
}

export const DEFAULT_TOOLS_PAGE_SIZE = 25;

export const NON_UPDATABLE_FIELDS = Object.freeze([
  'id',
  'type',
  'createdAt',
  'creator',
  'deletedAt',
  'updatedAt',
  'updater',
]);

export const apiGetTool = (toolId: string): Promise<Tool | undefined> => {
  return doRequest<Tool>('GET', getUrl(`/api/v1/tools/${toolId}`));
};

export const apiGetTools = ({
  toolType,
  partNumber,
  page,
  pageSize,
}: {
  toolType?: ToolType;
  partNumber?: string | null;
  page?: string;
  pageSize?: number;
}): Promise<ToolsResponse | undefined> => {
  const endpoint = '/api/v1/tools';
  const params = new URLSearchParams({ per: `${pageSize ?? DEFAULT_TOOLS_PAGE_SIZE}` });
  toolType && params.set('type', toolType);
  page && params.set('page', page);
  partNumber && params.set('part_number', partNumber);

  return doRequest<ToolsResponse>('GET', getUrl(`${endpoint}?${params.toString()}`));
};

export const apiPostTool = (tool: ToolPayload): Promise<Tool | undefined> => {
  return doRequest<Tool>('POST', getUrl('/api/v1/tools'), JSON.stringify({ tool }));
};

export const apiPutTool = (tool: ToolPayload): Promise<Tool | undefined> => {
  const updatableTool = Object.fromEntries(Object.entries(tool).filter(([key]) => !NON_UPDATABLE_FIELDS.includes(key)));

  return doRequest<Tool>('PUT', getUrl(`/api/v1/tools/${tool.id}`), JSON.stringify({ tool: updatableTool }));
};

export const apiDeleteTool = (toolId: string): Promise<undefined> => {
  return doRequest('DELETE', getUrl(`/api/v1/tools/${toolId}`));
};
