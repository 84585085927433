import { Text, Tooltip } from '@chakra-ui/react';
import { UUID } from '@web/apps/types';

import { useRenameDesignPartMutation } from '../../../../api/design-parts-api';
import { EditableTextInputComponent } from '../../../../components/EditableTextInputComponent';
import { useDesignId } from '../../../../hooks/useDesignId';

interface Props {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  partName: string;
  partId: UUID;
}

export const EditablePartName = ({ isEditing, setIsEditing, partName, partId }: Props) => {
  const designId = useDesignId();

  const { mutate: renameDesignPart } = useRenameDesignPartMutation();

  const handleRenamePart = (newName: string) => {
    renameDesignPart({ designId, partId, data: { name: newName } });
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <EditableTextInputComponent
        initialText={partName}
        onEdit={handleRenamePart}
        onCancelEdit={() => setIsEditing(false)}
        maxW="40%"
        flexShrink={0}
        height="fit-content"
        p={0.5}
      />
    );
  }

  return (
    <Tooltip label={partName}>
      <Text fontWeight="bold" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxW="40%" flexShrink={0}>
        {partName}
      </Text>
    </Tooltip>
  );
};
