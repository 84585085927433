import { Box } from '@chakra-ui/react';
import { getConnectorShapeData } from '@web/apps/Design/features/RelationalLayout/components/nodes/ConnectorNode/getConnectorShapeData.tsx';
import { useDesignParts } from '@web/apps/Design/hooks/useDesignParts.tsx';
import { isBackshellDesignPart, isConnectorDesignPart, UUID } from '@web/apps/types';
import { Node, NodeProps, useUpdateNodeInternals } from '@xyflow/react';
import { useEffect } from 'react';

import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState.ts';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { DesignPartLabel } from '../../../../Layout/components/nodes/common/DesignPartLabel.tsx';
import { RotatingHandle } from '../../../../Layout/components/nodes/common/RotatingHandle.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';
import { useRelationalShapeRotation } from '../../../hooks/useRelationalShapeRotation.tsx';
import { ConnectorContextMenu } from './ConnectorContextMenu.tsx';

export type RelationalConnectorNodeData = {
  designPartId: UUID;
  rotateLock: boolean;
  angle: number;
  noteGroupBuildNotes: [];
};

export const defaultRelationalConnectorNodeData: RelationalConnectorNodeData = {
  designPartId: '',
  rotateLock: false,
  angle: 0,
  noteGroupBuildNotes: [],
};

export type RelationalConnectorNodeType = Node<RelationalConnectorNodeData>;

/**
 * Connector node component.
 * @param props
 * @constructor
 */
export const RelationalConnectorNode = ({
  id,
  data = defaultRelationalConnectorNodeData,
  selected,
}: NodeProps<RelationalConnectorNodeType>) => {
  const { designPartId, rotateLock, angle, noteGroupBuildNotes } = data;

  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const designPart = useDesignParts().getDesignPartById(designPartId);

  // ReactFlow hook to update the node internals, because the handle is rotated and ReactFlow needs to know about it
  const updateNodeInternals = useUpdateNodeInternals();

  // Calculate the angle to rotate the shape
  const calculatedAngle = useRelationalShapeRotation({ nodeId: id });

  // Use the calculated angle if the rotate-lock is not enabled
  const resolvedAngle = rotateLock ? angle : calculatedAngle;

  useEffect(() => {
    updateNodeInternals(id);
  }, [resolvedAngle, id, updateNodeInternals]);

  if (!designPart) {
    return null;
  }

  if (!isConnectorDesignPart(designPart)) {
    return null;
  }

  let shape = designPart.partData.shape ?? null;
  let backshellAngle =
    designPart.backshell && isBackshellDesignPart(designPart.backshell) ? designPart.backshell.partData.angle : null;
  const { shape: ShapeComponent, connectionPoint: handleCoordinates } = getConnectorShapeData(shape, backshellAngle);

  return (
    <Box position="relative" onContextMenu={handleContextMenu}>
      <DesignPartLabel designPart={designPart} angle={resolvedAngle} />
      <ShapeComponent color={getNodeColor(selected)} angle={resolvedAngle} />
      <RotatingHandle id={HandleTypes.Segment} angle={resolvedAngle} offsetPosition={handleCoordinates} />
      <CenterHandle id={HandleTypes.Note} />
      <ConnectorContextMenu
        isOpen={isOpen}
        position={position}
        closeMenu={closeMenu}
        layoutNodeId={id}
        rotateLock={rotateLock}
        angle={angle}
        noteGroupBuildNotes={noteGroupBuildNotes}
      />
    </Box>
  );
};
