import { useCallback, useMemo, useState } from 'react';

import { useBuildWiringList, WiringListRow } from './useBuildWiringList';

/**
 * useBuildWiringListWithCrimpSplices - Manages a single temporary crimp splice row
 */
export const useBuildWiringListWithCrimpSplice = () => {
  const { wiringList: baseWiringList } = useBuildWiringList();
  const [tempCrimpSplice, setTempCrimpSplice] = useState<WiringListRow | null>(null);

  /**
   * Explicitly reset the temporary crimp splice row
   */
  const resetCrimpSplice = useCallback(() => {
    setTempCrimpSplice(null);
  }, []);

  /**
   * Adds a new temporary crimp splice row for a specific connection point
   */
  const addCrimpSplice = useCallback(
    (connectionPointId: string) => {
      const connectionPoint = baseWiringList.find(
        (row) => row.connectionPoint.id === connectionPointId,
      )?.connectionPoint;

      if (!connectionPoint) return;

      const newCrimpSplice: WiringListRow = {
        keyId: `unconnected-${connectionPointId}-crimp`,
        connectionPoint: connectionPoint,
        existingConnection: null,
      };

      setTempCrimpSplice(newCrimpSplice);
    },
    [baseWiringList],
  );

  /**
   * Merge the base wiring list with the temporary crimp splice
   */
  const wiringList = useMemo(() => {
    if (tempCrimpSplice) {
      const lastIndex = baseWiringList
        .map((row) => row.connectionPoint.id)
        .lastIndexOf(tempCrimpSplice.connectionPoint.id);

      if (lastIndex !== -1) {
        return [
          ...baseWiringList.slice(0, lastIndex + 1), // All rows before the last occurrence
          tempCrimpSplice, // Add the crimp splice after the last occurrence
          ...baseWiringList.slice(lastIndex + 1), // All rows after the last occurrence
        ];
      }
    }

    return baseWiringList;
  }, [baseWiringList, tempCrimpSplice]);

  return { wiringList, addCrimpSplice, resetCrimpSplice };
};
