import { MenuItemOption, MenuOptionGroup, Text } from '@chakra-ui/react';
import { BuildNote, UUID } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { useUpdateBuildNoteMutation } from '../../../../../api/build-notes-api';
import { useDesignBuildNotes } from '../../../../../hooks/useDesignBuildNotes';
import { useDesignId } from '../../../../../hooks/useDesignId';
import { MenuGroupTitleWithLoading } from '../../../../RelationalLayout/components/menus/MenuGroupTitleWithLoading';
import { useLayoutBuilder } from '../../useLayoutBuilder';

interface FlagNoteActionsOptions {
  noteGroupNodeId?: UUID;
  createNoteGroupNodeAndEdge?: createNewElements;
  shouldCloseOnAdd?: boolean;
}

type createNewElements = () => { newNode?: Node; newEdge?: Edge };

// Gets the note group id to add the build note to, potentially creating a new note group.
const useGetNoteGroupId = (noteGroupNodeId?: UUID, createNoteGroupNodeAndEdge?: createNewElements) => {
  const { executeGraphOperation } = useLayoutBuilder();

  const getNoteGroupId = useCallback(() => {
    if (noteGroupNodeId) {
      return noteGroupNodeId;
    } else if (createNoteGroupNodeAndEdge) {
      const { newNode, newEdge } = createNoteGroupNodeAndEdge();

      executeGraphOperation({
        type: 'AddElementsToGraph',
        params: {
          newNodes: newNode ? [newNode] : undefined,
          newEdges: newEdge ? [newEdge] : undefined,
        },
      });

      return newNode?.id;
    }

    return null;
  }, [noteGroupNodeId, createNoteGroupNodeAndEdge, executeGraphOperation]);

  return getNoteGroupId;
};

export const useFlagNoteActions = (closeMenu: VoidFunction, options: FlagNoteActionsOptions = {}) => {
  const { noteGroupNodeId, createNoteGroupNodeAndEdge, shouldCloseOnAdd } = options;

  const designId = useDesignId();
  const { data: buildNotes = [] } = useDesignBuildNotes();
  const getNoteGroupId = useGetNoteGroupId(noteGroupNodeId, createNoteGroupNodeAndEdge);

  const { mutateAsync: updateNoteMutation, isPending } = useUpdateBuildNoteMutation();

  const flagNoteIdsForElement = useMemo(() => {
    if (noteGroupNodeId) {
      const flagNotesForElement = buildNotes.filter((note) => note.noteGroupNodeIds.includes(noteGroupNodeId));
      return flagNotesForElement.map(({ id }) => id);
    }

    return [];
  }, [buildNotes, noteGroupNodeId]);

  const handleToggleFlagNote = useCallback(
    async (flagNote: BuildNote) => {
      const noteGroupId = getNoteGroupId();

      if (!designId || !noteGroupId) {
        return;
      }

      const { id: noteId, noteGroupNodeIds } = flagNote;
      const isRemovingNote = noteGroupNodeIds.includes(noteGroupId);

      const updatedNoteGroupNodeIds = isRemovingNote
        ? noteGroupNodeIds.filter((id) => id !== noteGroupId)
        : [...noteGroupNodeIds, noteGroupId];

      // Update the note in the server
      await updateNoteMutation({ designId, noteId, buildNote: { noteGroupNodeIds: updatedNoteGroupNodeIds } });

      if (isRemovingNote || shouldCloseOnAdd) {
        closeMenu();
      }
    },
    [designId, updateNoteMutation, getNoteGroupId, closeMenu, shouldCloseOnAdd],
  );

  const flagNoteActions = useMemo(() => {
    if (buildNotes.length === 0) {
      return (
        <MenuOptionGroup title="Flag Notes">
          <MenuItemOption isDisabled>No Flag Notes</MenuItemOption>
        </MenuOptionGroup>
      );
    }

    return (
      <MenuOptionGroup
        // @ts-expect-error Title is type string but supports elements, and we need a spinner to show loading.
        title={<MenuGroupTitleWithLoading title="Flag Notes" isLoading={isPending} />}
        type="checkbox"
        value={flagNoteIdsForElement}
      >
        {buildNotes.map((note) => (
          <MenuItemOption key={note.id} value={note.id} onClick={() => handleToggleFlagNote(note)}>
            <Text
              width="200px"
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >{`${note.position} - ${note.body}`}</Text>
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    );
  }, [buildNotes, flagNoteIdsForElement, handleToggleFlagNote, isPending]);

  return { flagNoteActions };
};
