import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { BiPlus, BiSolidPlusCircle } from 'react-icons/bi';

interface Props extends IconButtonProps {}

const AddIconButton = ({ ...rest }: Props) => {
  let icon = <BiPlus />;
  let color = 'gray.500';
  let size = '1.25em';
  let w = '30px';
  let h = '30px';
  if (rest.variant === 'primary') {
    icon = <BiSolidPlusCircle />;
    color = 'blue.500';
    size = '2.0em';
  }
  if (rest.variant === 'secondary') {
    w = 'auto';
    h = 'auto';
  }

  return (
    <IconButton
      icon={icon}
      color={color}
      variant="unstyled"
      fontSize={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      w={w}
      h={h}
      minW={w}
      {...rest}
    />
  );
};

export default AddIconButton;
