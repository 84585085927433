import { Box, Text } from '@chakra-ui/react';
import { Splice } from '@web/apps/Design/components/Shapes/Splice.tsx';
import { useDesignParts } from '@web/apps/Design/hooks/useDesignParts.tsx';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState.ts';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';

export type RelationalSpliceNodeData = {
  designPartId: UUID;
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalSpliceNodeData: RelationalSpliceNodeData = {
  designPartId: '',
  noteGroupBuildNotes: [],
};

export type RelationalSpliceNodeType = Node<RelationalSpliceNodeData>;

export const RelationalSpliceNode = ({
  id,
  data: { designPartId, noteGroupBuildNotes } = defaultRelationalSpliceNodeData,
  selected,
}: NodeProps<RelationalSpliceNodeType>) => {
  const { getDesignPartById } = useDesignParts();
  const designPart = getDesignPartById(designPartId);

  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative" onContextMenu={handleContextMenu}>
      <Text color="gray.900" fontFamily="mono" position="absolute" top="-20px">
        {designPart?.name}
      </Text>
      <Splice color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Note} />
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={id} />
      </ContextMenu>
    </Box>
  );
};
