import { Text } from '@chakra-ui/react';
import { Part, PartType, Passive } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.PASSIVE };

const columns: CustomColumnDef[] = [
  {
    header: 'Passive Type',
    renderFn: (part: Part) => {
      const passive = part as Passive;
      return <Text>{passive.type}</Text>;
    },
  },
  {
    header: 'Connections',
    renderFn: (part: Part) => {
      const passive = part as Passive;
      return <Text>{passive.connections.length}</Text>;
    },
  },
  {
    header: 'Passive Value',
    renderFn: (part: Part) => {
      const passive = part as Passive;
      return <Text>{passive.passiveValue}</Text>;
    },
  },
];

export const PassivesDashboard = () => (
  <PartsLibraryDashboard
    title="Passives"
    addPartButtonLabel="Passive"
    routeName="passives"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.PASSIVE}
  />
);
