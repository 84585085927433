import { Design, PartType, Schematic as SchematicType } from '@web/apps/types';
import { getViewportForBounds, Rect } from '@xyflow/react';
import domtoimage from 'dom-to-image';

import { fetchSchematic } from '../api/schematic-api';
import { filterPartsByType } from '../features/AssemblyNavigator/components/PartList/filterUtils';
import { defaultSchematicConfig } from '../features/Schematic/config';
import { buildGraph } from '../features/Schematic/utils/graph';
import { emptyDesignParts } from '../hooks/useConnectionsData';
import { selectDesignParts } from '../hooks/useDesignPartsData';

export const getSchematics = async (design: Design) => {
  const { designParts = emptyDesignParts } = selectDesignParts(design);
  const connectors = filterPartsByType(designParts, (p: PartType) => p === PartType.CONNECTOR);
  const schematics = await Promise.all(connectors.map((connector) => fetchSchematic(design.id, connector.id)));

  return schematics;
};

export const processSchematicLayout = async (schematic: SchematicType.SchematicData) => {
  if (!schematic) return null;

  return buildGraph(schematic, defaultSchematicConfig.width);
};

export const waitForNodes = async () =>
  new Promise((resolve) => {
    const checkLength = setInterval(() => {
      const nodeElements = document.querySelectorAll('.react-flow__node');
      if (nodeElements.length > 0) {
        clearInterval(checkLength);
        setTimeout(() => resolve(true), 500);
      }
    }, 100);
  });

export const generateImage = async (nodesBounds: Rect) => {
  const aspectRatio = nodesBounds.width / nodesBounds.height;
  const imageWidth = Math.max(nodesBounds.width, 1632);
  const imageHeight = imageWidth / aspectRatio;
  const nodeViewport = getViewportForBounds(nodesBounds, imageWidth + 100, imageHeight, 0.5, 2, 0.1);
  const dataUrl = await domtoimage.toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
    width: imageWidth + 100,
    height: imageHeight,
    bgcolor: 'white',
    style: {
      transform: `translate(${nodeViewport.x}px, ${nodeViewport.y}px) scale(${nodeViewport.zoom})`,
    },
  });
  return dataUrl.split(',')[1];
};
