import { Antenna } from '@web/apps/Design/components/Shapes/Antenna.tsx';
import { Capacitor } from '@web/apps/Design/components/Shapes/Capacitor.tsx';
import { Diode } from '@web/apps/Design/components/Shapes/Diode.tsx';
import { Inductor } from '@web/apps/Design/components/Shapes/Inductor.tsx';
import { Resistor } from '@web/apps/Design/components/Shapes/Resistor.tsx';
import { DesignPart, isPassiveDesignPart, PassiveType } from '@web/apps/types';

// Map of passive types to their respective shape components
export const passiveShapes = {
  [PassiveType.ANTENNA]: Antenna,
  [PassiveType.CAPACITOR]: Capacitor,
  [PassiveType.DIODE]: Diode,
  [PassiveType.INDUCTOR]: Inductor,
  [PassiveType.RESISTOR]: Resistor,
};

interface Props {
  designPart: DesignPart;
  color: string;
}

export const PassiveShape = ({ designPart, color }: Props) => {
  if (!isPassiveDesignPart(designPart)) {
    return null;
  }

  const ShapeComponent = passiveShapes[designPart.partData.subtype as keyof typeof passiveShapes];

  return ShapeComponent ? <ShapeComponent color={color} /> : null;
};
