import { PartType } from '@web/apps/types';

import { PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.LABEL };

export const LabelsDashboard = () => (
  <PartsLibraryDashboard
    title="Labels"
    addPartButtonLabel="Label"
    routeName="labels"
    defaultValues={defaultValues}
    partType={PartType.LABEL}
  />
);
