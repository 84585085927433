import { Tab, TabProps } from '@chakra-ui/react';

export const DesignTab = (props: TabProps) => (
  <Tab
    fontWeight="semibold"
    color="gray.500"
    borderBottomWidth={2}
    borderColor="transparent"
    _selected={{ color: 'blue.600', borderBottomColor: 'blue.600' }}
    py={3}
    {...props}
  />
);
