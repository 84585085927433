import { AbsoluteCenter, Icon, StackProps } from '@chakra-ui/react';
import { OpenGeneralPartSearchButton } from '@web/apps/Design/components/OpenGeneralPartSearchButton.tsx';
import { useDesign } from '@web/apps/Design/hooks/useDesign.tsx';
import { BsFillPencilFill } from 'react-icons/bs';

import { EmptyState } from './EmptyState';

export const LayoutEmptyState = (props: StackProps) => {
  const { isViewOnly } = useDesign();

  const message = isViewOnly
    ? 'This harness has no components to display.'
    : "You'll need to add some components to your harness to get started.";

  return (
    <AbsoluteCenter zIndex={5}>
      <EmptyState
        icon={isViewOnly ? undefined : <Icon as={BsFillPencilFill} boxSize={10} color="gray.400" />}
        message={message}
        action={isViewOnly ? undefined : <OpenGeneralPartSearchButton />}
        {...props}
      />
    </AbsoluteCenter>
  );
};
