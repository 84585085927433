import { FormControl, FormLabel, HStack, Select, Switch } from '@chakra-ui/react';
import { Backshell } from '@web/apps/types';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  editing: boolean;
}

export const BackshellFields = ({ editing }: Props) => {
  const { control, register } = useFormContext<Backshell>();
  const { field: shielded } = useController({ name: 'shielded', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  return (
    <HStack>
      <FormControl flex={1}>
        <FormLabel>Angle</FormLabel>
        <Select isDisabled={!editing} {...register('angle')}>
          <option value={0}></option>;
          {config.parts.backshell.angles.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl flex={1}>
        <FormLabel>Shielded</FormLabel>
        <Switch
          isDisabled={!editing}
          isChecked={shielded.value}
          onChange={(e) => {
            shielded.onChange(e.target.checked);
          }}
        />
      </FormControl>
    </HStack>
  );
};
