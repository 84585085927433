import { Input } from '@chakra-ui/react';
import { useRef } from 'react';

export interface FilterBoxProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  shouldFocus?: boolean;
}

export const FilterBox = ({ placeholder = 'Type to filter', value, onChange, shouldFocus = true }: FilterBoxProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const setRef = (node: HTMLInputElement | null) => {
    inputRef.current = node;
    if (shouldFocus && node) {
      node.focus();
    }
  };

  return (
    <Input
      ref={setRef}
      type="text"
      onChange={(event) => onChange(event.target.value)}
      value={value}
      placeholder={placeholder}
      maxW="20rem"
      size="sm"
    />
  );
};
