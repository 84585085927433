import { Button, Center, Flex, Stack, Table, TableContainer, Tbody, Td, Text, Th, Tr } from '@chakra-ui/react';
import { DEFAULT_TOOLS_PAGE_SIZE } from '@web/api/tools-api';
import { Tool, ToolType } from '@web/apps/types';
import generateURlSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import Loading from '@web/components/Loading';
import Paginate from '@web/components/Paginate';
import Title from '@web/components/Title';
import useDebouncedValue from '@web/hooks/useDebouncedValue';
import React, { useCallback, useState } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router';

import { FilterBoxWithClearButton } from '../../../components/FilterBoxWithClearButton.tsx';
import { StickyThead } from '../../../components/table/StickyThead.tsx';
import { useDeleteTool, useTool, useTools } from '../api/queries';
import ToolsLibraryModal from './ToolsLibraryModal.tsx';

export interface CustomColumnDef {
  header: string;
  renderFn: (tool: Tool) => React.ReactNode;
}

interface Props {
  additionalFieldsComponent?: React.JSX.ElementType;
  buttonText: string;
  columnDefs?: CustomColumnDef[];
  defaultValues?: UseFormProps<Tool>['defaultValues'];
  routeName: string;
  title: string;
  toolType: ToolType;
}

export enum Mode {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
}

const ToolsLibraryDashboard = ({
  additionalFieldsComponent,
  buttonText,
  columnDefs,
  defaultValues,
  routeName,
  title,
  toolType,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState(Mode.READ);
  const [isCreating, setIsCreating] = useState(false);

  const page = searchParams.get('page') ?? '1';
  const partNumber = searchParams.get('part_number') ?? '';
  const debouncedPartNumber = useDebouncedValue(partNumber, 500);

  const { isLoading, data: tools } = useTools(toolType, debouncedPartNumber, page, DEFAULT_TOOLS_PAGE_SIZE);
  const { toolId } = useParams<{ toolId: string }>();
  const { data: tool } = useTool(toolId);

  const handleAddTool = () => {
    setIsCreating(true);
    setMode(Mode.CREATE);
  };

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('part_number', value);
    } else {
      searchParams.delete('part_number');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setIsCreating(false);
    navigate(`/tools/${routeName}${generateURlSearch({ page: page })}`);
  };

  const handleViewTool = useCallback(
    (tool: Tool) => {
      navigate(
        `/tools/${routeName}/${tool.id}${generateURlSearch({
          page: page,
          mode: Mode.READ,
          part_number: partNumber,
        })}`,
      );

      setMode(Mode.READ);
    },
    [navigate, page, partNumber, routeName],
  );

  const deleteTool = useDeleteTool(toolType);

  const onDelete = async () => {
    if (toolId) {
      await deleteTool.mutateAsync(toolId);
      navigate(`/tools/${routeName}${generateURlSearch({ page: page })}`);
    }
  };

  return (
    <Stack spacing={6} pt={6} pb={2} minW={0} minH={0}>
      <Flex justifyContent={'space-between'} px={8}>
        <Title title={title} aria-label="page-title" />
        <Button onClick={handleAddTool}>+ Add {buttonText}</Button>
        <ToolsLibraryModal
          defaultValues={tool ? tool : defaultValues}
          title={title}
          toolType={toolType}
          mode={mode}
          isOpen={isCreating || !!tool}
          onClose={handleClose}
          additionalFieldsComponent={additionalFieldsComponent}
          enableEditMode={() => setMode(Mode.UPDATE)}
          onDelete={onDelete}
        />
      </Flex>
      <FilterBoxWithClearButton placeholder="Type to filter tools" value={partNumber} onChange={handleFilterQuery} />
      {isLoading ? (
        <Center height="50vh" width="80vw">
          <Loading message="Loading tools…" />
        </Center>
      ) : (
        <Stack minH={0} flex={1}>
          <TableContainer overflowY="auto" flex={1}>
            <Table variant="senraTable" size="sm">
              <StickyThead>
                <Tr>
                  <Th>Part Number</Th>
                  <Th>Manufacturer</Th>
                  {columnDefs?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
                </Tr>
              </StickyThead>
              <Tbody>
                {tools?.data.map((tool) => (
                  <Tr key={tool.id} role="group" onClick={() => handleViewTool(tool)} cursor="pointer">
                    <Td>
                      <Text paddingRight={4}>{tool.partNumber}</Text>
                    </Td>
                    <Td>{tool.manufacturer?.name}</Td>
                    {columnDefs?.map((columnDef) => <Td key={columnDef.header}>{columnDef.renderFn(tool)}</Td>)}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Paginate
            onPageChange={handlePageClick}
            selectedPage={parseInt(page) - 1}
            pageCount={tools?.meta.totalPages ?? 0}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ToolsLibraryDashboard;
