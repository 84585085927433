import { BaseEdge, getSmoothStepPath, Position, XYPosition } from '@xyflow/react';

import { defaultLayoutConfig } from '../../../../Layout/config.ts';
import { getTargetDirection } from '../../../../Layout/utils/geometry.ts';

const segmentStyle = {
  stroke: defaultLayoutConfig.edgeColor,
  strokeWidth: 1,
};

interface Props {
  sourceXYPosition: XYPosition;
  targetXYPosition: XYPosition;
  sourcePosition: Position;
}

export const BaseNoteEdge = ({ sourceXYPosition, targetXYPosition, sourcePosition }: Props) => {
  const { x: sourceX, y: sourceY } = sourceXYPosition;
  const { x: targetX, y: targetY } = targetXYPosition;
  const { vertical } = getTargetDirection(sourceXYPosition, targetXYPosition);

  const isTargetBelow = vertical === Position.Bottom;
  const targetEdgePosition = isTargetBelow ? Position.Top : Position.Bottom;
  const offsetY = isTargetBelow ? -20 : 25;

  // Get the edge path for the note edge
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: targetY + offsetY,
    targetPosition: targetEdgePosition,
  });

  return <BaseEdge path={edgePath} markerEnd={'url(#flag-note-arrow)'} style={segmentStyle} />;
};
