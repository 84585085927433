import { ResolvedConnection, ResolvedConnectionPoint } from '@web/apps/types';
import { useMemo } from 'react';

import { useConnections } from '../../../hooks/useConnections.tsx';
import { useSelectedDesignPart } from '../../../hooks/useSelectedDesignPart.tsx';

export interface WiringListRow {
  keyId: string;
  connectionPoint: ResolvedConnectionPoint;
  existingConnection: ResolvedConnection | null;
}

/**
 * useWiringList hook returns a list of connection rows for the selected design part.
 */
export const useBuildWiringList = () => {
  // Get the selected design part
  const selectedDesignPart = useSelectedDesignPart();

  // Get connection, and a map of connections by source ID to look up existing connections
  const { bidirectionalConnections: connections, connectionsBySourceId, connectionPointsById } = useConnections();

  /**
   * Build the wiring list by merging connections and connection points into a single list. The backend returns the
   * selected design part's connection points in a specific order, and that order should be maintained.
   * @returns {WiringListRow[]} The list of connection rows.
   */
  const wiringList = useMemo(() => {
    // Create a list of connection rows
    const wiringList: WiringListRow[] = [];

    // Maintain server returned order by mapping the connection points to their order
    const connectionPointOrder = new Map();
    selectedDesignPart?.connectionPoints?.forEach((cp, index) => {
      connectionPointOrder.set(cp.id, index);
    });

    // Add connections that have a source ID that matches a connection point ID
    connections.forEach((c) => {
      if (c.source) {
        const connectionPoint = selectedDesignPart?.connectionPoints?.find((cp) => cp.id === c.source?.id);
        if (connectionPoint) {
          wiringList.push({
            keyId: c.id,
            connectionPoint: connectionPointsById[connectionPoint.id],
            existingConnection: c,
          });
        }
      }
    });

    // Add connection points that do not have a connection
    selectedDesignPart?.connectionPoints?.forEach((cp) => {
      const existingConnection = connectionsBySourceId[cp.id];
      if (!existingConnection) {
        wiringList.push({
          keyId: `unconnected-${cp.id}`,
          connectionPoint: connectionPointsById[cp.id],
          existingConnection: null,
        });
      }
    });

    // Sort the list by the order of connection points in selectedDesignPart
    wiringList.sort((a, b) => {
      const orderA = connectionPointOrder.get(a.connectionPoint.id) ?? Infinity;
      const orderB = connectionPointOrder.get(b.connectionPoint.id) ?? Infinity;
      return orderA - orderB;
    });

    return wiringList;
  }, [selectedDesignPart?.connectionPoints, connections, connectionPointsById, connectionsBySourceId]);

  return { wiringList };
};
