import { Box, Menu, MenuList, Portal } from '@chakra-ui/react';
import { MouseEvent, ReactNode } from 'react';

import { MenuPosition } from './useContextMenuState';

interface Props {
  isOpen: boolean;
  position: MenuPosition;
  closeMenu: () => void;
  children: ReactNode;
}

export const ContextMenu = ({ isOpen, position, closeMenu, children }: Props) => {
  const handleCloseMenu = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    closeMenu();
  };

  if (isOpen) {
    return (
      <Menu isOpen>
        <Portal>
          <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex="overlay"
            onClick={handleCloseMenu}
            onContextMenu={handleCloseMenu}
          />
          <MenuList
            onClick={(e) => e.stopPropagation()}
            maxHeight="42rem"
            overflow="auto"
            position="fixed"
            top={`${position.y}px`}
            left={`${position.x}px`}
            zIndex="overlay"
          >
            {children}
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return null;
};
