import { EdgeProps } from '@xyflow/react';

type Size = 1 | 2 | 3 | 4 | 5;

const sizeMap: Record<Size, number> = {
  1: 10,
  2: 16,
  3: 22,
  4: 28,
  5: 36,
};

/**
 * Creates a figure-8 path.
 * @param sourceX
 * @param sourceY
 * @param sourcePosition
 * @param targetX
 * @param targetY
 * @param size
 */
export function getTwistingGroupPath(
  { sourceX, sourceY, targetX, targetY }: EdgeProps,
  size: Size = 3,
): [string, number, number, number, number] {
  // Ensure sourceY is always the top point
  const [topY, bottomY] = sourceY < targetY ? [sourceY, targetY] : [targetY, sourceY];

  // Calculate proportional width
  const width = sizeMap[size];

  // Calculate the center of the shape
  const centerX = (sourceX + targetX) / 2;
  const centerY = (topY + bottomY) / 2;

  // Control points for smooth loops
  const leftX = centerX - width;
  const rightX = centerX + width;

  const path = [
    // Start at the center
    `M ${centerX} ${centerY}`,
    // Top loop
    `C ${leftX} ${topY}, ${rightX} ${topY}, ${centerX} ${centerY}`,
    // Bottom loop
    `C ${leftX} ${bottomY}, ${rightX} ${bottomY}, ${centerX} ${centerY}`,
  ].join(' ');

  const labelX = centerX;
  const labelY = centerY;
  const offsetX = width * 0.1;
  const offsetY = 0;

  return [path, labelX, labelY, offsetX, offsetY];
}
