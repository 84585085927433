import { GroupedOption, Option } from '@web/common/select.ts';
import { PropsValue } from 'react-select';

import { Col, Row } from '../../types.ts';
import { EditableCellSelect } from './EditableCellSelect.tsx';
import { WiringListCell } from './WiringListCell.tsx';

interface Props {
  row: Row;
  col: Col;
  value: PropsValue<Option>;
  options: (Option | GroupedOption)[];
  onCellFocus: () => void;
  onFieldChange: (row: Row, col: Col, value: string) => void;
  onEnterKeyPress: () => void;
  onCellBlur: () => void;
}

export const WiringListSelectCell = ({
  row,
  col,
  value,
  options,
  onCellFocus,
  onFieldChange,
  onEnterKeyPress,
  onCellBlur,
}: Props) => {
  return (
    <WiringListCell row={row} col={col} onCellFocus={onCellFocus} onCellBlur={onCellBlur}>
      {({ isActive }) => (
        <EditableCellSelect
          row={row}
          col={col}
          isActive={isActive}
          value={value}
          options={options}
          onChange={(option) => onFieldChange(row, col, option ? option.value : '')}
          onEnterKeyPress={onEnterKeyPress}
        />
      )}
    </WiringListCell>
  );
};
