import { emptyConnectionState, Fields } from '@web/apps/Design/features/WiringList/types.ts';
import { Connection, ResolvedConnectionPoint } from '@web/apps/types';
import { Option } from '@web/common/select.ts';

const excludedConnectionPointTypes = new Set(['Housing', 'Shield']);

export const isConnectionStateEmpty = (connection: Fields) => {
  return Object.values(connection).every((value) => !value);
};

export const getConnectionStateFromConnection = (connection: Connection | null) => {
  if (connection) {
    return {
      destinationId: connection.destinationId ?? '',
      conductorId: connection.conductorId ?? '',
      signalName: connection.signalName ?? '',
      sourceContactId: connection.sourceContactId ?? '',
      destinationContactId: connection.destinationContactId ?? '',
    };
  }
  return emptyConnectionState;
};

export const getConnectionStateFromConductorChange = (
  state: Fields,
  sourceConnectionPoint: ResolvedConnectionPoint,
  destinationConnectionPoint: ResolvedConnectionPoint,
  sourceContactOptions: Option[],
  destinationContactOptions: Option[],
): Fields => {
  const updatedState = { ...state };

  if (state.conductorId) {
    const shouldAddSourceContact = !excludedConnectionPointTypes.has(sourceConnectionPoint.name);

    const shouldAddDestinationContact = !excludedConnectionPointTypes.has(destinationConnectionPoint?.name);

    if (shouldAddSourceContact && !state.sourceContactId && sourceContactOptions.length === 1) {
      updatedState.sourceContactId = sourceContactOptions[0].value;
    }

    if (shouldAddDestinationContact && !state.destinationContactId && destinationContactOptions.length === 1) {
      updatedState.destinationContactId = destinationContactOptions[0].value;
    }
  }

  return updatedState;
};
