import { BaseEdgeProps } from '@xyflow/react';

/**
 * Most of the logic is borrowed from here: https://github.com/xyflow/xyflow/blob/main/packages/react/src/components/Edges/BaseEdge.tsx
 */

export function CustomSegmentPath({ path, interactionWidth = 20, onContextMenu, ...props }: BaseEdgeProps) {
  return (
    <>
      <path {...props} d={path} fill="none" className="react-flow__edge-path" onContextMenu={onContextMenu} />
      {interactionWidth && (
        <path
          d={path}
          fill="none"
          strokeOpacity={0}
          strokeWidth={interactionWidth}
          className="react-flow__edge-interaction"
          onContextMenu={onContextMenu}
        />
      )}
    </>
  );
}
