import { MouseEvent, useCallback, useState } from 'react';

export interface MenuPosition {
  x: number;
  y: number;
}

const defaultMenuPosition: MenuPosition = { x: 0, y: 0 };

export const useContextMenuState = () => {
  const [position, setPosition] = useState<MenuPosition>(defaultMenuPosition);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleContextMenu = useCallback((event: MouseEvent | globalThis.MouseEvent) => {
    event.preventDefault();
    setPosition({ x: event.clientX, y: event.clientY });
    setIsOpen(true);
  }, []);

  const closeMenu = useCallback(() => setIsOpen(false), []);

  return {
    handleContextMenu,
    isOpen,
    position,
    closeMenu,
  };
};
