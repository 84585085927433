import { PartType } from '@web/apps/types';

import { PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.BACKSHELL };

export const BackshellsDashboard = () => (
  <PartsLibraryDashboard
    title="Backshells"
    addPartButtonLabel="Backshell"
    routeName="backshells"
    defaultValues={defaultValues}
    partType={PartType.BACKSHELL}
  />
);
