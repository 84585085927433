import { Text } from '@chakra-ui/react';
import { Cable, Part, PartType } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';
import { formatConductorDiameter } from '../../utils.tsx';

const defaultValues = { type: PartType.CABLE };

const columns: CustomColumnDef[] = [
  {
    header: 'Shielded',
    renderFn: (part: Part) => {
      const cable = part as Cable;
      return <Text>{cable.shielded}</Text>;
    },
  },
  {
    header: 'Shield Type',
    renderFn: (part: Part) => {
      const cable = part as Cable;
      return <Text>{cable.shieldType}</Text>;
    },
  },
  {
    header: 'Jacket Color',
    renderFn: (part: Part) => {
      const cable = part as Cable;
      return <Text>{cable.jacket}</Text>;
    },
  },
  {
    header: 'Inner Diameter',
    renderFn: (part: Part) => {
      const cable = part as Cable;
      return <Text>{formatConductorDiameter(cable.innerDiameter, cable.diameterUnitOfMeasurement)}</Text>;
    },
  },
  {
    header: 'Outer Diameter',
    renderFn: (part: Part) => {
      const cable = part as Cable;
      return <Text>{formatConductorDiameter(cable.outerDiameter, cable.diameterUnitOfMeasurement)}</Text>;
    },
  },
];

export const CablesDashboard = () => (
  <PartsLibraryDashboard
    title="Cables"
    addPartButtonLabel="Cable"
    routeName="cables"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.CABLE}
  />
);
