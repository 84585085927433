import { Menu, MenuList, Portal } from '@chakra-ui/react';
import { GraphElement } from '@web/apps/types';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';

import { GraphOperation } from '../../graph/Operations.ts';
import { useLayoutBuilder } from '../useLayoutBuilder.tsx';

export interface MenuPosition {
  x: number;
  y: number;
}

const defaultMenuPosition: MenuPosition = { x: 0, y: 0 };

/**
 * Base hook for menu actions. Node and edge actions should extend this hook.
 */
export const useActionsBase = () => {
  const { executeGraphOperation } = useLayoutBuilder();
  const [menuPosition, setMenuPosition] = useState<MenuPosition>(defaultMenuPosition);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [element, setElement] = useState<GraphElement>(null);

  const showMenu = useCallback((event: MouseEvent | globalThis.MouseEvent, graphElement?: GraphElement) => {
    event.preventDefault();
    if (graphElement) {
      setElement(graphElement);
    }
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setElement(null);
  }, []);

  const executeOperation = useCallback(
    (params: GraphOperation) => {
      executeGraphOperation(params);
      closeMenu();
    },
    [closeMenu, executeGraphOperation],
  );

  const renderMenu = useCallback(
    (children: ReactNode) =>
      isMenuOpen && (
        <Menu key={element?.id} isOpen>
          <Portal>
            <MenuList
              maxHeight="42rem"
              overflow="auto"
              position="fixed"
              top={`${menuPosition.y}px`}
              left={`${menuPosition.x}px`}
            >
              {children}
            </MenuList>
          </Portal>
        </Menu>
      ),
    [isMenuOpen, menuPosition.x, menuPosition.y, element],
  );

  return {
    showMenu,
    closeMenu,
    renderMenu,
    executeOperation,
    menuPosition,
    element,
  };
};
