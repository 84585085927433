import { Box, Text } from '@chakra-ui/react';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState.ts';
import { useDesignParts } from '../../../../../hooks/useDesignParts.tsx';
import { CenterHandle } from '../../../../Layout/components/nodes/common/CenterHandle.tsx';
import { HandleTypes } from '../../../../Layout/types/handles.ts';
import { getNodeColor } from '../../../../Layout/utils/common.ts';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';
import { PassiveShape } from './PassiveShape.tsx';

export type RelationalPassiveNodeData = {
  designPartId: UUID;
  noteGroupBuildNotes: NoteGroupBuildNote[];
};

export const defaultRelationalPassiveNodeData: RelationalPassiveNodeData = {
  designPartId: '',
  noteGroupBuildNotes: [],
};

export type RelationalPassiveNodeType = Node<RelationalPassiveNodeData>;

export const RelationalPassiveNode = ({
  id,
  data: { designPartId, noteGroupBuildNotes } = defaultRelationalPassiveNodeData,
  selected,
}: NodeProps<RelationalPassiveNodeType>) => {
  const { getDesignPartById } = useDesignParts();
  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const designPart = getDesignPartById(designPartId);

  if (!designPart) {
    return null;
  }

  return (
    <Box position="relative" onContextMenu={handleContextMenu}>
      <Text color="gray.900" fontFamily="mono" position="absolute" top="-20px">
        {designPart.name}
      </Text>
      <PassiveShape designPart={designPart} color={getNodeColor(selected)} />
      <CenterHandle id={HandleTypes.Segment} />
      <CenterHandle id={HandleTypes.Note} />
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={id} />
      </ContextMenu>
    </Box>
  );
};
