import { Fragment } from 'react';

import { Conductor } from '../Conductor.tsx';
import { Inline } from '../Inline.tsx';
import { RowProps } from '../Paths';
import { Row } from './Row.tsx';

export const InlineRow = ({ path }: RowProps) => (
  <Row>
    {path.inlines.map((inline, index) => {
      const isLastInline = index === path.inlines.length - 1;

      return (
        <Fragment key={`inline-${index}`}>
          {/* Path takes max available space but is equally distributed */}
          <Conductor conductor={path.conductors[index]} flex={1} />

          {/* Inline takes natural width */}
          <Inline inline={inline} path={path} />

          {/* Last inline should still have a following path if needed */}
          {isLastInline && path.conductors.length > index + 1 && (
            <Conductor conductor={path.conductors[index + 1]} flex={1} />
          )}
        </Fragment>
      );
    })}
  </Row>
);
