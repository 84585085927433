import { Text } from '@chakra-ui/react';
import { Part, PartType, Pigtail } from '@web/apps/types';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.PIGTAIL };

const columns: CustomColumnDef[] = [
  {
    header: 'Wires',
    renderFn: (part: Part) => {
      const pigtail = part as Pigtail;
      return <Text>{pigtail.partWires.length}</Text>;
    },
  },
];

export const PigtailsDashboard = () => (
  <PartsLibraryDashboard
    title="Pigtails"
    addPartButtonLabel="Pigtail"
    routeName="pigtails"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.PIGTAIL}
  />
);
