import { ShapeProps } from '@web/apps/types';

export const Diode = ({ color }: ShapeProps) => (
  <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7826 8.22962V10.4905C10.7826 10.7786 10.549 11.0122 10.2609 11.0122C9.97272 11.0122 9.73913 10.7786 9.73913 10.4905V8.61159L5.30435 11.172C5.14292 11.2652 4.94404 11.2652 4.78261 11.172C4.62118 11.0788 4.52174 10.9066 4.52174 10.7202V8.22962H1.91304C1.62489 8.22962 1.3913 7.99603 1.3913 7.70788C1.3913 7.41973 1.62489 7.18614 1.91304 7.18614H4.52174V4.69565C4.52174 4.50925 4.62118 4.33701 4.78261 4.24381C4.94404 4.15061 5.14292 4.15061 5.30435 4.24381L9.73913 6.80424V4.92527C9.73913 4.63712 9.97272 4.40353 10.2609 4.40353C10.549 4.40353 10.7826 4.63712 10.7826 4.92527V7.18614H13.7391C14.0273 7.18614 14.2609 7.41973 14.2609 7.70788C14.2609 7.99603 14.0273 8.22962 13.7391 8.22962H10.7826ZM5.56522 5.59933L9.21739 7.70792L5.56522 9.8165L5.56522 5.59933Z"
      fill="white"
    />
  </svg>
);
