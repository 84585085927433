import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { emptyPatterns, getOverwrapPattern } from '../utils/overwrapPatterns.tsx';

export const useOverwrapPatterns = () => {
  const { getOverwrapByPartNumber } = useDesignParts();

  const getPatternsForOutermostOverwrap = (overwraps: string[]) => {
    if (overwraps.length > 0) {
      const outermostOverwrapPartNumber = overwraps[overwraps.length - 1];
      const overwrap = getOverwrapByPartNumber(outermostOverwrapPartNumber);

      return getOverwrapPattern(overwrap);
    }

    return emptyPatterns;
  };

  return { getPatternsForOutermostOverwrap };
};
