export const colorDelimiter = '/';
export const partNumberDelimiter = '.';

export type MilSpecColor =
  | 'Black'
  | 'Brown'
  | 'Red'
  | 'Orange'
  | 'Yellow'
  | 'Green'
  | 'Blue'
  | 'Violet'
  | 'Pink'
  | 'Gray'
  | 'White';

// Colors natively supported by Chakra UI
export const chakraColors = ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Purple', 'Pink', 'Gray', 'Teal', 'Cyan'];

export const extractColors = (inputText: string, milSpecColors: Record<MilSpecColor, string>): string[] => {
  if (!inputText) return [];

  // Normalize input to lowercase
  const lowerInput = inputText.toLowerCase();

  const colors: string[] = [];

  // Loop through the input text and extract matches from milSpecColors
  Object.keys(milSpecColors).forEach((colorKey) => {
    if (lowerInput.includes(colorKey.toLowerCase())) {
      colors.push(milSpecColors[colorKey as MilSpecColor]);
    }
  });

  return colors;
};

export const createGradient = (gradientColors: string[]): string => {
  const percentage = 100 / gradientColors.length;
  const gradientStops = gradientColors
    .map((color, index) => {
      const start = index * percentage;
      const end = (index + 1) * percentage;
      return `${color} ${start}%, ${color} ${end}%`;
    })
    .join(', ');

  return `linear-gradient(45deg, ${gradientStops})`;
};
