import { HTMLChakraProps, Td } from '@chakra-ui/react';
import { Col, Row } from '@web/apps/Design/features/WiringList/types.ts';
import { ReactNode, useEffect, useRef } from 'react';

import { useDesign } from '../../../../hooks/useDesign.tsx';
import { useWiringList } from '../../hooks/useWiringList.tsx';

interface Props extends Omit<HTMLChakraProps<'td'>, 'children'> {
  row: Row;
  col: Col;
  onCellFocus: () => void;
  onCellBlur: () => void;
  children: (props: { isActive: boolean }) => ReactNode;
}

export const WiringListCell = ({ row, col, onCellFocus, onCellBlur, children, ...rest }: Props) => {
  const { isViewOnly } = useDesign();
  const { activeEditCell, setActiveEditCell, isActiveEditCell, isSelectMenuOpen, rowPendingChange } = useWiringList();
  const cellRef = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (!rowPendingChange && isActiveEditCell({ row, col }) && !cellRef.current?.contains(document.activeElement)) {
      cellRef.current?.focus();
    }
  }, [activeEditCell, rowPendingChange, col, row, isActiveEditCell]);

  const handleClick = () => {
    if (isViewOnly || isSelectMenuOpen) return;

    cellRef.current?.focus();
  };

  const handleFocus = () => {
    setActiveEditCell({ row, col });
    cellRef.current?.querySelector<HTMLElement>('input, select, textarea, button')?.focus();
    onCellFocus();
  };

  const handleBlur = () => {
    setActiveEditCell(null);
    onCellBlur();
  };

  return (
    <Td
      ref={cellRef}
      tabIndex={0}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      bg={isActiveEditCell({ row, col }) ? 'white' : 'transparent'}
      borderBottom="1px solid"
      borderColor="gray.300"
      _focus={{
        boxShadow: 'inset 0 0 0 2px #3182CE',
        borderRadius: 'md',
      }}
      _focusWithin={{
        boxShadow: 'inset 0 0 0 2px #3182CE',
        borderRadius: 'md',
      }}
      aria-label="Interactive cell"
      {...rest}
    >
      {typeof children === 'function' ? children({ isActive: isActiveEditCell({ row, col }) }) : children}
    </Td>
  );
};
