import { Box } from '@chakra-ui/react';
import { emptyEdges, emptyNodes } from '@web/apps/types';
import {
  ConnectionMode,
  EdgeTypes,
  NodeTypes,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { useCallback, useState } from 'react';

import { defaultLayoutConfig } from '../features/Layout/config';
import { generateImage, waitForNodes } from '../utils/generateImageForExport';

interface ExportReactFlowProps {
  nodeTypes: NodeTypes;
  edgeTypes: EdgeTypes;
  getData: (data: any) => any;
}

export const useExportReactFlow = ({ nodeTypes, edgeTypes, getData }: ExportReactFlowProps) => {
  const [nodes, setNodes] = useNodesState(emptyNodes);
  const [edges, setEdges] = useEdgesState(emptyEdges);
  const [isRendering, setIsRendering] = useState(false);

  const { getNodes, getNodesBounds } = useReactFlow();

  const exportImage = useCallback(
    async (props?: any) => {
      try {
        setIsRendering(true);
        const data = await getData(props);

        if (!data) return null;
        setNodes(data.nodes);
        setEdges(data.edges);

        await waitForNodes();

        const nodesBounds = getNodesBounds(getNodes());
        const image = await generateImage(nodesBounds);

        return image;
      } finally {
        setIsRendering(false);
        setNodes(emptyNodes);
        setEdges(emptyEdges);
      }
    },
    [getData, getNodes, getNodesBounds, setNodes, setEdges],
  );

  const renderedReactFlow = (
    <Box w="0px" h="0px" style={{ position: 'absolute' }}>
      <ReactFlow
        fitView
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        connectionMode={ConnectionMode.Loose}
        defaultViewport={defaultLayoutConfig.defaultViewport}
        minZoom={defaultLayoutConfig.minZoom}
        maxZoom={defaultLayoutConfig.maxZoom}
        nodeOrigin={defaultLayoutConfig.nodeOrigin}
        snapToGrid={defaultLayoutConfig.snapToGrid}
        snapGrid={defaultLayoutConfig.snapGrid}
        style={defaultLayoutConfig.style}
      />
    </Box>
  );

  return {
    exportImage,
    renderedReactFlow: isRendering ? renderedReactFlow : null,
  };
};
