import { NotableType, UUID } from '@web/apps/types';
import { Edge, EdgeProps, useReactFlow } from '@xyflow/react';

import { RelationalSegmentEdgeType } from '../SegmentEdge/RelationalSegmentEdge.tsx';
import { BaseNoteEdge } from './BaseNoteEdge.tsx';
import { ControlPointNoteEdge } from './ControlPointNoteEdge.tsx';

export type RelationalNoteEdgeData = {
  notableId: UUID;
  notableType: NotableType;
  targetEdgeId: UUID | undefined;
};

export const defaultRelationalNoteEdgeData = {
  notableId: '',
  notableType: NotableType.LAYOUT_NODE,
  targetEdgeId: '',
};

export type RelationalSegmentNoteEdgeType = Edge<RelationalNoteEdgeData>;

export const RelationalNoteEdge = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  data = defaultRelationalNoteEdgeData,
}: EdgeProps<RelationalSegmentNoteEdgeType>) => {
  const { notableId, notableType, targetEdgeId } = data;
  const { getEdge } = useReactFlow();

  const sourceXYPosition = { x: sourceX, y: sourceY };
  const targetXYPosition = { x: targetX, y: targetY };
  const targetEdge = targetEdgeId ? (getEdge(targetEdgeId) as RelationalSegmentEdgeType) : undefined;

  if (notableType === NotableType.LAYOUT_NODE) {
    return (
      <BaseNoteEdge
        sourceXYPosition={sourceXYPosition}
        targetXYPosition={targetXYPosition}
        sourcePosition={sourcePosition}
      />
    );
  } else if (notableType === NotableType.CONTROL_POINT && targetEdge) {
    return (
      <ControlPointNoteEdge
        sourceXYPosition={sourceXYPosition}
        sourcePosition={sourcePosition}
        targetEdge={targetEdge}
        controlPointId={notableId}
      />
    );
  }

  return null;
};
