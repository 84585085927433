import { useCreateDesignPartLayoutEdgeMutation } from '@web/apps/Design/api/design-part-layout-edges-api.ts';
import { useDesignId } from '@web/apps/Design/hooks/useDesignId.tsx';
import { useDesignParts } from '@web/apps/Design/hooks/useDesignParts.tsx';
import { generateDesignPartNameFromPart } from '@web/apps/Design/utils/generateDesignPartName.ts';
import { Part, PartType, UUID } from '@web/apps/types';
import { useCallback } from 'react';

import { usePartSearchModal } from '../../../../hooks/usePartSearchModal.tsx';
import AddOverwrapButton from '../../../Layout/components/AddOverwrapButton.tsx';

/**
 * Hook to add a new overwrap (by opening Part Search Modal), or toggle an existing overwrap.
 * @param elementId
 */
export const useRelationalOverwrapActions = (elementId?: UUID) => {
  const { openModal, closeModal } = usePartSearchModal();
  const designId = useDesignId();
  const { designParts = [] } = useDesignParts();

  const { mutateAsync: createDesignPartLayoutEdge } = useCreateDesignPartLayoutEdgeMutation();

  const addOverwrapDesignPart = useCallback(
    (part: Part) => {
      if (!elementId) return;
      createDesignPartLayoutEdge({
        designId,
        layoutEdgeId: elementId,
        partId: part.id,
        name: generateDesignPartNameFromPart(designParts, part),
      });
      closeModal();
    },
    [closeModal, elementId, designId, createDesignPartLayoutEdge, designParts],
  );

  const handleAddOverwrap = useCallback(() => {
    openModal({
      initialPartTypeFilters: [PartType.OVERWRAP],
      filtersDisabled: true,
      renderSearchResultAction: (part: Part) => (
        <AddOverwrapButton part={part} onPartAdded={() => addOverwrapDesignPart(part)} />
      ),
    });
  }, [openModal, addOverwrapDesignPart]);

  return { handleAddOverwrap };
};
