import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import { getInputHandle, getOutputHandle } from '@web/apps/Design/features/Schematic/utils/handles.ts';
import { centerAbsolute } from '@web/apps/styles.ts';
import { Schematic } from '@web/apps/types';
import { Position } from '@xyflow/system';

import { Splice } from '../../../../../components/Shapes/Splice.tsx';
import { defaultSchematicConfig } from '../../../config.ts';
import { conductorSymbolOffset } from '../../../types/positioning.ts';
import { getInlineType, InlineType } from '../../../utils/inlines.ts';
import { ConnectionHandle } from './ConnectionHandle.tsx';

interface Props extends BoxProps {
  inline: Schematic.ConnectionPoint;
  path: Schematic.Path;
}

export const Inline = ({ inline, path, ...rest }: Props) => {
  const inlineType = getInlineType(inline, path);

  const translateX: Record<InlineType, string> = {
    source: `translateX(${conductorSymbolOffset - 10}px)`,
    destination: `translateX(-${conductorSymbolOffset - 10}px)`,
    standard: 'none',
  };

  const handleClick = () => {
    console.debug('Inline', inline);
  };

  return (
    <HStack>
      {inlineType === 'destination' && (
        <Text fontFamily="mono" pr={8}>
          {inline.displayName}
        </Text>
      )}
      <Box
        role="listitem"
        aria-label={`inline-${inlineType}`}
        position="relative"
        h={defaultSchematicConfig.cavityHeight}
        onClick={handleClick}
        pointerEvents="all"
        cursor="pointer"
        sx={{ transform: translateX[inlineType] ?? 'none' }}
        {...rest}
      >
        {/* Standard Inline: Input on Left, Output on Right */}
        {inlineType === 'standard' && (
          <Box h="full" display="flex" alignItems="center">
            <HStack color="gray.100" bg="gray.700" borderRadius="md" px={2} py={1}>
              <ConnectionHandle id={getInputHandle(inline.displayName)} handlePosition={Position.Left} />
              <Splice color="black" size={14} />
              <Text fontFamily="mono" fontSize="sm">
                {inline.displayName}
              </Text>
              <ConnectionHandle id={getOutputHandle(inline.displayName)} handlePosition={Position.Right} />
            </HStack>
          </Box>
        )}

        {/* Source Inline: Input on Bottom, Output on Top */}
        {inlineType === 'source' && (
          <Box>
            <Splice color="black" size={20} />
            <Box sx={{ ...centerAbsolute }}>
              <ConnectionHandle id={getInputHandle(inline.displayName)} handlePosition={Position.Bottom} />
              <ConnectionHandle id={getOutputHandle(inline.displayName)} handlePosition={Position.Top} />
            </Box>
          </Box>
        )}

        {/* Destination Inline: Input on Top, Output on Bottom */}
        {inlineType === 'destination' && (
          <Box>
            <Splice color="black" size={20} />
            <Box sx={{ ...centerAbsolute }}>
              <ConnectionHandle id={getInputHandle(inline.displayName)} handlePosition={Position.Top} />
              <ConnectionHandle id={getOutputHandle(inline.displayName)} handlePosition={Position.Bottom} />
            </Box>
          </Box>
        )}
      </Box>
      {inlineType === 'source' && (
        <Text fontFamily="mono" pl={8}>
          {inline.displayName}
        </Text>
      )}
    </HStack>
  );
};
