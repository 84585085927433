import { Part, PartType } from '@web/apps/types';
import { UseFormProps } from 'react-hook-form';

import SkinnyModalForm from '../../../components/form/SkinnyModalForm.tsx';
import { useCreatePart, useUpdatePart } from '../api/queries.ts';
import { Mode } from '../partsLibraryTypes.ts';
import { PartFields } from './PartFields.tsx';
import { PartsLibraryModalHeader } from './PartsLibraryModalHeader.tsx';

export interface PartsLibraryModalProps {
  initialPartData?: UseFormProps<Part>['defaultValues'];
  title: string;
  partType: PartType;
  mode: Mode;
  setMode?: (mode: Mode) => void;
  isOpen: boolean;
  onClose: () => void;
  onPartCreated?: (part?: Part) => void;
  onDeletePart?: () => Promise<void>;
}

export type CustomPartModalProps = Omit<PartsLibraryModalProps, 'partType'>;

export const PartsLibraryModal = ({
  initialPartData = {},
  title,
  partType,
  mode,
  setMode,
  isOpen,
  onClose,
  onPartCreated,
  onDeletePart,
}: PartsLibraryModalProps) => {
  const createPart = useCreatePart(partType);
  const updatePart = useUpdatePart(partType);

  const isCreateMode = mode === Mode.CREATE;

  const handleSubmitPart = async (part: Part): Promise<Part | undefined> => {
    const mutation = isCreateMode ? createPart : updatePart;
    const { manufacturers, ...restPart } = part;

    const sanitizedPart = Object.fromEntries(
      Object.entries(restPart).map(([key, value]) => [key, value === null ? undefined : value]),
    );

    const newPart = await mutation.mutateAsync({
      ...sanitizedPart,
      type: partType,
      manufacturerIds: manufacturers?.map((m) => m.id) || [],
      id: part.id,
      status: part.status,
      partNumber: part.partNumber,
      description: part.description,
      confidence: part.confidence,
    });

    if (newPart) {
      isCreateMode && onPartCreated?.(newPart);
      return newPart as Part;
    }
  };

  return (
    <SkinnyModalForm
      defaultValues={initialPartData}
      isOpen={isOpen}
      onSubmit={handleSubmitPart}
      onClose={onClose}
      header={<PartsLibraryModalHeader title={title} />}
      successToastMsg="Part saved successfully"
      minWidth={['90%', '90%', '90%', '65%', '50%']}
      closeButton
    >
      <PartFields partType={partType} mode={mode} setMode={setMode} onDeletePart={onDeletePart} />
    </SkinnyModalForm>
  );
};
