import '@xyflow/react/dist/style.css';

import { Box, Icon } from '@chakra-ui/react';
import { EmptyState } from '@web/apps/Design/components/EmptyStates/EmptyState.tsx';
import { useSelectedDesignPart } from '@web/apps/Design/hooks/useSelectedDesignPart.tsx';
import { defaultViewport, emptyEdges, emptyNodes } from '@web/apps/types';
import { RouteNames } from '@web/consts/routeNames.ts';
import { ConnectionMode, Edge, PanOnScrollMode, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { LegacyRef, MouseEvent } from 'react';
import { PiEmpty } from 'react-icons/pi';
import { useDebounce, useMeasure } from 'react-use';

import ManagedWindow from '../../components/ManagedWindow.tsx';
import { defaultSchematicConfig } from './config.ts';
import { useLoadSchematic } from './hooks/useLoadSchematic.ts';
import { edgeTypes } from './types/edges.ts';
import { nodeTypes } from './types/nodes.ts';

/**
 * Entry point for the Schematic feature.
 * @constructor
 */
export const Schematic = () => {
  const selectedDesignPart = useSelectedDesignPart();
  const [ref, bounds] = useMeasure();

  // Initialize the nodes and edges state
  const [nodes, setNodes, onNodesChange] = useNodesState(emptyNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(emptyEdges);

  // Initialize Schematic
  const { schematicData, isSchematicDataLoaded, updateSchematicWidth } = useLoadSchematic(setNodes, setEdges);

  useDebounce(() => updateSchematicWidth(bounds.width), 300, [bounds.width]);

  const handleEdgeClick = (event: MouseEvent, edge: Edge) => {
    console.debug('Edge:', edge);
  };

  return (
    <ManagedWindow title="Schematic" routeName={RouteNames.DESIGNS.SCHEMATIC}>
      <Box ref={ref as LegacyRef<HTMLDivElement>} w="full" h="full">
        {!selectedDesignPart ? (
          <EmptyState
            icon={<Icon as={PiEmpty} boxSize={10} color="gray.400" />}
            message="Select a Connector to see the Schematic."
          />
        ) : !isSchematicDataLoaded ? null : !schematicData || schematicData.destinations.length === 0 ? (
          <EmptyState
            icon={<Icon as={PiEmpty} boxSize={10} color="gray.400" />}
            message="No Schematic data for the part selected."
          />
        ) : (
          <ReactFlow
            /* Config nodes */
            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={onNodesChange}
            nodesDraggable={false}
            nodesConnectable={false}
            /* Config edges */
            edgeTypes={edgeTypes}
            edges={edges}
            onEdgesChange={onEdgesChange}
            onEdgeClick={handleEdgeClick}
            /* ReactFlow settings (fixed) */
            connectionMode={ConnectionMode.Loose}
            proOptions={{ hideAttribution: true }}
            panOnDrag={false}
            panOnScroll={true}
            panOnScrollMode={PanOnScrollMode.Vertical}
            translateExtent={[
              [0, 0],
              [Infinity, Infinity],
            ]}
            zoomOnDoubleClick={false}
            zoomOnPinch={false}
            deleteKeyCode={null}
            selectionKeyCode={null}
            multiSelectionKeyCode={null}
            zoomActivationKeyCode={null}
            panActivationKeyCode={null}
            /* ReactFlow settings (from config.ts) */
            defaultViewport={defaultViewport}
            minZoom={defaultSchematicConfig.minZoom}
            maxZoom={defaultSchematicConfig.maxZoom}
            nodeOrigin={defaultSchematicConfig.nodeOrigin}
            style={defaultSchematicConfig.style}
          ></ReactFlow>
        )}
      </Box>
    </ManagedWindow>
  );
};
