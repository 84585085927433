import { MenuButtonProps } from '@chakra-ui/menu';
import { Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { ResolvedConnection } from '@web/apps/types';
import { useState } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props extends MenuButtonProps {
  connection: ResolvedConnection | null;
  onAddCrimpSplice: () => void;
  onClearRow: () => void;
}

export const WiringListRowActions = ({ connection, onAddCrimpSplice, onClearRow, ...rest }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Menu isOpen={isMenuOpen} onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
      <Box display={isMenuOpen ? 'block' : 'none'} _groupHover={{ display: 'block' }}>
        <MenuButton
          as={IconButton}
          icon={<SlOptionsVertical />}
          aria-label="Wiring list row actions"
          size="sm"
          variant="unstyled"
          {...rest}
        />
      </Box>
      <Portal>
        <MenuList fontSize="xs">
          <MenuItem onClick={onAddCrimpSplice}>Add Crimp Splice</MenuItem>
          <MenuItem onClick={onClearRow} isDisabled={connection === null}>
            Clear Row
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
