import { Edge, Node } from '@xyflow/react';

import { RelationalNoteEdgeData } from './components/edges/NoteEdges/RelationalNoteEdge.tsx';
import { RelationalSegmentEdgeData } from './components/edges/SegmentEdge/RelationalSegmentEdge.tsx';
import { RelationalBreakoutPointNodeData } from './components/nodes/BreakoutPointNode/RelationalBreakoutPointNode.tsx';
import { RelationalConnectorNodeData } from './components/nodes/ConnectorNode/RelationalConnectorNode.tsx';
import { RelationalNoteGroupNodeData } from './components/nodes/NoteGroupNode/RelationalNoteGroupNode.tsx';
import { RelationalPassiveNodeData } from './components/nodes/PassiveNode/RelationalPassiveNode.tsx';
import { RelationalPigtailNodeData } from './components/nodes/PigtailNode/RelationalPigtailNode.tsx';
import { RelationalSpliceNodeData } from './components/nodes/SpliceNode/RelationalSpliceNode.tsx';

// =======================
// Node Type Definitions
// =======================

// Define the node types
export enum NodeType {
  BreakoutPoint = 'BreakoutPoint',
  Connector = 'Connector',
  NoteGroup = 'NoteGroup',
  Passive = 'Passive',
  Pigtail = 'Pigtail',
  Splice = 'Splice',
}

// Define the node type guards
export const isBreakoutPointNode = (node: Node): node is Node<RelationalBreakoutPointNodeData> => {
  return node.type === NodeType.BreakoutPoint;
};

export const isConnectorNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return node.type === NodeType.Connector;
};

export const isNoteGroupNode = (node: Node): node is Node<RelationalNoteGroupNodeData> => {
  return node.type === NodeType.NoteGroup;
};

export const isPassiveNode = (node: Node): node is Node<RelationalPassiveNodeData> => {
  return node.type === NodeType.Passive;
};

export const isPigtailNode = (node: Node): node is Node<RelationalPigtailNodeData> => {
  return node.type === NodeType.Pigtail;
};

export const isSpliceNode = (node: Node): node is Node<RelationalSpliceNodeData> => {
  return node.type === NodeType.Splice;
};

// Type guard to determine if a node is a design part node
export const isDesignPartNode = (node: Node): node is Node<RelationalConnectorNodeData> => {
  return isConnectorNode(node) || isPassiveNode(node) || isPigtailNode(node) || isSpliceNode(node);
};

// =======================
// Edge Type Definitions
// =======================

// Define the edge types
export enum EdgeType {
  SegmentEdge = 'SegmentEdge',
  NoteEdge = 'NoteEdge',
}

// Define the edge type guards
export const isSegmentEdge = (
  edge: Edge,
): edge is Edge<RelationalSegmentEdgeData> & { data: RelationalSegmentEdgeData } => {
  return edge.type === EdgeType.SegmentEdge && edge.data !== undefined;
};

export const isNoteEdge = (edge: Edge): edge is Edge<RelationalNoteEdgeData> & { data: RelationalNoteEdgeData } => {
  return edge.type === EdgeType.NoteEdge;
};
