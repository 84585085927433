import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { ControlPoint, LayoutNode, UUID } from '@web/apps/types';
import { doRequest, getUrl } from '@web/common/api';

import { useDesignToast } from '../hooks/useDesignToast';
import { designKeys } from './query-keys';
import { useInvalidateQueries } from './react-query-helpers';

const upsertControlPoints = async (designId: UUID, layoutEdgeId: UUID, data: ControlPoint[]) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_edges/${layoutEdgeId}/control_points/upsert`);
  return doRequest<LayoutNode>('PUT', url, JSON.stringify({ controlPoints: data }));
};

interface UpsertControlPointParams {
  designId: UUID;
  layoutEdgeId: UUID;
  data: ControlPoint[];
}

export const useUpsertControlPointsMutation = (): UseMutationResult<void, Error, UpsertControlPointParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-control-point'],
    mutationFn: async ({ designId, layoutEdgeId, data }: UpsertControlPointParams) => {
      await upsertControlPoints(designId, layoutEdgeId, data);
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error Upserting Control Points', error.message),
  });
};
