import { StackProps, VStack } from '@chakra-ui/react';
import { Schematic } from '@web/apps/types';
import { Fragment } from 'react';

import { isLoopback } from '../../../utils/helpers.ts';
import { hasInlines, hasShieldSplices } from '../../../utils/inlines.ts';
import { InlineRow } from './rows/InlineRow.tsx';
import { LoopbackRow } from './rows/LoopbackRow.tsx';
import { ShieldSpliceRow } from './rows/ShieldSpliceRow.tsx';
import { SinglePathRow } from './rows/SinglePathRow.tsx';

export interface RowProps {
  path: Schematic.Path;
}

interface Props extends StackProps {
  destinationGroup: Schematic.Destination;
}

export const Paths = ({ destinationGroup }: Props) => {
  return (
    <VStack w="full" spacing={0} py={2}>
      {destinationGroup.paths.map((path, index) => (
        <Fragment key={`path-${index}`}>
          {hasShieldSplices(path) ? (
            <ShieldSpliceRow path={path} />
          ) : hasInlines(path) ? (
            <InlineRow path={path} />
          ) : (
            <SinglePathRow path={path} />
          )}
          {isLoopback(path) && <LoopbackRow />}
        </Fragment>
      ))}
    </VStack>
  );
};
