import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { CopyBOMButton } from '@web/apps/Design/features/BOM/components/CopyBOMButton.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';

import ManagedWindow from '../../components/ManagedWindow.tsx';
import { useBOM } from '../../hooks/useBOM.tsx';
import { SELECTION_TYPES, useDesign } from '../../hooks/useDesign.tsx';
import BOMItem from './components/BOMItem.tsx';
import BOMItemDetails from './components/BOMItemDetails.tsx';

/**
 * BOM component displays the Bill of Materials for the design.
 * @constructor
 */
export const BOM = () => {
  const { designId, setSelection } = useDesign();
  const { bom, bomCSV, designParts, isLoading, error } = useBOM(designId);

  if (isLoading || error) return null;

  if (!bom.length) {
    return (
      <ManagedWindow title="Bill of Materials" routeName={RouteNames.DESIGNS.BOM}>
        <Box p={8}>No BOM items found.</Box>
      </ManagedWindow>
    );
  }

  // Handle the change event for the accordion.
  const handleAccordionChange = (indices: number | number[]) => {
    // If no items are expanded, clear the selected BOM item.
    if (indices === -1 || (Array.isArray(indices) && indices.length === 0)) {
      setSelection(SELECTION_TYPES.BOM_ITEM, null);
    }
  };

  return (
    <ManagedWindow
      title="Bill of Materials"
      routeName={RouteNames.DESIGNS.BOM}
      actions={<CopyBOMButton bom={bom} bomCSV={bomCSV} />}
    >
      {({ hasFocus }) => (
        <Box h="full" overflowY="auto">
          <BOMItem py={1} isHeader />
          <Accordion allowToggle onChange={handleAccordionChange}>
            {bom.map((bomItem, index) => (
              <AccordionItem key={bomItem.partNumber}>
                {({ isExpanded }) => (
                  <Box>
                    <AccordionButton
                      as={Box}
                      px={0}
                      py={0}
                      onClick={() => setSelection(SELECTION_TYPES.BOM_ITEM, bomItem.partNumber)}
                    >
                      <BOMItem
                        key={index}
                        bomItem={bomItem}
                        isExpanded={isExpanded}
                        hasFocus={hasFocus}
                        designParts={designParts}
                      />
                    </AccordionButton>
                    <AccordionPanel px={0} py={0} bg="gray.50">
                      <BOMItemDetails bomItem={bomItem} hasFocus={hasFocus} designParts={designParts} />
                    </AccordionPanel>
                  </Box>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      )}
    </ManagedWindow>
  );
};
