import { EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';
import { getShieldGroupPath } from '../../utils/paths/getShieldGroupPath.ts';

export const SingleShieldGroup = (props: EdgeProps) => {
  const edgeStyle = {
    strokeDasharray: '4 4',
    strokeLinecap: 'square' as const,
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1,
    fill: 'none',
  };

  const [edgePath] = getShieldGroupPath(props, 2);

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
