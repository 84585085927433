import { UUID } from '@web/apps/types';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { useMemo } from 'react';

import { calculateAngleBetweenPoints } from '../../Layout/utils/geometry.ts';
import {
  isBreakoutPointNode,
  isConnectorNode,
  isDesignPartNode,
  isPigtailNode,
  isSegmentEdge,
} from '../relational_types.ts';

interface Props {
  nodeId: string;
}

/**
 * Get all layout nodes connected to a given node.
 * @param nodes - Array of nodes in the graph.
 * @param edges - Array of edges in the graph.
 * @param nodeId - ID of the node to find connected design part nodes for.
 * @returns Array of design part nodes connected to the specified node.
 */
const getConnectedLayoutNodes = (nodes: Node[], edges: Edge[], nodeId: UUID): Node[] => {
  return nodes.filter(
    (node) =>
      (isDesignPartNode(node) || isBreakoutPointNode(node)) &&
      edges.some(
        (edge) =>
          isSegmentEdge(edge) &&
          ((edge.source === nodeId && edge.target === node.id) || (edge.target === nodeId && edge.source === node.id)),
      ),
  );
};

export const useRelationalShapeRotation = ({ nodeId }: Props) => {
  // React Flow hooks
  const { getNode, getNodes, getEdges } = useReactFlow();

  // Get connected nodes, and find a target node to determine the angle to rotate this shape
  const connectedNodes = getConnectedLayoutNodes(getNodes(), getEdges(), nodeId);

  // Prioritize the nodes based on priority (control point > connector with rotate lock > any other node)
  const targetNode =
    connectedNodes.find((node) => isBreakoutPointNode(node)) ||
    connectedNodes.find(
      (node) =>
        (isConnectorNode(node) && node.data.rotateLock) ||
        (isPigtailNode(node) && node.data.rotateLock) ||
        connectedNodes[0],
    );

  // Calculate angle to make the node perpendicular to the edge
  return useMemo(() => {
    if (targetNode) {
      const thisNode = getNode(nodeId);
      if (thisNode) {
        return calculateAngleBetweenPoints(thisNode.position, targetNode.position);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }, [getNode, nodeId, targetNode]);
};
