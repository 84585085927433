import { Button, Center, Flex, Stack, Table, TableContainer, Tbody, Td, Text, Th, Tooltip, Tr } from '@chakra-ui/react';
import { DEFAULT_PARTS_PAGE_SIZE } from '@web/api/parts-api.ts';
import { Part, PartStatus, PartType, PartWire, UUID } from '@web/apps/types';
import generateURlSearch from '@web/common/lib/api-utils/generateURLSearch.ts';
import { successToast } from '@web/common/toasts.ts';
import DashboardRowActions from '@web/components/DashboardRowActions.tsx';
import Loading from '@web/components/Loading.tsx';
import Paginate from '@web/components/Paginate.tsx';
import useDebouncedValue from '@web/hooks/useDebouncedValue.ts';
import React, { MouseEvent, useCallback, useState } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router';

import { FilterBoxWithClearButton } from '../../../components/FilterBoxWithClearButton.tsx';
import { StickyThead } from '../../../components/table/StickyThead.tsx';
import Title from '../../../components/Title.tsx';
import { useDeletePart, usePart, useParts, useUpdatePart } from '../api/queries.ts';
import { Mode } from '../partsLibraryTypes.ts';
import ConfidenceBadge from './ConfidenceBadge.tsx';
import { PartsLibraryModal } from './PartsLibraryModal.tsx';
import { StatusBadge } from './StatusBadge.tsx';

export interface CustomColumnDef {
  header: string;
  renderFn: (part: Part) => React.ReactNode;
}

interface Props {
  title: string;
  addPartButtonLabel: string;
  routeName: string;
  columnDefs?: CustomColumnDef[];
  defaultValues?: UseFormProps<Part>['defaultValues'];
  partType: PartType;
}

export const PartsLibraryDashboard = ({
  title,
  addPartButtonLabel,
  routeName,
  columnDefs,
  defaultValues,
  partType,
}: Props) => {
  const navigate = useNavigate();
  const { partId } = useParams<{ partId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ?? '1';
  const partNumber = searchParams.get('part_number') ?? '';
  const mode = searchParams.get('mode') as Mode | null;

  const debouncedPartNumber = useDebouncedValue(partNumber, 500);
  const { isLoading, data: parts } = useParts(partType, debouncedPartNumber, page, DEFAULT_PARTS_PAGE_SIZE);

  const updatePart = useUpdatePart(partType);
  const deletePart = useDeletePart(partType);
  const { data: part } = usePart(partId);

  const [initialPartData, setInitialPartData] = useState<UseFormProps<Part>['defaultValues']>();

  const setMode = (mode: Mode | null) => {
    if (mode === null) {
      searchParams.delete('mode');
    } else {
      searchParams.set('mode', mode);
    }

    setSearchParams(searchParams);
  };

  const handleFilterQuery = (value: string) => {
    if (value) {
      searchParams.set('part_number', value);
    } else {
      searchParams.delete('part_number');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const closeModal = () => {
    navigate(`/parts/${routeName}${generateURlSearch({ page, part_number: partNumber })}`);
  };

  const handleAddPart = () => {
    setInitialPartData(defaultValues);
    setMode(Mode.CREATE);
  };

  const openModalWithMode = useCallback(
    (part: Part, mode: Mode) => {
      setInitialPartData(part);
      navigate(`/parts/${routeName}/${part.id}${generateURlSearch({ page, mode, part_number: partNumber })}`);
    },
    [navigate, routeName, partNumber, page],
  );

  const handleViewPart = useCallback((part: Part) => openModalWithMode(part, Mode.READ), [openModalWithMode]);
  const handleEditPart = useCallback((part: Part) => openModalWithMode(part, Mode.UPDATE), [openModalWithMode]);

  const handleReleasePart = async (part: Part) => {
    const res = await updatePart.mutateAsync({ ...part, status: PartStatus.RELEASED });

    if (res) {
      successToast('Part successfully released');
      closeModal();
    }
  };

  const handleDeletePart = async (partId: UUID) => {
    if (confirm('Are you sure you want to delete this part?')) {
      await deletePart.mutateAsync(partId);
      successToast('Part successfully deleted');
    }
    closeModal();
  };

  const handleClonePart = (part: Part) => {
    setInitialPartData({
      ...part,
      status: PartStatus.DRAFT,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      creator: undefined,
      updater: undefined,
      documents: part.documents.map((doc) => ({ ...doc, id: undefined })),
      partWires: part.partWires?.map((partWire) => ({ ...partWire, id: undefined }) as PartWire),
      partTools: part.partTools?.map((partTool) => ({ ...partTool, id: undefined })),
      accessories: part.accessories?.map((accessory) => ({ ...accessory, id: undefined })),
    });
    setMode(Mode.CREATE);
  };

  const handlePageClick = (event: { selected: number }) => {
    searchParams.set('page', (event.selected + 1).toString());
    setSearchParams(searchParams);
  };

  const initialModalData = partId ? part : initialPartData;
  const isOpen = Boolean(part) || mode === Mode.CREATE;

  return (
    <Stack spacing={6} pt={6} pb={2} minW={0} minH={0}>
      <Flex justifyContent={'space-between'} px={6}>
        <Title title={title} aria-label="page-title" />
        <Button onClick={handleAddPart}>+ Add {addPartButtonLabel}</Button>
        <PartsLibraryModal
          initialPartData={initialModalData}
          title={title}
          partType={partType}
          mode={mode || Mode.READ}
          setMode={setMode}
          isOpen={isOpen}
          onClose={closeModal}
          onDeletePart={partId ? () => handleDeletePart(partId) : undefined}
        />
      </Flex>
      <FilterBoxWithClearButton placeholder="Type to filter parts" value={partNumber} onChange={handleFilterQuery} />
      {isLoading ? (
        <Center height="50vh" width="80vw">
          <Loading message="Loading parts library…" />
        </Center>
      ) : (
        <Stack minH={0} flex={1}>
          <TableContainer overflowY="auto" flex={1}>
            <Table variant="senraTable" size="sm">
              <StickyThead zIndex={1}>
                <Tr>
                  <Th>Part Number</Th>
                  <Th>Status</Th>
                  <Th>Manufacturer(s)</Th>
                  <Th>Description</Th>
                  {columnDefs?.map((columnDef) => <Th key={columnDef.header}>{columnDef.header}</Th>)}
                  <Th>Data Confidence</Th>
                  <Th textAlign="right">Actions</Th>
                </Tr>
              </StickyThead>
              <Tbody>
                {parts?.data.map((part) => (
                  <Tr key={part.id} role="group" onClick={() => handleViewPart(part)} cursor="pointer">
                    <Td>
                      <Text paddingRight={4}>{part.partNumber}</Text>
                    </Td>
                    <Td>
                      <StatusBadge status={part.status} />
                    </Td>
                    <Td>{part.manufacturers?.map((m) => m.name).join(', ') || 'N/A'}</Td>
                    <Td>
                      {part.description && (
                        <Tooltip label={part.description}>
                          <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {part.description}
                          </Text>
                        </Tooltip>
                      )}
                    </Td>
                    {columnDefs?.map((columnDef) => <Td key={columnDef.header}>{columnDef.renderFn(part)}</Td>)}
                    <Td>
                      <ConfidenceBadge confidence={part.confidence} />
                    </Td>
                    <Td textAlign="right">
                      <DashboardRowActions
                        item={part}
                        onView={() => handleViewPart(part)}
                        onEdit={() => handleEditPart(part)}
                        onRelease={() => handleReleasePart(part)}
                        onDelete={() => handleDeletePart(part.id)}
                        onClone={() => handleClonePart(part)}
                        onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Paginate
            onPageChange={handlePageClick}
            selectedPage={parseInt(page) - 1}
            pageCount={parts?.meta.totalPages ?? 0}
          />
        </Stack>
      )}
    </Stack>
  );
};
