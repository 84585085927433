import { useAuth0 } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { AuthenticatedRoutes } from '@web/AuthenticatedRoutes.tsx';
import { RouteNames } from '@web/consts/routeNames.ts';
import { Route, Routes } from 'react-router';

import theme from './apps/themes.ts';
import AuthenticationGuard from './components/auth/AuthenticationGuard';
import Register from './routes/Register';

const NullComponent = () => null;

export function AppRoutes() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <ChakraProvider theme={theme}>
        <SentryRoutes>
          <Route index element={<AuthenticationGuard component={NullComponent} />} />
          <Route path={RouteNames.REGISTER} element={<Register />} />
          <Route path="*" element={<AuthenticationGuard component={NullComponent} />} />
        </SentryRoutes>
      </ChakraProvider>
    );
  }

  return <AuthenticatedRoutes />;
}
