import { DesignPart, isOverwrapDesignPart } from '../../../../types';
import { getPatternType } from '../../../../utils/patterns';
import { patternComponentMap } from '../../../components/DynamicPatternIcon';

export const emptyPatterns = { pattern: null, selectedPattern: null };

export const getOverwrapPattern = (overwrap: DesignPart | undefined) => {
  if (overwrap && isOverwrapDesignPart(overwrap)) {
    const patternType = getPatternType(overwrap.partData.subtype);
    const patternColor = overwrap.partData.color;
    const patternId = overwrap.partData.partNumber;
    const selectedPatternId = `${overwrap.partData.partNumber}-selected`;
    if (patternType) {
      const PatternComponent = patternComponentMap[patternType];

      if (patternType && PatternComponent && patternColor) {
        return {
          pattern: { patternId, patternComponent: <PatternComponent id={patternId} color={patternColor} /> },
          selectedPattern: {
            patternId: selectedPatternId,
            patternComponent: <PatternComponent id={selectedPatternId} color={patternColor} />,
          },
        };
      }
    }
  }

  return emptyPatterns;
};
