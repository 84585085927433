import { Box, Checkbox, FormControl, FormLabel, HStack, Select, Stack } from '@chakra-ui/react';
import { Cable } from '@web/apps/types';
import EditableFormText from '@web/components/form/EditableFormText.tsx';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';
import React from 'react';
import { ControllerRenderProps, useController, useFormContext } from 'react-hook-form';

import ConductorDiameterFields from '../../components/ConductorDiameterFields.tsx';
import PartWireTable from '../../components/PartWireTable.tsx';

interface Props {
  editing: boolean;
}

export const CableFields = ({ editing }: Props) => {
  const { control, register, getValues } = useFormContext<Cable>();
  const { field: shielded } = useController({ name: 'shielded', control });
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const handleSwitchChange = (field: ControllerRenderProps<Cable>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e.target.checked);
  };

  return (
    <Stack>
      <HStack>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Shield Type" value={getValues('shieldType')}>
            <FormControl>
              <FormLabel>Shield Type</FormLabel>
              <Select
                isDisabled={!editing || !shielded.value}
                defaultValue={config.parts.cable.shieldTypes[0]}
                {...register('shieldType')}
              >
                {config.parts.cable.shieldTypes.map((types) => {
                  return (
                    <option key={types} value={types}>
                      {types}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
        <Box flex={1}>
          <EditableFormText editing={editing} label="Jacket Color" value={getValues('jacket')}>
            <FormControl>
              <FormLabel>Jacket Color</FormLabel>
              <Select isDisabled={!editing} defaultValue={config.colors.milSpec.White} {...register('jacket')}>
                <option value="">None</option>;
                {Object.keys(config.colors.milSpec).map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </EditableFormText>
        </Box>
      </HStack>
      <Box flex={1}>
        <FormControl>
          <Checkbox
            isDisabled={!editing}
            isChecked={shielded.value}
            onChange={handleSwitchChange(shielded)}
            aria-label="Shielded"
          >
            Shielded
          </Checkbox>
        </FormControl>
      </Box>
      <ConductorDiameterFields config={config.parts.cable} editing={editing} />
      <FormControl>
        <FormLabel id="conductorsLabel">Conductors</FormLabel>
        <PartWireTable aria-labelledby="conductorsLabel" isEditing={editing} />
      </FormControl>
    </Stack>
  );
};
