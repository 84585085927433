import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';

export interface QuickLayerFilters {
  measurementHidden: boolean;
  bomNotesHidden: boolean;
  flagNotesHidden: boolean;
  gridLinesHidden: boolean;
  bezierSegments: boolean;
  segmentLabelsHidden: boolean;
}

export type DesignContextType = {
  filters: QuickLayerFilters;
  setFilters: (
    patch: Partial<QuickLayerFilters> | ((prevState: QuickLayerFilters) => Partial<QuickLayerFilters>),
  ) => void;
};

const DesignFiltersContext = createContext<DesignContextType | undefined>(undefined);

export const useDesignFilters = () => {
  const context = useContext(DesignFiltersContext);
  if (context === undefined) {
    throw new Error('useDesignFilters must be used within a DesignFiltersProvider');
  }
  return context;
};

export const DesignFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useSetState<QuickLayerFilters>({
    measurementHidden: false,
    bomNotesHidden: false,
    flagNotesHidden: false,
    gridLinesHidden: false,
    bezierSegments: false,
    segmentLabelsHidden: false,
  });
  const value = useMemo(() => ({ filters, setFilters }), [filters, setFilters]);
  return <DesignFiltersContext.Provider value={value}>{children}</DesignFiltersContext.Provider>;
};
