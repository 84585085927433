import { UUID } from '@web/apps/types';

/**
 * A Role is a type of user in the system. Roles include admin, manufacturing engineer, and technician.
 */
export enum Role {
  ADMIN = 'admin',
  MANUFACTURING_ENGINEER = 'manufacturing_engineer',
  TECHNICIAN = 'technician',
}

export enum Permission {
  MANAGE_USERS = 'manageUsers',
}

/**
 * A Users object contains an array of User objects and pagination information.
 */
export interface Users {
  data: User[];
  meta: PageData;
}

/**
 * A User is a person who has an account with Senra. Users can have different roles, such as admin, manufacturing engineer, or technician.
 */
export interface User {
  id: UUID;
  auth0Id: string | null;
  name: string;
  email: string;
  phone: string | null;
  employee: boolean;
  roles: Role[];
  permissions: { [key in Permission]: boolean };
  tenant: Tenant;
  featureFlags: FeatureFlags;
}

/**
 * A FeatureFlags object contains a list of feature flags and their values.
 */
export type FeatureFlags = Record<string, boolean>;

/**
 * A Tenant is a customer who has an account with Senra. Tenants can be based in the US or elsewhere.
 */
export interface Tenant {
  id: UUID;
  name: string;
  usBased: boolean;
  orderNumberPrefix: string;
  users: User[];
}

/**
 * A Tenants object contains an array of Tenant objects and pagination information.
 */
export interface Tenants {
  data: Tenant[];
  meta: PageData;
}

/**
 * A PageData object is used to track pagination information.
 */
export interface PageData {
  currentPage: number;
  nextPage?: number;
  prevPage?: number;
  totalCount: number;
  totalPages: number;
}

/**
 * Timestamps are used to track when a resource was created and last updated.
 */
export interface Timestamps {
  createdAt: string;
  updatedAt: string;
}

// For use with React-Select.
// See docs @ https://react-select.com/home
export interface IOption {
  label: string;
  value: string;
}

// For use with React-Select.
// See docs @ https://react-select.com/home
export interface IGroupedOption {
  label: string;
  options: IOption[];
}

export const doesUserHaveRole = (user: User | undefined, role: Role): boolean => !!user?.roles.includes(role);
export const hasPermission = (user: User | undefined, permission: Permission): boolean =>
  !!user?.permissions[permission];

export enum DocumentCategory {
  Datasheet = 'Datasheet',
  ManufacturerReference = 'Manufacturer Reference',
}

export interface DocumentAttributes {
  id?: string;
  s3Key: string;
  type: DocumentCategory;
}

export interface Document extends S3Download {
  type: DocumentCategory;
}

export interface S3Download {
  id: string;
  filename: string;
  s3Key: string;
  type: string;
  url: string;
}
