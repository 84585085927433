import { NodeOrigin } from '@xyflow/react';
import { CSSProperties } from 'react';

// Interface for LayoutConfig settings
export interface SchematicConfig {
  // ReactFlow properties
  fitView: boolean;
  width: number;
  minZoom: number;
  maxZoom: number;
  nodeOrigin: NodeOrigin | undefined;
  style: CSSProperties;
  // Custom properties
  connectorColor: string;
  cavityColor: string;
  cavityHeight: number;
  cavityWidth: number;
  conductorColor: string;
  conductorStrokeWidth: number;
}

// Default LayoutConfig settings
export const defaultSchematicConfig: SchematicConfig = {
  // ReactFlow properties
  fitView: true,
  width: 1024,
  minZoom: 1,
  maxZoom: 1,
  nodeOrigin: [0, 0],
  style: { backgroundColor: 'white' },
  // Custom properties
  connectorColor: '#2D3748',
  cavityColor: 'white',
  cavityHeight: 8,
  cavityWidth: 12,
  conductorColor: '#718096',
  conductorStrokeWidth: 0.5,
};
