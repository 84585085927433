import { WiringListRow } from '@web/apps/Design/features/WiringList/hooks/useBuildWiringList.tsx';
import { useBuildWiringListWithCrimpSplice } from '@web/apps/Design/features/WiringList/hooks/useBuildWiringListWithCrimpSplice.ts';
import { UUID } from '@web/apps/types';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { Cell, Row } from '../types.ts';

interface WiringListContextProps {
  // Wiring list
  wiringList: WiringListRow[];
  addCrimpSplice: (connectionPointId: UUID) => void;
  resetCrimpSplice: () => void;
  // Edit
  activeEditCell: Cell | null;
  setActiveEditCell: (cell: Cell | null) => void;
  isActiveEditCell: (cell: Cell) => boolean;
  // Keyboard navigation
  isKeyboardNav: boolean;
  setIsKeyboardNav: (isKeyboardNav: boolean) => void;
  // Select menu
  isSelectMenuOpen: boolean;
  setIsSelectMenuOpen: (isOpen: boolean) => void;
  // Row pending change
  rowPendingChange: Row;
  setRowPendingChange: (row: Row) => void;
}

const WiringListContext = createContext<WiringListContextProps | undefined>(undefined);

export const WiringListProvider = ({ children }: { children: ReactNode }) => {
  const { addCrimpSplice, resetCrimpSplice, wiringList } = useBuildWiringListWithCrimpSplice();

  const [activeEditCell, setActiveEditCell] = useState<Cell | null>(null);
  const [isKeyboardNav, setIsKeyboardNav] = useState(false);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [rowPendingChange, setRowPendingChange] = useState<Row>(null);

  const isActiveEditCell = useCallback(
    (cell: Cell) => activeEditCell !== null && activeEditCell.row === cell.row && activeEditCell.col === cell.col,
    [activeEditCell],
  );

  const contextValue = useMemo(
    () => ({
      wiringList,
      addCrimpSplice,
      resetCrimpSplice,
      activeEditCell,
      setActiveEditCell,
      isActiveEditCell,
      isKeyboardNav,
      setIsKeyboardNav,
      isSelectMenuOpen,
      setIsSelectMenuOpen,
      rowPendingChange,
      setRowPendingChange,
    }),
    [
      activeEditCell,
      addCrimpSplice,
      isActiveEditCell,
      isKeyboardNav,
      isSelectMenuOpen,
      resetCrimpSplice,
      rowPendingChange,
      wiringList,
    ],
  );

  return <WiringListContext.Provider value={contextValue}>{children}</WiringListContext.Provider>;
};

export const useWiringList = () => {
  const context = useContext(WiringListContext);
  if (!context) {
    throw new Error('useWiringList must be used within a WiringListProvider');
  }
  return context;
};
