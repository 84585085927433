import { ControlPointData, UUID } from '@web/apps/types';
import { Position, XYPosition } from '@xyflow/react';
import { useMemo } from 'react';

import { useDesignFilters } from '../../../../../hooks/useDesignFilters';
import { useDesignId } from '../../../../../hooks/useDesignId';
import { Algorithm } from './constants';
import { ControlPoint } from './ControlPoint/ControlPoint';
import { getControlPoints } from './path';

interface Props {
  pathPoints: XYPosition[];
  sourcePosition: Position;
  targetPosition: Position;
  controlPoints: ControlPointData[];
  color: string;
  layoutEdgeId: UUID;
}

export const SegmentControlPoints = ({ pathPoints, sourcePosition, targetPosition, color, layoutEdgeId }: Props) => {
  const designId = useDesignId();
  const { filters } = useDesignFilters();

  const algorithm = filters.bezierSegments ? Algorithm.BezierCatmullRom : Algorithm.Linear;

  const controlPointsWithIds = useMemo(() => {
    const pathControlPoints = getControlPoints(pathPoints, algorithm, {
      fromSide: sourcePosition,
      toSide: targetPosition,
    });
    return pathControlPoints.map((point) => (point.id ? point : { ...point, id: window.crypto.randomUUID() }));
  }, [pathPoints, sourcePosition, targetPosition, algorithm]);

  return controlPointsWithIds.map(({ id, x, y, active }, index) => (
    <ControlPoint
      key={id}
      id={id}
      index={index}
      x={x}
      y={y}
      active={active}
      color={color}
      designId={designId}
      layoutEdgeId={layoutEdgeId}
    />
  ));
};
