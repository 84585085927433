import {
  ControlPoint,
  LayoutEdge,
  LayoutNode,
  LayoutNodeType,
  NotableType,
  NoteLayoutNode,
  UUID,
} from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';

import { HandleTypes } from '../../features/Layout/types/handles';
import { EdgeType } from '../../features/RelationalLayout/relational_types';

export const toReactFlowNode = (node: LayoutNode, controlPointIdToEdgeIdMap: Map<UUID, UUID>): Node => {
  const {
    id,
    x,
    y,
    designPartType,
    type,
    designPartId,
    designPartName,
    rotateLocked,
    angle,
    noteGroupBuildNotes,
    groupedConductors,
    notableType,
    notableId,
  } = node;

  const notableEdgeId = controlPointIdToEdgeIdMap.get(notableId ?? '');

  return {
    id,
    position: { x, y },
    type: type === LayoutNodeType.DESIGN_PART_NODE ? designPartType : type,
    data: {
      designPartId,
      designPartName,
      rotateLock: rotateLocked,
      angle: angle,
      noteGroupBuildNotes: noteGroupBuildNotes || [],
      groupedConductors,
      notableType,
      notableId,
      notableEdgeId,
    },
    parentId: type === LayoutNodeType.NOTE_GROUP && notableType === NotableType.LAYOUT_NODE ? notableId : undefined,
  };
};

export const toSegmentReactFlowEdge = (edge: LayoutEdge): Edge => ({
  id: edge.id,
  type: EdgeType.SegmentEdge,
  source: edge.sourceId,
  target: edge.targetId,
  data: {
    distanceFromEdge: edge.distanceFromEdge,
    measurement: edge.measurement,
    toleranceMinimum: edge.toleranceMinimum,
    toleranceMaximum: edge.toleranceMaximum,
    flipped: edge.flipped,
    designPartLayoutEdges: edge.designPartLayoutEdges || [],
    groupedConductors: edge.groupedConductors || [],
    measurementHidden: edge.measurementHidden || false,
    controlPoints: (edge.controlPoints || []).map((point: ControlPoint) => ({ ...point, active: true })),
  },
  targetHandle: HandleTypes.Segment,
  sourceHandle: HandleTypes.Segment,
});

export const createReactFlowNoteEdge = (node: NoteLayoutNode, controlPointIdToEdgeIdMap: Map<UUID, UUID>): Edge => {
  const { id: nodeId, notableId, notableType } = node;
  const targetEdgeId = controlPointIdToEdgeIdMap.get(notableId);

  return {
    id: window.crypto.randomUUID(),
    type: EdgeType.NoteEdge,
    source: nodeId,
    target: notableType === NotableType.LAYOUT_NODE ? notableId : nodeId,
    data: { notableId, notableType, targetEdgeId },
    sourceHandle: HandleTypes.Note,
    targetHandle: HandleTypes.Note,
  };
};
