import { Text } from '@chakra-ui/react';
import { Connector, Part, PartType } from '@web/apps/types';
import { valueToLabel } from '@web/common/util.ts';
import Loading from '@web/components/Loading.tsx';
import { useConfiguration } from '@web/queries/admin.ts';

import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const defaultValues = { type: PartType.CONNECTOR };

export const ConnectorsDashboard = () => {
  const { data: config } = useConfiguration();

  if (!config) return <Loading />;

  const columns: CustomColumnDef[] = [
    {
      header: 'Positions',
      renderFn: (part: Part) => {
        const connector = part as Connector;
        return <Text>{connector.insertArrangement?.cavities.length}</Text>;
      },
    },
    {
      header: 'Gender',
      renderFn: (part: Part) => {
        const connector = part as Connector;
        return <Text>{valueToLabel(connector.gender, config.parts.connector.genders)}</Text>;
      },
    },
  ];

  return (
    <PartsLibraryDashboard
      title="Connectors"
      addPartButtonLabel="Connector"
      routeName="connectors"
      columnDefs={columns}
      defaultValues={defaultValues}
      partType={PartType.CONNECTOR}
    />
  );
};
