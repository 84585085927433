class AuthService {
  private _auth0Client: any;

  setClient(client: any) {
    this._auth0Client = client;
  }

  async getAccessToken(): Promise<string> {
    if (import.meta.env.MODE === 'test') {
      return 'mock-access-token';
    }

    if (!this._auth0Client) {
      throw new Error('Cannot get access token because Auth0 client is not set');
    }

    return (await this._auth0Client.getAccessToken()) as string;
  }
}

export const authService = new AuthService();
