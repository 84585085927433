import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuItemProps,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';
import { isEmpty } from 'lodash';
import { forwardRef, useMemo } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';

import {
  useCreateDesignPartMutation,
  useDeleteDesignPartMutation,
  useUpdateDesignPartMutation,
} from '../../../../api/design-parts-api';
import { useDesign } from '../../../../hooks/useDesign';
import { useDesignParts } from '../../../../hooks/useDesignParts';
import { useDesignToast } from '../../../../hooks/useDesignToast';
import { generateDesignPartNameFromDesignPart } from '../../../../utils/generateDesignPartName';
import { usePartSearchActions } from '../../hooks/usePartSearchActions';
import AcceptChangesModal from '../AcceptChangesModal';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  part: DesignPart;
  canRename?: boolean;
  onRenamePart?: () => void;
}

export const PartMenu = ({ isOpen, onOpen, onClose, part, canRename, onRenamePart }: Props) => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { designId, isViewOnly } = useDesign();
  const { designParts, isLoading: isDesignPartsLoading, error: designPartsError } = useDesignParts();
  const { showSuccessToast } = useDesignToast();

  const includedAccessory = part.includedAccessory;
  const partId = part.partData.id;
  const designPartId = part.id;
  const hasDiff = !isEmpty(part.partDataDiff);

  const duplicateDesignPartName = useMemo(
    () => generateDesignPartNameFromDesignPart(designParts, part),
    [designParts, part],
  );

  const { handleReplacePart } = usePartSearchActions();

  const { mutate: deleteDesignPart } = useDeleteDesignPartMutation();
  const { mutate: addDesignPart } = useCreateDesignPartMutation(() => {
    showSuccessToast('Part Duplicated', 'The part was successfully added to the design.');
  });
  const { mutate: updateDesignPart } = useUpdateDesignPartMutation();

  const handleDuplicatePart = () => addDesignPart({ designId, data: { name: duplicateDesignPartName, partId } });
  const handleUpdatePart = () => {
    updateDesignPart({ designId, partId: designPartId });
    onModalClose();
  };

  if (isViewOnly) {
    return null;
  }

  return (
    <>
      <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          display="flex"
          variant="unstyled"
          size="sm"
          justifyContent="center"
          icon={<SlOptionsVertical />}
          bg="transparent"
          opacity={0}
          _hover={{ color: 'blue.500' }}
          _groupHover={{ opacity: 1 }}
          onClick={(event) => event.stopPropagation()}
          minW={4}
        />
        <MenuList fontSize="sm">
          {canRename && <MenuItem onClick={onRenamePart}>Rename</MenuItem>}
          <Tooltip isDisabled={!includedAccessory} label="This part is included with the parent component">
            <MenuItem
              py={1}
              isDisabled={includedAccessory}
              onClick={() => deleteDesignPart({ designId, partId: designPartId })}
            >
              Remove
            </MenuItem>
          </Tooltip>
          <Tooltip isDisabled={!includedAccessory} label={'This part is included with the parent component'}>
            <MenuItem isDisabled={includedAccessory} onClick={() => handleReplacePart(part)}>
              Replace
            </MenuItem>
          </Tooltip>
          {!isDesignPartsLoading && !designPartsError && (
            <MenuItem isDisabled={includedAccessory} onClick={handleDuplicatePart}>
              Duplicate as {duplicateDesignPartName}
            </MenuItem>
          )}
          {hasDiff && (
            <MenuItem isDisabled={includedAccessory} onClick={onModalOpen} gap={2}>
              <Box boxSize={2} borderRadius="50%" bgColor="orange.400" />
              <Text>Update Part Data</Text>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <AcceptChangesModal isOpen={isModalOpen} onClose={onModalClose} part={part} onAccept={handleUpdatePart} />
    </>
  );
};

const MenuItem = forwardRef(function MenuItem(props: MenuItemProps, ref) {
  return <ChakraMenuItem ref={ref} px={2} py={1} {...props} />;
});
