import { Box, FormControl, FormLabel, Radio, RadioGroup, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { doesUserHaveRole, hasPermission, Permission, Role } from '@web/apps/types';
import { useAdminTenants, useConfiguration } from '@web/queries/admin.ts';
import { useCurrentUser } from '@web/queries/users.ts';
import { SingleValue } from 'chakra-react-select';
import { E164Number } from 'libphonenumber-js/core';
import { first, startCase } from 'lodash';
import { useState } from 'react';
import { Control, Controller, FieldValue, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';

import SubmitButton from '../../../components/buttons/SubmitButton';
import CheckboxInput from '../../../components/form/CheckboxInput';
import EmailInput from '../../../components/form/EmailInput';
import ErrorText from '../../../components/form/ErrorText.tsx';
import PhoneInput from '../../../components/form/PhoneInput';
import TextInput from '../../../components/form/TextInput';
import TypeAheadSelectInput from '../../../components/form/TypeAheadSelectInput';
import WarningBox from '../../../components/WarningBox';

interface Props {
  isNew?: boolean; // are we inviting a new user or editing an existing one
  onSubmit: () => void;
  register: UseFormRegister<FieldValue<any>>;
  selectedTenant?: { label: string; value: string };
  control: Control<any>;
  isSubmitting: boolean;
  isValid: boolean;
  phone: E164Number;
  setPhone: (value: E164Number) => void;
  setValue: UseFormSetValue<any>;
}

const UserForm = ({
  isNew,
  selectedTenant,
  onSubmit,
  register,
  control,
  isSubmitting,
  isValid,
  phone,
  setPhone,
  setValue: setFormValue,
}: Props) => {
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [tenant, setTenant] = useState<SingleValue<{ label: string; value: string }>>(selectedTenant ?? null);
  const userPermissions = useWatch({ control, name: 'permissions' });
  const { data: currentUser } = useCurrentUser();
  const { data: config } = useConfiguration();
  const { isLoading, data: tenants } = useAdminTenants('1', query);
  const data = tenants ?? { data: [], meta: { totalCount: 0 } };

  const updatePermissionsForRole = (role: string) => {
    if (!role || !config?.permissions) return;

    Object.values(config.permissions).forEach((permissions) => {
      Object.entries(permissions).forEach(([permission, { roles }]) => {
        setFormValue(`permissions.${permission}`, roles.includes(role));
      });
    });
  };

  const options = isLoading
    ? [{ value: 'loading', label: 'Loading…' }]
    : data.data.map((tenant: { id: string; name: string }) => ({
        value: tenant.id,
        label: tenant.name,
      }));

  const handleBlur = () => {
    setError(tenant?.label.trim().length ? '' : 'Tenant required');
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <TextInput label="Name *" {...register('name', { required: true })} />
        {!isNew && <TextInput label="Auth0 ID" hint="E.g. auth0|000000000000000000000000" {...register('auth0Id')} />}
        <EmailInput label="Email *" registerFieldName="email" register={register} />
        <PhoneInput label="Phone" name="phone" value={phone} onChange={setPhone} />
        <FormControl>
          <FormLabel>{'Tenant * '}</FormLabel>
          <Controller
            name="tenantId"
            control={control}
            rules={{ required: true, onBlur: handleBlur }}
            render={({ field: { onBlur, onChange, ref } }) => {
              const handleChange = (selectedOption?: SingleValue<{ label: string; value: string }>) => {
                onChange(selectedOption?.value ?? null);
                setError('');
                setTenant(selectedOption ?? { label: '', value: '' });
              };
              return (
                <TypeAheadSelectInput
                  options={options}
                  onChange={handleChange}
                  querySetter={setQuery}
                  value={tenant}
                  noOptionsMessage={() => 'No matching tenants'}
                  onBlur={onBlur}
                  placeholder="Select a tenant…"
                  ref={ref}
                />
              );
            }}
          />
          <ErrorText>{error}</ErrorText>
        </FormControl>
        {!isNew && doesUserHaveRole(currentUser, Role.ADMIN) && (
          <WarningBox text="The following settings are DANGEROUS since they grant access to ALL customer data! DO NOT set these unless (1) the user is a Senra employee and (2) you know what you are doing.">
            <Controller
              name="roles"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  onChange={(nextValue) => {
                    onChange([nextValue]);
                    updatePermissionsForRole(nextValue);
                  }}
                  value={first(value)}
                >
                  <Stack>
                    <Radio value={Role.ADMIN}>Admin User (user can view all orders, manage users & tenants)</Radio>
                    <Radio value={Role.MANUFACTURING_ENGINEER}>
                      Manufacturing Engineer (user can be assigned to orders as an owner)
                    </Radio>
                    <Radio value={Role.TECHNICIAN}>Technician (harness technician for the MES system)</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </WarningBox>
        )}
        {config && hasPermission(currentUser, Permission.MANAGE_USERS) && (
          <SimpleGrid columns={3} spacing={10}>
            {Object.entries(config.permissions).map(([category, permissions]) => (
              <Box key={category} borderWidth={1} borderRadius="md" p={5}>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                  {startCase(category)}
                </Text>
                <Stack>
                  {Object.entries(permissions).map(([permission, { description }]) => (
                    <Box key={`${permission}-${userPermissions?.[permission]}`}>
                      <CheckboxInput
                        label={
                          <Stack spacing={0}>
                            <Text fontWeight="semibold">{startCase(permission)}</Text>
                            <Text fontSize="xs">{description}</Text>
                          </Stack>
                        }
                        {...register(`permissions.${permission}`)}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        )}
        <Box>
          <SubmitButton loading={isSubmitting} disabled={!isValid} />
        </Box>
      </Stack>
    </form>
  );
};

export default UserForm;
