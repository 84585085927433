import { Flex, Text } from '@chakra-ui/react';
import FieldValueList from '@web/apps/Design/components/FieldValueList.tsx';
import { usePartData } from '@web/apps/Design/hooks/usePartData.tsx';
import { useSelectedDesignPart } from '@web/apps/Design/hooks/useSelectedDesignPart.tsx';

export const DetailsView = () => {
  const selectedDesignPart = useSelectedDesignPart();
  const data = usePartData(selectedDesignPart);

  if (!selectedDesignPart) {
    return <Text p={8}>Select a valid target to show details.</Text>;
  }

  return (
    <Flex w="full" flexDirection="column" overflow="auto">
      <FieldValueList data={data} p={4} />
    </Flex>
  );
};
