import { Grid, Spacer, VStack } from '@chakra-ui/react';
import { Schematic } from '@web/apps/types';
import { Node, NodeProps } from '@xyflow/react';

import { Cavities } from './Cavities.tsx';
import { PartLabel } from './PartLabel.tsx';
import { Paths } from './Paths.tsx';

export type SchematicNodeData = {
  schematic: Schematic.SchematicData;
  width: number;
};

export type SchematicNodeType = Node<SchematicNodeData>;

export const SchematicNode = ({ data }: NodeProps<SchematicNodeType>) => {
  const {
    schematic: { sourcePart, destinations },
    width,
  } = data;

  return (
    <VStack w={width} spacing={0} p={8}>
      {destinations.map((destinationGroup, index) => {
        const { part } = destinationGroup;
        const isFlyingLead = !part;
        const isSamePart = !isFlyingLead && sourcePart.id === part.id;

        return (
          <Grid key={part ? part.id : window.crypto.randomUUID()} templateRows="auto 1fr" alignSelf="stretch">
            {/* Labels Row */}
            <Grid templateColumns="auto 1fr auto" alignItems="stretch">
              <PartLabel part={index === 0 ? sourcePart : null} alignItems="flex-start" />
              <Spacer />
              <PartLabel
                part={part}
                alignItems="flex-end"
                visibility={isFlyingLead || isSamePart ? 'hidden' : 'visible'}
              />
            </Grid>

            {/* Schematic Content Row */}
            <Grid templateColumns="auto 1fr auto" gap={10}>
              <Cavities
                type="source"
                destinationGroup={destinationGroup}
                borderTopRadius={index === 0 ? 'md' : 'none'}
                borderBottomRadius={index === destinations.length - 1 ? 'md' : 'none'}
              />
              <Paths destinationGroup={destinationGroup} />
              <Cavities
                type="destination"
                destinationGroup={destinationGroup}
                visibility={isFlyingLead || isSamePart ? 'hidden' : 'visible'}
              />
            </Grid>
          </Grid>
        );
      })}
    </VStack>
  );
};
