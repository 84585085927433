import { Graph } from '../../../../types/reactFlow.ts';

export interface Operations<T extends GraphOperation> {
  execute(graph: Graph, params: T): Graph;
}

// Base interface for all operation params
export interface BaseOperation {
  type: string;
  params: Record<string, any>;
}

// Define a union type for all operations
// This will be populated by the individual operation files
export type GraphOperation = BaseOperation;

// Define a type for the operation class
export type OperationClass<T extends GraphOperation> = new () => Operations<T>;

// Create a mapping from operation type to corresponding class
export const operationsMap: Record<string, OperationClass<any>> = {};

// Function to register an operation implementation
export function registerOperation<T extends GraphOperation>(type: T['type'], operationClass: OperationClass<T>): void {
  operationsMap[type] = operationClass;
}
