import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: 0,
  y: 16,
};

export const originalWidth = 24;
export const originalHeight = 32;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const Pigtail = ({ color = defaultLayoutConfig.nodeColor, angle = 0 }: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0C1.34315 0 0 1.34315 0 3V16V29C0 30.6569 1.34315 32 3 32H13.5271C14.8929 32 16 30.8929 16 29.5271V27.3172C20.6608 25.6698 24 21.2249 24 16C24 10.7751 20.6608 6.33017 16 4.68282V3C16 1.34315 14.6569 0 13 0H3ZM16 24.5405V16V8V7.4595C19.2074 8.96432 21.4286 12.2228 21.4286 16C21.4286 19.7772 19.2074 23.0357 16 24.5405Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
