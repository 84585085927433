import { EdgeProps } from '@xyflow/react';

import { defaultSchematicConfig } from '../../config.ts';
import { getTwistingGroupPath } from '../../utils/paths/getTwistingGroupPath.ts';

export const TwistingGroup = (props: EdgeProps) => {
  const edgeStyle = {
    stroke: defaultSchematicConfig.conductorColor,
    strokeWidth: 1,
    fill: defaultSchematicConfig.style.backgroundColor,
  };

  const [edgePath] = getTwistingGroupPath(props);

  return <path id={props.id} d={edgePath} {...edgeStyle} />;
};
