import { Button, HStack } from '@chakra-ui/react';
import { Part, PartStatus } from '@web/apps/types';
import { useController, useFormContext } from 'react-hook-form';

import { Mode } from '../partsLibraryTypes';

interface Props {
  mode: Mode;
  setMode?: (mode: Mode) => void;
  canUpdatePart: boolean;
  onDeletePart?: () => Promise<void>;
}

export const PartFieldsActionBar = ({ mode, setMode, canUpdatePart, onDeletePart }: Props) => {
  const { control, reset } = useFormContext<Part>();
  const {
    field: { value: partStatus, onChange: onChangePartStatus },
  } = useController({ name: 'status', control });

  const handleDiscard = () => {
    reset();
    setMode?.(Mode.READ);
  };

  if (mode === Mode.READ && canUpdatePart) {
    return (
      <HStack justifyContent="flex-end">
        <Button colorScheme="blue" onClick={() => setMode?.(Mode.UPDATE)}>
          Edit
        </Button>
        {onDeletePart && (
          <Button colorScheme="red" onClick={onDeletePart}>
            Delete Part
          </Button>
        )}
      </HStack>
    );
  } else if (mode === Mode.CREATE) {
    return (
      <HStack justifyContent="flex-end">
        <Button
          type="submit"
          colorScheme="green"
          variant="outline"
          onClick={() => onChangePartStatus(PartStatus.DRAFT)}
        >
          Save as Draft
        </Button>
        <Button type="submit" colorScheme="green" onClick={() => onChangePartStatus(PartStatus.RELEASED)}>
          Save and Release
        </Button>
      </HStack>
    );
  } else if (mode === Mode.UPDATE && canUpdatePart) {
    return (
      <HStack justifyContent="flex-end">
        <Button colorScheme="yellow" onClick={handleDiscard}>
          Discard Changes
        </Button>
        {partStatus === PartStatus.DRAFT && (
          <Button
            type="submit"
            colorScheme="green"
            variant="outline"
            onClick={() => onChangePartStatus(PartStatus.DRAFT)}
          >
            Save
          </Button>
        )}
        <Button type="submit" colorScheme="green" onClick={() => onChangePartStatus(PartStatus.RELEASED)}>
          {partStatus === PartStatus.RELEASED ? 'Save' : 'Save and Release'}
        </Button>
      </HStack>
    );
  }
};
