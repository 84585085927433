import { Button, ListItem, OrderedList, Select, Stack, Text } from '@chakra-ui/react';
import { doRequest } from '@web/apps/Design/api/request-helpers';
import { PartType } from '@web/apps/types';
import { getUrl } from '@web/common/api';
import S3FileInput from '@web/components/form/S3FileInput';
import Title from '@web/components/Title';
import { useCurrentUser } from '@web/queries/users';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const postDocument = async (document: {
  s3Key: string;
  documentableId: string;
  documentableType: string;
  type: string;
  partType: PartType;
}) => {
  return await doRequest('POST', getUrl('/api/v1/documents'), JSON.stringify(document));
};

interface FormValues extends FieldValues {
  tenantId: string;
  partType: PartType;
  document: { name: string; s3Key: string };
}

const partTypeOptions = [
  { value: PartType.CONNECTOR, label: PartType.CONNECTOR },
  { value: PartType.WIRE, label: PartType.WIRE },
];

export const BulkUploadDashboard = () => {
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormValues>();

  if (isUserLoading || !currentUser) {
    return null;
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await postDocument({
      s3Key: data.document.s3Key,
      documentableId: currentUser.tenant.id,
      documentableType: 'Tenant',
      type: 'PartSpreadsheet',
      partType: data.partType,
    })
      .then(() => toast.success('Successfully uploaded part spreadsheet'))
      .catch((error) => toast.error(error.message))
      .finally(() => reset());
  };

  return (
    <Stack direction="column" w="full" h="full" p={8} gap={8}>
      <Title title="Bulk Upload" aria-label="bulk upload parts" />
      <Text>
        Add or update many parts in the library. The system will update existing records for matching part numbers.
        Spreadsheets are validated on the back end before processing. Please ensure your spreadsheet is formatted
        correctly.
      </Text>
      <OrderedList>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={8} w="xl">
            <Stack>
              <ListItem>
                <Stack>
                  <Text>Select part type for upload</Text>
                  <Select {...register('partType', { required: true })}>
                    {partTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </ListItem>
              <ListItem>
                <S3FileInput
                  control={control}
                  button={<Button>Select Excel Spreadsheet</Button>}
                  multiple={false}
                  singleFile={true}
                  direction={'column'}
                  showDeleteButton={true}
                  {...register('document', { required: true })}
                />
              </ListItem>
            </Stack>
            <Button type="submit" colorScheme="blue" isDisabled={!isValid || isSubmitting}>
              Upsert Parts
            </Button>
          </Stack>
        </form>
      </OrderedList>
    </Stack>
  );
};
