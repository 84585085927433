import { BuildNote, UUID } from '@web/apps/types';
import { Edge, Node } from '@xyflow/react';
import { flatMap, keyBy, map } from 'lodash';

import { Graph } from '../../../../../types/reactFlow.ts';
import { isDesignPartNode, isNoteGroupNode, isSegmentEdge } from '../../types';
import { removeNodesFromGraph } from '../../utils/graph';
import { defaultNoteNestedPosition, generateNewBuildNotesAndEdges } from '../../utils/notes';
import { BaseOperation, Operations, registerOperation } from '../Operations';

// Removes note nodes that point to non-existent layout nodes
const removeOrphanedNodes = (graph: Graph) => {
  const { nodes, edges } = graph;

  const layoutElementIdsSet = new Set(map([...nodes, ...edges], 'id'));
  const noteNodesWithoutElementNodes = nodes.filter(
    (node) => isNoteGroupNode(node) && node.data.targetElementId && !layoutElementIdsSet.has(node.data.targetElementId),
  );

  return removeNodesFromGraph(graph, noteNodesWithoutElementNodes);
};

// Removes note nodes that have no target and have no flag notes
const removeFloatingNodesWithoutNotes = (graph: Graph, flagNoteGroupIds: Set<UUID>) => {
  const { nodes } = graph;

  const noteNodesToRemove = nodes.filter(
    (node) => isNoteGroupNode(node) && !node.data.targetElementId && !flagNoteGroupIds.has(node.id),
  );

  return removeNodesFromGraph(graph, noteNodesToRemove);
};

// Updates note groups nodes to now have parentId set
const addMissingParentIds = (nodes: Node[]) => {
  const nodesById = keyBy(nodes, 'id');

  const nodesWithParentId = nodes.map((node) => {
    if (isNoteGroupNode(node)) {
      const targetElementId = node.data.targetElementId;

      // If there is a target element id pointed at a node without a parentId, set the parent id and default the position.
      if (targetElementId && nodesById[targetElementId] && !node.parentId) {
        return { ...node, parentId: targetElementId, position: defaultNoteNestedPosition };
      }
    }

    return node;
  });

  return nodesWithParentId;
};

// Adds or removes note nodes for layout elements
const updateNotesOnLayoutElements = (graph: Graph, flagNoteGroupIds: Set<UUID>) => {
  const { nodes, edges } = graph;
  const noteNodesWithTargetElement = nodes.filter((node) => isNoteGroupNode(node) && node.data.targetElementId);
  const noteNodesByTargetElementId = keyBy(noteNodesWithTargetElement, 'data.targetElementId');

  const nodesToAddNotesTo: Node[] = [];
  const edgesToAddNotesTo: Edge[] = [];
  const noteNodesToRemove: Node[] = [];

  const noteNodeHasFlagNotes = (noteNode: Node) => noteNode?.id && flagNoteGroupIds.has(noteNode.id);

  // For all nodes that can have notes, add or remove note group nodes as necessary.
  const notableNodes = nodes.filter((node) => !isNoteGroupNode(node));
  notableNodes.forEach((node) => {
    const currentNoteNode = noteNodesByTargetElementId[node.id];
    const shouldShowNotes = isDesignPartNode(node) || noteNodeHasFlagNotes(currentNoteNode);

    if (!currentNoteNode && shouldShowNotes) {
      nodesToAddNotesTo.push(node);
    } else if (currentNoteNode && !shouldShowNotes) {
      noteNodesToRemove.push(currentNoteNode);
    }
  });

  // For all edges that can have notes, adding or removing note group notes as necessary.
  const notableEdges = edges.filter(isSegmentEdge);
  notableEdges.forEach((edge) => {
    const hasOverwrap = edge.data.overwraps.length > 0;
    const currentNoteNode = noteNodesByTargetElementId[edge.id];
    const shouldShowNotes = hasOverwrap || noteNodeHasFlagNotes(currentNoteNode);

    if (!currentNoteNode && shouldShowNotes) {
      edgesToAddNotesTo.push(edge);
    } else if (currentNoteNode && !shouldShowNotes) {
      noteNodesToRemove.push(currentNoteNode);
    }
  });

  const { nodes: filteredNodes, edges: filteredEdges } = removeNodesFromGraph(graph, noteNodesToRemove);
  const { nodes: newNodes, edges: newEdges } = generateNewBuildNotesAndEdges(
    nodes,
    nodesToAddNotesTo,
    edgesToAddNotesTo,
  );

  // Updates existing note group nodes with targetElementId to now have parentId set
  const filteredNodesWithParentId = addMissingParentIds(filteredNodes);

  return {
    nodes: [...filteredNodesWithParentId, ...newNodes],
    edges: [...filteredEdges, ...newEdges],
  };
};

export interface UpdateBuildNotesOperation extends BaseOperation {
  type: 'UpdateBuildNotes';
  params: {
    flagNotes: BuildNote[];
  };
}

/**
 * Adds any missing and removes any unnecessary note groups from the layout.
 */
export class UpdateBuildNotes implements Operations<UpdateBuildNotesOperation> {
  // Execute the operation
  execute(graph: Graph, operation: UpdateBuildNotesOperation): Graph {
    const {
      params: { flagNotes },
    } = operation;

    const flagNoteGroupIds = new Set(flatMap(flagNotes, 'noteGroupNodeIds'));

    const flagNoteSteps: ((graph: Graph) => Graph)[] = [
      // Step 1: Remove note nodes for non-existent layout nodes
      removeOrphanedNodes,

      // Step 2: Remove floating nodes without notes
      (graph) => removeFloatingNodesWithoutNotes(graph, flagNoteGroupIds),

      // Step 3: Add or remove note nodes that have layout elements
      (graph) => updateNotesOnLayoutElements(graph, flagNoteGroupIds),
    ];

    // Iterate over the steps one by one and return the result.
    return flagNoteSteps.reduce((currentGraph, step) => step(currentGraph), graph);
  }
}

registerOperation('UpdateBuildNotes', UpdateBuildNotes);
