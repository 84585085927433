import { AccordionButton, Flex, HStack, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { size } from 'lodash';
import { MouseEvent, useState } from 'react';
import { IoCaretDownOutline, IoCaretUpOutline } from 'react-icons/io5';

import { DesignPart } from '../../../../../../types';
import { SELECTION_TYPES, useDesign } from '../../../../../hooks/useDesign';
import { EditablePartName } from '../EditablePartName';
import { PartDiffIndicator } from '../PartDiffIndicator';
import { PartMenu } from '../PartMenu';
import { PartNumberDisplay } from '../PartNumberDisplay';

const preventSelect = (event: MouseEvent<HTMLButtonElement>) => event.stopPropagation();

interface Props {
  part: DesignPart;
  isExpanded: boolean;
}

export const ConnectorListItemButton = ({ part, isExpanded }: Props) => {
  const { isSelected, setSelection } = useDesign();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();
  const [isEditingName, setIsEditingName] = useState(false);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onMenuOpen();
  };

  const selected = isSelected('designPart', part.id);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!selected) {
      event.stopPropagation();
      setSelection(SELECTION_TYPES.DESIGN_PART, part.id);
    }
  };

  const accessoryCount = size(part.accessoryParts);
  const accessoryLabel = accessoryCount === 1 ? 'accessory' : 'accessories';

  return (
    <HStack
      className="group"
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      pr={3}
      bgColor={selected ? 'blue.100' : 'transparent'}
      _hover={selected ? undefined : { bgColor: 'gray.200' }}
      fontSize="sm"
      whiteSpace="nowrap"
      cursor="pointer"
    >
      <Flex flex={1} gap={1} minW={0}>
        <AccordionButton onClick={preventSelect} p={2} width="fit-content">
          <Icon boxSize={3} as={isExpanded ? IoCaretUpOutline : IoCaretDownOutline} />
        </AccordionButton>
        <Stack gap={0} py="px" minW={0}>
          <HStack lineHeight={5}>
            <EditablePartName
              isEditing={isEditingName}
              setIsEditing={setIsEditingName}
              partName={part.name}
              partId={part.id}
            />
            <PartNumberDisplay partNumber={part.partData.partNumber} />
          </HStack>
          <AccordionButton
            onClick={preventSelect}
            width="fit-content"
            p={0}
            fontSize="xs"
            color="blue.700"
            _hover={{ textDecoration: 'underline' }}
          >{`${accessoryCount} ${accessoryLabel}`}</AccordionButton>
        </Stack>
      </Flex>
      <PartDiffIndicator part={part} />
      <PartMenu
        isOpen={isMenuOpen}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        part={part}
        canRename
        onRenamePart={() => setIsEditingName(true)}
      />
    </HStack>
  );
};
