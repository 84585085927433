import { defaultLayoutConfig } from '@web/apps/Design';
import { Edge, Node, useReactFlow } from '@xyflow/react';
import { MouseEvent, useState } from 'react';

import { useMergeBreakoutPointsMutation, useUpdateLayoutNodeMutation } from '../../../api/layout-nodes-api.ts';
import { SELECTION_TYPES, useDesign } from '../../../hooks/useDesign.tsx';
import { isBreakoutPointNode, isDesignPartNode, isSegmentEdge } from '../relational_types.ts';

/**
 * Hook to manage ReactFlow events and context menus.
 */
export const useRelationalLayout = () => {
  // Settings for the layout
  const [config, setConfig] = useState(defaultLayoutConfig);

  const { designId, isViewOnly, setSelection, clearSelections } = useDesign();
  const { getNodes, getEdges, getIntersectingNodes } = useReactFlow();
  const { mutate: updateLayoutNode } = useUpdateLayoutNodeMutation();
  const { mutate: mergeBreakoutPoints } = useMergeBreakoutPointsMutation();

  const onPaneClick = (event: MouseEvent) => {
    event.preventDefault();
    console.debug('Pane clicked', { nodes: getNodes(), edges: getEdges() });

    clearSelections();
  };

  const onNodeClick = (_event: MouseEvent, node: Node) => {
    console.debug('Node clicked:', node);
    setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, node.id);
    if (isDesignPartNode(node)) {
      setSelection(SELECTION_TYPES.DESIGN_PART, node.data.designPartId);
    }
  };

  const onEdgeClick = (_event: MouseEvent, edge: Edge) => {
    console.debug('Edge clicked:', edge);
    if (isSegmentEdge(edge)) {
      setSelection(SELECTION_TYPES.LAYOUT_ELEMENT, edge.id, true);
    }
  };

  const onNodeDragStop = (_event: MouseEvent, node: Node) => {
    if (isViewOnly) {
      return;
    }

    const { id: nodeId, position } = node;

    if (isBreakoutPointNode(node)) {
      const intersectingNodes = getIntersectingNodes(node, true);
      const intersectingNode = intersectingNodes[0];

      const canMerge = intersectingNodes.length === 1 && isBreakoutPointNode(intersectingNode);

      if (canMerge) {
        mergeBreakoutPoints({ designId, breakoutPointId: nodeId, toNodeId: intersectingNode.id });
        return;
      }
    }

    updateLayoutNode({ designId, layoutNodeId: nodeId, data: position });
  };

  return {
    config,
    setConfig,
    onPaneClick,
    onNodeClick,
    onEdgeClick,
    onNodeDragStop,
  };
};
