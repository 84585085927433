import { AlternateDesignPart, Design, DesignPart, isOverwrapDesignPart, PartType, UUID } from '@web/apps/types';

import { useDesignQuery } from '../api/designs-api';
import { useDesignId } from './useDesignId';

// Initialize designParts and alternateDesignParts
const emptyDesignParts = [] as DesignPart[];
const emptyAlternateDesignParts = [] as AlternateDesignPart[];
const emptyDesignPartsMap = new Map();
const emptyDesignPartsTypeMap = new Map();
const emptyAlternateDesignPartsMap = new Map();
const emptyPartNumberOverwrapMap = new Map();

// Define the select function
export const selectDesignParts = (design: Design | null) => {
  const designParts = design?.designParts || emptyDesignParts;
  const alternateDesignParts = design?.alternateDesignParts || emptyAlternateDesignParts;

  const designPartsMap = new Map<UUID, DesignPart>();
  designParts.forEach((part) => {
    designPartsMap.set(part.id, part);
    part.accessoryParts?.forEach((accessoryPart) => designPartsMap.set(accessoryPart.id, accessoryPart));
  });

  const designPartsTypeMap = new Map<PartType, DesignPart[]>();
  designParts.forEach((designPart) => {
    const type = designPart.partData.type;
    if (!designPartsTypeMap.has(type)) {
      designPartsTypeMap.set(type, []);
    }
    designPartsTypeMap.get(type)?.push(designPart);
  });

  const alternateDesignPartsMap = new Map<UUID, AlternateDesignPart>();
  alternateDesignParts.forEach((alternatePart) => alternateDesignPartsMap.set(alternatePart.id, alternatePart));

  const partNumberOverwrapMap = new Map<string, DesignPart>();
  designParts.forEach((part) => {
    if (isOverwrapDesignPart(part)) {
      partNumberOverwrapMap.set(part.partData.partNumber, part);
    }
  });

  return {
    designParts,
    alternateDesignParts,
    designPartsMap,
    designPartsTypeMap,
    alternateDesignPartsMap,
    partNumberOverwrapMap,
  };
};

export const useDesignPartsData = () => {
  const designId = useDesignId();

  // Fetch designParts and alternateDesignParts
  const { data, isLoading, isSuccess, error } = useDesignQuery(designId, selectDesignParts);

  return {
    designParts: data?.designParts || emptyDesignParts,
    alternateDesignParts: data?.alternateDesignParts || emptyAlternateDesignParts,
    designPartsMap: data?.designPartsMap || emptyDesignPartsMap,
    designPartsTypeMap: data?.designPartsTypeMap || emptyDesignPartsTypeMap,
    alternateDesignPartsMap: data?.alternateDesignPartsMap || emptyAlternateDesignPartsMap,
    partNumberOverwrapMap: data?.partNumberOverwrapMap || emptyPartNumberOverwrapMap,
    isLoading,
    isSuccess,
    error,
  };
};
