import { Schematic, UUID } from '@web/apps/types';
import { Edge } from '@xyflow/react';

import { EdgeType } from '../types/edges.ts';
import { ConductorGroup } from '../types/groups.ts';
import { createEdge } from '../utils/helpers.ts';
import { getConductorGroupHandle } from './handles.ts';

export const generateGroupedEdges = (
  data: Schematic.SchematicData,
  groupSelector: (conductor: Schematic.Conductor) => string[],
  groupedEdgeType: ConductorGroup,
): Edge[] => {
  const groupsMap = collectGroups(data, groupSelector);
  return createEdgesFromGroups(groupsMap, groupedEdgeType);
};

const collectGroups = (
  data: Schematic.SchematicData,
  groupSelector: (conductor: Schematic.Conductor) => string[],
): Map<string, { conductorIds: UUID[]; shielding: Schematic.ShieldingType }> => {
  const groupsMap = new Map<string, { conductorIds: UUID[]; shielding: Schematic.ShieldingType }>();

  data.destinations.forEach((destination) => {
    destination.paths.forEach((path) => {
      path.conductors.forEach((conductor) => {
        const group = groupSelector(conductor);
        if (group.length === 0) return;

        const groupKey = group.join(',');

        if (!groupsMap.has(groupKey)) {
          groupsMap.set(groupKey, { conductorIds: [...group], shielding: conductor.shielding });
        }
      });
    });
  });

  return groupsMap;
};

const createEdgesFromGroups = (
  groupsMap: Map<string, { conductorIds: UUID[]; shielding: Schematic.ShieldingType }>,
  groupedEdgeType: ConductorGroup,
): Edge[] => {
  const edges: Edge[] = [];

  groupsMap.forEach((group) => {
    const edgeType: EdgeType =
      groupedEdgeType === 'twisting'
        ? 'TwistingGroup'
        : group.shielding === 'Single'
          ? 'SingleShieldGroup'
          : 'DoubleShieldGroup';

    const sourceConductor = group.conductorIds[0];
    const targetConductor = group.conductorIds[group.conductorIds.length - 1] ?? sourceConductor;

    edges.push(...createSymbolEdges(edgeType, groupedEdgeType, sourceConductor, targetConductor));
  });

  return edges;
};

const createSymbolEdges = (
  edgeType: EdgeType,
  groupedEdgeType: ConductorGroup,
  sourceConductor: UUID,
  targetConductor: UUID,
): Edge[] => {
  return [
    createEdge(
      edgeType,
      getConductorGroupHandle(groupedEdgeType, sourceConductor, 'topLeft'),
      getConductorGroupHandle(groupedEdgeType, targetConductor, 'bottomLeft'),
    ),
    createEdge(
      edgeType,
      getConductorGroupHandle(groupedEdgeType, sourceConductor, 'topRight'),
      getConductorGroupHandle(groupedEdgeType, targetConductor, 'bottomRight'),
    ),
  ];
};
