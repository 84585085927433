import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -20,
  y: -8,
};

const originalWidth = 40;
const originalHeight = 32;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const RectangularConnector90 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M12 13C12.5523 13 13 12.5523 13 12L13 4C13 3.44772 12.5523 3 12 3L3.45822e-07 3L2.26573e-07 13L12 13Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 4L12.5 12C12.5 12.2761 12.2761 12.5 12 12.5L0.5 12.5L0.5 3.5L12 3.5C12.2761 3.5 12.5 3.72386 12.5 4ZM13 12C13 12.5523 12.5523 13 12 13L2.26573e-07 13L3.45822e-07 3L12 3C12.5523 3 13 3.44772 13 4L13 12Z"
          fill={outlineColor}
        />
        <path d="M36 17L36 -4.76995e-08L12 -3.33897e-07L12 17L36 17Z" fill="#1A202C" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5 0.5L35.5 16.5L12.5 16.5L12.5 0.5L35.5 0.5ZM36 17L12 17L12 -3.33897e-07L36 -4.76995e-08L36 17Z"
          fill={outlineColor}
        />
        <path d="M40 32L40 18C40 16.8954 39.1046 16 38 16L10 16C8.89543 16 8 16.8954 8 18L8 32L40 32Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5 18L39.5 31.5L8.5 31.5L8.5 18C8.5 17.1716 9.17157 16.5 10 16.5L38 16.5C38.8284 16.5 39.5 17.1716 39.5 18ZM40 32L8 32L8 18C8 16.8954 8.89543 16 10 16L38 16C39.1046 16 40 16.8954 40 18L40 32Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
