import { getInlineType } from '@web/apps/Design/features/Schematic/utils/inlines.ts';
import { Schematic } from '@web/apps/types';
import { Edge } from '@xyflow/react';

import { getDestinationHandle, getInputHandle, getOutputHandle, getSourceHandle } from './handles.ts';
import { createEdge, isLoopback, isShield } from './helpers.ts';

/**
 * Generates edges for a schematic path.
 *
 * @param path - The schematic path to generate edges for
 * @returns Array of Edge objects representing the connections in the path
 */
export const generatePathEdges = (path: Schematic.Path): Edge[] => {
  if (!path || !Array.isArray(path.conductors) || path.conductors.length === 0) {
    console.warn('Invalid path provided to generatePathEdges', path);
    return [];
  }

  return isLoopback(path) ? createLoopbackEdges(path) : createStandardEdges(path);
};

/**
 * Creates edges for a loopback path configuration.
 *
 * @param path - The loopback schematic path
 * @returns Array of Edge objects for the loopback path
 */
const createLoopbackEdges = (path: Schematic.Path): Edge[] => {
  const conductor = path.conductors[0];
  if (!conductor) {
    console.warn('Missing conductor in loopback path', path);
    return [];
  }

  return [
    createEdge('Conductor', getSourceHandle(path), getInputHandle(conductor.name), { conductor, type: 'bend' }),
    createEdge('Conductor', getInputHandle(conductor.name), getOutputHandle(conductor.name), {
      conductor,
      type: 'transition',
    }),
    createEdge('Loopback', getDestinationHandle(path), getOutputHandle(conductor.name), { conductor, type: 'bend' }),
  ];
};

/**
 * Generates edges for a standard path (source → inline1 → inline2 → destination).
 *
 * @param path - The standard schematic path
 * @returns Array of Edge objects for the standard path
 */
const createStandardEdges = (path: Schematic.Path): Edge[] => {
  const { conductors, inlines = [] } = path;
  const points = [path.source, ...inlines, path.destination];

  if (conductors.length !== points.length - 1) {
    console.warn('Mismatch between conductors and connection points in path', {
      conductorsLength: conductors.length,
      pointsLength: points.length,
      path,
    });
    return [];
  }

  const edges: Edge[] = [];

  for (let i = 0; i < points.length - 1; i++) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];
    const conductor = conductors[i];

    if (!conductor) {
      console.warn('Missing conductor at index', { index: i, path });
      continue;
    }

    const sourceHandle = currentPoint
      ? i == 0 && path.source
        ? getSourceHandle(path) // We're starting at the source
        : getOutputHandle(currentPoint.displayName) // We're moving from an inline
      : null;

    const targetHandle = nextPoint
      ? nextPoint.id === path.destination?.id
        ? getDestinationHandle(path) // We've reached the final destination
        : getInputHandle(nextPoint.displayName) // We're moving to the next inline
      : null;

    if (isShield(conductor) && conductor.shieldId) {
      if (sourceHandle) {
        edges.push(
          createEdge('Conductor', sourceHandle, getInputHandle(conductor.shieldId), {
            conductor,
            type: 'shield',
          }),
        );
      }

      if (targetHandle) {
        edges.push(
          createEdge('Conductor', getOutputHandle(conductor.shieldId), targetHandle, {
            conductor,
            type: 'shield',
          }),
        );
      }
    } else {
      // Handle special inline types
      if (sourceHandle && nextPoint && getInlineType(nextPoint, path) === 'source') {
        edges.push(
          createEdge('Conductor', sourceHandle, getInputHandle(nextPoint.displayName), {
            conductor,
            type: 'grouped-with-source',
          }),
        );
      } else if (targetHandle && currentPoint && getInlineType(currentPoint, path) === 'destination') {
        edges.push(
          createEdge('Conductor', getOutputHandle(currentPoint.displayName), targetHandle, {
            conductor,
            type: 'grouped-with-destination',
          }),
        );
      } else {
        // Standard connections through the conductor
        if (sourceHandle) {
          edges.push(
            createEdge('Conductor', sourceHandle, getInputHandle(conductor.name), {
              conductor,
              type: 'bend',
            }),
          );
        }
        if (targetHandle) {
          edges.push(
            createEdge('Conductor', getOutputHandle(conductor.name), targetHandle, {
              conductor,
              type: 'bend',
            }),
          );
        }
        edges.push(
          createEdge('Conductor', getInputHandle(conductor.name), getOutputHandle(conductor.name), {
            conductor,
            type: 'transition',
          }),
        );
      }
    }
  }

  return edges;
};
