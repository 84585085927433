import { Text, Tooltip } from '@chakra-ui/react';

interface Props {
  partNumber: string;
}

export const PartNumberDisplay = ({ partNumber }: Props) => (
  <Tooltip label={partNumber}>
    <Text color="gray.700" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
      {partNumber}
    </Text>
  </Tooltip>
);
