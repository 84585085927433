import { DesignPart, DesignPartLayoutEdge, PartType } from '@web/apps/types';
import { compact, groupBy, map } from 'lodash';
import { useCallback, useMemo } from 'react';

import { useDesignParts } from '../../../../hooks/useDesignParts';

const emptyEdges: DesignPartLayoutEdge[] = [];
const emptyParts: DesignPart[] = [];

export const useLayoutEdgeDesignParts = (designPartLayoutEdges?: DesignPartLayoutEdge[]) => {
  const { getDesignPartById } = useDesignParts();

  const layoutEdgesByType = useMemo(
    () => groupBy(designPartLayoutEdges, (layoutEdge) => getDesignPartById(layoutEdge.designPartId)?.partData.type),
    [designPartLayoutEdges, getDesignPartById],
  );

  const designPartsByType = useMemo(() => {
    const designParts = compact(map(designPartLayoutEdges, (layoutEdge) => getDesignPartById(layoutEdge.designPartId)));
    return groupBy(designParts, (designPart) => designPart.partData.type);
  }, [designPartLayoutEdges, getDesignPartById]);

  const getLayoutEdgesByType = useCallback(
    (type: PartType) => layoutEdgesByType[type] || emptyEdges,
    [layoutEdgesByType],
  );
  const getEdgeDesignPartsByType = useCallback(
    (type: PartType) => designPartsByType[type] || emptyParts,
    [designPartsByType],
  );

  return { getLayoutEdgesByType, getEdgeDesignPartsByType };
};
