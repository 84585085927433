import { Accordion, Stack } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';

import { PartListGroup } from '../PartListGroup';
import { ConnectorListItem } from './ConnectorListItem';

interface Props {
  parts?: DesignPart[];
}

export const ConnectorList = ({ parts }: Props) => {
  if (parts && parts.length > 0) {
    return (
      <PartListGroup label="Connectors" count={parts.length}>
        <Accordion as={Stack} gap={1} allowMultiple>
          {parts.map((part) => (
            <ConnectorListItem key={part.id} part={part} />
          ))}
        </Accordion>
      </PartListGroup>
    );
  }

  return null;
};
