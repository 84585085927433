export const RouteNames = {
  ROOT: '/',
  NOT_FOUND: '/404',
  REGISTER: '/register/:userId',
  SIGN_OUT: '/signout',
  PROFILE: '/profile',
  PARTS: {
    INDEX: '/parts',
    BACKSHELLS: '/parts/backshells',
    CABLES: '/parts/cables',
    CONNECTORS: '/parts/connectors',
    CONTACTS: '/parts/contacts',
    INSERT_ARRANGEMENTS: '/parts/insert-arrangements',
    GENERICS: '/parts/generic',
    LABELS: '/parts/labels',
    OVERWRAPS: '/parts/overwraps',
    PASSIVES: '/parts/passives',
    PIGTAILS: '/parts/pigtails',
    SPLICES: '/parts/splices',
    WIRES: '/parts/wires',
    BULK_UPLOAD: '/parts/bulk-upload',
  },
  TOOLS: {
    INDEX: '/tools',
    TORQUE_ADAPTERS: '/tools/torque-adapters',
    CRIMPERS: '/tools/crimpers',
    POSITIONERS: '/tools/positioners',
    DIE_SETS: '/tools/die-sets',
    TURRET_HEADS: '/tools/turret-heads',
    INSERTIONS_REMOVALS: '/tools/insertions-removals',
  },
  DESIGNS: {
    INDEX: '/',
    DESIGNS: '/designs',
    DESIGN: '/designs/:designId',
    DESIGN_PART: '/designs/:designId/design-parts/:designPartId',
    DESIGN_PARTS: '/designs/:designId/design-parts',
    BOM: '/designs/:designId/bom',
    WIRING_LIST: '/designs/:designId/wiring-list',
    LAYOUT: '/design/:designId/layout',
    SCHEMATIC: '/design/:designId/schematic',
    CUTLIST: '/design/:designId/cutlist',
  },
  TENANTS: {
    INDEX: '/tenants',
    NEW: '/tenants/new',
    EDIT: '/tenants/:tenantId/edit',
  },
  USERS: {
    INDEX: '/users',
    NEW: '/users/new',
    EDIT: '/users/:userId/edit',
  },
};
