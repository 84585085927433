import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { GroupedConductor, NoteGroupBuildNote, UUID } from '@web/apps/types';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { MenuPosition } from '../../../../../../../components/menu/useContextMenuState.ts';
import { useDeleteControlPointMutation } from '../../../../../api/layout-nodes-api.ts';
import { useDesignId } from '../../../../../hooks/useDesignId.tsx';
import { useRelationalUnmergeActions } from '../../../hooks/actions/useRelationalUnmergeActions.tsx';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';

const getGroupedConductorKey = (groupedConductor: GroupedConductor) =>
  `${groupedConductor.sourceDesignPartId}:${groupedConductor.targetDesignPartId}`;

interface Props {
  isOpen: boolean;
  position: MenuPosition;
  closeMenu: () => void;
  layoutNodeId: UUID;
  noteGroupBuildNotes: NoteGroupBuildNote[];
  groupedConductors: GroupedConductor[];
}

export const BreakoutPointContextMenu = ({
  isOpen,
  position,
  closeMenu,
  layoutNodeId,
  noteGroupBuildNotes,
  groupedConductors,
}: Props) => {
  const designId = useDesignId();

  const { mutateAsync: deleteControlPoint } = useDeleteControlPointMutation();

  const handleRemove = () => {
    deleteControlPoint({ designId, controlPointId: layoutNodeId });
    closeMenu();
  };

  const { handleUnmerge, getGroupedConductorName } = useRelationalUnmergeActions(layoutNodeId, closeMenu);

  return (
    <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
      <MenuGroup title="Actions">
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </MenuGroup>
      <MenuDivider />
      {groupedConductors.length > 1 && (
        <MenuGroup title="Unmerge Bundle">
          {groupedConductors.map((groupedConductor) => (
            <MenuItem key={getGroupedConductorKey(groupedConductor)} onClick={() => handleUnmerge(groupedConductor)}>
              {getGroupedConductorName(groupedConductor)}
            </MenuItem>
          ))}
        </MenuGroup>
      )}
      <BuildNoteMenuSection noteGroupBuildNotes={noteGroupBuildNotes} targetId={layoutNodeId} />
    </ContextMenu>
  );
};
