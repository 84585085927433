import { Stack, Table, TableContainer, Tbody, Text } from '@chakra-ui/react';
import { WiringListHead } from '@web/apps/Design/features/WiringList/components/WiringListHead.tsx';
import { WiringListRow } from '@web/apps/Design/features/WiringList/components/WiringListRow/WiringListRow.tsx';
import { useWiringList, WiringListProvider } from '@web/apps/Design/features/WiringList/hooks/useWiringList.tsx';
import { useSelectedDesignPart } from '@web/apps/Design/hooks/useSelectedDesignPart.tsx';
import { isConnectionTarget } from '@web/apps/utils/design.ts';
import { Fragment, useEffect, useRef } from 'react';

const WiringListComponent = () => {
  const selectedDesignPart = useSelectedDesignPart();
  const { activeEditCell, setActiveEditCell, setIsKeyboardNav, wiringList } = useWiringList();
  const wiringListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wiringListRef.current && activeEditCell !== null && activeEditCell.row === 0) {
      wiringListRef.current.scrollTop = 0;
    }
  }, [activeEditCell]);

  useEffect(() => {
    setActiveEditCell(null);
  }, [selectedDesignPart, setActiveEditCell]);

  const handleFocusChange = (newValue: boolean) => {
    if (wiringListRef.current && activeEditCell === null) {
      newValue ? wiringListRef.current.focus() : wiringListRef.current.blur();
    }
  };

  if (!selectedDesignPart || !isConnectionTarget(selectedDesignPart)) {
    return <Text p={8}>No connection target selected. Select a valid part to view wiring list.</Text>;
  }

  return (
    <Stack
      color="gray.700"
      fontSize="xs"
      fontWeight="normal"
      onMouseMove={() => handleFocusChange(false)}
      onMouseEnter={() => handleFocusChange(true)}
      onKeyDown={() => setIsKeyboardNav(true)}
      h="full"
      p={4}
      gap={0}
    >
      <TableContainer ref={wiringListRef} h="full" border="1px solid" borderColor="gray.300" overflowY="auto">
        <Table
          layout="fixed"
          sx={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            th: { p: 0 },
            td: { p: 0 },
          }}
        >
          <WiringListHead />
          <Tbody>
            {wiringList.map((row, index) => (
              <Fragment key={row.keyId}>
                <WiringListRow row={index} connectionPoint={row.connectionPoint} connection={row.existingConnection} />
              </Fragment>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export const WiringList = () => {
  return (
    <WiringListProvider>
      <WiringListComponent />
    </WiringListProvider>
  );
};
