import { Box, BoxProps } from '@chakra-ui/react';
import { Handle, Position } from '@xyflow/react';

interface Props extends BoxProps {
  id: string;
  handlePosition: Position;
}

export const ConnectionHandle = ({ id, handlePosition, ...rest }: Props) => {
  const handleClick = () => {
    console.debug('Handle', id);
  };

  return (
    <Box onClick={handleClick} style={{ visibility: 'hidden', cursor: 'pointer' }} {...rest}>
      <Handle id={id} type="source" position={handlePosition} />
    </Box>
  );
};
