import { Spacer, Text } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';
import useDebouncedValue from '@web/hooks/useDebouncedValue';
import { useState } from 'react';

import { BasePartListItem } from './BasePartListItem';
import { EditablePartName } from './EditablePartName';
import { PartNumberDisplay } from './PartNumberDisplay';

interface Props {
  part: DesignPart;
  info?: string;
}

export const PartNameListItem = ({ part, info }: Props) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const isEditingDebounced = useDebouncedValue(isEditingName, 10);

  return (
    <BasePartListItem part={part} canRename onRenamePart={() => setIsEditingName(true)}>
      <EditablePartName
        isEditing={isEditingDebounced}
        setIsEditing={setIsEditingName}
        partName={part.name}
        partId={part.id}
      />
      <PartNumberDisplay partNumber={part.partData.partNumber} />
      <Spacer />
      {info && (
        <Text fontSize="xs" fontFamily="monospace" color="blue.700">
          {info}
        </Text>
      )}
    </BasePartListItem>
  );
};
