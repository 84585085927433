import { Textarea, TextareaProps } from '@chakra-ui/react';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';

interface Props extends TextareaProps {
  initialText: string;
  onEdit: (value: string) => void;
  onCancelEdit: () => void;
}

export const EditableTextareaComponent = ({ initialText, onEdit, onCancelEdit, ...rest }: Props) => {
  const [value, setValue] = useState(initialText);
  const inputRef = useRef<HTMLInputElement>(null);

  // On mount, use a timeout to ensure focus is applied after rendering
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);
  }, []);

  const handleEdit = () => {
    const trimmedValue = value ? value?.trim() : value;
    if (trimmedValue !== initialText) {
      onEdit(trimmedValue);
    } else {
      onCancelEdit();
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleEdit();
    } else if (event.key === 'Escape') {
      onCancelEdit();
    }
  };

  return (
    <Textarea
      ref={inputRef}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleEdit}
      onKeyDown={handleOnKeyDown}
      size="sm"
      resize="both"
      rows={3}
      {...rest}
    />
  );
};
