import { LazyWrapper } from '@web/components/LazyWrapper.tsx';
import { ReactNode } from 'react';

import BOM from '../Design/features/BOM';
import BuildNotes from '../Design/features/BuildNotes';
import Cutlist from '../Design/features/Cutlist';
import Wiring from '../Design/features/WiringList';

export enum ContextPanelTab {
  BOM = 'bom',
  CUTLIST = 'cutlist',
  BUILD_NOTES = 'buildNotes',
  WIRING = 'wiring',
  DETAILS = 'details',
}

export interface TabData {
  value: ContextPanelTab;
  headerLabel: string;
  content: ReactNode;
}

export const baseContextPanelTabs: TabData[] = [
  { value: ContextPanelTab.BOM, headerLabel: 'BOM', content: <LazyWrapper component={BOM} /> },
  { value: ContextPanelTab.CUTLIST, headerLabel: 'Cutlist', content: <LazyWrapper component={Cutlist} /> },
  { value: ContextPanelTab.BUILD_NOTES, headerLabel: 'Build Notes', content: <LazyWrapper component={BuildNotes} /> },
  { value: ContextPanelTab.WIRING, headerLabel: 'Wiring List', content: <LazyWrapper component={Wiring} /> },
] as const;
