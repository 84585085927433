import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { doRequest } from '@web/apps/Design/api/request-helpers.ts';
import { NoteGroupBuildNote, UUID } from '@web/apps/types';
import { getUrl } from '@web/common/api.ts';

import { useDesignToast } from '../hooks/useDesignToast';
import { useInvalidateQueries } from './react-query-helpers';

type Position = { x: number; y: number };

type CreateNoteGroupBuildNoteParams = {
  designId: UUID;
  noteGroupId?: UUID;
  buildNoteId: UUID;
  targetId?: UUID;
  targetType?: 'LayoutNode' | 'LayoutEdge';
  position?: Position;
};

// API Calls
const createNoteGroupBuildNote = async ({
  designId,
  buildNoteId,
  noteGroupId,
  targetId,
  targetType,
  position,
}: CreateNoteGroupBuildNoteParams) => {
  const url = getUrl(`/api/v1/designs/${designId}/note_group_build_notes`);
  return await doRequest<NoteGroupBuildNote>(
    'POST',
    url,
    JSON.stringify({
      noteGroupBuildNote: { noteGroupId, buildNoteId },
      targetId,
      targetType,
      position,
    }),
  );
};

const deleteNoteGroupBuildNote = async (designId: UUID, noteGroupBuildNoteId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/note_group_build_notes/${noteGroupBuildNoteId}`);
  return await doRequest<NoteGroupBuildNote>('DELETE', url);
};

// React Query Hooks: Mutations
export const useCreateNoteGroupBuildNoteMutation = (): UseMutationResult<
  NoteGroupBuildNote | null,
  Error,
  CreateNoteGroupBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-note-group-build-note'],
    mutationFn: async (params: CreateNoteGroupBuildNoteParams) => {
      const createdNote = await createNoteGroupBuildNote(params);
      return createdNote ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error creating note group build note', error.message),
  });
};

type DeleteNoteGroupBuildNoteParams = {
  designId: UUID;
  noteGroupBuildNoteId: UUID;
};

export const useDeleteNoteGroupBuildNoteMutation = (): UseMutationResult<
  void,
  Error,
  DeleteNoteGroupBuildNoteParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-note-group-build-note'],
    mutationFn: async ({ designId, noteGroupBuildNoteId }: DeleteNoteGroupBuildNoteParams) => {
      await deleteNoteGroupBuildNote(designId, noteGroupBuildNoteId);
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error deleting note group build note', error.message),
  });
};
