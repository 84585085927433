import { ShapeProps } from '@web/apps/types';

export const Capacitor = ({ color }: ShapeProps) => (
  <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill={color}
    />
    <path
      d="M10.4348 5.04348C10.7229 5.04348 10.9565 5.27707 10.9565 5.56522V7.13043H13.913C14.2012 7.13043 14.4348 7.36403 14.4348 7.65217C14.4348 7.94032 14.2012 8.17391 13.913 8.17391H10.9565V9.73913C10.9565 10.0273 10.7229 10.2609 10.4348 10.2609C10.1466 10.2609 9.91304 10.0273 9.91304 9.73913V5.56522C9.91304 5.27707 10.1466 5.04348 10.4348 5.04348Z"
      fill="white"
    />
    <path
      d="M2.08696 7.13043C1.79881 7.13043 1.56522 7.36403 1.56522 7.65217C1.56522 7.94032 1.79881 8.17391 2.08696 8.17391H5.04348V9.73913C5.04348 10.0273 5.27707 10.2609 5.56522 10.2609C5.85337 10.2609 6.08696 10.0273 6.08696 9.73913V7.65259V5.56522C6.08696 5.27707 5.85337 5.04348 5.56522 5.04348C5.27707 5.04348 5.04348 5.27707 5.04348 5.56522V7.13043H2.08696Z"
      fill="white"
    />
  </svg>
);
