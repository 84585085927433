import { defaultLayoutConfig } from '@web/apps/Design';
import { ShapeProps } from '@web/apps/types';

export const connectionPointCoordinates = {
  x: -22,
  y: -3,
};

const originalWidth = 46;
const originalHeight = 38;

const centerX = originalWidth / 2;
const centerY = originalHeight / 2;

export const RectangularConnector45 = ({
  color = defaultLayoutConfig.nodeColor,
  secondaryColor = defaultLayoutConfig.nodeSecondaryColor,
  outlineColor = defaultLayoutConfig.nodeOutlineColor,
  angle = 0,
}: ShapeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      preserveAspectRatio="xMidYMin slice"
      style={{ overflow: 'visible' }}
      fill="none"
    >
      <g transform={`rotate(${angle} ${centerX} ${centerY})`}>
        <path
          d="M14.5508 4.71989L0.867925 4.71989L0.867927 26.4557L14.5508 26.4557L14.5508 4.71989Z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.36793 5.21989L14.0508 5.21989L14.0508 25.9557L1.36792 25.9557L1.36793 5.21989ZM14.5508 4.71989L14.5508 26.4557L0.867927 26.4557L0.867925 4.71989L14.5508 4.71989Z"
          fill={outlineColor}
        />
        <path
          d="M45.8827 16.2949L30.4924 0.90849L16.0495 0.873281C14.9483 0.869244 14.0588 1.75865 14.0629 2.85985L14.0482 28.8924C14.0523 29.9936 15.1239 30.7909 16.0495 30.8936L18.3059 31.1444L24.6695 37.5081L45.8827 16.2949Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4924 0.90849L45.8827 16.2949L24.6695 37.5081L18.3059 31.1444L16.0495 30.8936C15.1239 30.7909 14.0523 29.9936 14.0482 28.8924L14.0629 2.85985C14.0588 1.75865 14.9483 0.869244 16.0495 0.873281L30.4924 0.90849ZM30.4814 1.60452L45.1755 16.2949L24.6695 36.801L18.7011 30.8326L30.4814 1.60452ZM18.2419 30.6343L30.0218 1.40734L16.0483 1.37328C15.2246 1.37026 14.5599 2.03441 14.5629 2.85802L14.5482 28.8906L14.5482 28.8917C14.55 29.2704 14.734 29.6192 15.0399 29.8978C15.3499 30.1801 15.7519 30.3575 16.1046 30.3967L18.2419 30.6343Z"
          fill={outlineColor}
        />
      </g>
    </svg>
  );
};
