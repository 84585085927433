import { DesignPart } from '@web/apps/types';

import { PartListGroup } from './PartListGroup';
import { PartTypeListItem } from './PartTypeListItem';

interface Props {
  label: string;
  parts?: DesignPart[];
}

export const PartTypeList = ({ label, parts }: Props) => {
  if (parts && parts.length > 0) {
    return (
      <PartListGroup label={label} count={parts.length}>
        {parts.map((part) => (
          <PartTypeListItem key={part.id} part={part} />
        ))}
      </PartListGroup>
    );
  }

  return null;
};
