import { useQueryClient } from '@tanstack/react-query';
import { apiPutTenant } from '@web/api/admin-api.ts';
import { successToast } from '@web/common/toasts.ts';
import { RouteNames } from '@web/consts/routeNames.ts';
import { adminTenantCacheKey, useAdminTenant } from '@web/queries/admin.ts';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import AdminPage from '../AdminPage';
import TenantForm from './TenantForm';

const AdminEditTenant = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { tenantId = '' } = useParams();
  const { data: tenant } = useAdminTenant(tenantId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm();

  useEffect(() => {
    if (tenant) {
      reset(tenant);
    }
  }, [tenant, reset]);

  const onSubmit = async (data: object) => {
    const results = await apiPutTenant(tenantId, data);
    if (results) {
      navigate(RouteNames.TENANTS.INDEX);
      successToast('Tenant successfully updated.');
      await queryClient.invalidateQueries({ queryKey: adminTenantCacheKey(tenantId) });
    }
  };

  return (
    <AdminPage title="Edit Tenant">
      <TenantForm onSubmit={handleSubmit(onSubmit)} register={register} isSubmitting={isSubmitting} isValid={isValid} />
    </AdminPage>
  );
};

export default AdminEditTenant;
