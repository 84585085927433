import { Box, MenuItem, Spinner, Text } from '@chakra-ui/react';
import { UUID } from '@web/apps/types';
import { XYPosition } from '@xyflow/react';
import { useState } from 'react';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu';
import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState';
import {
  useDeleteDesignPartLayoutEdgeMutation,
  useUpdateDesignPartLayoutEdgeMutation,
} from '../../../../../api/design-part-layout-edges-api';
import { EditableTextareaComponent } from '../../../../../components/EditableTextareaComponent';
import { useDesignId } from '../../../../../hooks/useDesignId';

interface Props {
  designPartLayoutEdgeId: UUID;
  labelPosition: XYPosition;
  angle: number;
  text: string;
}

export const SegmentLabel = ({ designPartLayoutEdgeId, labelPosition, angle, text }: Props) => {
  const designId = useDesignId();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const { mutate: updateDesignPartLayoutEdge } = useUpdateDesignPartLayoutEdgeMutation();
  const { mutate: deleteDesignPartLayoutEdge, isPending: isDeletingLabel } = useDeleteDesignPartLayoutEdgeMutation();

  const updateLabelText = (updatedText: string) => {
    updateDesignPartLayoutEdge({ designId, designPartLayoutEdgeId, data: { text: updatedText } });
    setIsEditing(false);
  };

  const handleDelete = () => {
    deleteDesignPartLayoutEdge({ designId, designPartLayoutEdgeId });
  };

  const handleEditLabel = () => {
    setIsEditing(true);
    closeMenu();
  };

  return (
    <Box
      position="absolute"
      top={labelPosition.y}
      left={labelPosition.x}
      transform={` translate(-50%, -50%)`}
      className="edge-label-renderer__custom-edge"
      pointerEvents="all"
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsEditing(true);
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      onContextMenu={handleContextMenu}
    >
      {isEditing ? (
        <EditableTextareaComponent
          initialText={text}
          onEdit={updateLabelText}
          onCancelEdit={() => setIsEditing(false)}
          p={1}
          borderRadius="md"
          bgColor="white"
        />
      ) : (
        <Text
          transform={`rotate(${angle}deg)`}
          bgColor="white"
          p={1}
          borderRadius="md"
          borderWidth={2}
          fontSize="xs"
          textAlign="center"
          minHeight={8}
          minWidth={8}
          whiteSpace="pre-wrap"
        >
          {text}
        </Text>
      )}
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <MenuItem onClick={handleEditLabel}>Edit</MenuItem>
        <MenuItem onClick={handleDelete} gap={2}>
          <Text>Remove</Text>
          {isDeletingLabel && <Spinner size="xs" />}
        </MenuItem>
      </ContextMenu>
    </Box>
  );
};
