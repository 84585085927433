import { XYPosition } from '@xyflow/react';

import { calculateDistance } from '../../../../Layout/utils/geometry';

interface Props {
  sourcePosition: XYPosition;
  targetPosition: XYPosition;
  distanceFromEdge: number;
  flipped: boolean;
}

/**
 * Calculates the paths for the measurement line, start line, and end line. Also returns the midpoint.
 * @param sourcePosition
 * @param targetPosition
 * @param distanceFromEdge
 * @param flipped
 */
export const getRelationalMeasurementPaths = ({ sourcePosition, targetPosition, distanceFromEdge, flipped }: Props) => {
  // Get the source and target positions
  const { x: sourceX, y: sourceY } = sourcePosition;
  const { x: targetX, y: targetY } = targetPosition;

  // Calculate the distance between the source and target nodes
  const dx = targetX - sourceX;
  const dy = targetY - sourceY;
  const length = calculateDistance({ x: sourceX, y: sourceY }, { x: targetX, y: targetY });

  // Flip factor for mirroring
  const flipFactor = flipped ? -1 : 1;

  // Calculate the normalized vector for the measurement line
  const normX = (dy / length) * distanceFromEdge * flipFactor;
  const normY = (-dx / length) * distanceFromEdge * flipFactor;

  return {
    midpoint: { x: (sourceX + targetX) / 2 + normX, y: (sourceY + targetY) / 2 + normY },
  };
};
