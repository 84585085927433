import { DesignPartLayoutEdge } from '@web/apps/types';
import { EdgeLabelRenderer, XYPosition } from '@xyflow/react';

import { calculateAngleBetweenPoints, calculatePartialMidpoint } from '../../../../Layout/utils/geometry';
import { SegmentLabel } from './SegmentLabel';

interface Props {
  labelEdges: DesignPartLayoutEdge[];
  sourceXYPosition: XYPosition;
  targetXYPosition: XYPosition;
}

export const SegmentLabels = ({ labelEdges, sourceXYPosition, targetXYPosition }: Props) => {
  if (labelEdges && labelEdges.length > 0) {
    const angle =
      sourceXYPosition.x < targetXYPosition.x
        ? calculateAngleBetweenPoints(targetXYPosition, sourceXYPosition)
        : calculateAngleBetweenPoints(sourceXYPosition, targetXYPosition);

    return (
      <EdgeLabelRenderer>
        {labelEdges.map(({ id, text }, index) => {
          const labelPosition: XYPosition = calculatePartialMidpoint(
            sourceXYPosition,
            targetXYPosition,
            index,
            labelEdges.length,
          );

          return (
            <SegmentLabel
              key={id}
              designPartLayoutEdgeId={id}
              labelPosition={labelPosition}
              text={text}
              angle={angle}
            />
          );
        })}
      </EdgeLabelRenderer>
    );
  }

  return null;
};
