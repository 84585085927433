import { Box, Heading, Stack } from '@chakra-ui/react';
import { RouteNames } from '@web/consts/routeNames.ts';

import { PartsNavButton } from './PartsNavButton';

export const PartsSideNav = () => (
  <Box minW="225px" borderRight="2px" borderColor="gray.200">
    <Box borderBottom="2px" borderColor="gray.200" p={6}>
      <Heading as="h1" size="md">
        Part Types
      </Heading>
    </Box>
    <Stack spacing={0}>
      <PartsNavButton name="Connectors" path={RouteNames.PARTS.CONNECTORS} />
      <PartsNavButton name="Insert Arrangements" path={RouteNames.PARTS.INSERT_ARRANGEMENTS} isSubType />
      <PartsNavButton name="Contacts" path={RouteNames.PARTS.CONTACTS} />
      <PartsNavButton name="Wires" path={RouteNames.PARTS.WIRES} />
      <PartsNavButton name="Cables" path={RouteNames.PARTS.CABLES} />
      <PartsNavButton name="Backshells" path={RouteNames.PARTS.BACKSHELLS} />
      <PartsNavButton name="Splices" path={RouteNames.PARTS.SPLICES} />
      <PartsNavButton name="Pigtails" path={RouteNames.PARTS.PIGTAILS} />
      <PartsNavButton name="Passives" path={RouteNames.PARTS.PASSIVES} />
      <PartsNavButton name="Overwraps" path={RouteNames.PARTS.OVERWRAPS} />
      <PartsNavButton name="Generic" path={RouteNames.PARTS.GENERICS} />
      <PartsNavButton name="Labels" path={RouteNames.PARTS.LABELS} />
      <PartsNavButton name="Bulk Upload" path={RouteNames.PARTS.BULK_UPLOAD} />
    </Stack>
  </Box>
);
