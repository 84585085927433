import { HStack, Text } from '@chakra-ui/react';
import { useOnSelectionChange } from '@xyflow/react';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { useClickAway, useKeyPressEvent } from 'react-use';

import { MeasurementInput } from './MeasurementInput';

export interface MeasurementEditProps {
  measurement: string;
  toleranceMinimum: string;
  toleranceMaximum: string;
}

interface Props {
  initialMeasurement: string;
  initialToleranceMinimum: string;
  initialToleranceMaximum: string;
  lengthUnit: string;
  onEdit: ({ measurement, toleranceMinimum, toleranceMaximum }: MeasurementEditProps) => void;
  onCancelEdit: () => void;
}

export const MeasurementEdgeEditableText = ({
  initialMeasurement,
  initialToleranceMinimum,
  initialToleranceMaximum,
  lengthUnit,
  onEdit,
  onCancelEdit,
}: Props) => {
  const ref = useRef(null);
  const [measurement, setMeasurement] = useState(initialMeasurement);
  const [toleranceMinimum, setToleranceMinimum] = useState(initialToleranceMinimum);
  const [toleranceMaximum, setToleranceMaximum] = useState(initialToleranceMaximum);

  const handleEdit = useCallback(
    () => onEdit({ measurement, toleranceMinimum, toleranceMaximum }),
    [onEdit, measurement, toleranceMinimum, toleranceMaximum],
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEdit();
    }
  };

  useKeyPressEvent('Escape', onCancelEdit);
  useClickAway(ref, handleEdit);
  useOnSelectionChange({ onChange: handleEdit });

  return (
    <HStack
      ref={ref}
      onMouseDown={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
      borderWidth={1}
      borderColor="blue.400"
      px={2}
      py={1}
      borderRadius="base"
      bgColor="white"
    >
      <HStack gap={0} color="blue.700">
        <MeasurementInput
          value={measurement}
          onChange={(e) => setMeasurement(e.target.value)}
          onKeyDown={handleKeyDown}
          focusOnMount
        />
        <Text>{lengthUnit}</Text>
      </HStack>
      <HStack gap={0} color="gray.500">
        <Text>{'(+'}</Text>
        <MeasurementInput
          value={toleranceMaximum}
          onChange={(e) => setToleranceMaximum(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Text>{'/-'}</Text>
        <MeasurementInput
          value={toleranceMinimum}
          onChange={(e) => setToleranceMinimum(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Text>{`)${lengthUnit}`}</Text>
      </HStack>
    </HStack>
  );
};
