import { KeyboardEvent } from 'react';

const validKeys = new Set(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']);

const isNavigatingInsideInput = (key: string, target: HTMLInputElement | HTMLSelectElement) => {
  if (target instanceof HTMLInputElement || target instanceof HTMLSelectElement) {
    const input = target as HTMLInputElement;

    // Prevent navigation if the user is moving within the text field
    if (
      (key === 'ArrowLeft' && input.selectionStart !== 0) ||
      (key === 'ArrowRight' && input.selectionStart !== input.value.length)
    ) {
      return true;
    }
  }

  return false;
};

const isTableRowElement = (element: Element | null): element is HTMLTableRowElement => {
  return element instanceof HTMLTableRowElement;
};

const isTableCellElement = (element: Element | null): element is HTMLTableCellElement => {
  return element instanceof HTMLTableCellElement;
};

const getClosestTableCell = (element: Element | null): HTMLTableCellElement | null => {
  return element?.closest('td') as HTMLTableCellElement | null;
};

const getAdjacentCell = (currentCell: HTMLTableCellElement, direction: string) => {
  const row = currentCell.parentElement;
  if (!isTableRowElement(row)) return null;

  const cellIndex = Array.from(row.cells).indexOf(currentCell);
  if (cellIndex === -1) return null;

  if (direction === 'ArrowUp') {
    const prevRow = row.previousElementSibling;
    return isTableRowElement(prevRow) && isTableCellElement(prevRow.cells[cellIndex]) ? prevRow.cells[cellIndex] : null;
  }

  if (direction === 'ArrowDown') {
    const nextRow = row.nextElementSibling;
    return isTableRowElement(nextRow) && isTableCellElement(nextRow.cells[cellIndex]) ? nextRow.cells[cellIndex] : null;
  }

  if (direction === 'ArrowLeft') {
    return isTableCellElement(currentCell.previousElementSibling) ? currentCell.previousElementSibling : null;
  }

  if (direction === 'ArrowRight') {
    return isTableCellElement(currentCell.nextElementSibling) ? currentCell.nextElementSibling : null;
  }

  return null;
};

export const processArrowKeys = (e: KeyboardEvent) => {
  const { key, target } = e;

  // Early exit if the key is not valid
  if (!validKeys.has(key)) return;

  // Early exit for navigation happening inside an input-related element
  if (isNavigatingInsideInput(key, target as HTMLInputElement)) return;

  const currentCell = getClosestTableCell(e.currentTarget);

  if (currentCell) {
    const nextCell = getAdjacentCell(currentCell, key);

    if (nextCell) {
      nextCell.focus();
    } else {
      e.preventDefault();
    }

    e.stopPropagation();
  }
};
