import { defaultViewport, Graph, setEdgesType, setNodesType } from '@web/apps/types';
import { useReactFlow } from '@xyflow/react';
import { useEffect, useState } from 'react';

import { useConnections } from '../../../hooks/useConnections';
import { useDesignBuildNotes } from '../../../hooks/useDesignBuildNotes';
import { useDesignParts } from '../../../hooks/useDesignParts.tsx';
import { useLayoutData } from '../../../hooks/useLayoutData';
import { getUpdatedGraph } from '../utils/updateGraph';

export const useLoadLayout = (setNodes: setNodesType, setEdges: setEdgesType): boolean => {
  const [reactFlowInitialized, setReactFlowInitialized] = useState(false);
  const { setViewport } = useReactFlow();

  const { layoutData, isSuccess: isLayoutDataLoaded } = useLayoutData();
  const { connections } = useConnections();
  const { designParts } = useDesignParts();
  const { data: buildNotes } = useDesignBuildNotes();

  // Initialize layout
  useEffect(() => {
    const initializeReactFlow = async () => {
      if (reactFlowInitialized) {
        return;
      }

      // Wait for data to load before trying to set nodes and edges
      if (isLayoutDataLoaded && connections && designParts && buildNotes) {
        let updatedGraph: Graph = { nodes: [], edges: [] };

        try {
          const { nodes = [], edges = [], viewport } = layoutData;
          if (viewport) {
            console.debug('Loading viewport from design.layoutData', layoutData);
            await setViewport({ ...defaultViewport, ...viewport });
          } else {
            console.debug('No existing viewport found.');
            await setViewport(defaultViewport);
          }

          updatedGraph = getUpdatedGraph({ nodes, edges, connections, designParts, flagNotes: buildNotes });
        } catch (error) {
          console.error('Failed to parse layout:', error);
          await setViewport(defaultViewport);
        }

        setNodes(updatedGraph.nodes);
        setEdges(updatedGraph.edges);
        setReactFlowInitialized(true);
      }
    };

    void initializeReactFlow();
  }, [
    setNodes,
    setEdges,
    layoutData,
    isLayoutDataLoaded,
    reactFlowInitialized,
    buildNotes,
    connections,
    setViewport,
    designParts,
  ]);

  return reactFlowInitialized;
};
