import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';

import { usePartSearchActions } from '../features/AssemblyNavigator/hooks/usePartSearchActions.tsx';

interface Props extends ButtonProps {
  onOpenSearch?: () => void;
}

export const OpenGeneralPartSearchButton = ({ onOpenSearch, ...rest }: Props) => {
  const { handleAddGeneralPart } = usePartSearchActions();

  const handleAddPart = () => {
    handleAddGeneralPart();
    onOpenSearch?.();
  };

  return (
    <Button
      color="white"
      leftIcon={<AddIcon boxSize={2} />}
      colorScheme="blue"
      size="xs"
      onClick={handleAddPart}
      {...rest}
    >
      Add Part
    </Button>
  );
};
