import { XYPosition } from '@xyflow/react';
import { useMemo } from 'react';

import { ControlPointData } from '../../../../../../types';

interface Props {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  controlPoints: ControlPointData[];
}

export const usePathPoints = ({ sourceX, sourceY, targetX, targetY, controlPoints }: Props) => {
  const pathPoints = useMemo(() => {
    const sourceOrigin = { x: sourceX, y: sourceY } as XYPosition;
    const targetOrigin = { x: targetX, y: targetY } as XYPosition;
    return [sourceOrigin, ...(controlPoints || []), targetOrigin];
  }, [sourceX, sourceY, targetX, targetY, controlPoints]);

  return pathPoints;
};
