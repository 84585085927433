import { ShapeProps } from '@web/apps/types';

export const Antenna = ({ color }: ShapeProps) => (
  <svg width="23" height="23" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.5C3.80787 4.5 3.63273 4.6101 3.54943 4.78324C3.46614 4.95638 3.48942 5.16194 3.60934 5.31206L7.5 10.1828V12C7.5 12.2761 7.72386 12.5 8 12.5C8.27614 12.5 8.5 12.2761 8.5 12V10.168L12.3902 5.31264C12.5104 5.16259 12.534 4.9569 12.4507 4.78357C12.3675 4.61025 12.1923 4.5 12 4.5H4ZM7.5 5.5H5.03933L7.5 8.58051V5.5ZM8.5 8.56873V5.5H10.9587L8.5 8.56873Z"
      fill="white"
    />
  </svg>
);
