import type { ControlPointData } from '@web/apps/types';
import type { XYPosition } from '@xyflow/react';

import { isControlPoint } from './utils';

export function getLinearPath(points: XYPosition[]) {
  if (points.length < 1) return '';

  return points.map(({ x, y }, index) => (index === 0 ? `M ${x} ${y}` : `L ${x} ${y}`)).join(' ');
}

export function getLinearControlPoints(points: (ControlPointData | XYPosition)[]) {
  const controlPoints = [] as ControlPointData[];

  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];

    if (isControlPoint(p1)) {
      controlPoints.push(p1);
    }

    controlPoints.push({
      prev: 'id' in p1 ? p1.id : undefined,
      id: window.crypto.randomUUID(),
      active: false,
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    });
  }

  return controlPoints;
}
