import { Box } from '@chakra-ui/react';
import { emptyEdges, emptyNodes } from '@web/apps/types';
import { RouteNames } from '@web/consts/routeNames';
import {
  Background,
  BackgroundVariant,
  ConnectionMode,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from '@xyflow/react';
import { isEmpty } from 'lodash';

import { ContextMenu } from '../../../../components/menu/ContextMenu';
import { useContextMenuState } from '../../../../components/menu/useContextMenuState';
import { LayoutEmptyState } from '../../components/EmptyStates/LayoutEmptyState';
import ManagedWindow from '../../components/ManagedWindow';
import { useDesign } from '../../hooks/useDesign';
import { useDesignFilters } from '../../hooks/useDesignFilters';
import CoordinatePanel from '../Layout/components/CoordinatePanel';
import { LayerFilterControls } from '../Layout/components/LayerFilterControls';
import { BuildNoteMenuSection } from './components/menus/BuildNoteMenuSection';
import { useLoadRelationalLayout } from './hooks/useLoadRelationalLayout/useLoadRelationalLayout';
import { useRelationalLayout } from './hooks/useRelationalLayout';
import { relationalEdgeTypes, relationalNodeTypes } from './relational_types_map';

export const RelationalLayoutReactFlow = () => {
  const { isViewOnly } = useDesign();
  const { filters } = useDesignFilters();

  const [nodes, setNodes, onNodesChange] = useNodesState(emptyNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(emptyEdges);

  const reactFlowInitialized = useLoadRelationalLayout(setNodes, setEdges);

  const {
    handleContextMenu: handlePaneContextMenu,
    isOpen: isPaneMenuOpen,
    position: paneMenuPosition,
    closeMenu: closePaneMenu,
  } = useContextMenuState();

  const { config, onNodeClick, onNodeDragStop, onEdgeClick, onPaneClick } = useRelationalLayout();

  const layoutEmpty = isEmpty(nodes);

  return (
    <ManagedWindow title="Layout" routeName={RouteNames.DESIGNS.LAYOUT}>
      {() => (
        <Box w="full" h="full">
          {!reactFlowInitialized ? null : (
            <ReactFlow
              /* Config nodes */
              nodeTypes={relationalNodeTypes}
              nodes={nodes}
              onNodesChange={onNodesChange}
              nodesConnectable={false}
              nodesDraggable={!isViewOnly}
              selectNodesOnDrag={false}
              /* Config edges */
              edgeTypes={relationalEdgeTypes}
              edges={edges}
              onEdgesChange={onEdgesChange}
              /* Click handlers */
              onPaneClick={onPaneClick}
              onPaneContextMenu={handlePaneContextMenu}
              onNodeClick={onNodeClick}
              onEdgeClick={onEdgeClick}
              /* Drag handlers */
              onNodeDragStop={onNodeDragStop}
              /* ReactFlow settings (fixed) */
              connectionMode={ConnectionMode.Loose}
              proOptions={{ hideAttribution: true }}
              zoomOnDoubleClick={false}
              panOnScroll={true}
              deleteKeyCode={null}
              /* ReactFlow settings (from config.ts) */
              defaultViewport={config.defaultViewport}
              minZoom={config.minZoom}
              maxZoom={config.maxZoom}
              nodeOrigin={config.nodeOrigin}
              snapToGrid={config.snapToGrid}
              snapGrid={config.snapGrid}
              style={config.style}
            >
              {!filters.gridLinesHidden && !layoutEmpty && (
                <Background variant={BackgroundVariant.Lines} color={config.gridLinesColor} gap={config.gridLinesGap} />
              )}
              <LayerFilterControls />
              <Controls showInteractive={false} position="bottom-left" />
              <CoordinatePanel position="bottom-right" />
              {layoutEmpty && <LayoutEmptyState />}
            </ReactFlow>
          )}
          {/* Render all context menus */}
          {!isViewOnly && (
            <ContextMenu isOpen={isPaneMenuOpen} position={paneMenuPosition} closeMenu={closePaneMenu}>
              <BuildNoteMenuSection menuPosition={paneMenuPosition} onToggle={closePaneMenu} />
            </ContextMenu>
          )}
        </Box>
      )}
    </ManagedWindow>
  );
};
