import { Box, BoxProps, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { PartStatus } from '@web/apps/types';
import { useCurrentUser } from '@web/queries/users.ts';
import { SlOptionsVertical } from 'react-icons/sl';

interface Props<T extends { id: string; status?: PartStatus }> extends BoxProps {
  item: T;
  onView: (item: T) => void;
  onEdit: (item: T) => void;
  onRelease?: (item: T) => Promise<void>;
  onDelete: (itemId: string) => Promise<void>;
  onClone: (item: T) => void;
}

const DashboardRowActions = <T extends { id: string; tenantId: string; status?: PartStatus }>({
  item,
  onView,
  onEdit,
  onRelease,
  onDelete,
  onClone,
  ...rest
}: Props<T>) => {
  const { data: currentUser } = useCurrentUser();

  const canEdit = currentUser?.employee || currentUser?.tenant?.id === item.tenantId;

  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={IconButton} icon={<SlOptionsVertical />} />
        <Portal>
          <MenuList>
            <MenuItem onClick={() => onView(item)}>View</MenuItem>
            {canEdit && (
              <>
                <MenuItem onClick={() => onEdit(item)}>Edit</MenuItem>
                {onRelease && item.status === PartStatus.DRAFT && (
                  <MenuItem onClick={() => onRelease(item)}>Release</MenuItem>
                )}
                <MenuItem onClick={() => onClone(item)}>Clone</MenuItem>
                <MenuItem color="red.300" onClick={() => onDelete(item.id)}>
                  Delete
                </MenuItem>
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default DashboardRowActions;
