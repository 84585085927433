import { Text } from '@chakra-ui/react';
import { Contact, Part, PartType } from '@web/apps/types';

import { MinMaxAwg, minMaxAwgFormatter } from '../../components/MinMaxAwgFields.tsx';
import { CustomColumnDef, PartsLibraryDashboard } from '../../components/PartsLibraryDashboard.tsx';

const columns: CustomColumnDef[] = [
  {
    header: 'AWG',
    renderFn: (part: Part) => {
      const contact = part as Contact;
      const minMaxAwg: MinMaxAwg = {
        gaugeMinAwg: contact.gaugeMinAwg,
        gaugeMaxAwg: contact.gaugeMaxAwg,
      };
      return <Text>{minMaxAwgFormatter(minMaxAwg)}</Text>;
    },
  },
  {
    header: 'Gender',
    renderFn: (part: Part) => {
      const contact = part as Contact;
      return <Text>{contact.gender}</Text>;
    },
  },
  {
    header: 'Termination',
    renderFn: (part: Part) => {
      const contact = part as Contact;
      return <Text>{contact.termination}</Text>;
    },
  },
];

const defaultValues = { type: PartType.CONTACT };

export const ContactsDashboard = () => (
  <PartsLibraryDashboard
    title="Contacts"
    addPartButtonLabel="Contact"
    routeName="contacts"
    columnDefs={columns}
    defaultValues={defaultValues}
    partType={PartType.CONTACT}
  />
);
