import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { designKeys } from '@web/apps/Design/api/query-keys.ts';
import { useInvalidateQueries } from '@web/apps/Design/api/react-query-helpers.ts';
import { Design, LayoutNode, UUID } from '@web/apps/types';
import { doRequest, getUrl } from '@web/common/api.ts';

import { useDesignToast } from '../hooks/useDesignToast';

// API Calls
const updateLayoutNode = (designId: UUID, layoutNodeId: UUID, data: Partial<LayoutNode>) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes/${layoutNodeId}`);
  return doRequest<LayoutNode>('PUT', url, JSON.stringify({ layoutNode: data }));
};

const createControlPoint = (designId: UUID, data: Partial<LayoutNode>) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes`);
  return doRequest<LayoutNode>('POST', url, JSON.stringify({ layoutNode: data }));
};

const deleteControlPoint = (designId: UUID, controlPointId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes/${controlPointId}`);
  return doRequest<LayoutNode>('DELETE', url);
};

const mergeBreakoutPoints = (designId: UUID, breakoutPointId: UUID, toNodeId: UUID) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes/${breakoutPointId}/merge`);
  return doRequest<LayoutNode>('PUT', url, JSON.stringify({ toNodeId }));
};

const unmergeBreakoutPoint = (
  designId: UUID,
  breakoutPointId: UUID,
  startDesignPartNodeId: UUID,
  endDesignPartNodeId?: UUID,
) => {
  const url = getUrl(`/api/v1/designs/${designId}/layout_nodes/${breakoutPointId}/unmerge`);
  return doRequest<LayoutNode>('PUT', url, JSON.stringify({ startDesignPartNodeId, endDesignPartNodeId }));
};

// React Query Hook: useUpdateLayoutNodeMutation
interface UpdateLayoutNodeParams {
  designId: UUID;
  layoutNodeId: UUID;
  data: Partial<LayoutNode>;
}

export const useUpdateLayoutNodeMutation = (): UseMutationResult<LayoutNode | null, Error, UpdateLayoutNodeParams> => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['update-layout-node'],
    mutationFn: async ({ designId, layoutNodeId, data }: UpdateLayoutNodeParams) => {
      const updatedNode = await updateLayoutNode(designId, layoutNodeId, data);
      return updatedNode ?? null;
    },
    onSuccess: (updatedNode, variables) => {
      queryClient.setQueryData(designKeys.detail(variables.designId), (prevData: Design) => {
        if (!prevData) return prevData;
        prevData.layoutNodes.map((node) => (node.id === updatedNode?.id ? updatedNode : node));
      });
    },
    onError: (error) => showErrorToast('Error updating layout node', error.message),
  });
};

// React Query Hook: useCreateControlPointMutation
interface CreateControlPointParams {
  designId: UUID;
  data: Partial<LayoutNode>;
}

export const useCreateControlPointMutation = (): UseMutationResult<
  LayoutNode | null,
  Error,
  CreateControlPointParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['create-control-point'],
    mutationFn: async ({ designId, data }: CreateControlPointParams) => {
      const newControlPoint = await createControlPoint(designId, data);
      return newControlPoint ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error creating control point', error.message),
  });
};

// React Query Hook: useDeleteControlPointMutation
interface DeleteControlPointParams {
  designId: UUID;
  controlPointId: UUID;
}

export const useDeleteControlPointMutation = (): UseMutationResult<void, Error, DeleteControlPointParams> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['delete-control-point'],
    mutationFn: async ({ designId, controlPointId }: DeleteControlPointParams) => {
      await deleteControlPoint(designId, controlPointId);
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error deleting control point', error.message),
  });
};

// React Query Hook: useMergeBreakoutPointsMutation
interface MergeBreakoutPointsParams {
  designId: UUID;
  breakoutPointId: UUID;
  toNodeId: UUID;
}

export const useMergeBreakoutPointsMutation = (): UseMutationResult<
  LayoutNode | null,
  Error,
  MergeBreakoutPointsParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['merge-breakout-points'],
    mutationFn: async ({ designId, breakoutPointId, toNodeId }: MergeBreakoutPointsParams) => {
      const mergedNode = await mergeBreakoutPoints(designId, breakoutPointId, toNodeId);
      return mergedNode ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error merging breakout points', error.message),
  });
};

// React Query Hook: useMergeBreakoutPointsMutation
interface UnmergeBreakoutPointsParams {
  designId: UUID;
  breakoutPointId: UUID;
  startDesignPartNodeId: UUID;
  endDesignPartNodeId?: UUID;
}

export const useUnmergeMergeBreakoutPointMutation = (): UseMutationResult<
  LayoutNode | null,
  Error,
  UnmergeBreakoutPointsParams
> => {
  const invalidateQueries = useInvalidateQueries();
  const { showErrorToast } = useDesignToast();

  return useMutation({
    mutationKey: ['unmerge-breakout-point'],
    mutationFn: async ({
      designId,
      breakoutPointId,
      startDesignPartNodeId,
      endDesignPartNodeId,
    }: UnmergeBreakoutPointsParams) => {
      const unmergedNode = await unmergeBreakoutPoint(
        designId,
        breakoutPointId,
        startDesignPartNodeId,
        endDesignPartNodeId,
      );
      return unmergedNode ?? null;
    },
    onSuccess: async (_data, variables) => {
      await invalidateQueries(designKeys.detail(variables.designId));
    },
    onError: (error) => showErrorToast('Error unmerging breakout point', error.message),
  });
};
