import { MenuItem, Spinner, Text } from '@chakra-ui/react';
import { UUID } from '@web/apps/types';
import { MouseEvent } from 'react';

import { ContextMenu } from '../../../../../../../../components/menu/ContextMenu';
import { useContextMenuState } from '../../../../../../../../components/menu/useContextMenuState';
import { useControlPoint } from './useControlPoint';

export type ControlPointProps = {
  id: string;
  index: number;
  x: number;
  y: number;
  color: string;
  active?: boolean;
  designId: UUID;
  layoutEdgeId: UUID;
};

export function ControlPoint({ id, index, x, y, color, active, designId, layoutEdgeId }: ControlPointProps) {
  const { ref, deletePoint, isPendingUpsert, handleKeyPress, handlePointerDown, handlePointerUp } = useControlPoint({
    id,
    index,
    x,
    y,
    active,
    designId,
    layoutEdgeId,
  });
  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const handleContextMenuClick = (e: MouseEvent) => {
    e.preventDefault();
    if (active && !isPendingUpsert) {
      handleContextMenu(e);
    }
  };

  return (
    <circle
      ref={ref}
      tabIndex={0}
      id={id}
      className={'nopan nodrag' + (active ? ' active' : '')}
      cx={x}
      cy={y}
      r={active ? 7 : 5}
      strokeOpacity={active ? 1 : 0.3}
      stroke={color}
      fill={active ? color : 'orange'}
      style={{ pointerEvents: 'all' }}
      onContextMenu={handleContextMenuClick}
      onPointerDown={handlePointerDown}
      onKeyDown={handleKeyPress}
      onPointerUp={handlePointerUp}
    >
      <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
        <MenuItem onClick={deletePoint} gap={2}>
          <Text>Remove</Text>
          {isPendingUpsert && <Spinner size="xs" />}
        </MenuItem>
      </ContextMenu>
    </circle>
  );
}
