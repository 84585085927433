import { Box } from '@chakra-ui/react';
import { DesignPart } from '@web/apps/types';
import { isEmpty } from 'lodash';

interface Props {
  part: DesignPart;
}

export const PartDiffIndicator = ({ part }: Props) => {
  const hasDiff = !isEmpty(part.partDataDiff);

  return hasDiff ? <Box boxSize={2} borderRadius="50%" bgColor="orange.400" /> : null;
};
