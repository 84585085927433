import { InputProps, Text, TextProps } from '@chakra-ui/react';
import { useState } from 'react';

import { EditableTextInputComponent } from './EditableTextInputComponent';

interface Props extends InputProps {
  text: string;
  onEdit: (value: string) => void;
  editAction?: 'dblclick' | 'click';
  textProps?: TextProps;
}

export const EditableText = ({ text, onEdit, editAction = 'dblclick', textProps, ...rest }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = () => setIsEditing(true);

  const handleEdit = (newValue: string) => {
    setIsEditing(false);
    onEdit(newValue);
  };

  return isEditing ? (
    <EditableTextInputComponent
      initialText={text}
      onEdit={handleEdit}
      onCancelEdit={() => setIsEditing(false)}
      {...rest}
    />
  ) : (
    <Text
      onClick={editAction === 'click' ? startEditing : undefined}
      onDoubleClick={editAction === 'dblclick' ? startEditing : undefined}
      whiteSpace="pre-wrap"
      {...textProps}
    >
      {text}
    </Text>
  );
};
