import { Antenna } from '@web/apps/Design/components/Shapes/Antenna.tsx';
import { Capacitor } from '@web/apps/Design/components/Shapes/Capacitor.tsx';
import { Diode } from '@web/apps/Design/components/Shapes/Diode.tsx';
import { Inductor } from '@web/apps/Design/components/Shapes/Inductor.tsx';
import { Resistor } from '@web/apps/Design/components/Shapes/Resistor.tsx';
import { PassiveType, ShapeProps } from '@web/apps/types';
import React from 'react';

// Passive types that are used in the layout
type PassiveTypesInLayout =
  | PassiveType.CAPACITOR
  | PassiveType.DIODE
  | PassiveType.RESISTOR
  | PassiveType.ANTENNA
  | PassiveType.INDUCTOR;

// Type guard for passive types in layout
export const isPassiveTypeInLayout = (type: string): type is PassiveTypesInLayout =>
  type === PassiveType.CAPACITOR ||
  type === PassiveType.DIODE ||
  type === PassiveType.RESISTOR ||
  type === PassiveType.ANTENNA ||
  type === PassiveType.INDUCTOR;

// Map of passive types to their respective shape components
type PassiveShapeMap = {
  [key in PassiveTypesInLayout]: React.FC<ShapeProps>;
};

// Map of passive types to their respective shape components
export const passiveShapes: PassiveShapeMap = {
  [PassiveType.CAPACITOR]: Capacitor,
  [PassiveType.DIODE]: Diode,
  [PassiveType.RESISTOR]: Resistor,
  [PassiveType.ANTENNA]: Antenna,
  [PassiveType.INDUCTOR]: Inductor,
};
