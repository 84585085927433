import { FormControl, FormLabel } from '@chakra-ui/react';

import PartWireTable from '../../components/PartWireTable.tsx';

interface Props {
  editing: boolean;
}

export const PigtailFields = ({ editing }: Props) => {
  return (
    <FormControl>
      <FormLabel>Conductors</FormLabel>
      <PartWireTable isEditing={editing} isPigtailParent />
    </FormControl>
  );
};
