import { MenuDivider, MenuGroup, MenuItem } from '@chakra-ui/react';
import { DesignPartLayoutEdge, LayoutEdge, LayoutNodeType, PartType, UUID } from '@web/apps/types';

import { ContextMenu } from '../../../../../../../components/menu/ContextMenu.tsx';
import { MenuPosition } from '../../../../../../../components/menu/useContextMenuState.ts';
import { useUpdateLayoutEdgeMutation } from '../../../../../api/layout-edges-api.ts';
import { useCreateControlPointMutation } from '../../../../../api/layout-nodes-api.ts';
import { useDesignId } from '../../../../../hooks/useDesignId.tsx';
import { useLayoutEdgeDesignParts } from '../../../hooks/actions/useLayoutEdgeDesignParts.ts';
import { useRelationalLabelActions } from '../../../hooks/actions/useRelationalLabelActions.tsx';
import { useRelationalOverwrapActions } from '../../../hooks/actions/useRelationalOverwrapActions.tsx';
import { BuildNoteMenuSection } from '../../menus/BuildNoteMenuSection.tsx';
import { LabelMenuSection } from './LabelMenuSection.tsx';
import { OverwrapsMenuSection } from './OverwrapsMenuSection.tsx';

interface Props {
  isOpen: boolean;
  position: MenuPosition;
  closeMenu: () => void;
  layoutEdgeId: UUID;
  measurementFlipped: boolean;
  measurementHidden: boolean;
  designPartLayoutEdges: DesignPartLayoutEdge[];
}

export const SegmentEdgeContextMenu = ({
  isOpen,
  position,
  closeMenu,
  layoutEdgeId,
  measurementFlipped,
  measurementHidden,
  designPartLayoutEdges,
}: Props) => {
  const designId = useDesignId();
  const { getLayoutEdgesByType } = useLayoutEdgeDesignParts(designPartLayoutEdges);
  const overwrapEdges = getLayoutEdgesByType(PartType.OVERWRAP);
  const labelEdges = getLayoutEdgesByType(PartType.LABEL);

  const { mutate: createControlPoint } = useCreateControlPointMutation();
  const { mutate: updateLayoutEdge } = useUpdateLayoutEdgeMutation();

  const addControlPoint = (type: LayoutNodeType) => {
    createControlPoint({ designId, data: { type, layoutEdgeId } });
    closeMenu();
  };

  const updateLayoutEdgeData = (data: Partial<LayoutEdge>) => {
    updateLayoutEdge({ designId, layoutEdgeId, data });
    closeMenu();
  };

  const { handleAddOverwrap } = useRelationalOverwrapActions(layoutEdgeId);
  const { handleAddLabel } = useRelationalLabelActions(layoutEdgeId, closeMenu);

  const onAddOverwrap = () => {
    handleAddOverwrap();
    closeMenu();
  };

  return (
    <ContextMenu isOpen={isOpen} position={position} closeMenu={closeMenu}>
      <MenuGroup title="Actions">
        <MenuItem onClick={handleAddLabel}>Add label</MenuItem>
        <MenuItem onClick={onAddOverwrap}>Add overwrap</MenuItem>
        <MenuItem onClick={() => addControlPoint(LayoutNodeType.BREAKOUT_POINT)}>Add breakout point</MenuItem>
        <MenuItem onClick={() => updateLayoutEdgeData({ hidden: !measurementHidden })}>
          {measurementHidden ? 'Show measurement' : 'Hide measurement'}
        </MenuItem>
        <MenuItem onClick={() => updateLayoutEdgeData({ flipped: !measurementFlipped })}>Flip measurement</MenuItem>
      </MenuGroup>
      <MenuDivider />
      <BuildNoteMenuSection targetId={layoutEdgeId} targetType="LayoutEdge" onToggle={closeMenu} />
      <MenuDivider />
      <OverwrapsMenuSection overwrapEdges={overwrapEdges} />
      <MenuDivider />
      <LabelMenuSection labelEdges={labelEdges} layoutEdgeId={layoutEdgeId} closeMenu={closeMenu} />
    </ContextMenu>
  );
};
