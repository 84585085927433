import { Box, HStack, Spinner } from '@chakra-ui/react';

interface Props {
  title: string;
  isLoading: boolean;
}

export const MenuGroupTitleWithLoading = ({ title, isLoading }: Props) => (
  <HStack as="span">
    <Box as="span">{title}</Box>
    {isLoading && (
      <Box as="span">
        <Spinner as="span" size="xs" />
      </Box>
    )}
  </HStack>
);
