import { defaultLayoutConfig } from '@web/apps/Design';
import { useDesignFilters } from '@web/apps/Design/hooks/useDesignFilters.tsx';
import { ControlPointData, DesignPartLayoutEdge, GroupedConductor, PartType } from '@web/apps/types';
import { Edge, EdgeLabelRenderer, EdgeProps, useReactFlow } from '@xyflow/react';
import { isEmpty, last, size } from 'lodash';

import { useContextMenuState } from '../../../../../../../components/menu/useContextMenuState';
import { getOverwrapPattern } from '../../../../Layout/utils/overwrapPatterns.tsx';
import { useLayoutEdgeDesignParts } from '../../../hooks/actions/useLayoutEdgeDesignParts.ts';
import { getRelationalMeasurementPaths } from '../RelationalMeasurementEdge/getRelationalMeasurementPaths';
import { RelationalMeasurementEdgeLabel } from '../RelationalMeasurementEdge/RelationalMeasurementEdgeLabel';
import { Algorithm } from './constants';
import { CustomSegmentPath } from './CustomSegmentPath';
import { getPath } from './path';
import { SegmentControlPoints } from './SegmentControlPoints';
import { SegmentEdgeContextMenu } from './SegmentEdgeContextMenu.tsx';
import { SegmentLabels } from './SegmentLabels.tsx';
import { usePathPoints } from './usePathPoints';

export type RelationalSegmentEdgeData = {
  designPartLayoutEdges: DesignPartLayoutEdge[];
  groupedConductors: GroupedConductor[];
  measurementHidden: boolean;
  distanceFromEdge: number;
  flipped: boolean;
  measurement: string;
  toleranceMinimum: string;
  toleranceMaximum: string;
  controlPoints: ControlPointData[];
};

export const defaultRelationalSegmentEdgeData: RelationalSegmentEdgeData = {
  designPartLayoutEdges: [],
  groupedConductors: [],
  measurementHidden: false,
  distanceFromEdge: 15,
  flipped: false,
  measurement: '',
  toleranceMinimum: '',
  toleranceMaximum: '',
  controlPoints: [],
};

export type RelationalSegmentEdgeType = Edge<RelationalSegmentEdgeData>;

export const RelationalSegmentEdge = ({
  id,
  selected,
  source,
  sourceX,
  sourceY,
  sourcePosition,
  target,
  targetX,
  targetY,
  targetPosition,
  markerEnd,
  markerStart,
  style,
  data = defaultRelationalSegmentEdgeData,
}: EdgeProps<RelationalSegmentEdgeType>) => {
  const {
    designPartLayoutEdges,
    groupedConductors,
    measurementHidden,
    distanceFromEdge,
    flipped,
    measurement,
    toleranceMinimum,
    toleranceMaximum,
    controlPoints,
  } = data;
  const { filters } = useDesignFilters();
  const { getNode } = useReactFlow();
  const { handleContextMenu, isOpen, position, closeMenu } = useContextMenuState();

  const algorithm = filters.bezierSegments ? Algorithm.BezierCatmullRom : Algorithm.Linear;

  const { getEdgeDesignPartsByType, getLayoutEdgesByType } = useLayoutEdgeDesignParts(designPartLayoutEdges);
  const overwraps = getEdgeDesignPartsByType(PartType.OVERWRAP);
  const { pattern, selectedPattern } = getOverwrapPattern(last(overwraps));
  const hasOverwraps = !isEmpty(overwraps);
  const labelEdges = getLayoutEdgesByType(PartType.LABEL);

  const color = selected ? defaultLayoutConfig.selectedEdgeColor : defaultLayoutConfig.edgeColor;
  const stroke =
    hasOverwraps && pattern && selectedPattern
      ? selected
        ? `url('#${pattern.patternId}-pattern')`
        : `url('#${selectedPattern.patternId}-pattern')`
      : color;

  const conductorsStrokeWidth = size(groupedConductors) > 1 ? 4 : 2;
  const strokeWidth = hasOverwraps ? 8 : conductorsStrokeWidth;

  const segmentStyle = {
    stroke: stroke,
    strokeWidth: strokeWidth,
  };

  const sourceNode = getNode(source);
  const targetNode = getNode(target);
  const shouldShowPoints = selected || sourceNode?.selected || targetNode?.selected;

  const pathPoints = usePathPoints({ sourceX, sourceY, targetX, targetY, controlPoints });

  const path = getPath(pathPoints, algorithm, {
    fromSide: sourcePosition,
    toSide: targetPosition,
  });

  const sourceXYPosition = { x: sourceX, y: sourceY };
  const targetXYPosition = { x: targetX, y: targetY };

  const hideMeasurement = measurementHidden || filters.measurementHidden;
  const { midpoint: measurementPosition } = getRelationalMeasurementPaths({
    sourcePosition: sourceXYPosition,
    targetPosition: targetXYPosition,
    distanceFromEdge: distanceFromEdge,
    flipped: flipped,
  });

  return (
    <>
      {pattern && selectedPattern && (
        <svg>
          <defs>
            {pattern.patternComponent}
            {selectedPattern.patternComponent}
          </defs>
        </svg>
      )}
      <CustomSegmentPath
        path={path}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{ ...style, ...segmentStyle }}
        onContextMenu={handleContextMenu}
      />
      {shouldShowPoints && (
        <SegmentControlPoints
          pathPoints={pathPoints}
          sourcePosition={sourcePosition}
          targetPosition={targetPosition}
          controlPoints={controlPoints}
          color={color}
          layoutEdgeId={id}
        />
      )}
      {!hideMeasurement && (
        <EdgeLabelRenderer>
          <RelationalMeasurementEdgeLabel
            layoutEdgeId={id}
            position={measurementPosition}
            measurement={measurement}
            toleranceMinimum={toleranceMinimum}
            toleranceMaximum={toleranceMaximum}
          />
        </EdgeLabelRenderer>
      )}
      {!filters.segmentLabelsHidden && (
        <SegmentLabels
          labelEdges={labelEdges}
          sourceXYPosition={sourceXYPosition}
          targetXYPosition={targetXYPosition}
        />
      )}
      <SegmentEdgeContextMenu
        isOpen={isOpen}
        position={position}
        closeMenu={closeMenu}
        layoutEdgeId={id}
        measurementFlipped={flipped}
        measurementHidden={measurementHidden}
        designPartLayoutEdges={designPartLayoutEdges}
      />
    </>
  );
};
