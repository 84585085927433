import { Badge, BadgeProps, HStack, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';

interface Props {
  partNumber: string;
  partRevision: string;
  badgeProps?: BadgeProps;
}

export const PartRevisionLabel = ({ partNumber, partRevision, badgeProps }: Props) => (
  <HStack spacing={2}>
    <Tooltip label={partNumber}>
      <Text isTruncated overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {partNumber}
      </Text>
    </Tooltip>
    {partRevision && (
      <Badge bg="orange.500" color="white" {...badgeProps} flexShrink={0}>
        REV {partRevision}
      </Badge>
    )}
  </HStack>
);
